import React from 'react';
import { FieldWrapperStl, LabelStl, ClearStl, ErrorStl } from './fieldWrapperStl.styled';
import { ReactComponent as Cleaner } from '../../../../src/img/icons/cross-gray.svg';

/**
 * Компонент FieldWrapper — обертка для полей формы, которая добавляет лэйбл, очистку поля и отображение ошибок.
 * @param {Object} props - Свойства компонента.
 * @param {Object} [props.error] - Объект ошибки валидации, который содержит сообщение об ошибке.
 * @param {string} [props.messagesError] - Сообщение об ошибке, которое выводится, если есть проблема с полем.
 * @param {string} [props.label] - Лэйбл для поля.
 * @param {JSX.Element} props.children - Вложенные элементы (например, поле ввода).
 * @param {Function} [props.onClear] - Функция, вызываемая для очистки значения поля.
 * @param {boolean} [props.clearCondition] - Условие, по которому показывается иконка очистки (если `true`).
 * @param {boolean} [props.disabled=false] - Флаг, отключающий поле, если `true`.
 * @param {boolean} [props.isRequired=false] - Флаг, который показывает обязательность поля (добавляет * к метке).
 * @param {boolean} [props.isPrice=false] - Флаг, который указывает на то, что поле используется для цены (отключает метку).
 *
 * @returns {JSX.Element} Возвращает обертку для поля формы с меткой, кнопкой очистки и сообщениями об ошибках.
 */
export const FieldWrapper = ({
    error,
    messagesError,

    crossSize,
    label,
    children,
    onClear,
    clearCondition,
    disabled,
    isRequired,
    isPrice = false,
}) => {
    const hasError = !!(error || messagesError);

    return (
        <FieldWrapperStl disabled={disabled} hasError={hasError}>
            {label && !isPrice && (
                <LabelStl>
                    {label}
                    {isRequired && <span className="fieldWraper__red">*</span>}
                </LabelStl>
            )}
            {children}
            {!disabled && clearCondition && clearCondition !== '+7 (9' && (
                <ClearStl size={crossSize} as={Cleaner} onClick={onClear} />
            )}
            <ErrorStl>{error?.message || messagesError}</ErrorStl>
            {/* этот вариант не подходит для форм в лк врача и пациента из-за смещения полей в случае, когда ошибка отсутствует */}
            {/* но подходит для инпутов в рекомендациях, отзывах и комментариях пациента.  */}
            {/* {(error?.message || messagesError) && <ErrorStl>{error?.message || messagesError}</ErrorStl>} */}
        </FieldWrapperStl>
    );
};
