import { NavLink, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import ArrowIcon from '../../share/ArrowIcon';
import { MainMenuStyled } from './mainMenu.styled';

/**
 * Компонент MainMenu отображает основное меню навигации.
 * Использует данные из массива naviData для генерации пунктов меню.
 *
 * @param {Array} naviData - Массив данных для пунктов меню. Каждый элемент включает такие поля, как `key`, `title`, `linkPathTo`, и необязательное поле `children` для подменю.
 */

export const MainMenu = ({ naviData = [], lastName }) => {
    return (
        <>
            {naviData.map((el) => (
                <MenuItem key={el.key} menuItem={el} lastName={lastName} />
            ))}
        </>
    );
};

/**
 * Компонент MenuItem отвечает за рендеринг каждого отдельного пункта меню.
 *
 * @param {Object} menuItem - Объект, представляющий данные одного пункта меню. Включает такие поля, как `key`, `title`, `linkPathTo`, и необязательное поле `children`.
 * @param {string} lastName - Фамилия пользователя для проверки необходимости активации пунктов меню.
 */

const MenuItem = ({ menuItem, lastName }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 700px)' });
    const [subMenuVisible, setSubMenuVisible] = useState(false);
    const hasChildren = Boolean(menuItem.children);
    const menuRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        // Проверка, активен ли пункт меню, для изменения состояния подменю
        if (menuRef.current) {
            setSubMenuVisible(menuRef.current.classList.contains('active'));
        }
    }, [pathname, lastName]);

    const toggleSubMenu = () => {
        if (menuItem.isActiveWithoutData) setSubMenuVisible((prev) => !prev);
    };

    const renderNavLink = () => (
        <NavLink ref={menuRef} className="nav-link nav-link__head-link" key={menuItem.key} to={menuItem.linkPathTo}>
            {menuItem.title}
        </NavLink>
    );

    const renderStaticSpan = (extraClass = '') => (
        <span
            onClick={hasChildren ? toggleSubMenu : undefined}
            className={`nav-link nav-link__head-link nav-link--static ${extraClass}`}
        >
            {menuItem.title}
            {hasChildren && menuItem.isActiveWithoutData && <ArrowIcon isOpen={subMenuVisible} />}
        </span>
    );

    return (
        <MainMenuStyled>
            {menuItem.isActiveWithoutData
                ? isTablet && hasChildren
                    ? renderStaticSpan()
                    : renderNavLink()
                : renderStaticSpan('nav-link--disabled')}

            {hasChildren && subMenuVisible && (
                <div className="nav-link__sub-link">
                    <MainMenu naviData={menuItem.children} lastName={lastName} />
                </div>
            )}
        </MainMenuStyled>
    );
};
