import styled from 'styled-components';

export const EmptyProfileConsultationStl = styled.div`
    padding: 50px 76px 40px 76px;
    max-width: 416px;
    background: rgb(255, 255, 255);
    border-radius: 24px;
    position: relative;
    @media (max-width: 320px) {
        width: 288px;
        padding: 50px 15px 40px 15px;
    }
    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__cross {
            border: none;
            position: absolute;
            background: none;
            top: 26px;
            right: 26px;
            cursor: pointer;
        }
        &__icon {
            height: 112px;
            @media (max-width: 320px) {
                height: 76px;
            }
        }

        &__content {
            margin-top: 40px;
            line-height: 26px;
            text-align: center;
            > span {
                font-size: 20px;
                color: #666666;
                @media (max-width: 320px) {
                    font-size: 18px;
                }
            }
            @media (max-width: 320px) {
                line-height: 24px;
                margin-top: 27px;
            }
        }
        &__btn {
            margin-top: 36px;
            width: 100%;
            display: flex;

            @media (max-width: 320px) {
                margin-top: 23px;
                width: 85%;
            }
            &-transition {
                @media (max-width: 320px) {
                    padding: 10px;
                    font-size: 14px;
                    text-align: centerж;
                }
            }
        }
    }
`;
