import LkDoctorNavigation from '../../lkDoctor/lkDoctorNavigation/lkDoctorNavigation';
import { LkPatientNavigation } from '../../lkPatient/lkPatientNavigation/lkPatientNavigation';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { fetchLogout } from '../../authentification/api/authentificationSlice';
// import PopUpConfirm from '../../share/PopUpConfirm';
// import { Modal } from '../../share/Modal';
// import { useState } from 'react';
import { LogoutButtonStl } from '../../header/header/header.styled';
import React from 'react';
import { UserNavigationStl } from './userNavigation.styled';
import { useSelector } from 'react-redux';
export const UserNavigation = ({ setPopUpOpen }) => {
    const { isAuth, whoAreYou } = useSelector((state) => state.authentification);

    return (
        <>
            <UserNavigationStl>
                {whoAreYou === 'patient' && <LkPatientNavigation />}
                {whoAreYou === 'doctor' && <LkDoctorNavigation />}
                {isAuth && (
                    <LogoutButtonStl onClick={() => setPopUpOpen(true)}>
                        <span>Выход</span>
                    </LogoutButtonStl>
                )}
            </UserNavigationStl>
        </>
    );
};
