import React from 'react';
import { RowArticleStyled } from './rowArticle.styled';
import { ReactComponent as AuthorIcon } from '../../../../img/newsImg/newsIcons/news-author-icon.svg';
import { ReactComponent as FormattedDate } from '../../../../img/newsImg/newsIcons/news-date-icon.svg';
import { ReactComponent as CommentsIcon } from '../../../../img/newsImg/newsIcons/news-comments-icon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { getDateForBlog } from '../../../share/helpers';

/**
 * Компонент RowArticle
 *
 * React-компонент для отображения статьи в строковом формате с информацией о заголовке, авторе, дате создания,
 * количеством комментариев и сокращенным текстом.
 *
 * @component
 * @param {Object} props - Свойства, переданные в компонент.
 * @param {Object} props.props - Объект с данными о статье.
 * @param {number} props.props.id - ID статьи.
 * @param {string} props.props.cover_img - URL изображения для превью статьи.
 * @param {string} props.props.title - Заголовок статьи.
 * @param {string} props.props.pre_text - Предварительный текст статьи.
 * @param {string} props.props.author - Автор статьи.
 * @param {string} props.props.created - Дата создания статьи.
 * @param {number} props.props.comments_count - Количество комментариев.
 * @param {boolean} [props.isFullTextContent=false] - Флаг для отображения полного текста статьи. По умолчанию отображается только сокращенный текст.
 *
 * @returns {JSX.Element} JSX-разметка компонента.
 * */

const maxCharacters = 170;
const minScreenWidth = 625;
const screenWidth = window.innerWidth;

export const RowArticle = ({ props, isFullTextContent }) => {
    const navigate = useNavigate();
    const { themeId } = useParams();

    function cutPre_text(text) {
        if (text.length > maxCharacters) {
            return screenWidth >= minScreenWidth ? text.substring(0, maxCharacters) + '...' : text;
        }
        return text;
    }

    function openArticle(postId) {
        navigate(`/news/${themeId}/article/${postId}`);
        window.scrollTo({ top: 0, left: 0 });
    }

    return (
        <RowArticleStyled fullSize={isFullTextContent} onClick={() => openArticle(props.id)}>
            <div className="previewPictureBlog">
                <img src={props.cover_img} alt="" />
            </div>
            <div className="blogContent">
                <div className="textContent">
                    <h2 className="contentTitle">{props.title}</h2>
                    {isFullTextContent ? <p className="contentPreText">{cutPre_text(props.pre_text)}</p> : ''}
                </div>
                <div className="bottomContentInfo">
                    <div className="infoBlock">
                        <AuthorIcon />
                        <p>{props?.author}</p>
                    </div>
                    <div className="infoBlock">
                        <FormattedDate />
                        <p>{getDateForBlog(props.created)}</p>
                    </div>
                    {props.comments_count > 0 && (
                        <div className="infoBlock">
                            <CommentsIcon />
                            <p>{props.comments_count}</p>
                        </div>
                    )}
                </div>
            </div>
        </RowArticleStyled>
    );
};
