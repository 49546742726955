import styled from 'styled-components';

export const ContactFormStl = styled.div`
    .subtitle {
        color: #666;
        font-size: 20px;
        margin-bottom: 35px;
    }

    fieldset {
        padding: 0;
        border: none;
        display: grid;
        max-width: 863px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
        column-gap: 20px;
        & > div:has(label textarea) {
            grid-column: 1/-1;
        }
        textarea {
            max-width: none;
        }
        input {
            max-width: none;
        }
    }

    button.btn {
        display: block;
        width: 245px;
        padding: 16px;
        background: #19be6f;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: white;
        cursor: pointer;
        transition: background 0.5s ease-in-out;
        &:disabled {
            background: #8AF0BF;
            cursor: auto;
        }
    }

    @media (max-width: 680px) {
        .subtitle {
            font-size: 18px;
            margin-bottom: 26px;
        }
        fieldset {
            display: block;
            margin-bottom: 7px;
            font-size: 14px;
            input {
                font-size: 14px;
            }
        }
    }
    @media (max-width: 380px) {
        button {
            width: 100%;
        }
    }
`;
