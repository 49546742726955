import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import { WorkList } from '../../shared/WorkList';

/**
 * Компонент для управления блоком опыта работы.
 * Позволяет добавлять, редактировать и удалять записи об опыте работы
 * с указанием организации, позиции, периода работы.
 *
 * @component
 * @returns {JSX.Element} JSX элемент компонента WorkExperience
 */

const WorkExperience = () => {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'data.work',
        control,
    });

    /**
     * Если массив fields пуст при первой отрисовке компонента,
     * в append добавляется пустой объект
     */
    useEffect(() => {
        if (fields.length === 0) {
            append({
                organization: { name: '' },
                position: { name: '' },
                work_from: '',
                work_to: '',
                until_now: false,
            });
        }
    }, [fields, append]);

    /**
     * Функция для добавления нового блока опыта работы.
     * Добавляет новый объект с пустыми значениями полей.
     */
    const onAdd = () => {
        const newBlock = {
            organization: { name: '' },
            position: { name: '' },
            work_from: '',
            work_to: '',
            until_now: false,
        };
        append(newBlock);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Опыт работы</h2>

            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <WorkList name={`data.work[${index}]`} />
                        {fields.length > 1 && (
                            <button
                                type="button"
                                className="button button_del button_border"
                                onClick={() => remove(index)}
                            >
                                Удалить блок
                            </button>
                        )}
                    </div>
                );
            })}
            <button type="button" className="button" onClick={() => onAdd()}>
                <span className="span">+</span> Добавить место работы
            </button>
        </NewFormSTL>
    );
};

export default WorkExperience;
