import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecializations } from './api/specializationsSlice';
import Select from '../share/formFields/newElements/Select';
import { validateRequired } from '../share/formFields/validateInput';

/**
 * Компонент `SelectSpecialization` представляет собой поле выбора специализации врача.
 * Загружает данные специализаций из API и отображает их в выпадающем списке.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {string} props.label Метка для поля выбора.
 * @param {boolean} props.isRequired Флаг, указывающий, является ли поле обязательным для заполнения.
 * @param {Array<{value: number, label: string}>} props.defaultValue Значение по умолчанию для выбранных специализаций.
 * @param {function} props.onChange Функция-обработчик, вызываемая при изменении значения.
 * @param {string} props.name Имя поля выбора (используется в формах).
 * @param {string} props.placeholder Текст подсказки, отображаемый в поле выбора.
 * @param {boolean} props.isMulti Флаг, указывающий, поддерживает ли поле выбор нескольких значений.
 *
 * @returns {JSX.Element} JSX-элемент, представляющий поле выбора специализаций.
 *
 * @example
 * <SelectSpecialization
 *   label="Специализация"
 *   isRequired={true}
 *   defaultValue={[{ value: 1, label: 'Терапия' }]}
 *   onChange={(value) => // console.log(value)}
 *   name="specialization"
 *   placeholder="Выберите специализацию"
 *   isMulti={true}
 * />
 */
const SelectSpecialization = ({ label, isRequired, defaultValue, onChange, name, placeholder, isMulti }) => {
    const dispatch = useDispatch();
    const specializations = useSelector((state) => state.specializations.specializations);

    /**
     * Эффект для загрузки данных специализаций при монтировании компонента.
     */
    useEffect(() => {
        const data = {
            limit: 50, // Максимальное количество специализаций для загрузки
            offset: 1, // Смещение для пагинации
            searchString: '', // Поисковая строка (пустая, чтобы получить все специализации)
        };
        dispatch(fetchSpecializations(data));
    }, []);

    return (
        <Select
            name={name}
            label={label}
            options={specializations?.map((spec) => ({ value: spec.id, label: spec.name }))}
            defaultValue={defaultValue}
            placeholder={placeholder}
            validation={{ required: validateRequired(isRequired) }}
            onChange={onChange}
            isRequired={isRequired}
            isMulti={isMulti}
            isClearable={true}
            isSearchable={true}
            radioButton={false}
        />
    );
};

export default SelectSpecialization;
