import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { EditDataList } from '../../shared/EditDataList';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { convertDoctorDataBirthday } from '../../../../lkDoctor/lkDoctorProfile/viewDoctor/helpers/convertDoctorDataBirthday';
import { fetchPatientDataUpdate } from '../../../api/PatientProfileSlice';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../componets/share/Button';
import { Loader } from '../../../../../componets/loader/loader';
import Spacer from '../../../../../componets/share/Spacer';
import { useFormDirty } from '../../../../../routes/FormDirtyContext';

/**
 * Компонент EditData предоставляет форму для редактирования личной информации пользователя.
 * Использует `react-hook-form` для управления состоянием формы, сохранения промежуточных данных и валидации.
 * Поддерживает функционал сохранения состояния формы в `sessionStorage` для предотвращения потери данных при перезагрузке страницы.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.user - Текущие данные пользователя для редактирования.
 * @param {string} props.user.id - Уникальный идентификатор пользователя.
 * @param {string} props.user.username - Имя пользователя.
 * @param {string} props.user.phone - Номер телефона пользователя.
 * @param {string} props.user.first_name - Имя пользователя.
 * @param {string} props.user.last_name - Фамилия пользователя.
 * @param {string} [props.user.middle_name] - Отчество пользователя (необязательно).
 * @param {string} props.user.date_birth - Дата рождения пользователя
 * @param {string} props.user.sex - Пол пользователя
 * @param {string} props.user.email - Электронная почта пользователя
 * @param {string} [props.user.city] - Город пользователя (необязательно).
 *
 * @returns {JSX.Element} Форма для редактирования данных пользователя.
 * Функционал:
 * - Автоматическое сохранение данных формы в `sessionStorage`.
 * - Сброс флага "грязного" состояния формы после успешного сохранения.
 * - Валидация данных формы перед отправкой.
 * - Поддержка изменения полей, таких как имя, фамилия, дата рождения и другие.
 *
 * @hook useMemo - Определяет начальные значения формы из данных пользователя или сохраненных данных.
 * @hook useForm - Управляет состоянием формы и её валидацией.
 * @hook useEffect - Реализует:
 *    - Сохранение данных формы в `sessionStorage` при каждом изменении.
 *    - Установку флага "грязного" состояния, если форма была изменена.
 *    - Очистку данных из `sessionStorage` после успешного сохранения.
 * @hook useCallback - Оптимизирует обработчики событий (например, изменения состояния формы).
 */

const EditDataComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { patientProfile: user } = useSelector((state) => state.patientProfile);
    const [isLoaderData, setIsLoaderData] = useState(false);
    const { setIsFormDirty } = useFormDirty();

    const savedData = useMemo(() => {
        const data = sessionStorage.getItem('formData');
        return data ? JSON.parse(data) : null;
    }, []);

    const defaultValues = useMemo(
        () =>
            savedData || {
                user: convertDoctorDataBirthday(user),
            },
        [savedData, user]
    );

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const { isValid, isSubmitting, isDirty } = useFormState({ control: methods.control });

    useEffect(() => {
        const subscription = methods.watch((value) => {
            sessionStorage.setItem('formData', JSON.stringify(value));
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    /** Установка флаг грязного состояния при изменении формы */
    const handleFormChange = useCallback(() => {
        setIsFormDirty(true);
    }, []);

    /** Сброс грязного состояния формы при успешной отправке */
    const resetFormDirty = useCallback(() => {
        setIsFormDirty(false);
    }, []);

    /**  Обновлене состояния грязной формы, если флаг isDirty изменился*/
    useEffect(() => {
        setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    const onSubmit = async ({ user: userSubmit }) => {
        setIsLoaderData(true);

        const user = {
            id: userSubmit.id,
            username: userSubmit.username,
            phone: userSubmit?.phone?.replace(/[\s()-]/g, ''),
            first_name: userSubmit.first_name,
            last_name: userSubmit.last_name,
            middle_name: userSubmit.middle_name ?? '',
            date_birth: userSubmit.date_birth,
            sex: userSubmit.sex,
            email: userSubmit.email,
            city: userSubmit.city?.value ?? userSubmit.city ?? '',
        };

        try {
            await dispatch(fetchPatientDataUpdate(user)).unwrap();
            resetFormDirty();
            sessionStorage.removeItem('formData');
            navigate('/lk/patient-profile/profile/view');
        } catch (error) {
            console.error('Ошибка при обновлении данных пациента:', error);
        } finally {
            setIsLoaderData(false);
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    if (!user.email) return <Loader />;

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} onChange={handleFormChange}>
                <div className="inputs">
                    <EditDataList />
                </div>
                <Spacer marginBottom="40px" />
                <Button
                    green
                    margin="0px"
                    font_size="16px"
                    type="submit"
                    disabled={!isValid || isSubmitting || isLoaderData}
                >
                    Сохранить
                </Button>
            </form>
        </FormProvider>
    );
};

const EditData = React.memo(EditDataComponent);
export default EditData;
