import React from 'react';
import { useSelector } from 'react-redux';
import { TitleStl } from '../title/titleStl.styled';
import { SectionStl } from '../viewDoctor.styled';
import { DoctorDeseasesStyled } from './DoctorDeseases.styled';

/**
 * Компонент для отображения информации о диагностике и лечении заболеваний.
 *
 * @component
 * @returns {JSX.Element} Компонент, отображающий заголовок и информацию о заболеваниях.
 *
 * @example
 * // Пример использования в компоненте
 * <DoctorDeseases />
 *
 * @description
 * Компонент извлекает данные о заболеваниях из Redux хранилища и отображает их
 * в формате HTML с использованием dangerouslySetInnerHTML для рендеринга HTML-контента.
 */

export const DoctorDeseases = () => {
    const diseases = useSelector((state) => state.doctorProfile?.doctorData?.diagnosis_and_treatment);
    const filledInText = diseases?.replace(/<[^>]*>/g, '').trim();

    return (
        <SectionStl>
            <TitleStl>
                <h2 className="title">Диагностика и лечение заболеваний</h2>
            </TitleStl>
            <DoctorDeseasesStyled>
                {filledInText ? (
                    <div dangerouslySetInnerHTML={{ __html: diseases }} />
                ) : (
                    'Вы не указали заболевания, которые вы лечите'
                )}
            </DoctorDeseasesStyled>
        </SectionStl>
    );
};
