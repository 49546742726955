import React from 'react';
import { StyledDoctorRecommendations } from './styledDoctorRecommendations.styled';
import { ReactComponent as Certificate } from '../../../../../../img/certificate.svg';

/**
 * Компонент для отображения рекомендаций врача.
 * Показывает текст рекомендаций и список прикрепленных файлов, если они существуют.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {Object} props.recommendation Объект рекомендации.
 * @param {string} [props.recommendation.text] Текст рекомендации.
 * @param {Array<Object>} [props.files] Список файлов, прикрепленных к рекомендации.
 * @param {number} props.files[].id Уникальный идентификатор файла.
 * @param {string} props.files[].file URL файла.
 * @returns {JSX.Element} Компонент рекомендаций врача.
 */
export const DoctorRecommendations = ({ recommendation, files }) => {
    const hasFiles = files && files.length > 0;

    const trimEnding = (url) => {
        const trimmedStr = typeof url === 'string' && url.split('.').slice(-1).toString();
        return trimmedStr;
    };
    return (
        <StyledDoctorRecommendations hasFiles={hasFiles}>
            <div className="comments">
                <p className="comments__title">Рекомендации</p>
                <p className="comments__text">{recommendation?.text}</p>

                {hasFiles && (
                    <div className="comments__files">
                        {files?.map((file) => (
                            <div className="comments__file" key={file.id}>
                                <a href={file.file} target="_blank" rel="noreferrer">
                                    <Certificate />
                                    <span className="file_name">Файл{`.${trimEnding(file.file)}`}</span>
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </StyledDoctorRecommendations>
    );
};
