import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';

/**
 * Компонент InputNameText для обработки ввода имени с валидацией.
 * Интегрируется с react-hook-form для управления состоянием формы и валидации.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля ввода, используется для управления состоянием формы.
 * @param {boolean} [props.isFullName=false] - Если true, применяются дополнительные форматы для ввода полного имени.
 * @param {boolean} [props.isRequired=false] - Указывает, является ли поле обязательным.
 * @param {string} [props.label] - Метка для отображения поля ввода.
 * @param {string} [props.placeholder] - Текст-подсказка для поля ввода.
 * @param {Object} [props.validation={}] - Пользовательские правила валидации для поля ввода.
 * @param {Function} [props.onChange] - Колбек-функция, которая вызывается при изменении ввода.
 * @param {RegExp} [props.deleteSpaces=null] - Необязательное регулярное выражение для удаления определенных пробелов в значении ввода.
 * @param {string} [props.value] - Начальное значение поля ввода.
 *
 * @returns {JSX.Element} Отрисованное поле ввода, обернутое в FieldWrapper.
 *
 */
const InputNameText = ({
    name,
    isFullName = false,
    isRequired = false,
    label,
    placeholder,
    validation = {},
    onChange: outSideOnChange = () => {},
    deleteSpaces = null,
    value,
}) => {
    const { control, trigger, setValue } = useFormContext();
    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^[\p{L}\-\s]*$/u,
            message: 'Поле может содержать символы алфавита, пробел, дефис',
        },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        ...validation,
        validate: {
            ...validation.validate,
        },
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    // Обновляет значение поля, если переданное извне `value` изменилось.  
    // Это необходимо для синхронизации состояния с данными из sessionStorage или при обновлении формы.
    useEffect(() => {
        if (value !== undefined && value !== field.value) {
            setValue(name, value);
        }
    }, [value]);

    const handleBlur = () => {
        let inputValue = field.value || '';
        if (typeof inputValue === 'string') {
            inputValue = inputValue
                .trim()
                .replace(/ +/g, ' ')
                .replace(/-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '');
            if (isFullName && inputValue) {
                inputValue = inputValue
                    ?.split(/([-\s])/g)
                    .map((word) => word[0]?.toUpperCase() + word.slice(1))
                    .join('');
            }
            if (deleteSpaces) {
                inputValue = inputValue?.replace(deleteSpaces, '');
            }
        }
        trigger(field.name);
        field.onChange(inputValue);
    };
    const clearedView = '';
    return (
        <FieldWrapper
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={() => {
                field.onChange(clearedView);
                outSideOnChange(clearedView);
            }}
        >
            <input
                {...field}
                value={field.value || ''}
                onBlur={handleBlur}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="text"
                onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger(field.name);
                    outSideOnChange(e.target.value);
                }}
            />
        </FieldWrapper>
    );
};

export default InputNameText;
