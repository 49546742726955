import { FieldWrapper } from './FieldWrapper';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

/**
 * Компонент InputEmail — текстовое поле для ввода email с валидацией.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для формы.
 * @param {string} props.label - Лэйбл поля (текст, который отображается рядом с полем).
 * @param {string} props.placeholder - Текст подсказки, который отображается в поле до ввода данных.
 * @param {string} [props.defaultValue=''] - Значение поля по умолчанию.
 * @param {Object} [props.validation={}] - Дополнительные правила валидации.
 * @param {Function} [props.onChange=() => {}] - Функция, которая вызывается при изменении значения поля.
 * @param {Function} props.trigger - Функция для принудительной проверки валидации формы.
 * @param {Object} props.control - Контроллер react-hook-form для управления полем.
 * @param {Object} props.messagesError - Сообщения об ошибках, которые могут быть переданы для отображения.
 *
 * @returns {JSX.Element} Возвращает компонент текстового поля для ввода email с валидацией.
 */
const InputEmail = ({
    name,
    label,
    placeholder,
    isRequired,
    defaultValue = '',
    validation = {},
    onChange = () => {},
    messagesError,
    onClearError,
    errorKey,
}) => {
    const { control, trigger, setValue } = useFormContext();

    const validateEmail = (value) => {
        let errors = [];

        if (!value) return 'Поле обязательно для заполнения';

        const allowedChars = /^[a-zA-Z0-9._\-@]+$/;

        if (!allowedChars.test(value)) {
            errors.push('Используйте буквы латинского алфавита или . - _ @');
        }

        if (!value.includes('@')) {
            errors.push('Email должен содержать символ @');
        }

        const atCountInEmail = (value.match(/@/g) || []).length;
        if (atCountInEmail > 1) {
            errors.push('Email должен содержать только один символ @');
        }

        const atIndex = value.indexOf('@');
        const name = value.slice(0, atIndex);
        const domain = value.slice(atIndex + 1);

        if (name.length === 0) {
            errors.push('До символа @ должен быть хотя бы один символ');
        }

        if (domain.length === 0) {
            errors.push('После символа @ должен быть хотя бы один символ');
        }

        if (!domain.includes('.')) {
            errors.push('После символа @ должна быть хотя бы одна точка');
        }

        if (domain.includes('..')) {
            errors.push('Две точки подряд в домене недопустимы');
        }

        if (domain.includes('.')) {
            const domainParts = domain.split('.');
            const tld = domainParts.length > 1 ? domainParts[domainParts.length - 1] : '';
            if (tld.length < 2) {
                errors.push('Домен должен содержать не менее 2 символов');
            }
        }

        if (value.length < 6) {
            errors.push('Минимальная длина: 6 символов');
        }

        if (value.length > 70) {
            errors.push('Максимальная длина: 70 символов');
        }

        return errors.length ? errors[0] : true;
    };

    const defaultValidation = {
        required: { value: true, message: 'Поле обязательно для заполнения' },
        validate: validateEmail,
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });

    const clearedView = '';

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (messagesError) {
            onClearError(errorKey);
        }
        setValue(e.target.name, value, { shouldValidate: true });
        trigger(e.target.name);
    };

    const handleClear = () => {
        field.onChange(clearedView);
        onChange(clearedView);
        trigger(field.name);
        onClearError(errorKey);
    };

    return (
        <FieldWrapper
            error={error}
            messagesError={messagesError}
            label={label}
            clearCondition={field.value !== clearedView}
            isRequired={isRequired}
            onClear={handleClear}
        >
            <input
                {...field}
                placeholder={placeholder}
                id={name}
                type="email"
                autoComplete="email"
                onChange={(e) => handleInputChange(e)}
                onKeyDown={handleKeyDown}
            />
        </FieldWrapper>
    );
};

export default InputEmail;
