import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { StyledRecommendationForm } from './recommendationForm.styled';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { fetchDoctorConsultation, sendRecommendation } from '../../api/lkDoctorConsultationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ConsultationFiles } from '../consultationList/doctorConsultationPage/consultationFiles/ConsultationFiles';
import { useFormDirty } from '../../../../routes/FormDirtyContext';
import { Spinner } from '../../../share/Spinner';
import { Modal } from '../../../share/Modal';
import { SendRecommendationModal } from '../../lkDoctorModals/sendRecommendationModal/SendRecommendationModal';
import { SymbolCountTextArea } from '../../../share/symbolCountTextArea/SymbolCountTextArea';
import { ReactComponent as LowAttentionSvg } from '../../../../img/icons/icon_low_attention.svg';

/**
 * Компонент формы рекомендаций врача для пациента.
 *
 * @param {Object} props - Свойства компонента.
 * @param {number} props.consultationId - Уникальный идентификатор консультации.
 * @param {string} [props.recommendation] - Текст текущих рекомендаций.
 * @param {Array} props.doctorFiles - Список файлов, прикрепленных врачом.
 * @param {Array} props.progress_files - Список файлов в процессе загрузки.
 * @param {string} props.status - Статус консультации.
 * @returns {JSX.Element} - Компонент формы рекомендаций.
 */
export const RecommendationFormComponent = ({
    consultationId,
    recommendation,
    doctorFiles,
    progress_files,
    status,
}) => {
    const isLoading = useSelector((state) => state.patientConsultations.isLoading);
    const [isLoaderData, setIsLoaderData] = useState(false);
    const [sendRecommendationConfirm, setSendRecommendationConfirm] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const dispatch = useDispatch();
    const { setIsFormDirty } = useFormDirty();

    /**
     * Извлечение сохраненных данных формы из `sessionStorage`.
     * Используется для восстановления введенных данных после обновления страницы.
     * @type {Object|null}
     */
    const savedData = useMemo(() => {
        const data = sessionStorage.getItem('formData');
        return data ? JSON.parse(data) : null;
    }, []);

    /**
     * Значения по умолчанию для формы.
     * @type {Object}
     */
    const defaultValues = useMemo(() => savedData || '', [savedData]);

    /**
     * Методы управления формой из React Hook Form.
     */
    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const { isValid, isSubmitting, isDirty } = useFormState({ control: methods.control });

    /**
     * Сохранение данных формы в `sessionStorage` при любом изменении значений.
     */
    useEffect(() => {
        const subscription = methods.watch((value) => {
            sessionStorage.setItem('formData', JSON.stringify(value));
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    /**
     * Установка флага грязного состояния формы при изменении данных.
     */
    const handleFormChange = useCallback(() => {
        setIsFormDirty(true);
    }, []);

    /**
     * Сброс флага грязного состояния формы.
     */
    const resetFormDirty = useCallback(() => {
        setIsFormDirty(false);
    }, []);

    /**
     * Обновлене состояния грязной формы, если флаг isDirty изменился
     */
    useEffect(() => {
        setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    /**
     * Обработчик отправки формы.
     * @param {Object} data - Данные формы, содержащие комментарий пользователя.
     * @param {string} data.inputRecommendation - Текст рекомендаций врача.
     */
    const onSubmit = async ({ inputRecommendation }) => {
        setIsLoaderData(true);

        const data = {
            consultationId: consultationId,
            text: inputRecommendation,
        };

        try {
            await dispatch(sendRecommendation(data)).unwrap();
            resetFormDirty();
            sessionStorage.removeItem('formData');
            dispatch(fetchDoctorConsultation(consultationId));
        } catch (error) {
            console.error('Ошибка при отправке рекомендаций:', error);
        } finally {
            setIsLoaderData(false);
            setSendRecommendationConfirm(false);
        }
    };

    /**
     * Открытие модального окна подтверждения отправки рекомендаций.
     * @param {React.MouseEvent} e - Событие клика.
     */
    const openSendRecommendationModal = (e) => {
        e.stopPropagation();
        setSendRecommendationConfirm(true);
    };

    return (
        <StyledRecommendationForm>
            <FormProvider {...methods}>
                <form className="recommendationForm" onChange={handleFormChange}>
                    <SymbolCountTextArea
                        countSymbol={5000}
                        title="Рекомендации"
                        placeholder="Оставьте пациенту рекомендации по лечению."
                        name="inputRecommendation"
                    />
                    <ConsultationFiles
                        consultationId={consultationId}
                        files={doctorFiles}
                        status={status}
                        progress_files={progress_files}
                        recommendation={recommendation}
                    />

                    <div className="attention">
                        <LowAttentionSvg className="lowAttention" />
                        <span className="submitComment">
                            Комментарий и прикреплённые файлы нельзя будет изменить после отправки
                        </span>
                    </div>

                    <button
                        className="recommendationForm__button"
                        type="button"
                        onClick={openSendRecommendationModal}
                        disabled={!isValid || isSubmitting || isLoaderData}
                    >
                        {isLoading ? (
                            <Spinner color={'2px solid green'} width={'20px'} height={'20px'} marginTop={'0'} />
                        ) : (
                            'Подписать'
                        )}
                    </button>

                    {sendRecommendationConfirm && (
                        <Modal toggle={setSendRecommendationConfirm}>
                            <SendRecommendationModal
                                setSendRecommendationConfirm={setSendRecommendationConfirm}
                                sendFiles={methods.handleSubmit(onSubmit)}
                            />
                        </Modal>
                    )}
                </form>
            </FormProvider>
        </StyledRecommendationForm>
    );
};

/**
 * Обертка для оптимизации повторных рендеров компонента рекомендаций.
 */
export const RecommendationForm = React.memo(RecommendationFormComponent);
