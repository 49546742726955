import React from 'react';
import styled, { css } from 'styled-components';

/**
 * Настраиваемый компонент Button, стилизованный с помощью `styled-components`.
 *
 * @component
 * @example
 * // Пример использования:
 * <Button green S>Нажми меня</Button>
 *
 * @param {object} props - Свойства для компонента кнопки.
 * @param {string} [props.display='inline-block'] - CSS-свойство display для кнопки.
 * @param {string} [props.align='unset'] - Выравнивание содержимого кнопки.
 * @param {string} [props.justify='unset'] - Выравнивание содержимого кнопки по горизонтали.
 * @param {string} [props.font_size='20px'] - Размер шрифта текста кнопки.
 * @param {string} [props.color=theme.color.green.light] - Цвет текста кнопки.
 * @param {string} [props.background_color=theme.color.white] - Цвет фона кнопки.
 * @param {string} [props.border=theme.color.green.light] - Цвет рамки кнопки.
 * @param {string} [props.width='100%'] - Ширина кнопки.
 * @param {string} [props.height='60px'] - Высота кнопки.
 * @param {string} [props.margin='17px'] - Отступ вокруг кнопки.
 * @param {string} [props.padding='17px'] - Внутренний отступ кнопки.
 * @param {boolean} [props.green=false] - Если `true`, применяет стиль зеленой кнопки из темы.
 * @param {boolean} [props.white=false] - Если `true`, применяет стиль белой кнопки из темы.
 * @param {boolean} [props.XXS=false] - Если `true`, применяет стиль кнопки XXS.
 * @param {boolean} [props.XS=false] - Если `true`, применяет стиль кнопки XS.
 * @param {boolean} [props.S=false] - Если `true`, применяет стиль кнопки S.
 * @param {boolean} [props.M=false] - Если `true`, применяет стиль кнопки M.
 * @param {boolean} [props.L=false] - Если `true`, применяет стиль кнопки L.
 * @param {boolean} [props.XL=false] - Если `true`, применяет стиль кнопки XL.
 * @param {boolean} [props.XXL=false] - Если `true`, применяет стиль кнопки XXL.
 * @param {boolean} [props.XXXL=false] - Если `true`, применяет стиль кнопки XXXL.
 * @param {boolean} [props.XXXXL=false] - Если `true`, применяет стиль кнопки XXXXL.
 * @param {boolean} [props.XXXXXL=false] - Если `true`, применяет стиль кнопки XXXXXL.
 * @param {string} [props.hoverBorder] - Цвет рамки при наведении или фокусировке на кнопке.
 * @param {string} [props.hoverBGColor] - Цвет фона при наведении или фокусировке на кнопке.
 * @param {string} [props.hoverColor] - Цвет текста при наведении или фокусировке на кнопке.
 * @param {string} [props.activeBorder] - Цвет рамки, когда кнопка активна.
 * @param {string} [props.activeBGColor] - Цвет фона, когда кнопка активна.
 * @param {string} [props.activeColor] - Цвет текста, когда кнопка активна.
 * @param {boolean} [props.disabled=false] - Если `true`, кнопка отключена.
 *
 * @returns {React.ReactElement} Стилизованный элемент кнопки.
 */

const theme = {
    color: {
        green: {
            dark: '#126D42',
            medium: '#169056',
            light: '#19BE6F',
            ultralight: '#8AF0BF',
        },
        white: '#FFFFFF',
    },
};
// Ожидаемый результат: цвет background: #8AF0BF (opacity: 1), цвет текста: #FFFFFF (opacity: 1)
// Фактический результат: цвет background: #С9FCE3 (opacity: 0,4), цвет текста: #126D42 (opacity: 0,4).
const ButtonStyle = styled.button`
    display: ${(props) => props.display || 'inline-block'};
    align-items: ${(props) => props.align || 'unset'};
    justify-content: ${(props) => props.justify || 'unset'};
    border-radius: 10px;
    font-family: inherit;
    font-style: normal;
    font-weight: ${(props) => (props.weight || '500')};
    font-size: ${(props) => props.font_size || '20px'};
    line-height: 130%;
    color: ${(props) => props.color || theme.color.green.light};
    background-color: ${(props) => props.background_color || theme.color.white};
    border: 2px solid ${(props) => props.border || theme.color.green.light};
    padding: 17px;
    transition: opacity 0.5s linear;
    cursor: pointer;
    width: ${(props) => props?.width || '100%'};
    height: ${(props) => props.height || '60px'};
    margin: ${(props) => props.margin || '17px'};
    padding: ${(props) => props.padding || '17px'};
    disabled: ${(props) => props.disabled || false};
    ${(props) =>
        props.green &&
        css`
            background-color: ${theme.color.green.light};
            border: 2px solid ${theme.color.green.light};
            color: ${theme.color.white};
            transition: all 0.3s ease-in-out;
            &:hover,
            &:focus {
                transition: all 0.3s ease-in-out;
                background-color: ${theme.color.green.medium};
                border: 2px solid ${theme.color.green.medium};
            }
            &:active {
                background-color: ${theme.color.green.dark};
                border: 2px solid ${theme.color.green.dark};
            }
            &:disabled {
                cursor: auto;
                background-color: ${theme.color.green.ultralight};
                border: 2px solid ${theme.color.green.ultralight};
                color: ${theme.color.green.dark};
                &:hover {
                    border: 2px solid ${theme.color.green.ultralight};
                }
            }
        `}

    ${(props) =>
        props.white &&
        css`
            background-color: ${theme.color.white};
            border: 2px solid ${theme.color.green.light};
            color: ${theme.color.green.light};
            transition: all 0.3s ease-in-out;
            &:hover,
            &:focus {
                color: ${theme.color.green.medium};
                border: 2px solid ${theme.color.green.medium};
            }
            &:active {
                color: ${theme.color.green.dark};
                border: 2px solid ${theme.color.green.medium};
                background-color: ${theme.color.green.ultralight};
            }
            &:disabled {
                cursor: auto;
                background-color: ${theme.color.green.ultralight};
                border: 2px solid ${theme.color.green.ultralight};
                color: ${theme.color.green.dark};
                &:hover {
                    border: 2px solid ${theme.color.green.ultralight};
                }
            }
        `}

  ${(props) =>
        props.XXS &&
        css`
            width: 103px;
            padding: 9.5px;
            font-size: 16px;
        `}

  ${(props) =>
        props.XS &&
        css`
            width: 133px;
            padding: 3px;
            font-size: 16px;
        `}

  ${(props) =>
        props.S &&
        css`
            width: 180px;
            padding: 17px;
        `}

  ${(props) =>
        props.M &&
        css`
            width: 227px;
            padding: 9.5px;
        `}

  ${(props) =>
        props.L &&
        css`
            width: 245px;
            padding: 17px;
        `}

  ${(props) =>
        props.XL &&
        css`
            width: 271px;
            padding: 17px;
        `}

  ${(props) =>
        props.XL &&
        css`
            width: 301px;
            padding: 12px;
        `}

  ${(props) =>
        props.XXL &&
        css`
            width: 334px;
            padding: 17px;
        `}

  ${(props) =>
        props.XXXL &&
        css`
            width: 406px;
            padding: 14px;
        `}

  ${(props) =>
        props.XXXXL &&
        css`
            width: 470px;
            padding: 17px;
        `}

  ${(props) =>
        props.XXXXXL &&
        css`
            width: 510px;
            padding: 17px;
        `}

  &:hover, &:focus {
        transition: all 0.3s ease-in-out;
        border: 2px solid ${(props) => props.hoverBorder || ''};
        background-color: ${(props) => props.hoverBGColor || ''};
        color: ${(props) => props.hoverColor || ''};
    }
    &:active {
        border: 2px solid ${(props) => props.activeBorder || ''};
        background-color: ${(props) => props.activeBGColor || ''};
        color: ${(props) => props.activeColor || ''};
    }
    &:disabled {
        color: ${theme.color.white};
        opacity: 1;
        cursor: auto;
        font-weight: 500;
    }
`;

const Button = (props) => {
    return <ButtonStyle {...props} />;
};
export default Button;
