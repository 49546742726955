import React from 'react';
import { useSelector } from 'react-redux';
import { TitleStl } from '../title/titleStl.styled';
import { ViewEducation } from './ViewEducation';
import { SectionStl } from '../viewDoctor.styled';

/**
 * Компонент для отображения блока с информацией об образовании доктора.
 *
 * @component
 * @returns {JSX.Element} Компонент секции с информацией об образовании.
 *
 * @example
 * // Пример использования в компоненте
 * <DoctorEducation />
 *
 * @description
 * Компонент отображает заголовок "Образование" и вызывает компонент `ViewEducation`,
 * передавая ему данные об образовании, полученные из состояния Redux.
 */

export const DoctorEducation = () => {
    const education = useSelector((state) => state.doctorProfile?.doctorData?.education);

    return (
        <SectionStl>
            <TitleStl>
                <h2 className="title">Образование</h2>
            </TitleStl>
            <ViewEducation education={education} />
        </SectionStl>
    );
};
