import styled from 'styled-components';

export const ModerationLabelStl = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;

    .text {
        color: #19be6f;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
`;
