import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { StyledLkDoctorProfile } from './lkDoctorProfile.styled';
import { fetchAllDoctorData, fetchDeleteMyAvatar, fetchLoadMyAvatar } from '../api/doctorUserProfileSlice';
import { Loader } from '../../loader/loader';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '../../avatar/avatar';

/**
 * Компонент LkDoctorProfile управляет профилем врача в личном кабинете.
 *
 * В этом компоненте происходит:
 * - Запрос и отображение данных профиля врача, включая аватар.
 * - Редирект с главного пути `/lk/doctor-profile/profile` на страницу просмотра профиля.
 *
 * Если профиль врача загружается, отображается индикатор загрузки (`Loader`).
 *
 * @component
 * @returns {JSX.Element} Возвращает JSX с аватаром и данными профиля врача.
 *
 * @hook useLocation Хук для получения текущего пути.
 * @hook useNavigate Хук для перенаправления на другую страницу.
 * @hook useSelector Хук для получения состояния профиля врача и индикатора загрузки из Redux.
 * @hook useEffect Выполняет побочные эффекты при изменении пути и загрузке данных.
 *
 * @example
 * // Пример использования компонента
 * <LkDoctorProfile />
 */

/**
 * Логика отображения и управления аватаром вынесена в отдельный компонент для переиспользования.
 * Используется как для личного кабинета пациента, так и для личного кабинета врача.
 *
 * @see Avatar
 */

const LkDoctorProfile = () => {
    const path = useLocation();
    const redirect = useNavigate();
    const { doctorData, isLoader } = useSelector((state) => state.doctorProfile);

    useEffect(() => {
        if (!doctorData) dispatch(fetchAllDoctorData());
    }, []);

    useEffect(() => {
        if (path.pathname === '/lk/doctor-profile/profile') {
            redirect('/lk/doctor-profile/profile/view', { replace: true });
        }
    }, [path, doctorData]);

    // Отображение загрузчика, если email отсутствует.
    if (!doctorData.user.email) {
        return <Loader />;
    }

    return (
        <StyledLkDoctorProfile>
            <Avatar
                userProfile={doctorData.user}
                fetchDeleteMyAvatar={fetchDeleteMyAvatar}
                fetchLoadMyAvatar={fetchLoadMyAvatar}
            />
            <div className="data">
                <Outlet />
            </div>
        </StyledLkDoctorProfile>
    );
};

export default LkDoctorProfile;
