import React, { useEffect } from 'react';
import { fetchRemoveFavoriteDoctorUid, fetchSetFavoriteDoctor } from '../../../lkPatient/api/favoriteDoctorsSlice';
import { ReactComponent as CategoryIcon } from '../../../../img/icons/newDoctorIcons/category.svg';
import { ReactComponent as Clock } from '../../../../img/icons/newDoctorIcons/clock.svg';
import { ReactComponent as EducationIcon } from '../../../../img/icons/newDoctorIcons/education.svg';
import { ReactComponent as Geo } from '../../../../img/icons/newDoctorIcons/geo.svg';
import { ReactComponent as LikeEmpty } from '../../../../img/icons/doctorProfilIcons/like_profile_empty.svg';
import { ReactComponent as LikeFilled } from '../../../../img/icons/doctorProfilIcons/like_profile.svg';
import { Spinner } from '../../../share/Spinner';
import { ReactComponent as StarIcon } from '../../../../img/icons/newDoctorIcons/star.svg';
import { StyledDoctorCardProfile } from './doctorCardProfile.styled';
import { fetchGetDoctor } from '../../api/doctorsSlise';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import PhotoAlt from '../../../../img/photo_alt.svg';
import { getScientificDegree, getDoctorCategory, getSeniorityWithEnd } from '../../../share/helpers';
import { DoctorVerification } from '../../../doctorProfile/doctorVerification/doctorVerification';
import AvatarDefault from '../../../../img/avatar_default.png';

/**
 * Компонент DoctorCardProfile.
 * Отображает профиль врача, включая фото, информацию о специализации, стаже, рейтинге и текущем месте работы.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.doctor - Объект с данными врача.
 * @param {Object} props.doctor.user - Пользовательские данные врача.
 * @param {string} props.doctor.user.last_name - Фамилия врача.
 * @param {string} props.doctor.user.first_name - Имя врача.
 * @param {string} props.doctor.user.middle_name - Отчество врача.
 * @param {string} [props.doctor.user.image_standart] - URL-адрес изображения врача.
 * @param {Array<Object>} [props.doctor.specialization] - Массив специализаций врача.
 * @param {string} [props.doctor.specialization.name] - Название специализации.
 * @param {number} [props.doctor.specialization.id] - Уникальный идентификатор специализации.
 * @param {Array<Object>} [props.doctor.work] - Массив объектов работы врача.
 * @param {boolean} props.doctor.work.until_now - Индикатор текущего места работы.
 * @param {number} props.doctor.seniority - Стаж врача в годах.
 * @param {number} [props.doctor.rating_average] - Средний рейтинг врача.
 * @param {number} props.doctor.category - Категория врача.
 * @param {number} props.doctor.scientific_degree - Научная степень врача.
 * @param {boolean} props.isVerified - Индикатор верификации врача для передачи в компонент DoctorVerification для отображения в Профиле врача (только там).
 * @returns {JSX.Element} Компонент профиля врача.
 */

export const DoctorCardProfile = ({ doctor, isVerified, isOurDoctors, isProfile }) => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    /**
     * Форматированное отображение специализаций врача.
     * Если специализации не указаны, отображается сообщение "Доктор не указал специализацию".
     * @type {JSX.Element|string}
     */
    const specialization = doctor?.specialization?.length
        ? doctor.specialization.map((spec) => {
            return <li key={spec.name}>{spec.name}</li>;
        })
        : <li>Доктор не указал специализацию</li>;

    /**
     * Объект текущего места работы врача, если таковое имеется.
     * @type {Object|undefined}
     * @property {string} jobUntilNow.organization.name - Название текущей организации.
     */
    const jobUntilNow = doctor?.work?.find((job) => job.until_now);

    /**
     * URL-адрес изображения врача. Использует изображение по умолчанию, если изображение врача отсутствует.
     * @type {string}
     */
    const avatar = doctor.user?.image_standart ? doctor.user?.image_standart : AvatarDefault;

    /**
     * Научная степень врача, отображаемая в текстовом формате: "0" - "нет", "1" - "кандидат медицинских наук", "2" - "доктор медицинских наук")
     * @type {string|undefined}
     */
    const scientific_degree = getScientificDegree(doctor.scientific_degree);

    return (
        <StyledDoctorCardProfile isOurDoctors={isOurDoctors} isProfile={isProfile}>
            <div className="doctorPhoto">
                <img className={'doctorMainPhoto'} src={avatar} alt="doctor" />
                {isVerified && <DoctorVerification />}
            </div>
            <div className={'doctorName'}>
                <p className={'name'}>
                    {doctor.user.last_name} {(isOurDoctors || isProfile) && <br />}{' '}
                    {doctor.user.first_name + ' ' + doctor.user.middle_name}
                </p>
                <ul className={'specialization'}>{specialization}</ul>
            </div>
            <div className={'about'}>
                <div className={'seniority mainFlex clock'}>
                    <Clock />
                    <span className={'infoText starIcon'}>Стаж — {getSeniorityWithEnd(doctor.seniority)}</span>
                    {!!doctor.rating_average && (
                        <>
                            <StarIcon />
                            <span className={'infoText star'}>{Number(doctor.rating_average).toFixed(1)}</span>
                        </>
                    )}
                </div>
                {doctor.category !== 0 && (
                    <div className={'mainFlex categoryIcon'}>
                        <CategoryIcon />
                        <span className={'infoText'}>{getDoctorCategory(doctor.category)}</span>
                    </div>
                )}
                {doctor.scientific_degree !== 0 && (
                    <div className={'mainFlex educationIcon'}>
                        {scientific_degree && (
                            <>
                                <EducationIcon />
                                <span className={'infoText'}>{scientific_degree}</span>
                            </>
                        )}
                    </div>
                )}
                {jobUntilNow && (
                    <div className={'mainFlex geo'}>
                        <Geo />
                        <span className={'infoText'}>{jobUntilNow?.organization.name}</span>
                    </div>
                )}
            </div>
        </StyledDoctorCardProfile>
    );
};
