import {
    AuthMe,
    checkEmail,
    checkEmailCode,
    registerUser,
    loginUser,
    meUser,
    deleteUser,
    dataUpdate,
} from './authentificationApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetDoctorConsultations } from '../../lkDoctor/api/lkDoctorConsultationsSlice';
import { resetPatientConsultations } from '../../lkPatient/api/PatientConsultationsSlice';
import { fetchPatientProfile, clearPatientState } from '../../lkPatient/api/PatientProfileSlice';
import { fetchAllDoctorData, clearDoctorState } from '../../lkDoctor/api/doctorUserProfileSlice';
import { formatErrorMessage, formatNullData } from '../../../componets/share/helpers';

/**
 * Асинхронное действие для выполнения логина пользователя с последующим получением данных пользователя.
 * Сохраняет токен доступа в `sessionStorage` и токен обновления в `localStorage` при успешной аутентификации.
 * @function fetchLogin
 * @async
 * @param {Object} data Данные для входа пользователя.
 * @param {string} data.email Email пользователя.
 * @param {string} data.password Пароль пользователя.
 * @returns {Promise<Object>} Ответ от сервера. В случае успеха возвращает объект с токеном и данными пользователя, в случае ошибки - ошибку.
 */
export const fetchLogin = createAsyncThunk(
    'authentification/fetchLogin',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await loginUser(data);
            if (response.data.access) {
                sessionStorage.setItem('token', response.data.access);
                localStorage.setItem('refresh', response.data.refresh);
                // console.log('token', response.data.access);
            }

            if (response.status === 200) {
                // После успешного логина загружаем данные пользователя
                await dispatch(fetchMeUser());
                return response;
            }
        } catch (error) {
            console.error('error in login thunk', error);
            return rejectWithValue(error);
        }
    }
);

/**
 * Асинхронное действие для регистрации нового пользователя.
 * После успешной регистрации автоматически вызывает логин для нового пользователя и сохраняет токены.
 * @function fetchRegister
 * @async
 * @param {Object} data Данные пользователя для регистрации.
 * @param {string} data.username Имя пользователя.
 * @param {string} data.email Email пользователя.
 * @param {string} data.role Роль пользователя: пациент или доктор
 * @param {string} data.password Пароль пользователя.
 * @param {function} dispatch Функция диспетчера для вызова других действий.
 * @returns {Promise<Object>} Ответ от сервера. В случае успеха возвращает данные о зарегистрированном пользователе, в случае ошибки - ошибку.
 */
export const fetchRegister = createAsyncThunk(
    'authentification/fetchRegister',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await registerUser(data);
            if (response.status === 201) {
                // После успешной регистрации вызываем логин
                const loginData = {
                    email: data.email,
                    password: data.password,
                };
                await dispatch(fetchLogin(loginData));
            }
            return response;
        } catch (error) {
            console.error('Registration error', error);
            return rejectWithValue(error);
        }
    }
);

/**
 * Асинхронное действие для выполнения логаута пользователя.
 * Очищает данные в `localStorage` и `sessionStorage`, а также сбрасывает состояние консультаций для доктора и пациента.
 * @function fetchLogout
 * @async
 * @param {function} dispatch Функция диспетчера для вызова других действий.
 * @returns {Promise<void>} Ничего не возвращает, просто выполняет сброс состояния.
 */
export const fetchLogout = createAsyncThunk('authentification/fetchLogout', async (_, { dispatch }) => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(resetDoctorConsultations());
    dispatch(resetPatientConsultations());
    dispatch(clearDoctorState());
    dispatch(clearPatientState());
    dispatch(clearUserState());
});

/**
 * Асинхронное действие для получения информации о текущем пользователе.
 * Выполняет запрос к серверу для получения данных о пользователе, который уже прошёл аутентификацию.
 * Если роль пользователя - пациент, загружает профиль пациента; если доктор - (метод ещё не реализован).
 * @function fetchMeUser
 * @async
 * @returns {Promise<Object>} Ответ от сервера с информацией о текущем пользователе. В случае успеха возвращает данные пользователя, в случае ошибки - ошибку.
 */
export const fetchMeUser = createAsyncThunk('authentification/fetchMeUser', async (_, { dispatch }) => {
    const response = await meUser();
    const userRole = response.role;

    if (userRole === 'patient') {
        await dispatch(fetchPatientProfile());
    } else if (userRole === 'doctor') {
        await dispatch(fetchAllDoctorData());
    }

    return response;
});

/**
 * Асинхронное действие для обновления данных профиля пользователя.
 * Выполняет запрос на сервер для изменения данных профиля пользователя.
 * @function fetchDataUpdate
 * @async
 * @param {Object} data Данные для обновления профиля пользователя.
 * @returns {Promise<Object>} Ответ от сервера с обновлёнными данными профиля пользователя, в случае ошибки - ошибку.
 */
export const fetchDataUpdate = createAsyncThunk(
    'authentification/fetchDataUpdate',

    async (data, { rejectWithValue }) => {
        try {
            const response = await dataUpdate(data);
            return response;
        } catch (error) {
            console.error('Ошибка в fetchPatientDataUpdate', error.response.status);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронное действие для удаления пользователя и выхода из системы.
 * Удаляет пользователя по ID и выходит из системы.
 * @function fetchDeleteUser
 * @async
 * @param {string|number} id - Идентификатор пользователя, которого нужно удалить.
 * @param {Object} thunkAPI - Объект, предоставляемый `createAsyncThunk`, содержащий методы для управления действиями.
 * @param {Function} thunkAPI.dispatch - Функция для отправки действий Redux.
 * @returns {Promise<Object>} Ответ от сервера после удаления пользователя.
 */
export const fetchDeleteUser = createAsyncThunk('authentification/deleteUser', async (id, { dispatch }) => {
    const response = await deleteUser(id);
    await dispatch(fetchLogout());
    return response;
});

//----------------------------------------------------------
// старое, не будет использоваться
export const fetchAuthentification = createAsyncThunk('authentification/fetchAuthentification', async (phone) => {
    localStorage.clear();
    sessionStorage.clear();
    return await AuthMe(phone);
});

export const fetchCheckPhone = createAsyncThunk('authentification/fetchCheckPhone', async (data) => {
    try {
        const response = await loginUser(data);
        if (response.data.access) {
            sessionStorage.setItem('token', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
        }
        return response;
    } catch (e) {
        // console.log('ошибка checkPhone', e);
        return e;
    }
});

export const fetchMCheckEmail = createAsyncThunk('authentification/fetchMCheckinEmail', async (email) => {
    try {
        const response = await checkEmail(email);
        return response;
    } catch (e) {
        // console.log('ошибка checkEmail', e);
        return e;
    }
});

export const fetchMCheckEmailCode = createAsyncThunk('authentification/fetchMCheckEmailCode', async (data) => {
    try {
        const response = await checkEmailCode(data);
        return response;
    } catch (e) {
        // console.log('ошибка checkEmailCode', e);
        return e;
    }
});

//--------------------------------------------------
/**
 * Изначальное состояние слайса аутентификации.
 * @typedef {Object} InitialState
 * @property {Object} user Данные о текущем пользователе.
 * @property {number|string} user.id Идентификатор пользователя.
 * @property {string} user.username Имя пользователя.
 * @property {string} user.role Роль пользователя (пациент или доктор).
 * @property {string} user.first_name Имя пользователя.
 * @property {string} user.last_name Фамилия пользователя.
 * @property {string} user.middle_name Отчество пользователя.
 * @property {string} user.date_birth Дата рождения пользователя.
 * @property {string} user.sex Пол пользователя.
 * @property {string} user.email Email пользователя.
 * @property {string} user.phone Телефон пользователя.
 * @property {string} user.city Название города пользователя.
 * @property {File|string} user.avatar Аватар пользователя.
 * @property {boolean} isEmail Используется ли Email для аутентификации.
 * @property {boolean} isEmailCheck Прошла ли верификация Email.
 * @property {boolean} isPreloader Флаг отображения загрузки.
 * @property {boolean} isAuth Флаг авторизации пользователя.
 * @property {string} whoAreYou Роль пользователя (пациент или доктор), выбранная на этапе регистрации.
 * @property {Object} messagesError Объект с сообщениями об ошибках, сгруппированными по полям ("тип ошибки": "текст ошибки").
 */

const initialState = {
    user: {
        id: '',
        username: '',
        role: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        date_birth: '',
        sex: '',
        email: '',
        phone: '',
        city: '',
        avatar: '',
    },
    isEmail: true, // используем в хэдере см header.jsx
    isEmailCheck: false, // используем в хэдере см header.jsx
    isPreloader: false,
    isAuth: false, // используем в хэдере см header.jsx и в Authentication.jsx
    whoAreYou: '', //используется в компоненте whoAreYou.jsx при выборе радио-buttons
    messagesError: {},
};

/**
 * Слайс состояния для управления аутентификацией.
 * @constant
 * @type {Slice}
 */
const authentification = createSlice({
    name: 'authentification',
    initialState,
    reducers: {
        /**
         * Устанавливает флаг авторизации.
         * @function setAuth
         * @param {boolean} bool Статус авторизации.
         */
        setAuth: (state, bool) => {
            // используем для проверки авторизации и токена (см header.jsx)
            state.isAuth = bool.payload;
        },
        /**
         * Устанавливает роль пользователя.
         * @function setWhoAreYou
         * @param {string} role Роль пользователя (пациент или доктор).
         */
        setWhoAreYou(state, role) {
            // используется при регистрации
            state.whoAreYou = role.payload;
        },
        /**
         * Устанавливает email пользователя.
         * @function setEmailSlice
         * @param {string} email Email пользователя.
         */
        setEmailSlice(state, action) {
            // используется при логине
            state.user.email = action.payload;
        },
        /**
         * Очищает сообщения об ошибках для указанного поля.
         * @function clearMessage
         * @param {string} field Имя поля, ошибки которого нужно очистить.
         */
        clearMessage(state, action) {
            const field = action.payload;
            if (state.messagesError[field]) {
                delete state.messagesError[field];
            }
        },
        /**
         * Сбрасывает объект `messagesError`, удаляя все сообщения об ошибках.
         * @function clearAllMessages
         * @param {Object} state Текущее состояние слайса.
         */
        clearAllMessages(state) {
            state.messagesError = {};
        },
        clearUserState() {
            return initialState;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchRegister.pending, (state, action) => {
            state.isPreloader = true;
            // console.log('fetchRegister pending: ', JSON.stringify(state.isPreloader));
        });
        builder.addCase(fetchRegister.fulfilled, (state, action) => {
            if (action.payload.status !== 201) {
                const newMessages = formatErrorMessage(action.payload.data);
                state.messagesError = {
                    ...state.messagesError,
                    ...newMessages,
                };
            } else {
                state.user = action.payload;
            }
            state.isPreloader = false;
            // console.log('fetchRegister fulfilled: ', JSON.stringify(state.isPreloader));
        });
        builder.addCase(fetchRegister.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload.data);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isPreloader = false;
        });
        builder.addCase(fetchLogin.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchLogin.fulfilled, (state) => {
            state.isAuth = true;
            state.isEmail = true;
            state.isPreloader = false;
        });
        builder.addCase(fetchLogin.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload.data);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isPreloader = false;
        });
        builder.addCase(fetchMeUser.pending, (state, action) => {
            // state.isPreloader = true; // временно отключено, ныяснить почему не работает
        });
        builder.addCase(fetchMeUser.fulfilled, (state, action) => {
            state.user = formatNullData(action.payload);
        });
        builder.addCase(fetchMeUser.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isPreloader = false;
        });

        builder.addCase(fetchDataUpdate.pending, (state, action) => {
            // state.isPreloader = true; // временно отключено, ныяснить почему не работает
        });
        builder.addCase(fetchDataUpdate.fulfilled, (state, action) => {
            state.user = action.payload;
        });
        builder.addCase(fetchDataUpdate.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });

        builder.addCase(fetchDeleteUser.pending, (state, action) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchDeleteUser.fulfilled, (state, action) => {
            return initialState;
        });
        builder.addCase(fetchDeleteUser.rejected, (state, action) => {
            state.messagesError = formatErrorMessage(action.payload);
            state.isPreloader = false;
        });
    },
});

export const { setAuth, setEmailSlice, setWhoAreYou, clearMessage, clearAllMessages, clearUserState } =
    authentification.actions;
export default authentification.reducer;
