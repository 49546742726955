import React, { useEffect } from 'react';
import { ageCalculator } from '../../../../share/helpers';
import { DataBlockStl } from '../viewDoctor.styled';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * Компонент `ViewData` отображает основную информацию о пользователе, если она доступна.
 * Если фамилия пользователя отсутствует, происходит перенаправление на страницу редактирования профиля.
 *
 * @component
 * @returns {JSX.Element|null} Компонент, отображающий данные пользователя, либо `null`, если данных нет.
 *
 * @example
 * // Использование в маршруте React Router
 * <Route path="/lk/doctor-profile" element={<ViewData />} />
 */
export const ViewData = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.doctorProfile?.doctorData);

    useEffect(() => {
        if (user?.last_name === '' || null) {
            navigate('/lk/doctor-profile/profile/edit');
        }
    }, [user]);

    if (!user) return null;

    const { first_name, last_name, middle_name, date_birth, sex, phone, email, city } = user;

    const cityName = city && city.length > 0 ? city : '';

    return (
        <DataBlockStl>
            {(last_name || first_name || middle_name) && (
                <div className="info__wrapper">
                    <p className="info__data-bold">{`${last_name} ${first_name} ${middle_name ? middle_name : ''}`}</p>
                </div>
            )}
            {date_birth && (
                <div className="info__wrapper">
                    <p className="info__data">{ageCalculator(date_birth)}</p>
                </div>
            )}
            {sex && (
                <div className="info__wrapper">
                    <p className="info__data">{sex === 'М' ? 'Мужчина' : 'Женщина'}</p>
                </div>
            )}
            {email && (
                <div className="info__wrapper">
                    <p className="info__data">{email}</p>
                </div>
            )}
            {phone && (
                <div className="info__wrapper">
                    <p className="info__data">
                        {`+7 (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`}
                    </p>
                </div>
            )}
            {cityName && (
                <div className="info__wrapper">
                    <p className="info__data">{cityName}</p>
                </div>
            )}
        </DataBlockStl>
    );
};
