import styled from 'styled-components';

export const CalendarModalStl = styled.div`
    background: #ffffff;
    border-radius: 15px;
    padding: 29px;
    width: 361px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        color: #06a7b3;
        margin: 15px 0 18px 0;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #062a3f;
        text-align: center;
        margin: 0 0 24px 0;
    }
    img {
        cursor: pointer;
        width: 14px;
    }
`;
