import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPatient, getConsultationsByID, getAllPatientsOfDoctor } from './doctorApi';

const initialState = {
    next: '',
    isLoading: false,
    patients: [],
    patientFull: { user: {} },
    consultations: [],
};

export const fetchDoctorPatients = createAsyncThunk(
    'doctorPatients/fetchDoctorPatients',
    async (search, { getState }) => {
        try {
            const next = getState().doctorPatients.next;
            const response = await getAllPatientsOfDoctor(search, next);
            return response.data;
        } catch (err) {
            // console.log(err);
        }
    }
);

export const fetchPatient = createAsyncThunk('doctorPatients/fetchPatient', async (id) => {
    try {
        const response = await getPatient(id);
        return response.data;
    } catch (err) {
        // console.log(err);
    }
});

export const fetchConsultationPatient = createAsyncThunk('doctorPatients/fetchConsultationPatient', async (id) => {
    try {
        const response = await getConsultationsByID(id);
        return response;
    } catch (err) {
        // console.log(err);
    }
});

const doctorPatients = createSlice({
    name: 'doctorPatients',
    initialState,
    reducers: {
        resetPatients(state) {
            state.patients = [];
            state.next = '';
        },
        removeConsultations(state) {
            state.consultations = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDoctorPatients.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchDoctorPatients.fulfilled, (state, action) => {
                state.patients.push(...action.payload.results);
                state.next = action.payload.next;
                state.isLoading = false;
            });

        builder.addCase(fetchPatient.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPatient.fulfilled, (state, action) => {
            state.patientFull = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchPatient.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchConsultationPatient.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchConsultationPatient.fulfilled, (state, action) => {
            state.consultations = action.payload.data.data.consultations;
            state.isLoading = false;
        });
        builder.addCase(fetchConsultationPatient.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { removeConsultations, resetPatients } = doctorPatients.actions;
export default doctorPatients.reducer;
