import React from 'react';
import { FieldWrapper } from './FieldWrapper';
import { useController, useFormContext } from 'react-hook-form';

/**
 * Компонент InputUserName — текстовое поле для ввода имени пользователя с валидацией.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для формы.
 * @param {string} props.label - Лэйбл поля (текст, который отображается рядом с полем).
 * @param {string} props.placeholder - Текст подсказки, который отображается в поле до ввода данных.
 * @param {string} [props.defaultValue=''] - Значение поля по умолчанию.
 * @param {Object} [props.validation={}] - Дополнительные правила валидации.
 * @param {Function} [props.onChange=() => {}] - Функция, которая вызывается при изменении значения поля.
 * @param {Object} [props.messagesError] - Сообщения об ошибках, которые могут быть переданы для отображения.
 * @param {boolean} [props.isRequired=false] - Флаг, указывающий, является ли поле обязательным для заполнения.
 * @param {string} [props.errorKey] - Ключ ошибки для идентификации ошибки, связанной с данным полем.
 * @param {Function} [props.onClearError] - Функция, вызываемая для очистки сообщения об ошибке.
 *
 * @returns {JSX.Element} Возвращает компонент текстового поля с валидацией для имени пользователя.
 */

const InputUserName = ({
    name,
    isRequired,
    label,
    placeholder,
    defaultValue = '',
    validation = {},
    onChange = () => {},
    messagesError,
    errorKey,
    onClearError,
}) => {
    const { control, trigger, setValue } = useFormContext();

    const validateUsername =
        name === 'username'
            ? {
                  pattern: {
                      value: /^[A-Za-z0-9.+\-_@]{1,150}$/,
                      message: 'Используйте латинские буквы, цифры, символы .+-_@',
                  },
                  maxLength: {
                      value: 150,
                      message: 'Максимальная длина: 150 символов',
                  },
              }
            : {};

    const defaultValidation = {
        required: 'Поле обязательно для заполнения',
        pattern: {
            value: /^[\p{L}]*$/u,
            message: 'Имя может содержать только символы алфавита',
        },
        ...validateUsername,
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });

    const clearedView = '';

    const handleKeyDown = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (messagesError) {
            onClearError(errorKey);
        }

        setValue(e.target.name, value);
        trigger(e.target.name);
    };

    const handleClear = () => {
        field.onChange(clearedView);
        onChange(clearedView);
        trigger(field.name);
        onClearError(errorKey);
    };

    return (
        <FieldWrapper
            error={error}
            messagesError={messagesError}
            label={label}
            clearCondition={field.value !== clearedView}
            isRequired={isRequired}
            onClear={handleClear}
        >
            <input
                {...field}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                id={name}
                type="text"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
        </FieldWrapper>
    );
};

export default InputUserName;
