import styled from 'styled-components';

export const StyledPatientConsultationCard = styled.div`
    max-width: 600px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    font-weight: 400;
    line-height: 150%;
    transition: all ease 0.3s;
    display: grid;
    column-gap: 40px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'photoBlock status'
        'photoBlock info'
        'photoBlock details';

    .photoBlock {
        grid-area: photoBlock;
        width: 108px;
        height: 108px;
        border-radius: 50%;
        position: relative;
        z-index: 10;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    .status {
        grid-area: status;
    }

    .info {
        grid-area: info;
    }

    .details {
        grid-area: details;
    }

    .statusConsultation {
        font-size: 14px;
        color: #666666;
        padding-bottom: 8px;
        line-height: 130%;

        @media (max-width: 320px) {
            font-size: 12px;
        }
    }
    .red {
        color: #ff3636;
    }

    .green {
        color: #19be6f;
    }

    .doctorName {
        font-size: 18px;
        color: #212121;
        word-break: break-word;
        padding-bottom: 4px;
        @media (max-width: 500px) {
            font-size: 16px;
            line-height: 19px;
        }
    }

    .specialization {
        max-width: 90%;
        list-style: none;
        line-height: 130%;
        padding-bottom: 8px;
        > li {
            display: inline-block;
            font-size: 16px;
            color: #666;
            &:not(:last-child)::after {
                content: ", ";
                white-space: pre;
            }
            &:not(:first-child) {
                text-transform: lowercase;
            }
            @media (max-width: 420px) {
                font-size: 14px;
            }
        }
    }
    .dateAndTime {
        padding-bottom: 20px;
    }

    .comment {
        padding-bottom: 4px;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        color: #666666;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        max-height: calc(1.5em * 2);
        @media (max-width: 810px) {
            padding-bottom: 2px;
        }
        @media (max-width: 500px) {
            padding-bottom: 12px;
        }
    }

    .noComment {
        color: #fcb71b;
    }

    &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
    }

    @media (max-width: 880px) {
        padding: 20px;
        column-gap: 16px;
        .photoBlock {
            width: 64px;
            height: 64px;
        }
    }

    @media (max-width: 500px) {
        padding: 16px;
        gap: 12px;
        grid-template-areas:
            'status status'
            'photoBlock info'
            'details details';
    }
`;
