import styled from 'styled-components';

export const BlurBgStyled = styled.div`
  position: 'fixed',
  top: 0;
  left: 0;
  width: '100vw';
  height: '100vh';
  backgroundColor: 'rgba(0, 0, 0, 0.5)';
  zIndex: 1005;
`;
