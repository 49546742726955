/**
 * Функция для генерации CSS классов для времени в расписании.
 * Классы добавляются в зависимости от наличия консультации и рабочего времени.
 *
 * @param {Object} el - Объект, представляющий время в расписании.
 * @param {Object} el.consultation - Объект консультации, если она присутствует, иначе `null`.
 * @param {boolean} el.workTime - Флаг, указывающий, является ли время рабочим.
 *
 * @returns {string} - Строка с CSS классами для времени в расписании.
 */

export function setClassNameTime(el) {
    const { consultation, workTime } = el;
    const classes = ['time'];
    workTime ? classes.push('time__isWork') : classes.push('');
    consultation ? classes.push('time__consultation') : classes.push('');

    return classes.join(' ');
}

/**
 * Функция для генерации CSS классов для дня в календаре в зависимости от состояния дня.
 * Классы могут быть добавлены в зависимости от различных факторов, таких как:
 * - есть ли записи на день
 * - был ли день выбран ранее
 * - является ли день текущим
 * - есть ли консультации в день
 *
 * @param {Object} day - Объект, представляющий день календаря.
 * @param {number} index - Индекс дня в массиве дней.
 * @param {Array} arr - Массив всех дней в календаре.
 * @param {Array} choosenDates - Массив выбранных дат, с информацией о выбранных днях.
 *
 * @returns {string} - Строка с CSS классами, которые следует применить к данному дню.
 */

export function setClassName(day, index, arr, choosenDates, savedAndChoosen) {
    let classes = ['day'];

    // Проверяем, был ли выбор хотя бы раз
    const wasChoosenBefore = arr.some((d) => d.choosen);

    if (day.receptions?.some((el) => el.workTime === true)) {
        classes.push('calendar__days_receipts');
    } else {
        classes.push('calendar__days_no-receipts');
    }

    if (day.choosen) {
        classes.push('calendar__days_choosen');
    }

    const prevDay = arr[index - 1];
    if (prevDay && prevDay.receptions?.some((el) => el.workTime === true)) {
        if (classes.includes('calendar__days_receipts')) {
            if (prevDay.choosen) {
                classes.push('calendar__days_roundLeft');
            } else {
                classes.push('calendar__days_leftPosition');
            }
        }
    }

    const nextDay = arr[index + 1];
    if (nextDay && nextDay.receptions?.some((el) => el.workTime === true)) {
        if (classes.includes('calendar__days_receipts')) {
            if (nextDay.choosen) {
                classes.push('calendar__days_roundRight');
            } else {
                classes.push('calendar__days_rightPosition');
            }
        }
    }

    if (day.receptions?.some((el) => el.consultation !== null)) {
        classes.push('calendar__days_record');
    }

    if (choosenDates.some((el) => el.choosen && el.day === day.day)) {
        classes.push('calendar__days_choosen');

        if (choosenDates.some((el) => el.choosen && el.day - 1 === day.day)) {
            classes.push('calendar__days_rightPosition');
            classes.push('calendar__days_roundLeft');
        }

        if (choosenDates.some((el) => el.choosen && el.day + 1 === day.day)) {
            classes.push('calendar__days_leftPosition');
            classes.push('calendar__days_roundRight');
        }
    }

    if (day.day === new Date().getDate() && day.month === new Date().getMonth()) {
        classes.push('calendar__days_today');
    }

    // Сбрасываем классы ТОЛЬКО если ранее был выбор, но он сброшен (чтобы не затронуть первый рендер)
    if ((wasChoosenBefore && choosenDates.length < 1) || savedAndChoosen) {
        // if (wasChoosenBefore && choosenDates.length < 1) {
        classes = classes.filter((cls) => cls !== 'calendar__days_choosen');

        if (classes.includes('calendar__days_roundRight')) {
            classes.push('calendar__days_rightPosition');
            classes = classes.filter((cls) => cls !== 'calendar__days_roundRight');
        }

        if (classes.includes('calendar__days_roundLeft')) {
            classes.push('calendar__days_leftPosition');
            classes = classes.filter((cls) => cls !== 'calendar__days_roundLeft');
        }

        if (prevDay && prevDay.classes?.includes('calendar__days_roundLeft')) {
            prevDay.classes = prevDay.classes?.filter((cls) => cls !== 'calendar__days_roundRight');
            prevDay.classes?.push('calendar__days_rightPosition');
        }

        if (nextDay && nextDay.classes?.includes('calendar__days_roundRight')) {
            nextDay.classes = nextDay.classes?.filter((cls) => cls !== 'calendar__days_roundLeft');
            nextDay.classes?.push('calendar__days_leftPosition');
        }
    }

    if (classes.includes('calendar__days_leftPosition') && classes.includes('calendar__days_rightPosition')) {
        classes.push('calendar__days_pos_middle');
    } else if (classes.includes('calendar__days_leftPosition')) {
        classes.push('calendar__days_pos_first');
    } else if (classes.includes('calendar__days_rightPosition')) {
        classes.push('calendar__days_pos_last');
    }

    return classes.length > 0 ? classes.join(' ') : '';
}
