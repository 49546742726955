import React from 'react';
import { DataBlockStl } from '../viewDoctor.styled';
import { getDoctorCategory } from '../../../../share/helpers';
import { getScientificDegree } from '../../../../share/helpers';

/**
 * Компонент `ViewDoctorSpecialization` отображает специализацию, категорию и научную степень доктора.
 * Использует вспомогательные функции для преобразования значений категории и научной степени в человекочитаемый формат.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Array<Object>} props.specialization - Список специализаций доктора.
 * @param {string} props.specialization[].name - Название специализации.
 * @param {string} props.category - Категория доктора (например, 'Высшая', 'Первая').
 * @param {string} props.scientific_degree - Научная степень доктора (например, 'Кандидат наук', 'Доктор наук').
 *
 * @returns {JSX.Element} Блок с информацией о специализации, категории и научной степени доктора.
 *
 * @example
 * const specialization = [
 *   { name: 'Хирургия' },
 *   { name: 'Терапия' },
 * ];
 * const category = 'Высшая';
 * const scientific_degree = 'Доктор наук';
 *
 * return (
 *   <ViewDoctorSpecialization
 *     specialization={specialization}
 *     category={category}
 *     scientific_degree={scientific_degree}
 *   />
 * );
 */
export const ViewDoctorSpecialization = ({ specialization, category, scientific_degree }) => {
    const categoryLabel = getDoctorCategory(category);
    const scientificDegereeLabel = getScientificDegree(scientific_degree);

    return (
        <DataBlockStl>
            <div className="info__wrapper">
                {specialization.map((spec, i) => (
                    <h4 key={i} className="info__header">
                        {spec?.name}
                    </h4>
                ))}
            </div>

            <div className="info__wrapper">
                <p className="info__data">{categoryLabel}</p>
            </div>

            <div className="info__wrapper">
                <p className="info__data">{scientificDegereeLabel}</p>
            </div>
        </DataBlockStl>
    );
};
