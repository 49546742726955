import React, { useEffect } from 'react';
import { BlogArticleStyled } from './blogArticle.styled';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArticlePage } from '../api/blogSlice';
import { Spinner } from '../../share/Spinner';
import ArticleDescription from './articleDescription/ArticleDescription';
import ArticleForm from './articleForm/ArticleForm';
import { ArticleComments } from './articleComments/ArticleComments';
import SimilarPosts from './similarPosts/SimilarPosts';

/**
 * Компонент BlogArticle отвечает за отображение страницы статьи блога.
 * Он загружает данные статьи, комментарии и отображает связанные посты.
 * 
 * @component
 * @returns {JSX.Element} Страница с подробностями статьи.
 */

export const BlogArticle = () => {
    const dispatch = useDispatch();
    const { themeId, postId } = useParams();
    const { themes, articles, isLoading } = useSelector((state) => state.blog);
    const article = articles.filter((article) => article.id === +postId)[0];

    useEffect(() => {
        if (themes.length && !article) {
            dispatch(fetchArticlePage(postId));
        }
    }, [article, themes]);

    if (isLoading && article?.id !== +postId && !article)
        return (
            <div className="centerSpinner">
                <Spinner />
            </div>
        );

    return (
        <BlogArticleStyled>
            <ArticleDescription themeId={themeId} article={article} />
            <div className="articleComments">
                <h3 className="commentsTitle">Комментарии</h3>
                <ArticleForm id={article?.id} />
                <ArticleComments
                    comments={article?.comments}
                    postId={postId}
                    hasNextLink={article?.next}
                    isLoading={isLoading}
                />
            </div>
            <SimilarPosts />
        </BlogArticleStyled>
    );
};
