import styled from 'styled-components';

export const DivStl = styled.div`
    user-select: none;
    > .radio-group {
        margin-bottom: 10px;
        display: inline-block;
        font-size: 14px;
    }

    .fieldWraper__red {
        color: #ff3636;
    }
`;

export const LabelStl = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    input {
        appearance: none;
        &:checked + div svg .radio-dot {
            fill: #19be6f;
        }
        &:checked + div svg .radio-outline {
            stroke: #19be6f;
        }
    }
    div {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    &:hover .radio-outline {
        stroke: #19be6f;
    }
    &:active .radio-outline {
        fill: #c9fce3;
    }
    &:focus-within .radio-outline {
        stroke: #19be6f;
    }
`;
