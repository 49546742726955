import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DivStl, LabelStl } from './radioGroupStl.styled';
import { useEffect } from "react";

// Массив значений для передачи вместо индекса
const values = ['М', 'Ж'];

/**
 * Компонент RadioGroup — группа радиокнопок с валидацией.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для формы.
 * @param {string} props.label - Лэйбл группы радиокнопок (текст, который отображается рядом с кнопками).
 * @param {Array<string>} props.options - Массив опций для отображения рядом с радиокнопками.
 * @param {boolean} [props.isRequired=false] - Флаг, указывающий, является ли поле обязательным для заполнения.
 * @param {string} [props.defaultChecked] - Значение, которое должно быть выбрано по умолчанию.
 *
 * @returns {JSX.Element} Возвращает компонент группы радиокнопок с валидацией.
 */

const RadioGroup = ({ name, label, options, isRequired, defaultValue }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { field } = useController({
        name,
        control,
        rules: isRequired ? { required: 'Это поле обязательно для заполнения' } : {},
    });

    useEffect(() => {
        if (!field.value) {
            field.onChange('М');
        }
    }, []);

    return (
        <DivStl>
            <span className="radio-group">
                {label}
                {isRequired && <span className="fieldWraper__red">*</span>}
            </span>
            {options.map((option, index) => {
                return (
                    <LabelStl key={index}>
                        <input
                            type="radio"
                            {...field}
                            value={values[index]}
                            onChange={() => field.onChange(values[index])}
                            checked={field.value === values[index]}
                        />
                        <div>
                            <svg className="svg" height="24px" width="24px">
                                <circle
                                    className="radio-outline"
                                    cx="12"
                                    cy="12"
                                    r="11"
                                    fill="white"
                                    stroke="#B6B7BA"
                                    strokeWidth="2"
                                />
                                <circle className="radio-dot" cx="12" cy="12" r="8" fill="none" />
                            </svg>
                        </div>
                        <span>{option}</span>
                    </LabelStl>
                );
            })}
            {errors[name] && <span className="error-message">{errors[name]?.message}</span>}
        </DivStl>
    );
};

export default RadioGroup;
