import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAgreementById, getAgreements } from './api';

/**
 * Асинхронное действие для получения всех соглашений.
 * @function fetchAgreements
 * @async
 */

export const fetchAgreements = createAsyncThunk('politics/fetchAgreements', async (_, { rejectWithValue }) => {
        try {
            const response = await getAgreements();
            return response;
        } catch (error) {
            console.error('Error in fetchAgreements:', error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

/**
 * Асинхронное действие для получения соглашения по ID.
 * @function fetchAgreementById
 * @async
 */
export const fetchAgreementById = createAsyncThunk('politics/fetchAgreementById', async (id, { rejectWithValue }) => {
        try {
            const response = await getAgreementById(id); 
            return response;
        } catch (error) {
            console.error('Error fetch agreement by ID:', error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


const initialState = {
    agreements: [], 
    isLoading: null,
    isError: null,
};

const politicsSlice = createSlice({
    name: 'politics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAgreements.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchAgreements.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchAgreements.fulfilled, (state, action) => {
            const { results } = action.payload || {};
            if (results) {
                state.agreements = results;
            }
            state.isLoading = false;
        });
    },
});

export default politicsSlice.reducer;