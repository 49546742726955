import React from 'react';
import { AxiosErrorStl } from './axiosError.stl';
import { ReactComponent as AttentionSvg } from '../../../img/icons/icon_attention.svg';

export const AxiosError = ({ error }) => {
    return (
        <AxiosErrorStl>
            <p className="axiosError">
                <AttentionSvg /> <span className="axiosErrorText">{error}</span>
            </p>
        </AxiosErrorStl>
    );
};
