import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VacanciesFormStl } from './vacanciesForm.styled';
import InputTel from '../../share/formFields/InputTel';
import InputEmail from '../../share/formFields/InputEmail';
import AgreementField from '../../share/formFields/AgreementField';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import InputNameText from '../../share/formFields/InputNameText';
import { Modal } from '../../share/Modal';
import PopUpAlert from '../../share/PopUpAlert';
import { PopUpError } from "../../share/PopUpError";
import { clearAllMessages, clearMessage, fetchSendVacancies } from "../api/vacanciesSlice";
import { Spinner } from "../../share/Spinner";

/**
 * Компонент формы вакансий для отправки заявки "Связаться со мной".
 * Включает поля для ввода имени, специальности, номера телефона, email, и галочку для согласия с условиями.
 * Использует react-hook-form для управления состоянием формы и валидации.
 *
 * @component
 * @returns {JSX.Element} Компонент формы вакансий.
 */

export const VacanciesForm = () => {
    const dispatch = useDispatch();
    const [popUpSuccess, setPopUpSuccess] = useState(false);
    const [popUpUnsuccess, setPopUpUnsuccess] = useState(false);
    const { messagesError, isPreloader } = useSelector((state) => state.vacancies);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            specialization: '',
            phone: '',
            email: '',
            agreementVacancies: false,
        },
        mode: 'all',
    });

    const {
        formState: { isValid, isSubmitting },
        watch,
        setValue,
        register,
    } = methods;

    register('agreementVacancies', { required: true });

    /**
     * Сбрасывает состояние agreementVacancies при возникновении ошибки отправки контактных данных, 
     * оставляя остальные поля формы заполненными, чтобы пользователь мог повторить попытку отправки.
     */
    useEffect(() => {
        if (messagesError) {
            setValue('agreementVacancies', false);
        }
    }, [messagesError, setValue]);

    useEffect(() => {
        dispatch(clearAllMessages());
    }, [dispatch]);

    const onSubmit = async (data) => {
        const { name, specialization, phone, email } = data;
        try {
            await dispatch(fetchSendVacancies({
                name,
                specialization,
                phone: phone.replace(/[\s()-]/g, ''),
                email
            })).unwrap();

            setPopUpSuccess(true);
        methods.reset();
        } catch (error) {
            setPopUpUnsuccess(true);
            methods.setValue('agreementVacancies', false, { shouldValidate: true });
        }
    };

    const handleClearError = (errorKey) => {
        dispatch(clearMessage(errorKey));
    };

    if (isPreloader) {
        return <div className="centerSpinner">
            <Spinner />
        </div>
    }

    return (
        <VacanciesFormStl>
            <h2 className="vacancies__title">Связаться со мной</h2>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <InputNameText
                        name="name"
                        placeholder="Иванов Иван Иванович"
                        label="Как Вас называть"
                        validation={{
                            required: {
                                value: true,
                                message: 'Поле обязательно для заполнения'
                            },
                            maxLength: {
                                value: 152,
                                message: 'Максимальная длина: 152 символа',
                            },
                            minLength: {
                                value: 2,
                                message: 'Минимальная длина: 2 символа',
                            },
                            pattern: {
                                value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,152}$/,
                                message: 'Поле может содержать символы алфавита, пробел, дефис',
                            },
                        }}
                        isFullName={true}
                        isRequired={true}
                    />
                    <InputNameText
                        name="specialization"
                        placeholder="Терапевт"
                        label="Специальность"
                        validation={{
                            required: {
                                value: true,
                                message: 'Поле обязательно для заполнения'
                            },
                            maxLength: {
                                value: 100,
                                message: 'Максимальная длина: 100 символа',
                            },
                            minLength: {
                                value: 2,
                                message: 'Минимальная длина: 2 символа',
                            },
                            pattern: {
                                value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,100}$/,
                                message: 'Поле может содержать символы алфавита, пробел, дефис',
                            },
                        }}
                        isFullName={true}
                        isRequired={true}
                    />
                    <InputTel
                        validation={{ pattern: false }}
                        name="phone"
                        label="Телефон"
                        placeholder="+7 (999) 999-99-99"
                        isRequired={true}
                        messagesError={messagesError?.phone}
                        onClearError={handleClearError}
                        errorKey="phone"
                    />
                    <InputEmail
                        name="email"
                        label="Email"
                        placeholder={'example@gmail.com'}
                        control={methods.control}
                        isRequired={true}
                        messagesError={messagesError?.email}
                        onClearError={handleClearError}
                        errorKey="email"
                    />
                    <AgreementField name="agreementVacancies" required />
                    <button className="btn" type="submit" disabled={!isValid || isSubmitting}>
                        Отправить
                    </button>

                    {popUpSuccess && (
                        <Modal toggle={setPopUpSuccess}>
                            <PopUpAlert
                                onConfirm={() => setPopUpSuccess(false)}
                                contentHeader={<span>Ваше сообщение отправлено</span>}
                                isClarification={false}
                            />
                        </Modal>
                    )}

                    {popUpUnsuccess && (
                        <Modal toggle={setPopUpUnsuccess}>
                            <PopUpError
                                // Функция для повторной отправки данных формы при возникновении ошибки
                                handleConfirumClick={() => {
                                    setPopUpUnsuccess(false);
                                    const formData = methods.getValues();
                                    methods.setValue('agreementVacancies', true, { shouldValidate: true });
                                    onSubmit(formData);
                                }}
                                closeModal={setPopUpUnsuccess}
                            />
                        </Modal>
                    )}
                </form>
            </FormProvider>
        </VacanciesFormStl>
    );
};
