import { useEffect } from 'react';

/**
 * Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара.
 */
export const useLockBodyScroll = () => {
    useEffect(() => {
        const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${scrollWidth}px`;

        return () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };
    }, []);
};
