import { fetchCity, resetCity, clearMessage } from '../../api/doctorUserProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { validateBirthdayInput, validateInputTextName } from '../../../share/formFields/validateInput';
import InputDate from '../../../share/formFields/InputDate';
import InputTel from '../../../share/formFields/InputTel';
import InputNameText from '../../../share/formFields/InputNameText';
import RadioGroup from '../../../share/formFields/RadioGroup';
import React from 'react';
import SelectCity from '../../../share/formFields/selectCity/SelectCity';
import { EmailLKForm } from '../../../share/formFields/fieldWrapperStl.styled';
import { useFormContext } from 'react-hook-form';

/**
 * Компонент `DoctorDataList` предоставляет форму для редактирования данных доктора.
 *
 * Включает поля для ввода:
 * - Фамилии, имени и отчества (с валидацией на текст).
 * - Даты рождения (c маской ввода).
 * - Пола (выбор из двух вариантов).
 * - Номера телефона (с маской и валидацией).
 * - Электронной почты (только отображение).
 * - Города (с поиском, если введено 2+ символов).
 *
 * @component
 * @returns {JSX.Element} Форма для редактирования данных доктора.
 *
 * @description
 * - Использует `react-hook-form` для управления формой.
 * - Работает с `redux`, получая и отправляя данные о городе (`fetchCity`, `resetCity`).
 * - Обрабатывает ошибки валидации и отображает сообщения об ошибках.
 * - Электронная почта `user.email` доступна только для чтения.
 *
 * @example
 * import { DoctorDataList } from './DoctorDataList';
 *
 * function Profile() {
 *   return <DoctorDataList />;
 * }
 */

export function DoctorDataList() {
    const dispatch = useDispatch();
    const { control, watch } = useFormContext();
    const { cityOptionsForFetching, isPreloaderCity, messagesError } = useSelector((state) => state.doctorProfile);

    const handleOnChange = (searchString) =>
        searchString?.length >= 2 ? dispatch(fetchCity(searchString)) : dispatch(resetCity());

    const handleClearError = (errorKey) => {
        dispatch(clearMessage(errorKey));
    };

    const cityValue = watch('user.city');

    return (
        <div>
            <InputNameText
                name="user.last_name"
                label="Фамилия"
                placeholder="Иванов"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputNameText
                name="user.first_name"
                label="Имя"
                placeholder="Иван"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputNameText
                name="user.middle_name"
                label="Отчество"
                placeholder="Иванович"
                validation={validateInputTextName(false)}
                isFullName={true}
            />
            <div className="work__date">
                <InputDate
                    name="user.date_birth"
                    label="Дата рождения"
                    placeholder="ДД.ММ.ГГГГ"
                    mask="99.99.9999"
                    validation={validateBirthdayInput(true)}
                    isRequired={true}
                />
            </div>
            <RadioGroup name="user.sex" label="Пол" options={['Мужской', 'Женский']} isRequired={true} />
            <EmailLKForm>
                <p className="info__title">Email</p>
                <p className="info__data">{control._formValues.user?.email}</p>
            </EmailLKForm>
            <InputTel
                validation={{ pattern: false }}
                name="user.phone"
                label="Телефон"
                placeholder="+7 (999) 999-99-99"
                isRequired={true}
                messagesError={messagesError?.phone}
                onClearError={handleClearError}
                errorKey="phone"
            />
            <SelectCity
                name="user.city"
                label="Город"
                isCity={!!cityValue}
                placeholder={cityValue || 'Москва'}
                onChange={handleOnChange}
                options={cityOptionsForFetching.map((item) => ({
                    value: item.name,
                    label: item.name,
                    id: item.id,
                }))}
                isPreloaderCity={isPreloaderCity}
                validation={{}}
                isRequired={false}
            />
        </div>
    );
}
