import React, { useMemo, useState, useEffect, useRef } from 'react';
import { DoctorConsultationCard } from './docrotConsultationCard/doctorConsultationCard';
import { Spinner } from '../../../share/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router-dom';
import {
    fetchGetDoctorFutureConsultations,
    fetchGetDoctorPastConsultations,
    resetDoctorConsultations,
} from '../../api/lkDoctorConsultationsSlice';
import { ScrollToTopArrowUp } from '../../../share/ScrollToTopArrowUp';

/**
 * Компонент для отображения списка консультаций доктора.
 * Поддерживает пагинацию, отображение загрузки и сообщений об ошибках.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.list - Объект с данными о консультациях.
 * @param {Array} props.list.consultations - Массив объектов консультаций.
 * @param {string|null} props.list.next - URL следующей страницы для пагинации.
 * @param {string} props.pathname - Текущий маршрут.
 * @returns {JSX.Element} Список консультаций или сообщение об их отсутствии.
 */

export const ConsultationList = ({ list, pathname }) => {
    const dispatch = useDispatch();
    const navigationType = useNavigationType();
    const isFutureConsultations = useMemo(() => pathname.includes('future'), [pathname]);

    const [isLoadingNext, setIsLoadingNext] = useState(false);
    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);
    const [isLoadingSeenItem, setIsLoadingSeenItem] = useState(false);
    const [isUserTriggeredScroll, setIsUserTriggeredScroll] = useState(false);
    const [consultationNotFound, setConsultationNotFound] = useState(false);
    const [savedConsultationId, setSavedConsultationId] = useState(null);

    const { messagesError, isLoading } = useSelector((state) => state.doctorConsultations);
    const axiosError = messagesError?.AxiosError;

    const newConsultationRef = useRef(null);
    const lastSeenConsultation = useRef(null);

    useEffect(() => {
        if (navigationType !== 'POP') sessionStorage.removeItem('seenConsultation');

        const seenConsultation = sessionStorage.getItem('seenConsultation')
            ? JSON.parse(sessionStorage.getItem('seenConsultation'))
            : setSavedConsultationId(null);

        if (pathname === '/lk/doctor-profile/consultations/past' && [1, 2, 8, 9].includes(seenConsultation?.status)) {
            setSavedConsultationId(+seenConsultation?.id);
        }
        if (pathname === '/lk/doctor-profile/consultations/future' && [0, 3].includes(seenConsultation?.status)) {
            setSavedConsultationId(+seenConsultation?.id);
        }

        return () => sessionStorage.removeItem('seenConsultation');
    }, [pathname]);

    /**
     * Загружает данные консультаций.
     *
     * @param {boolean} [isCurrent=false] - Если `true`, перезапускает загрузку списка консультаций.
     * @param {boolean} [isNext=false] - Если `true`, загружает следующую страницу консультаций.
     * @param {boolean} [isSeenItem=false] - Если `true`, загружает данные для скролла к последней просмотренной консультации.
     * @returns {Promise<void>} Промис завершения загрузки данных.
     */
    const loadConsultations = async (isCurrent = false, isNext = false, isSeenItem = false) => {
        const action = isFutureConsultations ? fetchGetDoctorFutureConsultations : fetchGetDoctorPastConsultations;
        try {
            if (isNext) {
                sessionStorage.removeItem('seenConsultation');
                setSavedConsultationId(null);
                setIsLoadingNext(true);
                setIsUserTriggeredScroll(true);
            } else if (isCurrent) {
                setIsLoadingCurrent(true);
                dispatch(resetDoctorConsultations());
            } else if (isSeenItem) {
                setIsLoadingSeenItem(true);
            }
            await dispatch(action({ limit: 3 })).unwrap();
        } catch (error) {
            console.error('Ошибка при загрузке консультаций:', error);
        } finally {
            if (isNext) setIsLoadingNext(false);
            if (isCurrent) setIsLoadingCurrent(false);
        }
    };

    /**
     * useEffect для первичной загрузки данных консультаций.
     *
     * При изменении маршрута (pathname) происходит:
     * 1) сброс ненайденной консультации в значение false.
     * 2) сброс текущих данных консультаций в Redux.
     * 3) запрос данных о консультациях: для будущих — загружаются запланированные консультации,
     *    для прошедпих — загружаются прошедшие консультации.
     *
     * @effect
     * Запускается при изменении маршрута.
     * Загружает консультации в зависимости от текущего пути.
     */
    useEffect(() => {
        setConsultationNotFound(false);
        loadConsultations(true);
    }, [pathname]);

    /**
     * useEffect для прокрутки к последней порции консультации после загрузки данных.
     *
     * Этот эффект срабатывает, когда загружается новая порция консультаций, и если прокрутка была инициирована пользователем.
     * В этом случае происходит плавная прокрутка к последней консультации.
     * @effect
     * Срабатывает при изменении списка консультаций и флага isUserTriggeredScroll.
     */
    useEffect(() => {
        if (newConsultationRef?.current && isUserTriggeredScroll) {
            const rect = newConsultationRef.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - 100,
                behavior: 'smooth',
            });
            setIsUserTriggeredScroll(false);
        }
    }, [list.consultations, isUserTriggeredScroll]);

    /**
     * useEffect для поиска только что просмотренной консультации и прокрутки к ней.
     *
     * Этот эффект срабатывает, когда происходит возврат с большой карточки консультации и имеется сохранённый id просмотренной консультации.
     * В этом случае происходит плавная прокрутка к просмотренной консультации.
     */

    useEffect(() => {
        if (!savedConsultationId) return;

        const findConsultation = async () => {
            setIsLoadingSeenItem(true);
            setConsultationNotFound((prev) => false);

            let exists = list?.consultations?.some((consultation) => consultation.id === savedConsultationId);

            if (exists) {
                if (lastSeenConsultation.current) {
                    const rect = lastSeenConsultation.current.getBoundingClientRect();
                    window.scrollTo({
                        top: window.scrollY + rect.top - 100,
                        behavior: 'smooth',
                    });
                }
                lastSeenConsultation.current = null;
                setIsLoadingSeenItem(false);

                return;
            }

            if (list?.next && !exists) {
                await loadConsultations(false, false, true);
            }

            if (!list?.next && !exists) {
                lastSeenConsultation.current = null;
                setIsLoadingSeenItem(false);
                setConsultationNotFound((prev) => true);
            }
        };

        findConsultation();
    }, [savedConsultationId, list.consultations, list.next, isLoadingSeenItem, consultationNotFound]);

    /**
     * Мемоизированный список консультаций.
     *
     * Создает список компонентов `DoctorConsultationCard` для каждого элемента в массиве консультаций.
     * Последней или просмотренной консультации присваивается ref для отслеживания прокрутки.
     *
     * @memo
     * Возвращает массив компонентов для отображения консультаций.
     * @returns {JSX.Element[]} Список компонентов консультаций.
     */
    const consultationsList = useMemo(
        () =>
            list?.consultations?.map((consultation, index) => (
                <DoctorConsultationCard
                    key={consultation.id}
                    consultation={consultation}
                    ref={(el) => {
                        if (index === list.consultations.length - 3) newConsultationRef.current = el;
                        if (consultation.id === savedConsultationId) lastSeenConsultation.current = el;
                    }}
                    id={`consultation-${consultation.id}`}
                />
            )),
        [list.consultations]
    );

    return (
        <>
            {isLoading || isLoadingNext || isLoadingCurrent || isLoadingSeenItem ? (
                <Spinner />
            ) : consultationNotFound ? (
                <div className="no-consult-container">
                    <p className="no-consult-text">
                        Такой консультации нет в списке Ваших {isFutureConsultations ? 'запланированных' : 'прошедших'}{' '}
                        консультаций.
                    </p>
                    <p className="no-consult-text">
                        Проверьте список {!isFutureConsultations ? 'запланированных' : 'прошедших'} консультаций.
                    </p>
                </div>
            ) : list?.consultations?.length > 0 ? (
                <>
                    {consultationsList}
                    <div className="buttonsPaginate">
                        {list.next ? (
                            <button
                                className="buttonAddDoctors"
                                onClick={() => loadConsultations(false, true, false)}
                                disabled={isLoadingNext}
                            >
                                {isLoadingNext ? <Spinner /> : 'Показать больше'}
                            </button>
                        ) : (
                            <div />
                        )}
                        <ScrollToTopArrowUp />
                    </div>
                </>
            ) : (
                !isLoading &&
                !axiosError &&
                list?.consultations?.length === 0 && (
                    <div>
                        <p className="no-consult-text">Записей нет</p>
                    </div>
                )
            )}
        </>
    );
};
