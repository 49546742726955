import React from 'react';
import InputMask from 'react-input-mask';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';

/**
 * Компонент InputTel для ввода телефонного номера с маской, валидацией и обработкой ошибок.
 * Использует react-hook-form для управления полем формы и InputMask для маскирования телефонного ввода.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля ввода, используемое для управления формой.
 * @param {string} props.placeholder - Текст плейсхолдера для поля ввода.
 * @param {Object} [props.validation] - Дополнительные правила валидации для поля.
 * @param {string} props.label - Текст метки для поля ввода.
 * @param {boolean} [props.disabled=false] - Флаг, указывающий, отключено ли поле.
 * @param {boolean} [props.isRequired=false] - Флаг, указывающий, является ли поле обязательным.
 * @param {Object} [props.messagesError] - Ошибки валидации с сервера, привязанные к полю.
 * @param {Function} props.onClearError - Функция для очистки ошибок валидации.
 * @param {string} props.errorKey - Ключ ошибки, соответствующий этому полю, для очистки конкретной ошибки.
 *
 * @returns {JSX.Element} Компонент ввода телефонного номера с маской.
 */

const InputTel = ({
    name,
    placeholder,
    validation,
    label,
    disabled,
    isRequired,
    messagesError,
    onClearError,
    errorKey,
}) => {
    const { control, setValue, trigger } = useFormContext();

    const defaultValidation = {
        required: { value: true, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^\+7\(9\d{2}\) \d{3}-\d{2}-\d{2}$/,
            message: 'Поле обязательно для заполнения',
        },
        validate: {
            noUnderscore: (value) => !value.includes('_') || 'Поле обязательно для заполнения',
        },
        ...validation,
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const handleInputChange = (e) => {
        let value = e.target.value;

        if (!value.startsWith('+7(9')) {
            value = '+7(9' + value.slice(5);
        }

        setValue(e.target.name, value);

        if (messagesError && onClearError) {
            onClearError(errorKey);
        }
    };

    const handleBlur = () => {
        trigger(name);
    };

    const handleClear = () => {
        const clearedView = '';
        field.onChange(clearedView);
        trigger(field.name);

        if (onClearError) {
            onClearError(errorKey);
        }
    };

    return (
        <FieldWrapper
            error={error}
            messagesError={messagesError}
            label={label}
            clearCondition={field.value}
            isRequired={isRequired}
            onClear={handleClear}
            disabled={disabled}
        >
            <InputMask
                {...field}
                className={`${!error ? '' : 'invalid'}`}
                mask={'+7 (999) 999-99-99'}
                inputMode="numeric"
                placeholder={placeholder}
                id={name}
                type="tel"
                maskChar={'_'}
                disabled={disabled}
                style={{ color: disabled && 'gray' }}
                onChange={handleInputChange}
                onBlur={handleBlur}
                value={field.value || ''}
            />
        </FieldWrapper>
    );
};

export default InputTel;
