import React from 'react';
import { ReviewCardStl } from './reviewCard.styled';
import RatingStars from '../../../share/RatingStars';
import { getDateForReviews } from '../../../share/helpers';
import RoundPhoto from '../../../share/RoundPhoto';
import AvatarDefault from '../../../../img/avatar_default.png';

/**
 * Компонент `ReviewCard` отображает карточку с отзывом пациента о враче.
 * Карточка включает фотографию пациента, имя, рейтинг, дату отзыва и текст отзыва.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.feedback - Объект отзыва, содержащий данные пациента и отзыва.
 * @param {string} props.feedback.patient_avatar - URL-адрес аватара пациента. Если не указан, используется изображение по умолчанию.
 * @param {string} props.feedback.patient_first_name - Имя пациента.
 * @param {number} props.feedback.rating - Рейтинг, выставленный пациентом (например, от 1 до 5).
 * @param {string} props.feedback.created - Дата создания отзыва в виде строки.
 * @param {string} props.feedback.text - Текст отзыва пациента.
 * @returns {JSX.Element} Компонент карточки отзыва.
 */
export const ReviewCard = ({ feedback, doctorCard = false, patientCard = false }) => {
    /**
     * URL-адрес изображения врача. Использует изображение по умолчанию, если изображение врача отсутствует.
     * @type {string}
     */
    const avatar = feedback.patient_avatar ? feedback.patient_avatar : AvatarDefault;

    return (
        <ReviewCardStl>
            {doctorCard ? (
                <p className="docReview__title">Пациент оставил отзыв</p>
            ) : patientCard ? (
                <p className="docReview__title"> Вы оставили отзыв</p>
            ) : (
                ''
            )}
            <div className="docReview__card">
                <RoundPhoto size="49px" addr={avatar} />
                <div className="docReview__card_header">
                    <h3 className="docReview__card_patient-name">{feedback.patient_first_name}</h3>
                    <div>
                        <RatingStars score={feedback.rating} />
                        <p className="docReview__card_date">{getDateForReviews(feedback.created)}</p>
                    </div>
                </div>
            </div>
            <div className="docReview__card_body">
                <p className="docReview__card_description">{feedback.text}</p>
            </div>
        </ReviewCardStl>
    );
};
