import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../../share/api';
import { removeFavoriteDoctors, removeFavoriteDoctorsUid, setFavoriteDoctors } from './patientApi';

export const fetchFavoriteDoctors = createAsyncThunk(
    'favoriteDoctors/fetchFavoriteDoctors',
    async ([search = '', size = 6], { getState }) => {
        try {
            const next = getState().favoriteDoctors.next;
            if (next) {
                const res = await apiRequest.get(next);
                return res.data;
            }
            const res = await apiRequest.get(`/doctors/favorites/by_patient/?search=${search}&page_size=${size}`);
            return res.data;
        } catch (err) {
            // console.log(err);
        }
    }
);
export const fetchSetFavoriteDoctor = createAsyncThunk('favoriteDoctors/fetchSetFavoriteDoctors', async (id) => {
    try {
        const res = await setFavoriteDoctors(id);
        return res;
    } catch (err) {
        // console.log(err);
    }
});
export const fetchRemoveFavoriteDoctor = createAsyncThunk('favoriteDoctors/fetchRemoveFavoriteDoctors', async (id) => {
    try {
        const res = removeFavoriteDoctors(id);
        return res;
    } catch (err) {
        // console.log(err);
    }
});

export const fetchRemoveFavoriteDoctorUid = createAsyncThunk(
    'topDoctorsSlice/fetchRemoveFavoriteDoctorsUid',
    async (uid) => {
        try {
            const res = await removeFavoriteDoctorsUid(uid);
            return res;
        } catch (err) {
            // console.log(err);
        }
    }
);

const favoriteDoctors = createSlice({
    name: 'favoriteDoctors',
    initialState: {
        favorite: [],
        flagChangedFavDocs: false,
        next: null,
    },
    reducers: {
        resetFavoritesDoctors: (state) => {
            state.favorite = [];
            state.next = null;
        },
        removeCardFavoriteDoctor: (state, action) => {
            state.favorite = state.favorite.filter((doc) => doc.doctor.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFavoriteDoctors.fulfilled, (state, action) => {
            const newFavorite = action.payload.data?.users.filter(
                (user) => !state.favorite.some((item) => item.id === user.id)
            );
            state.favorite.push(...newFavorite);
            state.next = action.payload?.next;
        });
        builder.addCase(fetchRemoveFavoriteDoctorUid.pending, (state) => {
            state.next = null;
        });
        builder.addCase(fetchSetFavoriteDoctor.fulfilled, (state) => {
            state.flagChangedFavDocs = !state.flagChangedFavDocs;
        });
        builder.addCase(fetchRemoveFavoriteDoctor.fulfilled, (state) => {
            state.flagChangedFavDocs = !state.flagChangedFavDocs;
        });
        builder.addCase(fetchRemoveFavoriteDoctorUid.fulfilled, (state) => {
            state.flagChangedFavDocs = !state.flagChangedFavDocs;
        });
    },
});

export default favoriteDoctors.reducer;
export const { resetFavoritesDoctors, removeCardFavoriteDoctor } = favoriteDoctors.actions;
