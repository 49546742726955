import React from 'react';
import { FeedbackForm } from './feedbackForm/FeedbackForm';
import { ReviewCard } from '../../../../../doctorProfile/doctorReviews/reviewCard/ReviewCard';

/**
 * Компонент для отображения отзыва о консультации.
 * Если отзыв уже существует, отображается карточка с отзывом.
 * Если отзыва нет, отображается форма для его отправки.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {number} props.consultationId ID консультации.
 * @param {Object|null} props.feedback Объект отзыва, если он уже существует.
 * @returns {JSX.Element} Компонент отзыва.
 */
export const Feedback = ({ consultationId, feedback }) => {
    return (
        <>
            {feedback ? (
                <ReviewCard feedback={feedback} patientCard={true} />
            ) : (
                <FeedbackForm consultationId={consultationId} />
            )}
        </>
    );
};
