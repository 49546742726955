import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConsultationList } from './ConsultationList';
import { ConsultationListStl } from './consultationList.styled';
import { Modal } from '../../../share/Modal.jsx';
import { ModalSuccessfulConsultation } from '../../../lkDoctor/lkDoctorModals/modalSuccessfulConsultation/ModalSuccessfulConsultation.jsx';
import { setIsModalSuccessfulConsultationPatient } from '../../api/PatientConsultationsSlice';
import { AxiosError } from '../../../share/axiosError/AxiosError.jsx';

/**
 * Компонент `PatientConsultationList`.
 *
 * Отвечает за отображение списка консультаций (прошедших или будущих),
 * а также управление модальными окнами и пагинацией.
 *
 * @component
 * @returns {JSX.Element} Элемент списка консультаций или дочерний маршрут.
 *
 * @example
 * <Route path="/lk/patient-profile/consultation" element={<PatientConsultationList />}>
 *   <Route path="future/:id" element={<FutureConsultationDetails />} />
 *   <Route path="past/:id" element={<PastConsultationDetails />} />
 * </Route>
 */
export const PatientConsultationList = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const regex = /\/\d+$/;
    const { patientConsultations, isModalSuccessfulConsultation, timeConsultation, messagesError } = useSelector(
        (state) => state.patientConsultations
    );
    const axiosError = messagesError.AxiosError;
    const role = 'patient';

    const [consultationsList, setConsultationsList] = useState({});

    useEffect(() => {
        setConsultationsList((prev) =>
            pathname.includes('future') ? patientConsultations.future : patientConsultations.past
        );
    }, [pathname, patientConsultations.future.consultations, patientConsultations.past.consultations]);

    const closePopupSuccessfulConsultation = () => {
        dispatch(setIsModalSuccessfulConsultationPatient());
    };

    // Если текущий маршрут соответствует шаблону (содержит ID консультации), рендерится компонент Outlet с конкретной консультацией.
    // Если путь не содержит чисел в конце, рендерится компонент ConsultationList.
    return (
        <ConsultationListStl>
            {axiosError && <AxiosError error={axiosError} />}
            {regex.test(pathname) ? <Outlet /> : <ConsultationList list={consultationsList} pathname={pathname} />}
            {isModalSuccessfulConsultation && (
                <Modal toggle={closePopupSuccessfulConsultation}>
                    <ModalSuccessfulConsultation
                        closePopupSuccessfulConsultation={closePopupSuccessfulConsultation}
                        timeConsultation={timeConsultation}
                        role={role}
                    />
                </Modal>
            )}
        </ConsultationListStl>
    );
};
