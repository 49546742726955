import React, { useState } from 'react';
import { StyledConsultationButtons } from './consultationButtons.styled';
import { Modal } from '../../../share/Modal';
import { CancelConsultationModal } from '../../lkDoctorModals/closeConsultationModal/closeConsultationModal';
import { StartConsultationModal } from '../../lkDoctorModals/startConsultationModal/startConsultationModal';
import { useDispatch } from 'react-redux';
import { setKeyConsultationDoctor } from '../../api/lkDoctorConsultationsSlice';
import { useLocation } from 'react-router-dom';
import { useTimer } from '../../../../hooks/useTimer';

/**
 * Компонент отображает кнопки для управления консультацией (например, начать или отменить).
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.consultation - Объект консультации, содержащий информацию о статусе, времени, пациенте и других данных.
 * @param {number} props.consultation.status - Текущий статус консультации.
 * @param {Object} props.consultation.patient - Объект с данными пациента.
 * @param {Object} props.consultation.patient.user - Объект с персональными данными пациента.
 * @param {string} props.consultation.patient.user.last_name - Фамилия пациента.
 * @param {string} props.consultation.patient.user.first_name - Имя пациента.
 * @param {string} props.consultation.patient.user.middle_name - Отчество пациента.
 * @param {string} props.consultation.consultation_time - Дата и время консультации в формате строки.
 * @param {string} props.consultation.security_key - Уникальный ключ безопасности для консультации.
 * @returns {JSX.Element} JSX-элемент, представляющий кнопки управления консультацией.
 */

export const ConsultationButtons = ({ consultation }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const regex = /\/\d+$/;
    const isSingleConsultation = regex.test(pathname);

    const [startConsultation, setStartConsultation] = useState(false);
    // const [firstCancelConfirm, setFirstCancelConfirm] = useState(false);  / оставлено на следующий релиз
    const { status, patient, id, consultation_time, security_key, is_payed } = consultation;
    const { last_name, first_name, middle_name } = patient.user;
    let fullName = `${last_name} ${first_name} ${middle_name}`;
    let buttons;

    const { minutes, isExpired } = useTimer({ date: consultation_time, intervalMs: 60000, trackSeconds: false });

    const disabledStart = (minutes >= 30 && isExpired) || (minutes >= 15 && !isExpired) || !is_payed;
    // для тестирования видео-комнаты:
    // const disabledStart = (minutes >= 30 && isExpired) || (minutes >= 60 && !isExpired);

    // оставлено на следующий релиз
    /**
     * Обработчик отмены консультации.
     * Открывает модальное окно для подтверждения отмены.
     *
     * @param {React.MouseEvent} e - Событие клика мыши.
     */
    // const cancelConsultation = (e) => {
    //     e.stopPropagation();
    //     setFirstCancelConfirm(true);
    // };

    /**
     * Обработчик открытия модального окна для начала консультации.
     * Устанавливает ключ безопасности консультации в Redux store.
     *
     * @param {React.MouseEvent} e - Событие клика мыши.
     */
    const openStartModal = (e) => {
        e.stopPropagation();
        setStartConsultation(true);
        dispatch(setKeyConsultationDoctor(security_key));
    };

    switch (status) {
        // новая
        case 0:
            buttons = (
                <>
                    <button
                        onClick={openStartModal}
                        className={disabledStart ? 'disabledBtn' : 'startBtn'}
                        disabled={disabledStart}
                    >
                        Начать консультацию
                    </button>

                    {/* оставлено на следующий релиз */}
                    {/* <button onClick={cancelConsultation} className="closeBtn">Отменить</button> */}
                </>
            );
            break;
        // состоялась
        case 1:
            if (!consultation.recommendation) {
                buttons = (
                    <>
                        <button className="startBtn">Выдать заключение</button>
                    </>
                );
            } else {
                buttons = (
                    <>
                        <p className="recommendText">Посмотреть заключение</p>
                    </>
                );
            }
            break;
        // отменена
        case 2:
            buttons = null;
            break;
        // началась
        case 3:
            buttons = (
                <button
                    onClick={openStartModal}
                    className={disabledStart ? 'disabledBtn' : 'startBtn'}
                    disabled={disabledStart}
                >
                    Начать консультацию
                </button>
            );
            break;
        //прервана
        case 8:
            buttons = (
                <button
                    onClick={openStartModal}
                    className={disabledStart ? 'disabledBtn' : 'startBtn'}
                    disabled={disabledStart}
                >
                    Начать консультацию
                </button>
            );
            break;
        // не проводилась
        case 9:
            buttons = null;
            break;
        default:
            buttons = null;
    }

    return (
        <StyledConsultationButtons isSingleConsultation={isSingleConsultation}>
            {buttons}
            {startConsultation && (
                <Modal toggle={setStartConsultation}>
                    <StartConsultationModal
                        consultation={id}
                        isOpen={startConsultation}
                        setIsOpen={setStartConsultation}
                        patient={fullName}
                        consultationDate={consultation_time}
                    />
                </Modal>
            )}
            {/* оставлено на следующий релиз */}
            {/* {firstCancelConfirm && (
                <Modal toggle={setFirstCancelConfirm}>
                    <CancelConsultationModal
                      consultation={consultation}
                      setFirstConfirm={setFirstCancelConfirm}
                      reload={true}
                    />
                </Modal>
            )} */}
        </StyledConsultationButtons>
    );
};
