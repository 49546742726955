import styled from 'styled-components';

export const StyledUserLogo = styled.div`
    position: relative;
    height: 156px;
    width: 156px;
    cursor: pointer;

    input {
        display: none;
    }

    > img {
        position: absolute;
        top: 0;
        object-fit: cover;
    }

    .hoverImg {
        opacity: 0;
    }

    &:hover div > img {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &:hover .hoverImg {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .avatarSpinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
    }
`;
