import React from 'react';
import { StyledDoctorCard } from './doctorCard.styled';
import { DoctorCardProfile } from './doctorCardProfile/doctorCardProfile';
import { DoctorSchedule } from './doctorSchedule/doctorSchedule';
import { useNavigate } from 'react-router-dom';

/**
 * Компонент DoctorCard отображает карточку врача с возможностью навигации на детальную страницу врача.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.doctor - Объект, содержащий данные о враче.
 * @param {number|string} props.doctor.id - Уникальный идентификатор врача для получения данных о враче при переходе на его страницу.
 * @param {string} props.doctor.slug - Уникальный текстовый идентификатор (слаг) для врача.
 * @returns {JSX.Element} Карточка врача.
 */
export const DoctorCard = ({ doctor, consultations }) => {
    const navigate = useNavigate();

    return (
        <StyledDoctorCard onClick={() => {
            navigate(`${doctor.id}/${doctor.slug}`);
            // Запоминаем, что был переход на страницу врача
            sessionStorage.setItem("visitedDoctorPage", "true");
            // Запоминаем координаты прокрутки
            sessionStorage.setItem("scrollPosition", window.scrollY);
        }}>
            <DoctorCardProfile doctor={doctor} isOurDoctors={true} />
            <DoctorSchedule doctor={doctor} consultations={consultations} />
        </StyledDoctorCard>
    );
};
