import React from 'react';
import { useSelector } from 'react-redux';
import { TitleStl } from '../title/titleStl.styled';
import { SectionStl } from '../viewDoctor.styled';
import { ViewWork } from './ViewWork';

/**
 * Компонент для отображения информации об опыте работы доктора.
 *
 * @component
 * @returns {JSX.Element} Компонент секции с информацией об опыте работы.
 *
 * @example
 * // Пример использования в компоненте
 * <DoctorWork />
 *
 * @description
 * Компонент отображает заголовок "Опыт работы" и вызывает компонент `ViewWork`,
 * передавая ему данные о предыдущих местах работы доктора, полученные из состояния Redux.
 */

export const DoctorWork = () => {
    const work = useSelector((state) => state.doctorProfile?.doctorData?.work);

    return (
        <SectionStl>
            <TitleStl>
                <h2 className="title">Опыт работы</h2>
            </TitleStl>
            <ViewWork work={work} />
        </SectionStl>
    );
};
