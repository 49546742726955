import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import { HomePage } from '../pages/homePage/homePage';
import About from '../pages/About';
import { Authentication } from '../pages/authentication/Authentication';
import { Login } from '../pages/login/Login';
import Contacts from '../pages/contactUsPage/Contacts';
import { OurDoctorsPage } from '../pages/ourDoctorsPage/ourDoctorsPage';
import { DoctorProfilePage } from '../pages/doctorProfile/DoctorProfilePage';
import { LkPatient } from '../componets/lkPatient/lkPatient/lkPatient';
import { LkPatientProfile } from '../componets/lkPatient/lkPatientProfile/lkPatientProfile';
import { ViewPatient } from '../componets/lkPatient/lkPatientProfile/viewPatient/viewPatient';
import { LkPatientConsultation } from '../componets/lkPatient/lkPatientConsultation/lkPatientConsultation';
import { PatientConsultationList } from '../componets/lkPatient/lkPatientConsultation/consultationList/PatientConsultationList';
import PatientConsultationPage from '../componets/lkPatient/lkPatientConsultation/consultationList/patientConsultationPage/patientConsultationPage';
import { LkPatientFavorites } from '../componets/lkPatient/lkPatintFavorites/lkPatientFavorites';
import LkPatientSettings from '../componets/lkPatient/lkPatientSettings/lkPatientSettings';
import { VideoChatPage } from '../pages/videoChatPage/videoChatPage';
import Vacancies from '../pages/Vacancies';
import Blog from '../pages/blog/Blog';
import { ReviewPage } from '../componets/reviews/reviewPage/reviewPage';
import SearchDoctors from '../pages/serchDoctorsPage/searchDoctors';
import Payment from '../pages/payment';
import { ReviewsRegulations } from '../pages/reviewsRegulations/reviewsRegulations';
import Politics from '../pages/politicsPage/Politics';
import LkDoctor from '../componets/lkDoctor/lkDoctor/lkDoctor';
import LkDoctorProfile from '../componets/lkDoctor/lkDoctorProfile/lkDoctorProfile';
// import { EditDoctorInfo } from '../componets/lkDoctor/lkDoctorProfile/editDoctorInfo/editDoctorInfo';
import { ViewDoctor } from '../componets/lkDoctor/lkDoctorProfile/viewDoctor/viewDoctor';
import { DoctorConsultationList } from '../componets/lkDoctor/lkDoctorConsultations/consultationList/DoctorConsultationList';
import DoctorConsultationPage from '../componets/lkDoctor/lkDoctorConsultations/consultationList/doctorConsultationPage/doctorConsultationPage';
import { Patients } from '../componets/lkDoctor/lkDoctorPatients/Patients';
import PatientPage from '../componets/lkDoctor/lkDoctorPatients/patientPage/patientPage';
import { LkDoctorCalendar } from '../componets/lkDoctor/lkDoctorCalendar/lkDoctorCalendar';
import LkDoctorSettings from '../componets/lkDoctor/lkDoctorSettings/lkDoctorSettings';
import { Wallet } from '../componets/lkDoctor/lkDoctorWallet/Wallet';
import { TransactionsDoctor } from '../componets/lkDoctor/lkDoctorWallet/transactions/TransactionsDoctor';
// import { WalletDetails } from '../componets/lkDoctor/lkDoctorWallet/walletDetails/WalletDetails';
import Error from '../pages/Error';
import ProtectedRoute from './ProtectedRoute';
import { LkDoctorConsultations } from '../componets/lkDoctor/lkDoctorConsultations/lkDoctorConsultations';
import { CalendarViewer } from '../componets/lkDoctor/lkDoctorCalendar/calendarViewer/CalendarViewer';
import { CalendarSettings } from '../componets/lkDoctor/lkDoctorCalendar/calendarSettings/CalendarSettings';
import { BlogList } from '../componets/blog/blogList/BlogList';
import { BlogArticle } from '../componets/blog/blogArticle/BlogArticle';
import EditDoctor from '../componets/lkDoctor/lkDoctorProfile/editDoctor/EditDoctor';
import { ReviewsMain } from '../componets/newReviews/reviewsMain/ReviewsMain';
import Reviews from '../pages/Reviews';
import EditPatient from '../componets/lkPatient/lkPatientProfile/editPatient/EditPatient';
import Useful from '../pages/usefulPage/Usefil';
import { FormDirtyProvider } from './FormDirtyContext';
import RouteChangeHandler from './RouteChangeHandler';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <FormDirtyProvider>
                <RouteChangeHandler>
                    <App />
                </RouteChangeHandler>
            </FormDirtyProvider>
        ),
        children: [
            {
                path: '/',
                element: <HomePage />,
            },
            {
                path: 'about',
                element: <About />,
            },
            {
                // регистрация
                path: 'signup',
                element: <Authentication />,
            },
            {
                // вход
                path: 'login',
                element: <Login />,
            },
            {
                path: 'contacts',
                element: <Contacts />,
            },
            {
                path: 'useful',
                element: <Useful />,
            },
            {
                path: 'doctors',
                element: <OurDoctorsPage />,
            },
            {
                path: 'doctors/:id/:slug',
                element: <DoctorProfilePage />,
            },
            {
                path: 'videochat',
                element: <ProtectedRoute />,
                children: [
                    {
                        path: ':id',
                        element: <VideoChatPage />,
                    },
                ],
            },
            {
                path: 'vacancies',
                element: <Vacancies />,
            },
            {
                path: 'news',
                element: <Blog />,
                children: [
                    {
                        path: ':themeId',
                        element: <BlogList />,
                    },
                    {
                        path: ':themeId/article/:postId',
                        element: <BlogArticle />,
                    },
                ],
            },
            // { path: 'reviews', element: <Reviews /> },
            {
                path: 'searchDoctors',
                element: <SearchDoctors />,
            },
            {
                path: 'politics',
                element: <Politics />,
            },
            // {
            //     path: 'payment/:id/successful',
            //     element: <Payment />,
            // },
            {
                path: 'regulations_reviews',
                element: <ReviewsRegulations />,
            },
            {
                path: 'lk',
                element: <ProtectedRoute />,
                children: [
                    {
                        path: 'patient-profile',
                        element: <LkPatient />,
                        children: [
                            {
                                path: 'profile',
                                element: <LkPatientProfile />,
                                children: [
                                    {
                                        path: 'view',
                                        element: <ViewPatient />,
                                    },
                                    {
                                        path: 'edit',
                                        element: <EditPatient />,
                                    },
                                ],
                            },
                            {
                                path: 'consultation',
                                element: <LkPatientConsultation />,
                                children: [
                                    {
                                        path: 'future-consultation',
                                        element: <PatientConsultationList />,
                                        children: [
                                            {
                                                path: ':id',
                                                element: <PatientConsultationPage />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'past-consultation',
                                        element: <PatientConsultationList />,
                                        children: [
                                            {
                                                path: ':id',
                                                element: <PatientConsultationPage />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'favorites',
                                element: <LkPatientFavorites />,
                            },

                            {
                                path: 'settings',
                                element: <LkPatientSettings />,
                            },
                        ],
                    },
                    {
                        path: 'doctor-profile',
                        element: <LkDoctor />,
                        children: [
                            {
                                path: 'profile',
                                element: <LkDoctorProfile />,
                                children: [
                                    {
                                        path: 'view',
                                        element: <ViewDoctor />,
                                    },
                                    {
                                        path: 'edit',
                                        element: <EditDoctor />,
                                    },
                                ],
                            },
                            {
                                path: 'consultations',
                                element: <LkDoctorConsultations />,
                                children: [
                                    {
                                        path: 'future',
                                        element: <DoctorConsultationList />,
                                        children: [
                                            {
                                                path: ':id',
                                                element: <DoctorConsultationPage />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'past',
                                        element: <DoctorConsultationList />,
                                        children: [
                                            {
                                                path: ':id',
                                                element: <DoctorConsultationPage />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            // {
                            //     path: 'patients',
                            //     element: <Patients />,
                            // },
                            // {
                            //     path: 'patients/:id',
                            //     element: <PatientPage />,
                            // },
                            {
                                path: 'doctor-calendar',
                                element: <LkDoctorCalendar />,
                                children: [
                                    {
                                        path: 'viewer',
                                        element: <CalendarViewer />,
                                    },
                                    {
                                        path: 'settings',
                                        element: <CalendarSettings />,
                                    },
                                ],
                            },
                            // {
                            //     path: 'wallet',
                            //     element: <Wallet />,
                            //     children: [
                            //         {
                            //             path: 'transactions',
                            //             element: <TransactionsDoctor />,
                            //         },
                            // {
                            //     path: 'wallet-details',
                            //     element: <WalletDetails />,
                            // },
                            //     ],
                            // },

                            {
                                path: 'settings',
                                element: <LkDoctorSettings />,
                            },
                        ],
                    },
                ],
            },
            {
                path: '*',
                element: <Error />,
            },
        ],
    },
]);

export default router;
