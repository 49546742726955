import React, { useEffect } from 'react';
import { StepButton } from './stepButton/StepButton';
import { CalendarHeaderStl } from './calendarHeader.styled';

/**
 * Компонент для отображения заголовка календаря.
 * Отображает месяц, год, а также кнопки для перехода к предыдущему и следующему месяцам.
 *
 * @param {Object} props
 * @param {Date} props.choosenDate - Текущая выбранная дата в календаре.
 * @param {Function} props.setChoosenDate - Функция для изменения выбранной даты.
 *
 * @returns {JSX.Element} Рендерит заголовок календаря с месяцем, годом и кнопками для навигации.
 */

export const CalendarHeader = ({ choosenDate, setChoosenDate, clearChoosenDates }) => {
    return (
        <CalendarHeaderStl>
            <StepButton
                direction={-1}
                setTargetDate={setChoosenDate}
                className="sdh__button sdh__button_turn_left"
                targetDate={choosenDate}
                clearChoosenDates={clearChoosenDates}
            />
            <div className="sdh__short-date">
                <div className="sdh__month">{choosenDate.toLocaleDateString('ru-RU', { month: 'long' })}</div>
                <div className="sdh__year">{choosenDate.getFullYear()}</div>
            </div>
            <StepButton
                direction={1}
                setTargetDate={setChoosenDate}
                className="sdh__button"
                targetDate={choosenDate}
                clearChoosenDates={clearChoosenDates}
            />
        </CalendarHeaderStl>
    );
};
