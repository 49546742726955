import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import ReactSelect, { components } from 'react-select';
import { useController, useFormContext, useFormState } from 'react-hook-form';
import styled from 'styled-components';

import { SelectStl } from '../selectStl.styled';
import { ReactComponent as Cross } from '../../../../img/icons/cross-gray.svg';
import { ReactComponent as Arrow } from '../../../../img/icons/Arrow WEB.svg';
import { FieldWrapperStl, ErrorStl, LabelStl } from '../fieldWrapperStl.styled';
import { useEffect } from 'react';

const SelectCommonFieldStl = styled(FieldWrapperStl)`
    .selected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        gap: 8px;
        &__option {
            border-radius: 10px;
            background-color: #8af0bf;
            display: flex;
            flex-direction: row;
            &-label {
                padding: 7px 8px 6px 15px;
                user-select: none;
            }
            &-clear {
                display: flex;
                align-items: center;
                padding-right: 8px;
                path {
                    stroke: #19be6f;
                }
                &:hover path {
                    stroke: #169056;
                }
            }
        }
    }
    > svg {
        position: absolute;
        right: 24px;
        bottom: 30px;
        display: none;
        &.visible {
            display: block;
        }
        &:hover path {
            stroke: #19be6f;
        }
    }
    .rs__value-container {
        width: 100%;
        max-width: 370px;
    }
    .rs__control {
        width: 100%;
    }
`;

/**
 * Компонент для отображения выбранного значения в выпадающем списке.
 * @param {Object} props - Свойства, переданные в компонент.
 * @returns {JSX.Element} - Отображает единственное выбранное значение.
 */

const SingleValue = (props) => {
    return <components.SingleValue {...props}></components.SingleValue>;
};

/**
 * Компонент для отображения стрелки в выпадающем списке.
 * @param {Object} props - Свойства, переданные в компонент.
 * @returns {JSX.Element} - Отображает стрелку индикатора раскрытия списка.
 */
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Arrow />
        </components.DropdownIndicator>
    );
};

/**
 * Компонент для отображения кнопки очистки выбранного значения.
 * @param {Object} props - Свойства, переданные в компонент.
 * @returns {JSX.Element} - Отображает индикатор очистки.
 */
const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <Cross />
        </components.ClearIndicator>
    );
};

/**
 * Компонент для отображения опции с радиокнопкой.
 * @param {Object} props - Свойства, переданные в компонент.
 * @param {string} props.children - Текстовое значение опции.
 * @returns {JSX.Element} - Отображает опцию со стилем радиокнопки.
 */
const Option = (props) => {
    return (
        <components.Option {...props} className="rs__option_radbtn">
            <div className="rs__option-btn">
                <svg className="svg" height="24px" width="24px">
                    <circle className="radio-outline" cx="12" cy="12" r="11" fill="white" strokeWidth="2" />
                    <circle className="radio-dot" cx="12" cy="12" r="8" />
                </svg>
            </div>
            <span>{props.children}</span>
        </components.Option>
    );
};

/**
 * Компонент для управления отображением индикатора очистки.
 * @param {Object} props - Свойства компонента.
 * @param {React.Ref} ref - Ссылка для управления видимостью индикатора.
 * @returns {JSX.Element} - Кнопка "очистить" с динамической видимостью.
 */

const Clear = forwardRef(function Clear(props, ref) {
    const show = useRef(false);
    const [force, setForce] = useState(false);

    useImperativeHandle(
        ref,
        () => {
            return {
                toShow(inputValue) {
                    const boolInput = Boolean(inputValue);

                    if (boolInput !== show.current) {
                        show.current = boolInput;
                        setForce(() => boolInput);
                    }
                },
            };
        },
        []
    );

    return <Cross className={`${show.current ? 'visible' : ''}`} />;
});

/**
 * Компонент для отображения выбранных опций в виде меток.
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.field - Объект поля для управления значением.
 * @param {Function} props.trigger - Функция для валидации поля.
 * @returns {JSX.Element|null} - Контейнер с выбранными метками или null, если массив пуст.
 */
const SelectedOptions = ({ field, trigger }) => {
    const selected = field.value;
    if (!Array.isArray(selected)) return null;

    return (
        <div className="selected">
            {selected.map((choise) => (
                <div key={choise.value} className="selected__option">
                    <div className="selected__option-label">{choise.label}</div>
                    <div
                        className="selected__option-clear"
                        id={choise.value}
                        onClick={() => {
                            field.onChange(selected.filter((el) => el.value !== choise.value));
                            trigger(field.name);
                        }}
                    >
                        <Cross />
                    </div>
                </div>
            ))}
        </div>
    );
};

/**
 * Компонент Select - Кастомный выпадающий список с поддержкой множественного выбора, очистки, поиска,
 * обязательности, радиокнопок и валидации. Использует React Select и подключается к форме через React Hook Form.
 *
 * Используется в компоненте specializations.jsx из компонентов Filters.jsx и FormDoctor.jsx
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для регистрации.
 * @param {Object} props.validation - Правила валидации.
 * @param {Array<Object>} props.options - Опции для выбора.
 * @param {string} props.placeholder - Плейсхолдер поля.
 * @param {string} props.label - Лейбл поля.
 * @param {boolean} props.isMulti - Флаг возможности выбора нескольких значений.
 * @param {boolean} props.isClearable - Флаг возможности очистки поля.
 * @param {boolean} props.isDisabled - Флаг возможности блокировки поля.
 * @param {boolean} props.isSearchable - Флаг возможности поиска по опциям из встроенной функции React Select.
 * @param {Array|Object} props.defaultValue - Начальное значение при его наличии.
 * @param {boolean} props.isRequired - Флаг для обязательного поля.
 * @param {boolean} props.radioButton - Флаг для отображения радиокнопок, по умолчанию `false`.
 * @param {Object} props.style - Дополнительные стили для элемента.
 * @param {Function} props.onChange - Функция для обработки изменений, по умолчанию не используется.
 * @returns {JSX.Element} - Выпадающий список с кастомными компонентами.
 */
const Select = ({
    name,
    validation = {},
    options,
    placeholder,
    label,
    isMulti,
    isClearable,
    isDisabled = false,
    isSearchable,
    defaultValue,
    isRequired,
    radioButton = false,
    style = {
        showDropdownIndicator: false,
    },
    onChange = () => {},
}) => {
    // Настройка стандартных правил валидации, включая обязательность заполнения
    const defaultValidation = {
        required: { value: isRequired, message: 'Поле обязательно для заполнения' },
        validate: {
            isValidValue: (value) => {
                if (!isRequired) {
                    return true; //поле не обязательное, а значит всегда валидное
                }
                if (Array.isArray(value) && value.length) {
                    return true; //поле не пустое и не равно пустому массиву, а значит валидное
                }
                return 'Поле обязательно для заполнения';
            },
        },
        ...validation,
    };

    const { control, trigger } = useFormContext();

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules: defaultValidation,
    });

    // Настройка компонентов для React Select, включая индикаторы и опции
    let components = {
        DropdownIndicator,
        ClearIndicator,
        ...(radioButton && { SingleValue, Option }),
    };

    const refClear = useRef(null);

    /**
     * Обработчик изменений выбранного значения в компоненте Select.
     * Вызывается при каждом изменении выбора пользователем, обновляя состояние поля в форме
     * и вызывая переданный `onChange` колбэк с новым значением.
     *
     * @param {Array|Object} value - Новое значение выбора.
     * - Если `isMulti` равно true, `value` может быть массивом объектов (мультивыбор).
     * - Если `isMulti` равно false, `value` - это объект, представляющий одно выбранное значение.
     *
     * @returns {void}
     */
    const handleSelectChange = (value) => {
        if (isMulti) {
            // Проверяем, является ли текущее значение массивом (ожидаем для множественного выбора)
            const updatedValue = Array.isArray(value) ? value : [value]; // Преобразуем значение к массиву, если нужно

            // Обновляем значение формы через `field.onChange`, обновляя также родительский `onChange` колбэк
            field.onChange(updatedValue);
            onChange(updatedValue);
        } else {
            // Если `isMulti` false, работаем с одиночным значением
            field.onChange(value);
            onChange(value);
        }

        // Триггерим валидацию для поля, чтобы обновить состояние и возможные сообщения об ошибках
        trigger(field.name);
    };

    /**
     * Обработчик потери фокуса, для запуска валидации.
     */
    const handleBlur = () => {
        trigger(field.name);
    };

    return (
        <SelectCommonFieldStl>
            {label !== undefined && (
                <LabelStl>
                    {label}
                    {isRequired && <span className="fieldWraper__red">*</span>}
                </LabelStl>
            )}
            {isMulti && <SelectedOptions field={field} trigger={trigger} />}
            <SelectStl
                as={ReactSelect}
                {...field}
                onChange={handleSelectChange}
                onInputChange={(value) => {
                    refClear.current.toShow(value);
                }}
                onBlur={handleBlur}
                components={components}
                options={options}
                placeholder={placeholder}
                hideSelectedOptions={false}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                {...(isMulti && {
                    isMulti,
                    controlShouldRenderValue: false,
                    closeMenuOnSelect: false,
                })}
                noOptionsMessage={() => 'Нет совпадений'}
                classNamePrefix="rs"
                radioButton={radioButton}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        borderColor: error ? '#ff3636' : state.isFocused ? '#19BE6F' : '#B6B7BA',
                    }),
                    clearIndicator: (base, state) => ({
                        ...base,
                        display: !isMulti && state.hasValue ? 'flex' : 'none',
                    }),
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        display:
                            state.selectProps.inputValue || (!isMulti && state.hasValue)
                                ? style.showDropdownIndicator
                                    ? 'flex'
                                    : 'none'
                                : 'flex',
                    }),
                }}
            />
            <Clear ref={refClear} />
            <ErrorStl>{error?.message}</ErrorStl>
        </SelectCommonFieldStl>
    );
};

export default Select;
