import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { StyledResults, StyledDoctors } from './searchResult.styled';
import PhotoAlt from '../../../img/photo_alt.svg';
import star from '../../../img/icons/star.svg';
import emptyStar from '../../../img/icons/emptyStar.svg';

/**
 * Компонент для отображения кнопки "Показать все результаты" в результатах поиска или сообщения об отсутствии результатов
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {Array<Object>} props.doctors - Массив объектов с данными о врачах
 * @param {Function} props.setSearchActive - Функция для переключения состояния активности поиска
 * @returns {JSX.Element} Кнопка для перехода к странице всех результатов или сообщение об отсутствии результатов
 */
const ShowAllResults = ({ doctors, setSearchActive }) => {
    const show = doctors.length > 0;
    const AllResults = () => {
        return (
            <div
                onClick={
                    show
                        ? () => {
                              setSearchActive(false);
                          }
                        : null
                }
                className="showallresults__wrapper"
            >
                <span className="showallresults__text">
                    {show
                        ? 'Показать все результаты'
                        : `Ничего не найдено. Введите фамилию или имя доктора. Проверьте корректность ввода.`}
                </span>
                {show ? (
                    <img src="/img/header/arrowToRight.svg" alt="show more" className="showallresults__arrow" />
                ) : null}
            </div>
        );
    };
    return (
        <>
            {show ? (
                <Link to={`/searchDoctors`} style={{ textDecoration: 'none' }}>
                    <AllResults />
                </Link>
            ) : (
                <AllResults />
            )}
        </>
    );
};

/**
 * Компонент для отображения карточки врача в результатах поиска
 * При клике на карточку переход на страницу профиля врача
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {Object} props.doctor - Объект с данными о враче
 * @param {Object} props.doctor.user - Объект с данными пользователя (врач)
 * @param {string} props.doctor.user.image_standart - URL изображения врача
 * @param {string} props.doctor.user.last_name - Фамилия врача
 * @param {string} props.doctor.user.first_name - Имя врача
 * @param {string} props.doctor.user.middle_name - Отчество врача
 * @param {Array<Object>} props.doctor.specialization - Массив специализаций врача
 * @param {string} props.doctor.specialization[].name - Название специализации
 * @param {string} props.doctor.slug - Слаг врача (для навигации)
 * @param {number} props.doctor.rating_average - Средний рейтинг врача
 * @param {Function} props.setSearchActive - Функция для переключения состояния активности поиска
 * @param {Function} props.setSearchValue - Функция для установки значения поиска
 * @returns {JSX.Element} Карточка с краткой информацией о враче
 */

const SearchResultsDoctors = ({ doctor, setSearchActive, setSearchValue }) => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/doctors/${doctor.id}/${doctor.slug}`)}>
            <StyledDoctors
                onClick={() => {
                    setSearchActive(false);
                    setSearchValue('');
                }}
            >
                <img className="searchresultdoctors__img" src={doctor.user.image_standart || PhotoAlt} alt="doctor" />
                <div className="doctor_descr">
                    <div className="doctor_name">
                        {doctor.user.last_name} {doctor.user.first_name} {doctor.user.middle_name}
                        <ul className="doctor_spec">
                            {doctor.specialization.map((item, i) => {
                                return (
                                    <li key={i}>
                                        {item.name}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="doctor__rating">
                    <img src={doctor.rating_average ? star : emptyStar} alt="doctor rating star" />
                    <span className="search__rating" aria-label="doctor rating">
                        {doctor.rating_average}
                    </span>
                </div>
            </StyledDoctors>
        </div>
    );
};

/**
 * Компонент для отображения результатов поиска по врачам
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {Function} props.setSearchActive - Функция для переключения состояния активности поиска
 * @param {Function} props.setSearchValue - Функция для установки значения поиска
 * @returns {JSX.Element} Блок отображения результатов поиска врачей
 */
const SearchResult = ({ setSearchActive, setSearchValue }) => {
    const doctors = useSelector((state) => state.doctors.doctors);

    return (
        <StyledResults>
            <ShowAllResults doctors={doctors} setSearchActive={setSearchActive} />
            {doctors.map((doctor, i) => {
                if (i < 3) {
                    return (
                        <SearchResultsDoctors
                            key={doctor.id}
                            doctor={doctor}
                            setSearchActive={setSearchActive}
                            setSearchValue={setSearchValue}
                        />
                    );
                }
                return null;
            })}
        </StyledResults>
    );
};

export default SearchResult;
