import { apiRequest } from '../../share/api';
/**
 * Позволяет получить список всех пользовательских соглашений.
 *
 * @async
 * @function getAgreements
 * @returns {Promise<Object[]>} - Промис, который разрешается в массив объектов соглашений.
 * @throws {Object} - Выбрасывает ошибку, содержащую ответ сервера (если доступно) или сообщение об ошибке.
 *
 */

export const getAgreements = async () => {
    try {
        const response = await apiRequest.get('/agreements/');
        return response.data;
    } catch (error) {
        // console.log(error);
        throw error.response ? error.response : { message: error.message };
    }
};

/**
 * Позволяет получить конкретное пользовательское соглашение по ID.
 * @async
 * @function getAgreementById
 * @param {string} id - ID соглашения
 * @returns {Promise<Object>} - Данные соглашения
 */
export const getAgreementById = async (id) => {
    try {
        const response = await apiRequest.get(`/agreements/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching agreement by ID:', error);
        throw error.response ? error.response : { message: error.message };
    }
};
