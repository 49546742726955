import React, { useState } from 'react';
import { StyledDoctorSchedule } from './doctorSchedule.styled';
import { Schedule } from './schedule/schedule';
import { FreeTime } from './freeTime/FreeTime';
import { useSelector } from 'react-redux';
import { VisitNoticeModal } from '../../ourDoctorsModals/visitNotice/VisitNoticeModal';
import { useParams } from 'react-router-dom';
import { formatNumber } from '../../../share/helpers';

export const DoctorSchedule = ({ doctor, consultations }) => {
    const [activeNav] = useState('online'); // activeNav = online | clinic | home
    const [selectedDay, setSelectedDay] = useState({});
    const [showModal, setShowModal] = useState(false);
    const consultation = useSelector((state) => state.doctors.consultation);
    const isAuth = useSelector((state) => state.authentification.isAuth);
    const { patientProfile } = useSelector((state) => state.patientProfile);
    const { id, free_reception_time: freeTime, price, nearest_reception_time: nearestTime } = doctor;
    const params = useParams();
    const disabled = consultation.id !== id;
    const existsConsultation = consultations?.some((el) => el.doctor.id === id && el.status === 0);

    // useEffect(() => {
    //     !patientProfile
    //         ? dispatch(fetchPatientProfile())
    //         : patientProfile.first_name === '' && dispatch(fetchPatientProfile());
    // }, []);

    const handleClick = (e) => {
        e.stopPropagation();
        setShowModal(true);
        if (!isAuth) return;
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    return (
        <StyledDoctorSchedule>
            <div className={'navMenu'} onClick={(e) => e.stopPropagation()}>
                {!params.id && <p className={'active'}>Запись на онлайн-консультацию</p>}
            </div>
            <div className="calendar-container">
                <Schedule setSelectedDay={setSelectedDay} selectedDay={selectedDay} freeTime={freeTime} />
                <FreeTime doctor={doctor} selectedDay={selectedDay} freeTime={freeTime} id={id} />
            </div>
            <div className={'price'}>
                <p>Стоимость консультации</p>
                <p>{formatNumber(price)}&nbsp;₽</p>
            </div>

            <button
                disabled={disabled}
                className={`scheduleBtn ${disabled ? 'scheduleBtn-disabled' : ''}`}
                onClick={(e) => handleClick(e)}
            >
                {activeNav === 'online' ? `Записаться` : `Оставить заявку`}
            </button>

            {showModal && (
                <VisitNoticeModal
                    existsConsultation={existsConsultation}
                    patientProfile={patientProfile}
                    closeModal={setShowModal}
                    handleCancelClick={handleCancelClick}
                    typeOfNotice={activeNav}
                    isAuth={isAuth}
                    doctor={doctor}
                />
            )}
        </StyledDoctorSchedule>
    );
};

// export const DoctorSchedule = ({ doctor }) => {
// const dispatch = useDispatch();
// const navigate = useNavigate();
// const [activeNav, setActiveNav] = useState('online'); // activeNav = online | clinic | home
// const [selectedDay, setSelectedDay] = useState({});
// const [showModal, setShowModal] = useState(false);
// const consultation = useSelector((state) => state.doctors.consultation);
// const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
// const isAuth = useSelector((state) => state.authentification.isAuth);
// const { patientProfile } = useSelector((state) => state.patientProfile);
// const { id, free_reception_time: freeTime, video_chat_price: price, nearest_reception_time: nearestTime } = doctor;

// useEffect(() => {
//     !patientProfile
//         ? dispatch(fetchPatientProfile())
//         : patientProfile.user.first_name === '' && dispatch(fetchPatientProfile());
// }, []);

// const handleClick = (e) => {
//     e.stopPropagation();
//     setShowModal(true);
//     if (!isAuth || whoAreYou === 'doctor') return;

//     if (whoAreYou === 'patient') {
//         if (activeNav === 'online') {
//             !patientProfile
//                 ? navigate('/lk/patient-profile/profile')
//                 : patientProfile.user.first_name === '' && navigate('/lk/patient-profile/profile');
//             // создание консультации на бэке и в сторе при открытии попапа оплаты, при оплате через юкассу
//             // const consultationRequest = {
//             //     doctor_id: id,
//             //     request: consultation.comment,
//             //     recommendation: 'string',
//             //     price: price,
//             //     is_payed: false,
//             //     status: 0,
//             //     date: consultation.time ? consultation.time : nearestTime,
//             // };
//             // dispatch(fetchRecordConsultations(consultationRequest));
//             // dispatch(setConsultation({ doctor, time: consultationRequest.date }));
//         }
//         if (activeNav === 'clinic') {
//             const data = {
//                 doctor_id: doctor.id,
//                 visit_type: 1,
//             };
//             postVizit(data);
//         }
//         if (activeNav === 'home') {
//             const data = {
//                 doctor_id: doctor.id,
//                 visit_type: 0,
//             };
//             postVizit(data);
//         }
//     }
// };

// const handleCancelClick = () => {
//     setShowModal(false);
// };

// const closeModal = (e) => {
//     // удаление консультации с бэка и из стора при закрытии попапа оплаты, при оплате через юкассу
//     // if (!e && activeNav === 'online' && consultation.id) {
//     //     dispatch(fetchMyConsultationFullDelete(consultation.id));
//     //     dispatch(resetConsultation());
//     // }
//     setShowModal(false);
// };

// const toggleNav = (event) => {
//     if (!event.target.checked) return;
//     setActiveNav(event.target.value);
// };

// return (
//     <StyledDoctorSchedule>
//         <div className={'price'}>
//             <p>Стоимость консультации</p>
//             <p>{doctor.price}&nbsp;₽</p>
//         </div>
//     </StyledDoctorSchedule>
// );

// return (
//     <StyledDoctorSchedule>
//         <div className={'navMenu'} onClick={(e) => e.stopPropagation()}>
//             <input
//                 className="navMenu__input"
//                 name="doctor_schedule_input"
//                 value="online"
//                 type="radio"
//                 id={`online-${id}`}
//                 onClick={toggleNav}
//             />
//             <label htmlFor={`online-${id}`}>
//                 <p className={activeNav === 'online' ? 'active' : ''}>Онлайн</p>
//             </label>

//             <input
//                 className="navMenu__input"
//                 name="doctor_schedule_input"
//                 value="clinic"
//                 type="radio"
//                 id={`clinic-${id}`}
//                 onClick={toggleNav}
//             />
//             <label htmlFor={`clinic-${id}`}>
//                 <p className={activeNav === 'clinic' ? 'active' : ''}>В&nbsp;клинике</p>
//             </label>

//             <input
//                 className="navMenu__input"
//                 name="doctor_schedule_input"
//                 value="home"
//                 type="radio"
//                 id={`home-${id}`}
//                 onClick={toggleNav}
//             />
//             <label htmlFor={`home-${id}`}>
//                 <p className={activeNav === 'home' ? 'active' : ''}>На&nbsp;дом</p>
//             </label>
//         </div>
//         <div className="calendar-container">
//             {/* вдруг опять макет перепишут
//                 {activeNav !== 'home' && (
//                 <Schedule setSelectedDay={setSelectedDay} selectedDay={selectedDay} freeTime={freeTime} />
//             )} */}
//             {activeNav === 'online' && (
//                 <>
//                     <Schedule setSelectedDay={setSelectedDay} selectedDay={selectedDay} freeTime={freeTime} />
//                     <FreeTime selectedDay={selectedDay} freeTime={freeTime} />
//                 </>
//             )}
//             {activeNav === 'clinic' && (
//                 <p className="calendar-container__text calendar-container__text_clinic">
//                     Доступное время можно уточнить у оператора после оставления заявки.
//                 </p>
//             )}
//             {activeNav === 'home' && (
//                 <p className="calendar-container__text">
//                     Вызов на дом осуществляется в пределах Москвы и ближайшего Подмосковья. Подробности можно
//                     уточнить у оператора после оставления заявки.
//                 </p>
//             )}
//         </div>
//         <div className={'price'}>
//             <p>Стоимость консультации</p>
//             <p>{doctor.video_chat_price}&nbsp;₽</p>
//         </div>

//         <button
//             disabled={freeTime.length === 0}
//             className={`scheduleBtn ${freeTime.length === 0 ? 'scheduleBtn-disabled' : ''}`}
//             onClick={(e) => handleClick(e)}
//         >
//             {activeNav === 'online' ? `Записаться` : `Оставить заявку`}
//         </button>

//         {showModal && (
//             <VisitNoticeModal
//                 closeModal={closeModal}
//                 handleCancelClick={handleCancelClick}
//                 typeOfNotice={activeNav}
//                 isAuth={isAuth}
//                 doctor={doctor}
//             />
//         )}
//     </StyledDoctorSchedule>
// );
// };
