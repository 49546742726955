import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageHover from '../../img/photo_hover.svg';
import PhotoAlt from '../../img/photo_alt.svg';
import { StyledUserLogo } from './avatar.styled';
import RoundPhoto from '../share/RoundPhoto';
import { uploadConsultationFile, uploadFile } from '../share/fileUploader';
import { processTiffFile } from '../../utils/tiffProcessor';
import { Spinner } from '../share/Spinner';
import { validateFile } from '../share/helpers';

/**
 * Компонент аватара пользователя.
 * Отображает аватар пользователя с возможностью его изменения и удаления.
 *
 * @component
 * @param {Object} props - Пропсы компонента
 * @param {Object} props.userProfile - Данные профиля пользователя
 * @param {Function} props.fetchDeleteMyAvatar - Действие для удаления аватара
 * @param {Function} props.fetchLoadMyAvatar - Действие для загрузки нового аватара
 *
 * @returns {JSX.Element} Компонент для отображения и изменения аватара
 */

export const Avatar = ({ userProfile, fetchDeleteMyAvatar, fetchLoadMyAvatar }) => {
    const dispatch = useDispatch();
    const [errorLoadAvatar, setErrorLoadAvatar] = useState('');
    const [localAvatarUrl, setLocalAvatarUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const refAvatar = useRef();
    /**
     * Таймер для сброса ошибки через 5 секунд.
     * Если ошибка загрузки аватара возникает, она будет удалена через 5 секунд.
     */
    useEffect(() => {
        if (errorLoadAvatar) {
            const delayAvatarId = setTimeout(() => setErrorLoadAvatar(''), 5000);
            return () => clearTimeout(delayAvatarId);
        }
    }, [errorLoadAvatar]);

    /**
     * Обработчик загрузки нового фото аватара.
     * Проверяет размер файла и отправляет его на сервер через fetchLoadMyAvatar.
     * Сбрасывает значения input после обработки файла
     */

    const addPhoto = () => {
        const sendUpResult = async (file) => {
            setIsLoading(true);
            try {
                let processedFile = file;

                if (file.type === 'image/tiff' || file.type === 'image/x-tiff') {
                    const tiffDataUrl = await processTiffFile(file);
                    const response = await fetch(tiffDataUrl);
                    const blob = await response.blob();
                    processedFile = new File([blob], file.name.replace(/\.tiff?$/, '.png'), { type: 'image/png' });
                }
                await dispatch(fetchLoadMyAvatar(processedFile)).unwrap();
                setLocalAvatarUrl(URL.createObjectURL(processedFile));
            } catch (error) {
                // console.log('error loading file', err);
            } finally {
                setIsLoading(false); // Выключаем спиннер
            }

            refAvatar.current.value = '';
        };

        uploadConsultationFile(
            refAvatar,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.tiff'],
                validate: (file) => validateFile(file, ['.jpg', '.png', '.jpeg', '.tiff']),
                onValidationError: (errorMessage) => setErrorLoadAvatar(errorMessage),
            },
            sendUpResult
        );
    };
    /**
     * Обработчик удаления текущего аватара.
     * Удаляет аватар через fetchDeleteMyAvatar.
     */
    const handleDeleteAvatar = async () => {
        try {
            await dispatch(fetchDeleteMyAvatar()).unwrap();
            setLocalAvatarUrl('');
        } catch (error) {
            setErrorLoadAvatar('Ошибка при удалении изображения');
        }
    };

    // Выбор URL аватара: либо локально загруженный, либо из профиля пользователя, либо по умолчанию
    const avatarUrl = localAvatarUrl || userProfile?.image_standart || PhotoAlt;

    return (
        <div className="avatar">
            <StyledUserLogo onClick={addPhoto}>
                <img className="hoverImg" src={ImageHover} />
                {/* <RoundPhoto className="hoverImg" src={ImageHover} size="156px" /> */}
                <RoundPhoto size="156px" addr={avatarUrl} />
                {isLoading && <Spinner className="avatarSpinner" margin-top={'0'} />}
                <input type="file" name="" id="loadPhoto" ref={refAvatar} />
            </StyledUserLogo>

            {!errorLoadAvatar && (userProfile.image_standart || localAvatarUrl) ? (
                <button className="deleteAvatarButton" onClick={handleDeleteAvatar}>
                    Удалить фото
                </button>
            ) : null}
            {errorLoadAvatar && <span className="errorLoadAvatar">{errorLoadAvatar}</span>}
        </div>
    );
};
