import React from 'react';
import Socials from './Socials';

function FooterContacts() {
    const onScroll = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div className='footer__block'>
            <h2 className="footer__title">Контакты</h2>
            <a href="tel: +74951441495" onClick={onScroll} className="footer__link">
                +7 (495) 14-41-495
            </a>
            <a href="mailto: info@doct24.ru" onClick={onScroll} className="footer__link">
                info@doct24.ru
            </a>
            <div className="footer__social">
                <Socials socials={['vk', 'odnok', 'telegram']} />
            </div>
        </div>
    );
}

export default FooterContacts;