import React, { useEffect, useState } from 'react';
import TopBox from '../../componets/homePage/topBox/TopBox';
import Statistic from '../../componets/homePage/statisticSection/Statistic';
import TopDrCarousel from '../../componets/homePage/carousel/topDrCarousel/topDrCarousel';
import Services from '../../componets/homePage/services/services';
import Gallery from '../../componets/homePage/gallery/Gallery';
import CarouselReview from '../../componets/homePage/carousel/carouselReview';
import { useLocation } from 'react-router-dom';
import { DoctorsTop } from '../../componets/home/doctorTop/DoctorsTop';
import ArticlesNews from '../../componets/homePage/articlesNews/articlesNews/ArticlesNews';
import { StyledHomePage } from './homePage.styled';
import TopBanner from '../../componets/home/topBanner/TopBanner';
import InfoBlock from '../../componets/home/infoBlock/InfoBlock';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortalReviews } from '../../componets/newReviews/api/reviewsSlice';
import { fetchTopDoctors, fetchTopDoctorsAuth } from '../../componets/home/doctorTop/api/doctorsTopSlice';

export const HomePage = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [showDoctors, setShowDoctors] = useState(false);
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);

    const options = {
        // root: по умолчанию window
        rootMargin: '0px 0px 75px 0px',
        threshold: 0,
    };
    const callback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                // if (entry.target.classList.contains('reviews')) {
                //     // console.log('Пользователь почти докрутил до reviews!');
                //     // запрос на отзывы
                //     dispatch(fetchPortalReviews());
                // }
                if (entry.target.classList.contains('articlesNews')) {
                    // console.log('Пользователь почти докрутил до articlesNews!');
                    // запрос на отзывы
                    // debugger;
                }
                observer.unobserve(entry.target);
            }
        });
    };
    const observer = new IntersectionObserver(callback, options);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const articlesSection = document.querySelector('.articlesNews');
        if (articlesSection) {
            observer.observe(articlesSection);
        }
    }, []);

    return (
        <StyledHomePage>
            <TopBanner />
            {whoAreYou !== 'doctor' && <DoctorsTop />}
            <InfoBlock />
            {/* Данные разделы не будут использоваться */}
            {/* <Statistic /> */}
            {/* <TopDrCarousel /> */}
            {/* <Services /> */}
            {/* <Gallery /> */}
            {/* Данный раздел временно убран т.к. нет эндпоинта */}
            {/* <CarouselReview /> */}
            <ArticlesNews />
        </StyledHomePage>
    );
};
