import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoadFileStl } from './consultationFiles.styled';
import { uploadConsultationFile } from '../../../../../share/fileUploader';
import {
    sendConsultationFile,
    fetchDoctorConsultation,
    deleteConsultationFile,
} from '../../../../api/lkDoctorConsultationsSlice';
import { ReactComponent as Certificate } from '../../../../../../img/certificate.svg';
import addFile from '../../../../../../img/addFile.svg';
import { ReactComponent as CertificateDelete } from '../../../../../../img/certificate-delete.svg';
import { validateFile } from '../../../../../share/helpers';
import { Spinner } from '../../../../../share/Spinner';

/**
 * Компонент для управления файлами консультации.
 * Позволяет добавлять, удалять и просматривать файлы, связанные с консультацией.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.consultationId - ID текущей консультации.
 * @param {Array<Object>} props.files - Список файлов, связанных с консультацией.
 * @param {number} props.progress_files - Прогресс загрузки файла в процентах.
 * @param {boolean} props.readOnly - Если true, компонент отображается только для просмотра (без возможности добавления/удаления файлов).
 * @param {boolean} [props.recommendation=false] - Флаг, указывающий, что компонент используется для отображения рекомендаций.
 *
 * @returns {JSX.Element} - Компонент для работы с файлами консультации.
 */
export const ConsultationFiles = ({ recommendation, consultationId, files, progress_files, readOnly }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [deletingFiles, setDeletingFiles] = useState({});
    const [error, setError] = useState('');
    const [fakeFile, setFakeFile] = useState(null);
    const [realFiles, setRealFiles] = useState(files);
    const loadFile = useRef();

    /**
     * Таймер для сброса ошибки через 5 секунд.
     * Если ошибка загрузки файла возникает, она будет удалена через 5 секунд.
     */
    useEffect(() => {
        if (error) {
            const delayClearError = setTimeout(() => setError(''), 5000);
            return () => clearTimeout(delayClearError);
        }
    }, [error]);

    /**
     * Обновление состояния файлов при изменении переданного пропса `files`.
     */
    useEffect(() => {
        setRealFiles(files);
    }, [files]);

    /**
     * Обновление состояния "фейкового" файла для отображения прогресса загрузки.
     */
    useEffect(() => {
        if (progress_files >= 0 && progress_files <= 100) {
            setFakeFile({});
        }
        if (progress_files === undefined) {
            setFakeFile(null);
        }
    }, [progress_files]);

    /**
     * Обработчик загрузки нового файла.
     * Проверяет валидность файла и отправляет его на сервер.
     *
     * @param {React.SyntheticEvent} e - Событие клика.
     */
    const handleFileUpload = (e) => {
        e.preventDefault();
        uploadConsultationFile(
            loadFile,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.tiff', '.pdf', '.doc', '.docx', '.xls'],
                validate: validateFile,
                onValidationError: (errorMessage) => setError(errorMessage),
            },
            sendUpResult
        );
        if (loadFile.current) {
            loadFile.current.value = '';
        }
    };

    /**
     * Отправляет файл на сервер.
     *
     * @param {File} file - Загружаемый файл.
     */
    const sendUpResult = async (file) => {
        setIsLoading(true);
        try {
            await dispatch(sendConsultationFile({ consultationId, file })).unwrap();
            await dispatch(fetchDoctorConsultation(consultationId)).unwrap();
        } catch (err) {
            // console.log('error loading file', err);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Обработчик удаления файла.
     *
     * @param {string} id - ID удаляемого файла.
     */
    const handleDeleteFile = async (id) => {
        setDeletingFiles((prev) => ({ ...prev, [id]: true }));
        try {
            await dispatch(deleteConsultationFile(id)).unwrap();
            await dispatch(fetchDoctorConsultation(consultationId)).unwrap();
        } catch (err) {
            // console.log('error deleting file', err);
        } finally {
            setDeletingFiles((prev) => ({ ...prev, [id]: false }));
        }
    };

    /**
     * Получает расширение файла из URL.
     *
     * @param {string} url - URL файла.
     * @returns {string|null} - Расширение файла.
     */
    const trimEnding = (url) => {
        const trimmedStr = typeof url === 'string' && url.split('.').slice(-1).toString();
        return trimmedStr;
    };

    if (readOnly) {
        return (
            <LoadFileStl readOnly={readOnly}>
                <div className="files">
                    {files.map((file) => (
                        <div className="file" key={file.id}>
                            <a href={file.file} target="_blank" rel="noreferrer">
                                <Certificate />
                                <div className="file_name">Файл{`.${trimEnding(file.file)}`}</div>
                            </a>
                        </div>
                    ))}
                </div>
            </LoadFileStl>
        );
    }

    return (
        <LoadFileStl>
            <>
                <input type="file" id="loadFile" ref={loadFile} />
                <label htmlFor="loadFile" className="btn" onClick={(e) => handleFileUpload(e)}>
                    {files.length < 3 && <img src={addFile} alt="Добавить файл" />}
                </label>
            </>

            <>
                {(files.length > 0 || fakeFile) && (
                    <div className="files">
                        {realFiles
                            ?.slice()
                            .reverse()
                            .map((file) => (
                                <div key={file.id} className="fileWrapper">
                                    <div className="file">
                                        <a href={file.file} target="_blank" rel="noreferrer">
                                            <Certificate />
                                            <div className="file_name">Файл{`.${trimEnding(file.file)}`}</div>
                                        </a>
                                        {/* Отображение превью документа с возможностью открыть или загрузить его. Сделано на будущее */}
                                        {/* {file.file && <FilePreviewer file={file.file} />} */}
                                        {!recommendation &&
                                            (deletingFiles[file.id] ? (
                                                <Spinner height={'20px'} width={'20px'} marginTop={'0'} />
                                            ) : (
                                                <CertificateDelete onClick={() => handleDeleteFile(file.id)} />
                                            ))}
                                    </div>
                                </div>
                            ))}
                        {fakeFile && (
                            <div key={fakeFile.id} className="fileWrapper">
                                <div className="file">
                                    <a href={fakeFile.file} target="_blank" rel="noreferrer">
                                        <Certificate />
                                        <div className="file_name">Файл...</div>
                                    </a>
                                </div>
                                {
                                    <div className="progress">
                                        <progress max="100" value="50"></progress>
                                        <div className="progress-bg" style={{ width: progress_files - 3 || 0 }}>
                                            <div className="progress-bar"></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                )}
                {error && <div className="error">{error}</div>}
            </>
        </LoadFileStl>
    );
};
