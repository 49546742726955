import { Divider, StyledMobileMenu } from './mobileMenu.styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '../avatar/avatar';
import { Container } from '../../share/Container';
import { Modal } from '../../share/Modal';
import { NavLink } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import PopUpConfirm from '../../share/PopUpConfirm';
import SearchInput from '../search/searchInput';
import { UserNavigation } from '../userNavigation/UserNavigation';
import { logoutDoctor } from '../../lkDoctor/api/doctorUserProfileSlice';
import { logoutPatient } from '../../lkPatient/api/PatientProfileSlice';

/**
 * Компонент MobileMenu, отображающий мобильное меню с навигацией, аватаром, формой поиска и дополнительными функциями.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.user - Информация о пользователе.
 * @returns {JSX.Element} Мобильное меню для сайта.
 * @function handleLogOut - Функция для выхода из профиля пользователя (пациент или врач).
 * @function toggleMenu - Функция для открытия/закрытия мобильного меню.
 * @function handleClickOutside - Закрытие меню при клике вне его области.
 * @useEffect {Dependency: path} Сбрасывает состояние меню при смене маршрута.
 * @useEffect {Dependency: none} Добавляет и удаляет слушатель событий для клика вне области меню.
 */

const MobileMenu = ({ user }) => {
    const path = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mobileMenu = useRef(null);
    const [isOpenDropMenu, setIsOpenDropMenu] = useState(false);

    const { isAuth, whoAreYou } = useSelector((state) => state.authentification);
    const [isPopUpOpen, setPopUpOpen] = useState(false);

    const handleLogOut = () => {
        dispatch(whoAreYou === 'patient' ? logoutPatient() : logoutDoctor());
        setPopUpOpen(false);
        navigate('/');
    };

    const toggleMenu = () => {
        setIsOpenDropMenu((prev) => !prev);
        document.body.classList.toggle('stop-scroll');
    };

    useEffect(() => {
        setIsOpenDropMenu(false);
        document.body.classList.remove('stop-scroll');
    }, [path]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileMenu.current && !mobileMenu.current.contains(event.target)) {
                setIsOpenDropMenu(false);
                document.body.classList.remove('stop-scroll');
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <StyledMobileMenu ref={mobileMenu}>
            <div className="mobileMenu__inner">
                <div className={`mobileMenu__burger ${isOpenDropMenu ? 'open' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={`mobileMenu__dropMenu ${isOpenDropMenu ? 'open' : ''}`}>
                    <Container>
                        {whoAreYou !== 'doctor' && <SearchInput />}
                        <div className="menu-wrapper">
                            <Navbar />
                            {isAuth ? (
                                <>
                                    {' '}
                                    <Divider />
                                    {whoAreYou !== 'doctor' && (
                                        <NavLink className="nav-link nav-button" to="/doctors">
                                            Записаться&nbsp;к&nbsp;врачу
                                        </NavLink>
                                    )}
                                    <Avatar role={user?.role} avatar={user?.avatar} size={26} />
                                    <UserNavigation setPopUpOpen={setPopUpOpen} />
                                </>
                            ) : (
                                <Link className="header__signin-link" to={'login'}>
                                    {' '}
                                    Войти{' '}
                                </Link>
                            )}
                        </div>
                    </Container>
                </div>
                {isPopUpOpen && (
                    <Modal toggle={setPopUpOpen}>
                        <PopUpConfirm
                            onClose={() => setPopUpOpen(false)}
                            onConfirm={() => handleLogOut()}
                            contentHeader={
                                <span>
                                    Вы уверены, что хотите <br /> выйти из личного кабинета?
                                </span>
                            }
                            isClarification={false}
                        />
                    </Modal>
                )}
            </div>
        </StyledMobileMenu>
    );
};

export default MobileMenu;
