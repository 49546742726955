import { PopUpErrorStl } from "./PopUpError.styled";
import { React } from 'react';
import Button from "./Button";
import errorIcon from '../../img/icons/icon-error.svg';
import { useLockBodyScroll } from "../../hooks/useLockBodyScroll";

/**
 * PopUpError - Компонент, который отображает сообщение об ошибке при отправке данных формы 
 * (в разделах Контакты, Вакансии).
 * Позволяет пользователю повторить попытку отправки данных или закрыть модальное окно.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.closeModal - Функция для закрытия модального окна. 
 * Передает булево значение для указания состояния модала.
 * @param {Function} props.handleConfirumClick - Функция для повторной попытки отправки данных формы.
 *
 * @returns {JSX.Element} Возвращает JSX элемент, представляющий состояние ошибки.
 *
 * @example
 * return (
 *   <PopUpError
 *     closeModal={someCloseModalFunction}
 *     handleConfirumClick={someHandleConfirmFunction}
 *   />
 * );
 *
 * Компонент включает в себя следующие элементы:
 * - Иконка ошибки для визуального представления проблемы.
 * - Заголовок с текстом об ошибке.
 * - Текстовое описание с предложением проверить подключение к интернету.
 * - Кнопка для повторной попытки отправки данных формы.
 * - Кнопка закрытия модального окна.
 */

export const PopUpError = ({ closeModal, handleConfirumClick }) => {
    useLockBodyScroll();
    return (
        <PopUpErrorStl>
            <div className="error">
                <img src={errorIcon} alt="error" className="error__icon" />
                <h3 className="error__title">Не удалось отправить сообщение</h3>
                <div className="error__content">
                    <span>
                        Попробуйте снова. Если проблема сохраняется, проверьте подключение к интернету или повторите попытку позже
                    </span>
                </div>
                <div className="error__btn">
                    <Button className="error__btn-repeat" padding="16px 0" font_size="16px" 
                        onClick={handleConfirumClick}>
                        Повторить попытку
                    </Button>
                    <Button className="error__btn-close" padding="16px 0" font_size="16px" 
                        onClick={() => closeModal(() => false)}>
                        Закрыть
                    </Button>
                </div>
            </div>
        </PopUpErrorStl>
    );
};
