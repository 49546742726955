import React from 'react';
import { StyledSearchButton } from './searchButton.styled';

function SearchButton({ isSearchActive, setSearchActive }) {
    function handleClick() {
        setSearchActive(!isSearchActive);
    }

    return (
        <StyledSearchButton name="search_btn" src="/img/header/search.svg" alt="search icon" onClick={handleClick} />
    );
}

export default SearchButton;
