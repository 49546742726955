import styled from 'styled-components';

export const StyledVacanciesContent = styled.div`
    display: grid;
    gap: 6.3%;
    grid-template-columns: 44.6% 49%;
    margin-bottom: 185px;
    min-height: 889px;

    .vacancies {
        &__image {
            position: relative;
            width: 100%;
            min-height: 548px;

            img {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                z-index: 10;
            }
        }

        &__right {
            position: relative;
        }
        &__descr {
            margin-bottom: 64px;
            max-width: 509px;
            font-size: 18px;
            line-height: 150%;
            color: #212121;
        }
        &__form {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 80px 87px;
            border: 2px solid #f4f4f4;
            border-radius: 10px;
            width: 596px;
            height: 717px;
        }
    }

    @media (max-width: 980px) {
        grid-template-columns: 36% 57%;
        gap: 7%;
        margin-bottom: 159px;

        .vacancies {
            &__form {
                padding: 80px 93px;
            }
        }
    }
    @media (max-width: 840px) {
        grid-template-columns: 30% 63%;
        .vacancies {
            &__form {
                width: 540px;
            }
            &__descr {
                max-width: 447px;
            }
        }
    }
    @media (max-width: 567px) {
        display: flex;
        flex-direction: column;
        min-height: auto;
        margin-bottom: 85px;
        .vacancies {
            &__right {
                margin: 0 -16px;
            }
            &__form {
                width: 100%;
                position: relative;
                padding: 40px 16px;
                height: auto;
           
            }
            &__image {
                text-align: center;
                margin-bottom: 40px;
                img {
                    transform: translateX(-50%);
                }
            }
            &__descr {
                margin-bottom: 15px;
                max-width: none;
                width: 100%;
                font-size: 16px;
            }
        }
    }

    @media (max-width: 400px) {
        .vacancies__image {
            min-height: 343px;
            margin-bottom: 40px;
        }
    }
`;
