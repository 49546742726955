import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../share/Modal';
import Switcher from '../../../share/formFields/Switcher';
import { ScheduleSettings } from './schedule/ScheduleSettings';
import { fetchDoctorReceptions, fetchCreateDoctorReception, setLoading } from '../../api/lkDoctorCalendarSlice';
import { Calendar } from '../calendarComponents/calendar/Calendar';
import { DateHint, DateHintAppointment, TimeHint, TimeHintAppointment } from '../calendarComponents/CalendarHint';
import { CalendarModal } from '../calendarComponents/CalendarModal';
import { ErrorCalenderModal } from '../calendarComponents/errorCalendarModal/ErrorCalendarModal';
import { formatUnixDatesForServer } from '../../../../utils/dateTransform';
import errorIcon from '../../../../img/icons/icon-error.svg';
import { preloadImage } from '../calendarComponents/helpers/helpers';

/**
 * Компонент для настройки расписания врача.
 * Позволяет выбирать даты (по отдельности или интервалом),
 * а также временные интервалы для записи.
 *
 * Включает в себя:
 * - Выбор одного дня или интервала.
 * - Выбор времени для записи.
 * - Отправка данных на сервер.
 * - Модальное окно с подтверждением успешного сохранения изменений.
 *
 * @component
 */

export const CalendarSettings = () => {
    // выбраный день, по умолчанию сегодняшний
    const [choosenDate, setChoosenDate] = useState(new Date());
    // выбор одного или нескольких дней
    const [isDays, setIsDays] = useState(false);
    // выбор интервала времени или по отдельности
    const [isInterval, setIsInterval] = useState(false);
    // модальное окно-уведомление о том, что данные сохранены
    const [toShowSaveToast, setToShowSaveToast] = useState(false);
    // модальное окно-уведомление об ошибке сохранения
    const [errorPopUp, setErrorPopUp] = useState(false);
    // массив выбранных дат
    const [choosenDates, setChoosenDates] = useState([]);
    // массив выбранных временных меток
    const [selectedTime, setSelectedTime] = useState([]);
    const dispatch = useDispatch();
    // проверяем, что есть изменения для сохранения
    const [timeIsModified, setTimeIsModified] = useState(false);
    // ориентир на ширину экрана для отрисовки тоста сохранения
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // метка о сохранении
    const [savedAndChoosen, setSavedAndChoosen] = useState(window.innerWidth);

    useEffect(() => {
        setTimeIsModified(false);
        setSavedAndChoosen(false);
        const allReceptions = choosenDates
            .flatMap((day) => day.receptions)
            .filter((reception) => reception.workTime === true);
        if (selectedTime.length === allReceptions.length) {
            setTimeIsModified(false);
        } else {
            setTimeIsModified(true);
        }
    }, [selectedTime, choosenDates]);

    useEffect(() => {
        preloadImage(errorIcon);
    }, []);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSend = async () => {
        setErrorPopUp(false);
        const now = Date.now();
        const timeStamps = selectedTime
            .filter((el) => el * 1000 > now)
            .map((el) => ({ reception_time: new Date(el * 1000).getTime() }));

        const formattedTimeStamps = formatUnixDatesForServer(timeStamps, 'Europe/Moscow', 'dd.MM.yyyy HH:mm:ss');

        try {
            const results = await Promise.allSettled(
                choosenDates.map((el) => dispatch(fetchCreateDoctorReception(formattedTimeStamps)))
            );

            const hasErrors = results.some(
                (result) =>
                    result.status === 'rejected' ||
                    (result.value && result.value.error) ||
                    result.value?.payload === undefined
            );

            if (hasErrors) {
                console.error('Ошибка при сохранении расписания:', results);
                setErrorPopUp(true);
                return;
            }

            const receptionResult = dispatch(fetchDoctorReceptions());

            receptionResult.then((result) => {
                if (
                    fetchDoctorReceptions.rejected.match(result) ||
                    result.payload === undefined ||
                    result.payload?.error
                ) {
                    setErrorPopUp(true);
                    return;
                }

                setToShowSaveToast(true);
                setTimeout(() => {
                    setToShowSaveToast(false);
                }, 3000);

                dispatch(setLoading());
                // setChoosenDates([]);
                // setSelectedTime([]);
                // setIsInterval(false);
                // setIsDays(false);
                setTimeIsModified(false);
                setSavedAndChoosen(true);
            });
        } catch (error) {
            setErrorPopUp(true);
        }
    };

    return (
        <>
            <h2 className="title">Создайте расписание</h2>
            <p className="title-hint">Вы можете выбрать день отдельно либо интервалом на 14 дней вперед</p>
            <div className="calendar">
                <div className="calendar__wrapper">
                    <Switcher
                        text="Гибкий выбор дат"
                        checked={isDays}
                        onChange={(e) => {
                            setIsDays(e.target.checked);
                        }}
                    />
                    <div className="hints">
                        <DateHint />
                        <DateHintAppointment />
                    </div>
                    <Calendar
                        choosenDate={choosenDate}
                        setChoosenDate={setChoosenDate}
                        isDays={isDays}
                        setChoosenDates={setChoosenDates}
                        choosenDates={choosenDates}
                        savedAndChoosen={savedAndChoosen}
                    />
                    {windowWidth >= 1023 && toShowSaveToast ? (
                        <div className="save-toast">Расписание сохранено!</div>
                    ) : (
                        <span
                            className={`reset-btn ${choosenDates.length > 0 ? 'reset-btn_active' : ''}`}
                            onClick={() => {
                                setChoosenDates([]);
                                setSelectedTime([]);
                                setIsInterval(false);
                                setIsDays(false);
                                setChoosenDate(new Date());
                            }}
                        >
                            Сбросить
                        </span>
                    )}
                </div>
                {choosenDates.length ? (
                    <div className="calendar__wrapper">
                        <Switcher
                            text="Гибкий выбор времени"
                            checked={isInterval}
                            onChange={(e) => {
                                setIsInterval(e.target.checked);
                            }}
                        />
                        <div className="hints">
                            <TimeHint />
                            <TimeHintAppointment />
                        </div>
                        <ScheduleSettings
                            choosenDates={choosenDates}
                            setSelectedTime={setSelectedTime}
                            isInterval={isInterval}
                        />
                        {/* <span
                            className={`reset-btn ${timeIsModified ? 'reset-btn_active' : ''}`}
                            onClick={() => {
                                setChoosenDates([...choosenDates]);
                            }}
                        >
                            Сбросить
                        </span> */}
                        {windowWidth < 1023 && toShowSaveToast ? (
                            <div className="save-toast">Расписание сохранено!</div>
                        ) : (
                            <span
                                className={`reset-btn ${timeIsModified ? 'reset-btn_active' : ''}`}
                                onClick={() => {
                                    setChoosenDates([...choosenDates]);
                                }}
                            >
                                Сбросить
                            </span>
                        )}
                    </div>
                ) : (
                    <div className="calendar__wrapper">
                        <div className="calendar__select--date">
                            <p className="calendar__select--date__placeholder">
                                Пожалуйста, выберете день, чтобы создать расписание
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <button
                className="save-btn"
                type="button"
                onClick={handleSend}
                disabled={!(choosenDates.length > 0 && timeIsModified)}
            >
                Сохранить изменения
            </button>
            {errorPopUp && (
                <Modal toggle={setErrorPopUp}>
                    <ErrorCalenderModal toggle={setErrorPopUp} errorIcon={errorIcon} />
                </Modal>
            )}
        </>
    );
};
