import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearConsultation, fetchDoctorConsultation } from '../../../api/lkDoctorConsultationsSlice';
import { DoctorConsultationPageStyled } from './doctorConsultationPage.styled';
import { consultationStatus } from '../lkDoctorConsultationHelpers';
import { Spinner } from '../../../../share/Spinner';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { RecommendationForm } from '../../recommendationForm/recommendationForm';
import { ConsultationFiles } from './consultationFiles/ConsultationFiles';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';
import { PatientCardProfile } from '../PatientCardProfile/PatientCardProfile';
import { ConsultationTimer } from '../../../../consultationTimer/ConsultationTimer';
import { useTimer } from '../../../../../hooks/useTimer';
import { ReviewCard } from '../../../../doctorProfile/doctorReviews/reviewCard/ReviewCard';

/**
 * Компонент страницы консультации врача.
 *
 * Компонент отображает подробную информацию о конкретной консультации врача, включая статус консультации,
 * время и стоимость, данные пациента, комментарии, рекомендации, прикрепленные файлы и другие элементы.
 *
 * @component
 * @returns {JSX.Element} Элемент страницы консультации врача.
 *
 * Хуки:
 * - `useDispatch` - для отправки действий в Redux.
 * - `useNavigate` - для навигации между страницами.
 * - `useParams` - для получения параметров из URL (например, ID консультации).
 * - `useSelector` - для получения текущего состояния из Redux.
 * - `useEffect` - для загрузки данных консультации при монтировании компонента.
 * - `useTimer` - для расчета времени до начала или окончания консультации.
 *
 * Действия Redux:
 * - `fetchDoctorConsultation` - загрузка данных конкретной консультации.
 * - `clearConsultation` - очистка данных консультации при размонтировании компонента.
 *
 * Переменные состояния:
 * - `currentConsultation` - объект текущей консультации из Redux-состояния.
 * - `id` - идентификатор консультации из параметров URL.
 *
 * Основные компоненты:
 * - `Spinner` - отображение загрузочного спиннера до загрузки данных.
 * - `ConsultationTimer` - отображение таймера для консультации.
 * - `PatientCardProfile` - отображение карточки профиля пациента.
 * - `ConsultationButtons` - кнопки управления консультацией.
 * - `RecommendationForm` - форма для ввода рекомендаций.
 * - `ConsultationFiles` - отображение файлов, прикрепленных к консультации.
 * - `ConsultationTimeAndPrice` - отображение времени и стоимости консультации.
 * - `ReviewCard` - карточка с отзывом пациента.
 *
 * Методы:
 * - `handleGoBack` - возвращение к предыдущей странице.
 * - `renderPatientComments` - рендеринг комментариев пациента.
 * - `renderDoctorRecommendation` - рендеринг рекомендаций врача.
 *
 * Стили:
 * - Используются стили, подключенные через `DoctorConsultationPageStyled`.
 */
export default function DoctorConsultationPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { currentConsultation, isLoading } = useSelector((state) => state.doctorConsultations);

    useEffect(() => {
        dispatch(fetchDoctorConsultation(id))
            .unwrap()
            .catch((error) => console.error('Error fetching consultation:', error));

        return () => dispatch(clearConsultation());
    }, [dispatch, id]);

    const {
        status,
        consultation_time,
        doctor,
        patient,
        recommendation,
        patient_notes,
        feedback,
        registration_log,
        is_payed,
        files,
        progress_files,
    } = currentConsultation;

    const { price, id: doctorId } = doctor || {};
    const feedbackId = feedback?.id;

    const isPatientCancelled = doctorId !== registration_log?.cancelled_by;

    const patientFiles = files?.filter((item) => item.owners_role === 'patient') || [];
    const doctorFiles = files?.filter((item) => item.owners_role === 'doctor') || [];

    const { minutes, isExpired } = useTimer({ date: consultation_time, trackSeconds: false });

    const disabledStart = (minutes >= 30 && isExpired) || (minutes >= 15 && !isExpired);

    const handleGoBack = async () => {
        navigate(-1);
    };

    useEffect(() => {
        const seenConsultation = { id, status };
        sessionStorage.setItem('seenConsultation', JSON.stringify(seenConsultation));
    }, [id, status]);

    const renderPatientComments = () => {
        if (!patient_notes && patientFiles.length === 0) return null;

        return (
            <div className="patient-comments">
                <h4 className="patient-comments__title">Комментарий пациента</h4>
                {patient_notes && <div className="patient-comments__text">{patient_notes}</div>}
                {patientFiles.length > 0 && <ConsultationFiles files={patientFiles} readOnly />}
            </div>
        );
    };

    const renderDoctorRecommendation = () => {
        if (status === 1 && !recommendation) {
            return (
                <RecommendationForm
                    consultationId={id}
                    recommendation={recommendation}
                    feedbackId={feedbackId}
                    doctorFiles={doctorFiles}
                    status={status}
                    progress_files={progress_files}
                />
            );
        } else if (status === 1 && recommendation) {
            return (
                <div className="patient-comments">
                    <h4 className="patient-comments__title">Рекомендации</h4>
                    <p className="patient-comments__text">{recommendation?.text}</p>
                    <ConsultationFiles files={doctorFiles} readOnly />
                </div>
            );
        }
    };

    // isLoading влияет на загрузку файлов - проверить
    // if (isLoading || !consultation_time) return <Spinner />;
    if (!consultation_time) return <Spinner />;

    return (
        <DoctorConsultationPageStyled>
            <div onClick={handleGoBack} className={'go-back-btn'}>
                Назад к списку
            </div>
            {!currentConsultation.id ? (
                <Spinner />
            ) : (
                <div className="consultation-data">
                    <div className="schedule">
                        {consultationStatus(status, is_payed, isPatientCancelled)}
                        <ConsultationTimeAndPrice date={consultation_time} price={price} isCurrentConsultation={true} />
                    </div>
                    {(status === 0 || status === 3) && !disabledStart && <ConsultationTimer date={consultation_time} />}
                    <PatientCardProfile patient={patient} />
                    {patient_notes && renderPatientComments()}
                    {renderDoctorRecommendation()}
                    {(status === 0 || status === 3) && (
                        <ConsultationButtons consultation={currentConsultation} consultationPage={true} />
                    )}
                    {status === 1 && feedback && <ReviewCard feedback={feedback} doctorCard={true} />}
                </div>
            )}
        </DoctorConsultationPageStyled>
    );
}
