import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { WhoAreYouStl } from './whoAreYou.styled';
import RadioButton from '../../share/RadioButton';

/**
 * Компонент WhoAreYou — выбор роли пользователя с помощью радио-кнопок.
 * @returns {JSX.Element} Возвращает компонент выбора роли пользователя.
 */
export const WhoAreYou = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <WhoAreYouStl>
            <h5>
                Кто вы<span className="required">*</span>
            </h5>
            <Controller
                name="who"
                control={control}
                rules={{ required: 'Выбор роли обязательно для заполнения' }}
                render={({ field }) => (
                    <>
                        <RadioButton
                            label={'Пациент'}
                            id={'patient'}
                            name={'who'}
                            isChecked={field.value === 'patient'}
                            onChange={() => field.onChange('patient')}
                        />
                        <RadioButton
                            label={'Врач'}
                            id={'doctor'}
                            name={'who'}
                            isChecked={field.value === 'doctor'}
                            onChange={() => field.onChange('doctor')}
                        />
                        {errors.who && <span className="error-message">{errors.who.message}</span>}
                    </>
                )}
            />
        </WhoAreYouStl>
    );
};
