import { DataBlockStl } from '../viewDoctor.styled';
import React from 'react';

/**
 * Компонент `ViewAdvancedTraining` отображает список программ повышения квалификации, переданных через `props`.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Array<Object>} props.advanced_training - Список программ повышения квалификации.
 * @param {Object} props.advanced_training[].program - Информация о программе.
 * @param {string} props.advanced_training[].program.name - Название программы.
 * @param {Object} props.advanced_training[].organization - Информация об организации.
 * @param {string} props.advanced_training[].organization.name - Название организации.
 * @param {string} [props.advanced_training[].graduation_year] - Год окончания программы (опционально).
 * @param {string} [props.advanced_training[].skills_improvement_image] - URL изображения сертификата (опционально).
 *
 * @returns {JSX.Element} Список программ повышения квалификации с возможностью просмотра сертификата.
 *
 * @example
 * const advancedTrainingData = [
 *   {
 *     program: { name: 'Программа A' },
 *     organization: { name: 'Организация X' },
 *     graduation_year: '2022',
 *     skills_improvement_image: 'https://example.com/certificate.jpg',
 *   },
 *   {
 *     program: { name: 'Программа B' },
 *     organization: { name: 'Организация Y' },
 *     graduation_year: '2021',
 *   },
 * ];
 *
 * return <ViewAdvancedTraining advanced_training={advancedTrainingData} />;
 */
export const ViewAdvancedTraining = ({ advanced_training }) => {
    const openImageInNewTab = (imageURL) => {
        window.open(imageURL, '_blank');
    };
    const trainingShow = advanced_training?.map((training, index) => {
        const certificateURL = training.skills_improvement_image;

        return (
            <DataBlockStl key={index}>
                <div className="info__wrapper">
                    <h4 className="info__header">{training.program?.name}</h4>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{training.organization?.name}</p>
                </div>
                <div className="info__wrapper info__wrapper-training">
                    <div className="info__data info__data-training">
                        <p>{training.graduation_year ? training.graduation_year : ''}</p>
                    </div>
                </div>
                {/* Отображение иконки сертификата с возможностью открыть изображение */}
                {certificateURL && (
                    <div className="certificate-image-wrapper">
                        <img
                            src={certificateURL}
                            alt="Сертификат"
                            onClick={() => openImageInNewTab(certificateURL)}
                            style={{ maxWidth: '200px', cursor: 'pointer' }}
                        />
                    </div>
                )}
            </DataBlockStl>
        );
    });

    return trainingShow;
};
