import styled from 'styled-components';

export const StyledLkDoctor = styled.section`
    .content {
        width: 100%;
        display: grid;
        grid-template-columns: 1.5fr 4fr;
        gap: 60px;
    }
    .rightBlock {
        width: 100%;
    }

    @media (max-width: 980px) {
        .content {
            gap: 30px;
        }
    }

    @media (max-width: 700px) {
        .content {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
        }
    }
`;
