import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    requestReviews,
    postReview,
    findCountConsultationsPatient,
    findCountConsultationsDoctor,
} from './api/reviewsApi';

export const fetchReviews = createAsyncThunk('reviews/fetchReviews', async (countPage) => {
    const response = await requestReviews(countPage);
    // // console.log(response);
    return response.results;
});

export const addReview = createAsyncThunk('reviews/addReview', async (post) => {
    await postReview(post);
    // const response = await postReview(post);
    // // console.log(response);
});

export const countConsultationPatient = createAsyncThunk('reviews/countConsultationPatient', async () => {
    const response = await findCountConsultationsPatient();
    // // console.log(response);
    return response;
});

export const countConsultationDoctor = createAsyncThunk('reviews/countConsultationDoctor', async () => {
    const response = await findCountConsultationsDoctor();
    // // console.log(response);
    return response;
});

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState: {
        reviews: [], // отображаемые отзывы
        feedback: {
            theme: null,
            text: '',
            role: '',
        },
        countConsultationUser: 0, // количество консультаций юзера
        countPage: 1, // страница пагинации для запроса
        idLastPostReviews: 6, // для определения последней страницы пагинации
        isPreloader: false,
        error: null,
    },
    reducers: {
        setCountPage(state, action) {
            state.countPage = action.payload;
        },
        resetState(state) {
            (state.feedback = {
                theme: null,
                text: '',
                role: '',
            }),
                (state.countConsultationUser = 0);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetchReviews.fulfilled, (state, action) => {
            state.isPreloader = false;
            state.reviews = action.payload;
            state.reviews.length !== 0 && (state.idLastPostReviews = state.reviews[state.reviews.length - 1].id);
        });
        builder.addCase(fetchReviews.pending, (state) => {
            state.isPreloader = true;
        });
        builder.addCase(countConsultationPatient.fulfilled, (state, action) => {
            state.countConsultationUser = action.payload;
        });
        builder.addCase(countConsultationDoctor.fulfilled, (state, action) => {
            state.countConsultationUser = action.payload;
        });
    },
});

export const { setCountPage, resetState } = reviewsSlice.actions;
export default reviewsSlice.reducer;
