import React, { memo } from 'react';
import { ArticleDescriptionStyled } from './articleDescription.styled';
import { ReactComponent as AuthorIcon } from '../../../../img/newsImg/newsIcons/news-author-icon.svg';
import { ReactComponent as FormattedDate } from '../../../../img/newsImg/newsIcons/news-date-icon.svg';
import { SocialMedia } from '../socialMedia/SocialMedia';
import { getDateForBlog } from '../../../share/helpers';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LeftArrowIcon } from '../../../../img/icons/icon-arrow-back.svg';

/**
 * Компонент ArticleDescription
 *
 * React-компонент, отображающий детальную информацию о статье, включая заголовок, автора, дату создания и содержание.
 * Также включает кнопку для возврата к списку статей и блок с кнопками для социальных сетей.
 *
 * @component
 * @param {Object} props - Свойства, переданные в компонент.
 * @param {number|string} props.themeId - ID темы или категории для навигации назад.
 * @param {Object} props.article - Данные статьи для отображения.
 * @param {string} [props.article.cover_img] - URL обложки статьи.
 * @param {string} [props.article.author] - Имя автора статьи.
 * @param {string} [props.article.created] - Дата создания статьи.
 * @param {string} [props.article.title] - Заголовок статьи.
 * @param {string} [props.article.text] - Полное текстовое содержание статьи. Абзацы разделены `\r\n\r\n`.
 *
 * @returns {JSX.Element} JSX-разметка компонента.
 */

const ArticleDescription = ({ themeId, article }) => {
    const navigate = useNavigate();
    const paragraphs = article?.text ? article.text.split('\r\n\r\n') : [];

    return (
        <ArticleDescriptionStyled>
            <div className="backToPostsButton" onClick={() => navigate(`/news/${themeId}`)}>
                <LeftArrowIcon />
                <p>Назад к списку</p>
            </div>
            <div className="articleContent">
                <div className="articleTopContent">
                    <img className="previewArticle" src={article?.cover_img} alt="" />
                    <div className="articleInfo">
                        <div className="infoBlock">
                            <AuthorIcon />
                            <p className="infoCreated">{article?.author}</p>
                        </div>
                        {article?.created && (
                            <div className="infoBlock">
                                <FormattedDate />
                                <p className="infoCreated">{getDateForBlog(article?.created)}</p>
                            </div>
                        )}
                    </div>
                </div>
                <h2 className="articleTitle">{article?.title}</h2>
                <div className="articleText_container">
                    {paragraphs.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
                <SocialMedia />
            </div>
        </ArticleDescriptionStyled>
    );
};
export default memo(ArticleDescription);
