import styled from 'styled-components';

export const ConsultationTimerStyled = styled.div`
    width: 280px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #f4f4f4;
    border-radius: 26px;
    padding: 8px 9.5px 8px 14.5px;

    .timer_comment {
        font-size: 14px;
        line-height: 16.8px;
        color: #666666;
    }

    .timer_green {
        font-size: 16px;
        color: #ffffff;
        background-color: #19be6f;
        border-radius: 24px;
        width: 81px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timer_red {
        font-size: 16px;
        color: #ffffff;
        background-color: #ff3636;
        border-radius: 24px;
        width: 81px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
