import styled from 'styled-components';

export const AppointmentStl = styled.div`
    padding: 79px 40px 67px 40px;
    position: relative;
    max-width: 414px;
    background: rgb(255, 255, 255);
    border-radius: 24px;
    .appointment {
         &__close {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            justify-content: center;
            align-content: center;
            width: 28px;
            height: 28px;

            &__icon {
                width: 14px;
                height: 14px;
            }
        }
        &__icon {
            width: 109px;
            height: 114px;
            margin: 0 auto 59px auto;
        }

        &__text {
            font-weight: 500;
            font-size: 20px;
            line-height: 130%;
            text-align: center;
            color: rgb(102, 102, 102);
            margin-bottom: 59px;
        }
        &__button {
            display: block;
            width: 100%;
            padding: 16px;
            background: rgb(25, 190, 111);
            border-radius: 10px;
            border: none;
            font-weight: 500;
            font-size: 16px;
            color: rgb(244, 244, 244);
            cursor: pointer;
        }
    }
`;
