import { ErrorConsultationStl } from './errorConsultation.styled';
import { React } from 'react';
import { Button } from '../Button';
import errorIcon from '../../../../img/icons/icon-error.svg';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';

/**
 * ErrorConsultation - Компонент, который отображает сообщение об ошибке при создании консультации.
 * Позволяет пользователю повторить попытку создания консультации или закрыть модальное окно.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.closeModal - Функция для закрытия модального окна. Передает булево значение для указания состояния модала.
 * @param {Function} props.handleConfirumClick - Функция для повторной попытки создания консультации.
 * @param {boolean} props.loading - Состояние загрузки, определяет текст на кнопке повторной попытки.
 *
 * @returns {JSX.Element} Возвращает JSX элемент, представляющий состояние ошибки.
 *
 * @example
 * return (
 *   <ErrorConsultation
 *     closeModal={someCloseModalFunction}
 *     handleConfirumClick={someHandleConfirmFunction}
 *     loading={isLoading}
 *   />
 * );
 *
 * Компонент включает в себя следующие элементы:
 * - Иконка ошибки для визуального представления проблемы.
 * - Заголовок с текстом об ошибке.
 * - Текстовое описание с предложением проверить подключение к интернету.
 * - Кнопка для повторной попытки создания консультации.
 * - Кнопка закрытия модального окна.
 */

export const ErrorConsultation = ({ errorMessage, closeModal, handleConfirumClick, loading }) => {
    useLockBodyScroll();
    return (
        <ErrorConsultationStl>
            <div className="error">
                <img src={errorIcon} alt="error" className="error__icon" />
                <h3 className="error__title">Не удалось создать консультацию</h3>
                <div className="error__content">
                    <span>
                        {errorMessage === ' '
                            ? `Попробуйте снова. Если проблема сохраняется, проверьте подключение к интернету или повторите
                        попытку позже`
                            : errorMessage}
                    </span>
                </div>
                <div className="error__btn">
                    {!errorMessage && (
                        <Button className="error__btn-repeat" onClick={handleConfirumClick}>
                            {loading ? 'Отправка...' : 'Повторить попытку'}
                        </Button>
                    )}

                    <Button className="error__btn-close" onClick={() => closeModal(() => false)}>
                        Закрыть
                    </Button>
                </div>
            </div>
        </ErrorConsultationStl>
    );
};
