import styled from 'styled-components';

export const StyledDoctorsTop = styled.section`
    background-color: #f7f7f7;
    padding-bottom: 90px;

    .doctorsTop__heading {
        margin-bottom: 60px;
        font-weight: 500;
        font-size: 42px;
        line-height: 56px;
        color: #212121;
    }

    .doctorsTop__specialization {
        list-style: none;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        justify-content: center;
        row-gap: 16px;
        column-gap: 20px;
    }

    .doctorsTop__button {
        margin-top: 28px;
        border: none;
        background: transparent;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #19be6f;
        cursor: pointer;
        :hover {
            opacity: 0.8;
        }
    }

    @media (max-width: 768px) {
        .doctorsTop__heading {
            font-size: 36px;
        }

        .doctorsTop__specialization {
            grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        }
    }

    @media (max-width: 500px) {
        .doctorsTop__heading {
            margin-bottom: 40px;
            font-size: 28px;
        }
    }
`;
