import styled from 'styled-components';

export const ConsultationListStl = styled.div`
    position: relative;
    display: flex;
    gap: 35px;
    flex-direction: column;
    @media (max-width: 500px) {
        gap: 28px;
    }

    .no-consult-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .no-consult-text {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        align-items: left;
        color: #666666;
        @media (max-width: 768px) {
            align-items: left;
            font-size: 20px;
        }
        @media (max-width: 361px) {
            font-size: 18px;
        }
    }

    .no-consult-btn {
        border-radius: 8px;
        background: #19be6f;
        width: 204px;
        height: 47px;
        border: 0;
        @media (max-width: 361px) {
            width: 100%;
        }
    }

    .link {
        color: white;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
    }

    .buttonsPaginate {
        display: flex;
        justify-content: flex-end;
        margin-top: 35px;
        max-width: 600px;
        width: 100%;
        @media (max-width: 320px) {
            justify-content: space-between;
            margin-top: 0;
        }
    }

    .buttonsPaginate button {
        border: none;
        background-color: transparent;
        color: #19be6f;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        transition: opacity 0.5s linear;
        @media (max-width: 320px) {
            font-size: 14px;
        }
        &:hover {
            opacity: 0.7;
        }
    }

    .buttonAddDoctors {
        margin-right: 42px;
    }

    .buttonUp {
        display: flex;
        gap: 4px;
        align-items: center;
        @media (max-width: 320px) {
            gap: 7px;
            padding-right: 4px;
        }
    }

    // .spinner-container {
    //     position: relative;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 100%;
    //     height: 100%;
    // }
`;
