import { ErrorDeleteAccountModalStl } from "./errorDeleteAccountModal.styled";
import { React } from 'react';
import errorIcon from '../../../../img/icons/icon-error.svg';
import Button from "../../../share/Button";
import { useLockBodyScroll } from "../../../../hooks/useLockBodyScroll";

/**
 * ErrorDeleteAccountModal - Компонент, который отображает сообщение об ошибке при попытке удаления 
 * аккаунта врача, у которого есть запланированные оплаченные консультации.
 * Позволяет пользователю повторить попытку позже и закрыть модальное окно.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.closeModal - Функция для закрытия модального окна. 
 * Передает булево значение для указания состояния модального окна.
 *
 * @returns {JSX.Element} Возвращает JSX элемент, представляющий состояние ошибки.
 *
 * @example
 * return (
 *   <ErrorDeleteAccountModal
 *     closeModal={someCloseModalFunction}
 *   />
 * );
 *
 * Компонент включает в себя следующие элементы:
 * - Иконка ошибки для визуального представления проблемы.
 * - Заголовок с текстом об ошибке.
 * - Текстовое описание с предупреждением о запланированных оплаченных консультациях 
 * и сообщением, когда станет возможно удаление аккаунта.
 * - Кнопка закрытия модального окна.
 */

export const ErrorDeleteAccountModal = ({ toggle }) => {
    useLockBodyScroll();
    return (
        <ErrorDeleteAccountModalStl>
            <div className="error">
                <img src={errorIcon} alt="error" className="error__icon" />
                <h3 className="error__title">Невозможно удалить аккаунт</h3>
                <p className="error__content">
                    У Вас есть запланированные оплаченные консультации. Удаление аккаунта возможно только после их завершения
                </p>
                <Button green XS margin="0" height="100%" className="error__btn-close" 
                    onClick={() => toggle(false)}>
                    Закрыть
                </Button>
            </div>
        </ErrorDeleteAccountModalStl>
    );
};
