import styled from 'styled-components';

export const ConsultationButtonsStl = styled.div`
    grid-column: 2;
    font-size: ${(props) => (props.isSingleConsultation ? '16px' : '14px')};
    line-height: 20px;
    margin-top: ${(props) => props.pathname && '12px'};
    display: flex;
    justify-content: ${(props) => (props.isSingleConsultation ? 'justify-start' : 'space-between')};
    gap: 12px;

    @media (max-width: 880px) {
        gap: 8px;
        flex-direction: column;
    }

    button {
        // width: ${(props) => (props.isSingleConsultation ? '244px' : props.isPastConsultations ? '200px' : '100%')};
        width: ${(props) => (props.isSingleConsultation ? '244px' : '200px')};
        padding: ${(props) => (props.isSingleConsultation ? '14px' : '8px')};
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @media (max-width: 880px) {
            width: ${(props) => (props.isSingleConsultation ? '244px' : props.isPastConsultations ? '200px' : '204px')};
        }

        @media (max-width: 760px) {
            width: ${(props) => (props.isSingleConsultation ? '244px' : '100%')};
        }
        @media (max-width: 550px) {
            width: 100%;
        }
    }

    .startBtn {
        border: 2px solid #19be6f;
        border-radius: 10px;
        background: #19be6f;
        color: #ffffff;
        font-weight: 500;
        background: #19be6f;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: #169056;
            border: 2px solid #169056;
        }
    }

    .startBtn:disabled {
        background: #8af0bf;
        border: 2px solid #8af0bf;
        cursor: default;
    }

    .closeBtn {
        color: #19be6f;
        background: #ffffff;
        font-weight: 500;
        border: 2px solid #19be6f;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        &:hover {
            color: #169056;
            border: 2px solid #169056;
        }
    }

    .more {
        align-items: center;
        display: flex;
        color: #19be6f;
        background: #ffffff;
        border: none;
        text-decoration: none;
        align-self: center;
        text-align: center;
        @media (min-width: 768px) {
            display: none;
        }
    }
    @media (max-width: 500px) {
        grid-column: 1/3;
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;

        .more {
            order: -1;
            width: 100%;
            padding-left: 0;
        }
        button {
            flex: 1;
        }
    }
`;
