import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { formatDateToMonthYear, formatToFullDate } from '../../../share/helpers';
import HigherEducationForm from './components/HigherEducationForm';
import AdvancedTraining from './components/AdvancedTraining';
import WorkExperience from './components/WorkExperience';
import Specialization from './components/Specialization';
import Diagnostic from './components/Diagnostic';
import { NewFormSTL } from './components/newFormSTL.styled';
import { useNavigate } from 'react-router-dom';
import Price from './components/Price';
import {
    fetchAllDoctorData,
    fetchDoctorDataUpdate,
    fetchDoctorProffDataUpdate,
    fetchPostDrEducation,
    fetchPatchDrEducation,
    fetchPostDrSkillsImprovement,
    fetchPatchDrSkillsImprovement,
} from '../../api/doctorUserProfileSlice';
import { Loader } from '../../../loader/loader';
import { DoctorDataList } from '../shared/DoctorDataList';
import { convertDoctorDataBirthday } from '../viewDoctor/helpers/convertDoctorDataBirthday';
import { ReactComponent as AttentionSvg } from '../../../../img/icons/icon_attention.svg';
import { useFormDirty } from '../../../../routes/FormDirtyContext';

/**
 * Компонент FormDoctor представляет собой форму для редактирования профиля врача.
 * Использует `react-hook-form` для управления состоянием формы, сохранения промежуточных данных и валидации.
 * Поддерживает функционал сохранения состояния формы в `sessionStorage` для предотвращения потери данных при перезагрузке страницы.
 *
 * Этот компонент предоставляет пользователям возможность обновить как личные, так и профессиональные данные:
 * - Личные данные: имя, фамилия, отчество, номер телефона, город, дата рождения, пол.
 * - Профессиональные данные: специализация, опыт работы, образование, повышение квалификации, научная степень, категория, стоимость услуг и т.д.
 *
 * Основные задачи компонента:
 * - Инициализация данных формы из состояния Redux или сохранённых данных в `sessionStorage`.
 * - Поддержание грязного состояния формы через контекст `FormDirtyContext`.
 * - Форматирование и отправка данных на сервер через Redux экшены.
 * - Управление состоянием загрузки.
 *
 * @component
 * @returns {JSX.Element} Форма для обновления профиля врача.
 */

/**
 * Компонент FormDoctor представляет форму для редактирования профиля врача.
 *
 * Этот компонент отвечает за:
 * - Рендеринг формы для обновления данных врача, включая личные данные и профессиональные данные (опыт, специализация и тд).
 * - Проверку, впервые ли врач заходит на сайт, и перенаправление его на страницу просмотра профиля, если уже есть данные.
 * - Обработку отправки формы и обновление данных через Redux экшены для обновления на сервере.
 *
 * @component
 * @param {Object} methods - Методы, переданные через `react-hook-form`, для управления формой.
 *
 * @returns {JSX.Element} Возвращает JSX с формой для обновления данных профиля врача.
 */

const FormDoctorComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setIsFormDirty } = useFormDirty();

    /** Состояния для хранения данных об образовании и повышении квалификации. */
    const [skillsImprovementData, setSkillsImprovementData] = useState([]);
    const [higherEducationData, setHigherEducationData] = useState([]);

    /** Получение данных о враче из Redux состояния. */
    const { doctorData, messagesError } = useSelector((state) => state.doctorProfile);
    const doctorID = doctorData.id;
    const user = doctorData.user;
    const isLoaderProfile = useSelector((state) => state.doctorProfile.isLoader);
    const AxiosError = messagesError.AxiosError;

    /**Лоадеры для состояния загрузки формы.*/
    const [isLoaderData, setIsLoaderData] = useState(false); // Лоадер для отправки данных.

    /** Проверка необходима для корректной передачи начальных значений в компоненте Select.jsx */
    const specializationForMethods =
        doctorData.specialization.length === 1 && doctorData.specialization[0].id === 0
            ? []
            : doctorData.specialization.map((item) => ({ value: item.id, label: item.name }));

    const savedData = useMemo(() => {
        const data = sessionStorage.getItem('formData');
        return data ? JSON.parse(data) : null;
    }, []);

    const defaultValues = useMemo(() => {
        if (savedData) return savedData;
        if (!doctorData || !user.email) return {};

        return {
            user: convertDoctorDataBirthday(user),
            data: {
                ...doctorData,
                specialization: specializationForMethods,
                work: doctorData.work?.map((workItem) => ({
                    ...workItem,
                    work_from: formatDateToMonthYear(workItem.work_from),
                    work_to: workItem.work_to ? formatDateToMonthYear(workItem.work_to) : '',
                })),
            },
        };
    }, [doctorData, user, specializationForMethods]);

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const { isValid, isSubmitting, isDirty } = useFormState({ control: methods.control });

    useEffect(() => {
        const subscription = methods.watch((value) => {
            sessionStorage.setItem('formData', JSON.stringify(value));
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    /** Установка флаг грязного состояния при изменении формы */
    const handleFormChange = useCallback(() => {
        setIsFormDirty(true);
    }, []);

    /** Сброс грязного состояния формы при успешной отправке */
    const resetFormDirty = useCallback(() => {
        setIsFormDirty(false);
    }, []);

    /**  Обновлене состояния грязной формы, если флаг isDirty изменился*/
    useEffect(() => {
        setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    /**
     * Функция для обработки данных об образовании, переданных из дочернего компонента.
     * @param {Array} data - Данные о высшем образовании.
     * @returns {void}
     */
    const handleHigherEducationData = (data) => {
        setHigherEducationData(data);
    };

    /**
     * Функция для обработки данных о повышении квалификации, переданных из дочернего компонента.
     * @param {Array} data - Данные о повышении квалификации.
     * @returns {void}
     */
    const handleSkillsImprovementData = (data) => {
        setSkillsImprovementData(data);
    };

    /**
     * Функция для обработки отправки данных формы.
     * При отправке формы данные проходят форматирование, разделяются на новые записи и отправляются на сервер
     * через Redux actions. Также выполняются параллельные запросы на обновление данных пользователя, опыта работы,
     * образования и повышения квалификации.
     *
     * @param {Object} data - Объект с данными, полученными из формы.
     * @param {Object} data.user - Личные данные пользователя (имя, фамилия, дата рождения, пол и т.д.).
     * @param {Object} data.data - Профессиональные данные врача (специализация, опыт работы, образование и т.д.).
     * @returns {Promise<void>} Возвращает промис с результатом выполнения обновления данных.
     */
    const onSubmit = async ({ user: userSubmit, data: data }) => {
        setIsLoaderData(true);

        const user = {
            first_name: userSubmit.first_name,
            last_name: userSubmit.last_name,
            middle_name: userSubmit.middle_name ?? '',
            phone: userSubmit?.phone?.replace(/[\s()-]/g, ''),
            city: userSubmit.city?.value ?? userSubmit.city ?? '',
            date_birth: userSubmit.date_birth,
            sex: userSubmit.sex,
        };

        const workModified = data.work?.map((work) => {
            const today = new Date().toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });

            return {
                organization: { name: work.organization.name },
                position: { name: work.position.name },
                work_from: formatToFullDate(work.work_from),
                work_to: work.until_now ? today : formatToFullDate(work.work_to),
                until_now: work.until_now || false,
            };
        });

        const dataForSubmit = {
            work: workModified,
            specialization: data.specialization?.map((spec) => ({ name: spec.label })), // преобразуем обратно в формат {name} для передачи в запрос
            scientific_degree: data.scientific_degree,
            category: data.category,
            diagnosis_and_treatment: data.diagnosis_and_treatment || '',
            price: Number(data.price),
        };

        const postEducationData = higherEducationData.filter((item) => !item.id);
        const patchEducationData = higherEducationData.filter((item) => item.id);

        const postSkillsData = skillsImprovementData.filter((item) => !item.id);
        const patchSkillsData = skillsImprovementData.filter((item) => item.id);

        try {
            // Выполняем параллельно обновление пользовательских данных и профессиональных данных
            const results = await Promise.allSettled([
                dispatch(fetchDoctorDataUpdate(user)),
                dispatch(fetchDoctorProffDataUpdate({ id: doctorID, data: dataForSubmit })),

                ...postEducationData.map((item) => dispatch(fetchPostDrEducation(item))),
                ...patchEducationData.map((item) => dispatch(fetchPatchDrEducation(item))),
                ...postSkillsData.map((item) => dispatch(fetchPostDrSkillsImprovement(item))),
                ...patchSkillsData.map((item) => dispatch(fetchPatchDrSkillsImprovement(item))),
            ]);

            const hasErrors = results.some(
                (result) => result.status === 'rejected' || result.value?.error?.message === 'Rejected'
            );

            // После выполнения всех промисов, делаем запрос на обновленные данных в стейте
            if (!hasErrors) {
                await dispatch(fetchAllDoctorData()).unwrap();
                resetFormDirty(); // Сбрасываем грязное состояние
                sessionStorage.removeItem('formData'); // очищаем Session Storage
                navigate('/lk/doctor-profile/profile/view'); // После успешного выполнения, перенаправляем пользователя
            } else {
                console.warn('Обнаружены ошибки при обновлении данных, редирект отменен.');
            }
        } catch (error) {
            console.error('Ошибка при обновлении данных:', error);
        } finally {
            setIsLoaderData(false);
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    if (!doctorData.user.email) return <Loader />;

    return (
        <NewFormSTL>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} onChange={handleFormChange}>
                    {AxiosError && (
                        <p className="axiosError">
                            <AttentionSvg /> <span className="axiosErrorText">{AxiosError}</span>
                        </p>
                    )}

                    <div className="formContainer">
                        <DoctorDataList />
                        <HigherEducationForm onHigherEducationData={handleHigherEducationData} />
                        <AdvancedTraining onSkillsImprovementData={handleSkillsImprovementData} />
                        <WorkExperience />
                        <Specialization />
                        <Diagnostic initialDiagnosis={doctorData.diagnosis_and_treatment} />
                        <Price />
                    </div>

                    <button disabled={!isValid || isSubmitting || isLoaderData} className="btn__recive">
                        {isLoaderData ? 'Загрузка...' : 'Отправить данные'}
                    </button>
                </form>
            </FormProvider>
        </NewFormSTL>
    );
};
const FormDoctor = React.memo(FormDoctorComponent);
export default FormDoctor;

// заготовка для перевода на архитектуру mvc
// const FormDoctor = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const [skillsImprovementData, setSkillsImprovementData] = useState([]);

//     const { doctorData, isLoader } = useSelector((state) => state.doctorProfile);
//     const doctorID = doctorData.id;
//     const user = doctorData.user;
//     // const isLoaderProfile = useSelector((state) => state.doctorProfile.isLoader);

//     // проверить использование лоудеров и упростить
//     // const [loader, setLoader] = useState(false);
//     // const [isLoaderData, setIsLoaderData] = useState(false);

//     const formatDateToMonthYear = (date) => {
//         if (!date || !/^\d{2}\.\d{2}\.\d{4}$/.test(date)) return '';
//         const [day, month, year] = date.split('.');
//         return `${month}.${year}`;
//     };
//     const formatToFullDate = (monthYear) => {
//         if (!monthYear || !/^\d{2}\.\d{4}$/.test(monthYear)) return '';
//         const [month, year] = monthYear.split('.');
//         return `01.${month}.${year}`; // '01' - это стандартный день
//     };

//     // Проверка необходима для корректной передачи начальных значений в компоненте Select.jsx
//     const specializationForMethods =
//         doctorData.specialization.length === 1 && doctorData.specialization[0].id === 0
//             ? []
//             : doctorData.specialization.map((item) => ({ value: item.id, label: item.name }));

//     const methods = useForm({
//         mode: 'onChange',
//         defaultValues: {
//             user: convertDoctorDataBirthday(user),
//             // data: doctorData,
//             data: {
//                 ...doctorData,
//                 specialization: specializationForMethods,
//                 work:
//                     doctorData.work.map((workItem) => ({
//                         ...workItem,
//                         work_from: formatDateToMonthYear(workItem.work_from), // Преобразуем формат
//                         work_to: workItem.work_to ? formatDateToMonthYear(workItem.work_to) : '', // Преобразуем формат
//                     })) || [],
//             },
//         },
//     });
//     const { isValid, isSubmitting } = useFormState({ control: methods.control });

//     // Функция для обработки данных от дочернего компонента AdvancedTraining
//     const handleSkillsImprovementData = (data) => {
//         setSkillsImprovementData(data);
//     };

//     useEffect(() => {
//         if (!doctorData.user?.last_name) {
//             navigate('/lk/doctor-profile/profile/edit');
//         }
//     }, [doctorData, navigate]);

//     // console.log('skillsImprovementData', skillsImprovementData); // Лог данных по доп образованию

//     const onSubmit = (data) => {
//         // console.log('onSubmit triggered', data); // Лог до вызова handleSubmit
//         handleFormSubmit(data, skillsImprovementData, dispatch, navigate);
//     };

//     // местное
//     // const {
//     //     formState: { isValid },
//     // } = useFormContext();

//     // if (!user?.first_name) return null; пока откл

//     return (
//         <NewFormSTL>
//             {isLoader ? (
//                 <Loader />
//             ) : (
//                 <FormProvider {...methods}>
//                     {/* <form onSubmit={methods.handleSubmit(onSubmit)}> */}
//                     <form
//                         onSubmit={(e) => {
//                             // console.log('Form submit triggered');
//                             methods.handleSubmit(onSubmit)(e); // Явная передача события в handleSubmit
//                         }}
//                     >
//                         <DoctorDataList />
//                         <HigherEducationForm />
//                         <AdvancedTraining onSkillsImprovementData={handleSkillsImprovementData} />
//                         <WorkExperience />
//                         <Specialization />
//                         <Diagnostic initialDiagnosis={doctorData.diagnosis_and_treatment} />
//                         <Price />
//                         <button disabled={!isValid || isSubmitting || isLoader} className="btn__recive">
//                             {isLoader ? 'Загрузка...' : 'Отправить данные'}
//                         </button>
//                     </form>
//                 </FormProvider>
//             )}
//         </NewFormSTL>
//     );
// };

// export default FormDoctor;
