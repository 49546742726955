import { useState, useEffect } from 'react';
import { convertDatesToUserTimeZone } from "../../../../../utils/dateTransform";

export const useTimeReceptions = (timeStamps, currentDate) => {
    const [timeReceptions, setTimeReceptions] = useState([]);

    useEffect(() => {
        if (!currentDate) return;
        if (!timeStamps || timeStamps.length === 0) {
            setTimeReceptions([]);
            return;
        }

        // Используем convertDatesToUserTimeZone, которая преобразует время в нужный формат 
        // в часовом поясе пользователя
        const convertedTimeStamps = convertDatesToUserTimeZone(
            timeStamps,
            ['reception_time'],
            'Europe/Moscow',
            'dd.MM.yyyy HH:mm:ss',
            'formatted'
        );

        const filteredTimeStamps = convertedTimeStamps.filter((timeStamp) => {
            if (!timeStamp.reception_time) return false;

            // Преобразуем строку даты в объект Date
            const date = parseDate(timeStamp.reception_time);

            // Оставляем только те записи, у которых совпадают день, месяц и год
            return (
                date.getFullYear() === currentDate.year &&
                date.getMonth() + 1 === currentDate.month &&
                date.getDate() === currentDate.day
            );
        });

        // Сортируем время по возрастанию и форматируем для отображения
        const timeReceptions = filteredTimeStamps
            .sort((a, b) => parseDate(a.reception_time).getTime() - parseDate(b.reception_time).getTime())
            .map((timeStamp) => {
                const date = parseDate(timeStamp.reception_time);
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');

                return { timeStamp: timeStamp.reception_time, timeString: `${hours}:${minutes}` };
            });

        setTimeReceptions(timeReceptions);
    }, [timeStamps, currentDate]);

    return [timeReceptions];
};

// Функция для преобразования строки даты в объект Date
// Например, '04.03.2025 15:30:00' → new Date(2025, 2, 4, 15, 30)
function parseDate(dateStr) {
    if (!dateStr) return new Date(NaN);

    const [day, month, year, hours, minutes] = dateStr.split(/[\s:.]/).map(Number);
    return new Date(year, month - 1, day, hours, minutes);
}
