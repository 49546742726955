import React, { useRef, useState, useEffect } from 'react';
import { uploadConsultationFile } from '../../../../../share/fileUploader';
import { sendConsultationFile, deleteConsultationFile } from '../../../../api/PatientConsultationsSlice';
import { useDispatch } from 'react-redux';
import { fetchFeedback, getPatientConsultation } from '../../../../api/PatientConsultationsSlice';
import { LoadFileStl } from './consultationFiles.styled';
import addFile from '../../../../../../img/addFile.svg';
import { Spinner } from '../../../../../share/Spinner';
import { ReactComponent as Certificate } from '../../../../../../img/certificate.svg';
import { ReactComponent as CertificateDelete } from '../../../../../../img/certificate-delete.svg';
import { validateFile } from '../../../../../share/helpers';
import { FilePreviewer } from '../../../../../share/filePreviewer/FilePreviewer';

/**
 * Компонент `ConsultationFiles` предоставляет возможность загрузки, удаления и просмотра файлов,
 * связанных с консультацией пациента.
 *
 * Компонент включает:
 * - Отображение списка файлов.
 * - Загрузку новых файлов.
 * - Удаление существующих файлов (при определённых условиях).
 * - Отображение прогресса загрузки файлов.
 *
 * Если текст проблемы (`problem`) присутствует или статус отличается от 0, функциональность загрузки и удаления файлов отключается.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {number} props.consultationId Уникальный идентификатор консультации, связанной с файлами.
 * @param {Array<Object>} props.files Массив объектов файлов. Каждый файл имеет следующую структуру:
 * - `id` {number} — Уникальный идентификатор файла.
 * - `file` {string} — URL-адрес файла.
 * - `file_name` {string} — Имя файла.
 * @param {string|null} props.problem Текст проблемы. Если присутствует, загрузка и удаление файлов недоступны.
 * @param {number} props.status Статус консультации. Значение `0` позволяет загружать и удалять файлы.
 * @param {number} props.progress_files Прогресс загрузки текущего файла в процентах (0–100).
 *
 * @returns {JSX.Element} Компонент для управления файлами.
 */
export const ConsultationFiles = ({ consultationId, files, progress_files, problem, status }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [deletingFiles, setDeletingFiles] = useState({});
    const [error, setError] = useState('');
    const [fakeFile, setFakeFile] = useState(null);
    const [realFiles, setRealFiles] = useState(files);
    const loadFile = useRef();

    /**
     * Таймер для сброса ошибки через 5 секунд.
     * Если ошибка загрузки файла возникает, она будет удалена через 5 секунд.
     */
    useEffect(() => {
        if (error) {
            const delayClearError = setTimeout(() => setError(''), 5000);
            return () => clearTimeout(delayClearError);
        }
    }, [error]);

    useEffect(() => {
        setRealFiles(files);
    }, [files]);

    useEffect(() => {
        if (progress_files >= 0 && progress_files <= 100) {
            setFakeFile({});
        }
        if (progress_files == undefined) {
            setFakeFile(null);
        }
    }, [progress_files]);

    /**
     * Обработчик загрузки нового файла.
     * Проверяет размер файла и отправляет его на сервер с помощью функции sendUpResult.
     * Сбрасывает значения input после обработки файла
     */
    const handleFileUpload = (e) => {
        e.preventDefault();

        setError('');

        uploadConsultationFile(
            loadFile,
            {
                multiple: false,
                accept: ['.jpg', '.png', '.jpeg', '.tiff', '.pdf', '.doc', '.docx', '.xls'],
                validate: validateFile,
                onValidationError: (errorMessage) => setError(errorMessage),
            },
            sendUpResult
        );

        if (loadFile.current) {
            loadFile.current.value = '';
        }
    };

    /**
     * Функции для отправки файла на сервер и обновления данных консультации по её id
     */
    const sendUpResult = async (file) => {
        setIsLoading(true);
        try {
            await dispatch(sendConsultationFile({ consultationId, file })).unwrap();
            await dispatch(getPatientConsultation(consultationId)).unwrap();
        } catch (err) {
            // console.log('error loading file', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteFile = async (id) => {
        setDeletingFiles((prev) => ({ ...prev, [id]: true }));
        try {
            await dispatch(deleteConsultationFile(id)).unwrap();
            await dispatch(getPatientConsultation(consultationId)).unwrap();
        } catch (err) {
            console.err('error deleting file', err);
        } finally {
            setDeletingFiles((prev) => ({ ...prev, [id]: false }));
        }
    };

    const trimEnding = (url) => {
        const trimmedStr = typeof url === 'string' && url.split('.').slice(-1).toString();
        return trimmedStr;
    };

    return (
        <LoadFileStl>
            {!problem && status === 0 && (
                <>
                    <input type="file" id="loadFile" ref={loadFile} />
                    <label htmlFor="loadFile" className="btn" onClick={(e) => handleFileUpload(e)}>
                        {files.length < 3 && <img src={addFile} alt="Добавить файл" />}
                    </label>
                </>
            )}

            <>
                {(files.length > 0 || fakeFile) && (
                    <div className="files">
                        {realFiles
                            ?.slice()
                            .reverse()
                            .map((file) => (
                                <div key={file.id} className="fileWrapper">
                                    <div className="file">
                                        <a href={file.file} target="_blank" rel="noreferrer">
                                            <Certificate />
                                            <span className="file_name">Файл{`.${trimEnding(file.file)}`}</span>
                                        </a>
                                        {/* Отображение превью документа с возможностью открыть или загрузить его. Сделано на будущее */}
                                        {/* {file.file && <FilePreviewer file={file.file} />} */}
                                        {status === 0 &&
                                            !problem &&
                                            (deletingFiles[file.id] ? (
                                                <Spinner height={'20px'} width={'20px'} marginTop={'0'} />
                                            ) : (
                                                <CertificateDelete onClick={() => handleDeleteFile(file.id)} />
                                            ))}
                                    </div>
                                </div>
                            ))}
                        {fakeFile && (
                            <div key={fakeFile.id} className="fileWrapper">
                                <div className="file">
                                    <a href={fakeFile.file} target="_blank" rel="noreferrer">
                                        <Certificate />
                                        <span className="file_name">Файл...</span>
                                    </a>
                                </div>
                                {
                                    <div className="progress">
                                        <progress max="100" value="50"></progress>
                                        <div className="progress-bg" style={{ width: progress_files - 3 || 0 }}>
                                            <div className="progress-bar"></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                )}
                {error && <div className="error">{error}</div>}
            </>
        </LoadFileStl>
    );
};
