import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormDoctor from './FormDoctor';
import { TitleWithPencil } from '../../../lkPatient/lkPatientProfile/viewPatient/titleWithPencil/TitleWithPencil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from '../../../loader/loader';
import { SectionStl } from './editDoctorStl.styled';

/**
 * EditDoctor компонент для редактирования профиля врача.
 *
 * Этот компонент предоставляет форму редактирования профиля врача, используя данные из Redux-хранилища
 * и библиотеки React Hook Form. Если профиль врача загружается (определяется через `isLoaderProfile`),
 * отображается компонент загрузки. В противном случае, форма заполняется начальными значениями из профиля врача.
 *
 * @component
 * @returns {JSX.Element} Возвращает JSX с формой для редактирования профиля врача или компонент загрузки.
 *
 * @hook useSelector Извлекает данные профиля врача (`doctorProfile`) и состояние загрузки профиля (`isPreloader`)
 * из Redux-хранилища.
 * @hook useForm Используется для управления состоянием формы и ее валидацией с начальными значениями.
 */

const EditDoctor = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/lk/doctor-profile/profile/view');
    };

    return (
        <SectionStl>
            <TitleWithPencil handleRedirect={handleRedirect} title={'Личные данные'} />
            <FormDoctor />
        </SectionStl>
    );
};

export default EditDoctor;
