import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPosts, getThemes, postComments, getArticlePage, getQuotes } from './api';

/**
 * Асинхронное действие для получения всех тем новостей.
 * @function fetchThemes
 * @async
 */

export const fetchThemes = createAsyncThunk('blog/fetchThemes', async (_, { rejectWithValue }) => {
    try {
        const response = await getThemes();
        return response;
    } catch (e) {
        console.error('Error fetch themes:', e);
        return rejectWithValue(e.response?.data || { message: e.message });
    }
});

/**
 * Асинхронное действие для получения постов по определённой теме.
 * @function fetchPostsByTheme
 * @async
 */

export const fetchPostsByTheme = createAsyncThunk(
    'blog/fetchPostsByTheme',
    async (data, { getState, rejectWithValue }) => {
        try {
            const next = getState().blog.posts[data?.themeId]?.next;
            const response = await getPosts(data, next);
            return { ...response, data };
        } catch (e) {
            console.error('Error fetch posts by theme:', e);
            return rejectWithValue({ [e.name]: e.message });
        }
    }
);

/**
 * Асинхронное действие для получения статьи и её комментария. Если статья уже загружена, получает только новые комментарии
 * @function fetchArticlePage
 * @async
 */

export const fetchArticlePage = createAsyncThunk(
    'blog/fetchArticlePage',
    async (articleId, { getState, rejectWithValue }) => {
        try {
            const next = getState().blog.articles.find((article) => article.id == articleId)?.next;
            const response = await getArticlePage(articleId, next);
            return { response, articleId };
        } catch (e) {
            console.error('Error fetch article page:', e);
            return rejectWithValue({ [e.name]: e.message });
        }
    }
);

/**
 * Асинхронное действие для  отправления комментария к статье.
 * @function postArticleComments
 * @async
 */

export const postArticleComments = createAsyncThunk('blog/postComments', async (data, { rejectWithValue }) => {
    try {
        const response = await postComments(data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error(`Ошибка: ${response.status} ${response.data?.message || 'Неизвестная ошибка'}`);
        }
        return response.data;
    } catch (error) {
        console.error('Error posting comments:', error);
        return rejectWithValue({ name: error.name, message: error.message });
    }
});
/**
 * Асинхронное действие для получения цитат и их парсинга с последующим добавлением в initialState.
 * @function fetchQuotes
 * @async
 */

export const fetchQuotes = createAsyncThunk('blog/fetchQuotes', async (_, { rejectWithValue }) => {
    try {
        const response = await getQuotes();
        return response;
    } catch (e) {
        console.error('Error fetch quotes:', e);
        return rejectWithValue({ error: e.message });
    }
});

const initialState = {
    themes: [],
    posts: {},
    articles: [],
    quotes: [],
    isLoading: null,
    isError: null,
};

const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        /**
         * Обновляет посты, добавляя случайную цитату после 2 поста
         * @function updatePosts
         */
        updatePosts(state, action) {
            state.posts[action.payload]?.postsByTheme?.forEach((post, index) => {
                if ((index + 1) % 2 === 0 && !post?.quote) {
                    const randomId = Math.floor(Math.random() * state.quotes.length);
                    post.quote = { ...state.quotes[randomId] };
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchThemes.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchThemes.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchThemes.fulfilled, (state, action) => {
            const { results } = action.payload || {};
            if (results) {
                const themes = results.sort((a, b) => a.id - b.id);
                state.themes = themes;
            }
            state.isLoading = false;
        });
        builder.addCase(fetchPostsByTheme.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchPostsByTheme.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchPostsByTheme.fulfilled, (state, action) => {
            // Проверка на наличие results и next
            const { data, results = [], next = null } = action.payload || {};
            const themeOfPosts = data?.themeId;

            // рендер постов на главной странице (homepage)
            if (!themeOfPosts) {
                state.posts['postsOnHomePage'] = { next: next, postsByTheme: [...results.slice(0, 3)] };
            }
            // рендер постов при изменении темы постов
            if (!state.posts[themeOfPosts]) {
                state.posts[themeOfPosts] = { next: next, postsByTheme: [...results] };
            }
            // рендер постов при нажатии на кнопку "показать больше"
            if (data.getMorePosts) {
                state.posts[themeOfPosts] = {
                    next: next,
                    postsByTheme: [...state.posts[themeOfPosts].postsByTheme, ...results],
                };
            }

            state.isLoading = false;
        });
        builder.addCase(fetchArticlePage.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(fetchArticlePage.rejected, (state) => {
            state.isError = true;
            state.isLoading = false;
        });
        builder.addCase(fetchArticlePage.fulfilled, (state, action) => {
            const {
                response: { article, comments },
                articleId,
            } = action.payload;

            if (article) {
                // Инициализация доп. полей при первой загрузке
                article.data.comments = comments.data.results;
                article.data.next = comments.data.next;
                state.articles.push(article.data);
            } else {
                // Добавление комментариев к статье
                const currentArticle = state.articles.find((article) => article.id == articleId);
                currentArticle.next = comments.data.next;
                currentArticle.comments.push(...comments.data.results);
            }

            state.isLoading = false;
        });
        builder.addCase(fetchQuotes.fulfilled, (state, action) => {
            const { results = [] } = action.payload || {};
            if (results.length > 0) {
                state.quotes = results.map((quote) => ({
                    text: quote.content,
                    author: quote.author,
                }));
            } else {
                console.error('No quotes found or invalid format:', action.payload);
            }
        });
    },
});
export const { updatePosts } = blogSlice.actions;
export default blogSlice.reducer;
