import styled from 'styled-components';

export const PatientCardProfileStyled = styled.div`
    width: 598px;
    border: 2px solid #f4f4f4;
    border-radius: 10px;
    padding: 20px 52px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    gap: 52px;

    @media all and (max-width: 900px) {
        padding: 20px;
        gap: 32px;
        width: 100%;
    }

    @media all and (max-width: 768px) {
        gap: 20px;
    }

    @media all and (max-width: 450px) {
     padding: 16px;
        gap: 8px;
    }

    .avatar {
        border-radius: 200px;
        overflow: hidden;
        flex-shrink: 0;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            width: 108px;
            height: 108px;
            border-radius: 50%;

            @media all and (max-width: 768px) {
              width: 100px;
              height: 100px;
            }

             @media all and (max-width: 450px) {
              width: 64px;
              height: 64px;
            }
        }
    }

        .name-and-age {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &__name {
                font-size: 20px;
                line-height: 130%;
                color: #212121;

                @media all and (max-width: 450px){
                  font-size: 16px;
                }
            }

            &__age {
                font-size: 18px;
                line-height: 150%;
                color: #666666;

                @media all and (max-width: 450px){
                  font-size: 14px;
                }
            }


        }
    }
`;
