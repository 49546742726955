import React from 'react';
import Container from '../share/Container';
import { useSelector } from 'react-redux';
import DoctorsCard from '../doctorsList/doctorsCard/DoctorsCard';
import styled from 'styled-components';
import DoctorSearchCard from './SearchDoctorCard/SearchDoctorCard';

const SearchDoctorsListStl = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

/**
 * Компонент списка врачей для страницы с результатом поиска
 *
 * @component
 * @returns {JSX.Element} Список карточек врачей для отображения результатов поиска
 */

const SearchDoctorsList = () => {

  const doctors = useSelector(state => state.doctors.doctors)
  const doctorCards = doctors.length > 0 ? doctors.map((doctor) => {

  return (
      <DoctorSearchCard
        key={doctor.id}
        doctor={doctor}
      />)
  }) : null // Если список пуст, возвращаем null, чтобы не отображать карточки
  return (
    <Container>
      <SearchDoctorsListStl>
        {doctorCards}
      </SearchDoctorsListStl>
    </Container>
  );
};

export default SearchDoctorsList;