import React from 'react';
import { WeekDaysStl } from './weekDays.styled';

/**
 * Компонент для отображения дней недели в календаре.
 * Отображает сокращенные названия дней недели (пн, вт, ср и т.д.)
 * Используется в шапке календаря для отображения названий дней недели.
 *
 * @returns {JSX.Element} Рендерит дни недели в виде списка.
 */

export const WeekDays = () => {
    const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

    return (
        <WeekDaysStl>
            {weekDays.map((weekDay) => (
                <div key={weekDay}>{weekDay}</div>
            ))}
        </WeekDaysStl>
    );
};
