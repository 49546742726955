/* eslint-disable react/prop-types */
import React from 'react';
import Flex from './Flex';
import { RoundPhotoStyled } from './roundPhotoStl.styled';

/**
 * @function RoundPhoto
 * Компонент для отображения круглого изображения
 */
const RoundPhoto = (props) => {
    return (
        <RoundPhotoStyled {...props}>
            <Flex align="center" justify="center">
                <img src={props.addr} {...props} alt="" />
            </Flex>
        </RoundPhotoStyled>
    );
};

export default RoundPhoto;
