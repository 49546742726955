import React, { useState } from 'react';
import { TextArea } from '../formFields/TextArea';
import { SymbolCountTextAreaStl } from './symbolCountTextAreaStl.styled';

/**
 * Компонент для отображения текстовой области с подсчетом символов.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Заголовок, отображаемый над текстовой областью.
 * @param {string} [props.placeholder] - Плейсхолдер для текстовой области.
 * @param {string} props.name - Имя, присваиваемое текстовой области.
 *
 * @returns {JSX.Element} Компонент SymbolCountTextArea.
 */

export const SymbolCountTextArea = ({ title, placeholder, name, countSymbol }) => {
    const [charCount, setCharCount] = useState(0);

    return (
        <SymbolCountTextAreaStl>
            <div className="container">
                <p className="title">{title}</p>
                <p className="symbols">
                    <span className="symbols__title">Введено символов</span>
                    <span className="symbols__count">{charCount} </span>
                    <span className="symbols__total">/ {countSymbol ? countSymbol : 1000}</span>
                </p>
            </div>
            <TextArea
                countSymbol={countSymbol}
                placeholder={placeholder}
                name={name}
                onCharCountChange={setCharCount}
            />
        </SymbolCountTextAreaStl>
    );
};
