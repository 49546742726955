import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BlogStyled } from './blog.styled';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import Container from '../../componets/share/Container';
import { BlogMenu } from '../../componets/blog/blogMenu/BlogMenu';
import { Spinner } from '../../componets/share/Spinner';
import { fetchPostsByTheme, fetchQuotes, fetchThemes } from '../../componets/blog/api/blogSlice';

/**
 * Компонент для отображения блога с постами и темами.
 * Загружает список постов и темы с сервера. Если посты для выбранной темы не загружены,
 * запрашивает их. Также обрабатывает навигацию и загрузку цитат.
 *
 * @returns {JSX.Element} Рендерит список постов по теме, меню блога и спиннер загрузки.
 */
const Blog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { themes, posts, isLoading, quotes } = useSelector((state) => state.blog);
    const { pathname } = useLocation();
    const { themeId } = useParams();

    useEffect(() => {
        if (!posts[themeId]) {
            dispatch(fetchPostsByTheme({ themeId: themeId, getMorePosts: false }));
        }
    }, [themeId, posts, dispatch]);

    useEffect(() => {
        if (!themes.length) {
            dispatch(fetchThemes());
        }
    }, []);

    useEffect(() => {
        if (!quotes.length) {
            dispatch(fetchQuotes());
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (pathname === '/news') {
            navigate('/news/1', { replace: true });
        }
    }, [pathname]);

    return (
        <Container>
            <MainHeader title={'Блог'} />
            <BlogStyled>
                {isLoading || !posts[themeId] ? (
                    <div className="centerSpinner">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <BlogMenu />
                        {posts[themeId]?.postsByTheme?.length > 0 && !isLoading ? (
                            <Outlet />
                        ) : (
                            <p className="no-posts-text">На данный момент статей нет</p>
                        )}
                    </>
                )}
            </BlogStyled>
        </Container>
    );
};
export default Blog;
