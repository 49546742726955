import styled from 'styled-components';

export const StyledStartConsultationModal = styled.div`
    .modalStart {
        width: 416px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 36px;
        background: #ffffff;
        border-radius: 24px;
        padding: 52px 40px;
        cursor: auto;
        position: relative;

        &__cross-icon {
            position: absolute;
            cursor: pointer;
            top: 30px;
            right: 30px;
            width: 20px;
            height: 20px;
        }

        > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }

        &__heading {
            color: #666;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }

        &__namePatient {
            width: 100%;
            word-wrap: break-word;
            color: #212121;
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-align: center;
        }

        &__timeBeforeConsultation {
            color: #212121;
            background-color: #f4f4f4;
            border-radius: 12px;
            padding: 12px 18px;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-align: center;
        }

        &__delayBtn {
            width: 100%;
            text-decoration: none;
            text-align: center;
            border: 2px solid #19be6f;
            border-radius: 8px;
            background-color: #19be6f;
            height: 47px;
            padding: 14px 24px;
            color: #ffffff;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                background-color: #169056;
                border: 2px solid #169056;
            }
        }
    }
    @media (max-width: 500px) {
        .modalStart {
            width: 288px;
            padding: 52px 16px 40px;

            &__cross-icon {
                position: absolute;
                cursor: pointer;
                top: 20px;
                right: 20px;
                width: 13.8px;
                height: 13.8px;
            }
        }
    }
`;
