import styled from 'styled-components';

export const StyledHeader = styled.header`
    position: sticky;
    top: 0px;
    z-index: 1002;
    background-color: #fff;

    margin: 0 auto;
    padding-left: max(20px, calc((100vw - 1070px) / 2));
    padding-right: max(20px, calc((100vw - 1070px) / 2));

    .header__inner {
        box-sizing: border-box;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 0;
        border-radius: 5px;
        gap: 40px;
        @media (max-width: 1024px) {
            padding: 0;
            display: grid;
            row-gap: 8px;
            grid-template-areas:
                'logo navbar search'
                'timer timer timer';
            padding-top: 13px;
        }
        @media (max-width: 768px) {
            padding: 9px 0;
            display: flex;
            justify-content: space-between;
        }
    }

    .search-container {
        grid-area: search;
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .header__signin-link {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #212121;
        text-decoration: none;
        transition: opacity 0.3s linear;
        display: block;
        position: relative;
        cursor: pointer;
    }

    .header__signin-link:hover {
        opacity: 0.6;
    }

    .dropbox {
        position: absolute;
        background: #ffffff;
        right: 0;
        top: 64px;
        padding: 12px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        @media (max-width: 768px) and (min-width: 513px) {
            top: 80px;
        }
        @media (max-width: 512px) and (min-width: 501px) {
            top: 96px;
        }
        @media (max-width: 768px) {
            position: static;
        }
    }
`;

export const LogoutButtonStl = styled.button`
    background: none;
    border: none;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.3s linear;

    &:hover {
        opacity: 0.6;
    }
`;
