import styled from 'styled-components';

export const StyledInfoBlock = styled.section`
    background-image: url('img/homePage/infoBlock.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    height: 720px;
    background-color: rgb(224 239 240);
    padding: 80px 0;

    @media (max-width: 768px) {
        padding: 26px 0;
        height: 403px;
    }
    @media (max-width: 500px) {
        background-position: center;
        background-size: auto 100%;
        padding: 16px 0;
        height: 610px;
    }

    .infoBlock__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .infoBlock__title {
            font-size: 40px;
            line-height: 52px;
            font-weight: 500;

            @media (max-width: 768px) {
                font-size: 34px;
                line-height: 40px;
            }
            @media (max-width: 500px) {
                font-size: 28px;
                line-height: 30px;
                margin-bottom: 230px 
            }
        }

        .infoBlock__list {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
            gap: 20px;
            list-style: none;

            @media (max-width: 768px) {
                grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
            }

            @media (max-width: 500px) {
                grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
                gap: 8px;
            }

            .infoBlock__list-item {
                padding: 16px 10px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
                /* box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2); */
                backdrop-filter: blur(10px);
                border-radius: 16px;

                @media (max-width: 500px) {
                    padding: 9px 10px;
                }

                .infoBlock__list-title {
                    margin-bottom: 4px;
                    font-size: 22px;
                    line-height: 28px;
                    font-weight: 500;
                }
                .infoBlock__list-description {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 300;
                }
            }
        }
    }
`;
