import React, { useEffect } from 'react';
import { ViewDoctorStl } from './viewDoctor.styled';
import { DoctorEducation } from './doctorEducation/DoctorEducation';
import { DoctorAdvancedTraining } from './doctorAdvancedTraining/DoctorAdvancedTraining';
import { DoctorPrice } from './doctorPrice/doctorPrice';
import { DoctorWork } from './doctorWork/DoctorWork';
import { DoctorSpecialization } from './doctorSpecialization/DoctorSpecialization';
import { useSelector } from 'react-redux';
import { DoctorDeseases } from './doctorDeseases/DoctorDeseases';
import { Loader } from '../../../loader/loader';
import { ViewData } from './doctorData/ViewData';
import { useNavigate } from 'react-router-dom';
import { TitleWithPencil } from '../../../lkPatient/lkPatientProfile/viewPatient/titleWithPencil/TitleWithPencil';
import { ModerationLabel } from '../../../share/ModerationLabel';

/**
 * Компонент ViewDoctor отображает информацию о враче в личном кабинете.
 * Он включает в себя различные разделы с данными профиля врача:
 * образование, повышение квалификации, специализация, болезни, которые лечит доктор, и стоимость услуг.
 * Компонент также предоставляет возможность редактировать профиль через кнопку с иконкой карандаша.
 *
 * В случае загрузки данных, отображается индикатор загрузки (`Loader`).
 *
 * @component
 * @returns {JSX.Element} Возвращает JSX с данными врача, включая разделы образования, работы, специализации, заболеваний и цен.
 *
 * @example
 * // Пример использования компонента
 * <ViewDoctor />
 */
export const ViewDoctor = () => {
    const navigate = useNavigate();
    const { isLoader } = useSelector((state) => state.doctorProfile);
    const { is_approved } = useSelector((state) => state.doctorProfile?.doctorData);

    const handleRedirect = () => {
        navigate('/lk/doctor-profile/profile/edit');
    };

    if (isLoader) {
        return <Loader />;
    }

    return (
        <ViewDoctorStl>
            <TitleWithPencil handleRedirect={handleRedirect} title={'Личные данные'} />
            <ModerationLabel isApproved={is_approved} />
            <div className="viewBlock">
                <ViewData />
                <DoctorEducation />
                <DoctorAdvancedTraining />
                <DoctorWork />
                <DoctorSpecialization />
                <DoctorDeseases />
                <DoctorPrice />
            </div>
        </ViewDoctorStl>
    );
};
