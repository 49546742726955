import React, { useEffect, useState } from 'react';
import { FiltersStl } from './filters.styled';
import { useSelector } from 'react-redux';
import { fetchSpecializations } from '../../lkDoctor/api/doctorUserProfileSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as SearchSvg } from '../../../img/icons/newDoctorIcons/search.svg';
import Select from '../../share/formFields/Select';
import InputText from '../../share/formFields/InputText';
import Form from '../../share/formFields/Form';
import InputNameText from '../../share/formFields/InputNameText';
import SelectSpecialization from '../../allSpecializations/specializations';

/**
 * Компонент Filters.
 * Предоставляет фильтрацию списка врачей по имени, специальности и сортировке.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {function} props.setFilters - Функция для обновления состояния фильтров в родительском компоненте.
 * @returns {JSX.Element} Компонент фильтров.
 */

export const Filters = ({ filters, setFilters }) => {
    /**
   * Массив доступных вариантов сортировки.
   * @type {Array<Object>}
   * @property {string} value - Значение, по которому будет сортироваться список.
   * @property {string} label - Текст, отображаемый в выпадающем списке.
   */
    const sortType = [
        { value: 'price', label: 'По возрастанию стоимости' },
        { value: '-price', label: 'По убыванию стоимости' },
        { value: '-rating_average', label: 'По рейтингу' },
        { value: '-seniority', label: 'Большой стаж' },
    ];

    /**
     * Список доступных специализаций врачей.
     * @constant {Array<Object>} specializations - Массив объектов со специализациями врачей.
     * @constant {Object|undefined} currenctSpec - Текущая выбранная специализация врача.
     */
    const specializations = useSelector((state) => state.specializations.specializations);
    const currenctSpec = specializations.find(spec => spec.id === filters.spec);

    /**
     * Локальное состояние строки поиска с debounce.
     * Используется для предотвращения частых запросов и перерисовки при быстром вводе текста.
     */
    const [debouncedSearch, setDebouncedSearch] = useState(filters.search);

    useEffect(() => {
        setDebouncedSearch(filters.search || "");
    }, [filters.search]);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (debouncedSearch !== filters.search) {
                setFilters((prev) => ({ ...prev, search: String(debouncedSearch) }));
            }
        }, 500);
        return () => clearTimeout(handler);
    }, [debouncedSearch]);

    /**
     * Обработчик изменения строки поиска.
     * @function
     * @param {string} search - Введенное пользователем значение.
     */
    const handleSearchChange = (search) => {
        setDebouncedSearch(search);
    };

    return (
        <FiltersStl>
            <Form>
                {/* Поле ввода для поиска по Ф.И.О */}
                <label className="inputSearch">
                    <InputNameText
                        name="search"
                        placeholder={'Поиск по Ф.И.О'}
                        onChange={handleSearchChange}
                        validation={{
                            required: false,
                            pattern: {
                                value: /^[\p{L} ]*$/u,
                                message: 'Имя может содержать только символы алфавита',
                            },
                        }}
                        isRequired={false}
                        deleteSpaces={/[\s]/g}
                        isFullName={true}
                        value={debouncedSearch}
                    />
                    <SearchSvg />
                </label>
                {/* Выпадающий список для выбора специализации */}
                <SelectSpecialization
                    onChange={(spec) => setFilters((prev) => ({ ...prev, spec: spec ? Number(spec.value) : 0 }))}
                    placeholder='Специализация'
                    name="data.specialization"
                    isMulti={false}
                    isRequired={false}
                    defaultValue={currenctSpec ? { value: currenctSpec.id, label: currenctSpec.name } : null}
                />
                {/* Выпадающий список для выбора типа сортировки */}
                <Select
                    name="sort"
                    options={sortType}
                    placeholder="Сортировка"
                    validation={{ required: false }}
                    onChange={(sort) =>
                        setFilters((prev) => {
                            if (sort == null) {
                                return { ...prev, sort: '' };
                            }
                            return { ...prev, sort: String(sort.value) };
                        })
                    }
                    defaultValue={filters.sort ? sortType.find((s) => s.value === filters.sort) : null}
                />
            </Form>
        </FiltersStl>
    );
};
