import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormDirty } from './FormDirtyContext';
import PopUpConfirm from '../componets/share/PopUpConfirm';
import { BlurBgStyled } from './BlurBgStyled.style';

/**
 * Компонент-обработчик смены маршрута с контролем "грязного" состояния формы.
 * Отображает модальное окно подтверждения, если форма содержит несохраненные изменения при попытке навигации.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.children Дочерние элементы, которые будут отрисованы внутри компонента.
 * @returns {JSX.Element} JSX-элемент обработчика смены маршрута.
 * @example
 * <RouteChangeHandler>
 *     <YourComponent />
 * </RouteChangeHandler>
 */

const RouteChangeHandler = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isFormDirty, setIsFormDirty } = useFormDirty();

    const [showModal, setShowModal] = useState(false);
    const [nextPath, setNextPath] = useState(null);
    const [currentPath, setCurrentPath] = useState(location.pathname);

    /** Отслеживание смены маршрута */
    useEffect(() => {
        if (location.pathname !== currentPath) {
            if (location.pathname === '/login') {
                setCurrentPath(location.pathname);
                setIsFormDirty(false);
                setShowModal(false);
                return;
            }
            if (isFormDirty) {
                setNextPath(location.pathname);
                setShowModal(true);
                navigate(currentPath, { replace: true });
            } else {
                setCurrentPath(location.pathname);
            }
        }
    }, [location.pathname, isFormDirty, currentPath, navigate]);

    /** Подтверждение навигации */
    const handleConfirmNavigation = () => {
        setShowModal(false);
        setIsFormDirty(false);
        sessionStorage.removeItem('formData');
        if (nextPath) {
            navigate(nextPath);
            setCurrentPath((prevPath) => {
                return nextPath;
            });
        }
    };

    /** Отмена навигации */
    const handleCancelNavigation = () => {
        setShowModal(false);
        setNextPath(null);
        setCurrentPath(currentPath);
        setIsFormDirty(true);
    };

    /** Обработчик попытки закрыть вкладку */
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isFormDirty) {
                e.preventDefault();
                e.returnValue = 'Изменения могут не сохраниться.';
                sessionStorage.setItem('pendingUnload', 'true');
            } else {
                sessionStorage.removeItem('pendingUnload');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isFormDirty]);

    /** Очистка Session Storage при условии наличия флага "pendingUnload"
     *  Очистка происходит при следующей загрузке, если была перезагрузка/закрытие
     */
    useEffect(() => {
        if (sessionStorage.getItem('pendingUnload') === 'true') {
            sessionStorage.removeItem('formData');
            sessionStorage.removeItem('pendingUnload');
        }
    }, []);

    return (
        <>
            {showModal && (
                <BlurBgStyled
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1005,
                    }}
                >
                    <PopUpConfirm
                        onClose={handleCancelNavigation}
                        onConfirm={handleConfirmNavigation}
                        isClarification={true}
                        contentHeader={'Вы уверены, что хотите покинуть страницу?'}
                        contentClarification={'Внесённые Вами изменения не будут сохранены'}
                        buttonClose={'Остаться'}
                        buttonConfirm={'Выйти'}
                    />
                </BlurBgStyled>
            )}
            {children}
        </>
    );
};

export default RouteChangeHandler;
