import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledAvatar = styled.img`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 50%;
    object-fit: cover;
`;
export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
