import React, { memo, useState } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { ArticleFormStyled } from './articleForm.styled';
import { useDispatch, useSelector } from 'react-redux';
import { postArticleComments } from '../../api/blogSlice';
import { Appointment } from '../articleModal/Appointment';
import { Modal } from '../../../share/Modal';
import { SymbolCountTextArea } from '../../../share/symbolCountTextArea/SymbolCountTextArea';

/**
 * Компонент ArticleForm предоставляет форму для добавления комментария к статье.
 * Если пользователь не авторизован, отображается модальное окно с предложением авторизоваться.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {number} props.id - ID статьи, к которой добавляется комментарий.
 * @returns {JSX.Element} Форма для добавления комментария.
 */

const ArticleForm = ({ id }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isAuth } = useSelector((state) => state.authentification);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: { comment: '' },
    });
    const { isValid } = useFormState({ control: methods.control });
    const leaveComment = async ({ comment }) => {
        if (!isAuth) {
            setShowModal(true);
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            await dispatch(
                postArticleComments({
                    post_id: id,
                    text: comment,
                })
            ).unwrap();
            methods.reset({ comment: '' });
            alert('Комментарий был успешно отправлен на модерацию. Ожидайте публикацию');
        } catch (err) {
            setError('Ошибка при отправке комментария. Попробуйте еще раз.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ArticleFormStyled isDisabled={isValid || isLoading}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(leaveComment)}>
                    <SymbolCountTextArea title={''} name={'comment'} placeholder={'По желанию оставьте комментарий'} />
                    <button className="commentSubmitButton" type="submit" disabled={!isValid || isLoading}>
                        {isLoading ? 'Отправка...' : 'Оставить комментарий'}
                    </button>
                    {error && <p className="error">{error}</p>}
                </form>
            </FormProvider>

            {showModal && (
                <Modal toggle={setShowModal}>
                    <Appointment closeModal={setShowModal} />
                </Modal>
            )}
        </ArticleFormStyled>
    );
};

export default memo(ArticleForm);
