import { apiRequest } from "../../share/api";

/**
 * Отправляет POST-запрос с контактными данными пользователя.
 * @async
 * @function sendContacts
 * @param {Object} vacanciesData Данные формы вакансий.
 * @property {string} vacanciesData.name Ф.И.О пользователя.
 * @property {string} vacanciesData.specialization Специализация пользователя.
 * @property {string} vacanciesData.phone Номер телефона пользователя.
 * @property {string} vacanciesData.email Email пользователя.
 * @property {boolean} vacanciesData.agreement Флаг согласия с обработкой персональных данных.
 * @returns {Promise<Object>} Ответ сервера с подтверждением отправки формы вакансий. 
 * При успешном запросе возвращает объект:
 * {
 *   "name": "Иванов Иван Иванович",
 *   "specialization": "Терапевт",
 *   "phone": "+79999991010",
 *   "email": "user@someexample.com"
 * }
 * @throws {Error} В случае ошибки выбрасывает объект ошибки с ответом сервера или текстом ошибки.
 */

export const sendVacancies = async (vacanciesData) => {
    try {
        return await apiRequest.post(`contacts/contact_doctor/`, vacanciesData);
    } catch (error) {
        console.error(error.response);
        throw error.response ? error.response : new Error(error.message);
    }
};
