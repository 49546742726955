import React from 'react';
import { ageCalculator } from '../../../../share/helpers';
import { PatientCardProfileStyled } from './patientCardProfileStyled.stl';
import AvatarDefault from '../../../../../img/avatar_default.png';

/**
 * Компонент профиля пациента. Отображает информацию о пациенте, включая фото, ФИО, пол и возраст.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.patient - Объект с данными пациента.
 * @param {Object} props.patient.user - Объект с данными пользователя пациента.
 * @param {string} props.patient.user.last_name - Фамилия пациента.
 * @param {string} props.patient.user.first_name - Имя пациента.
 * @param {string} [props.patient.user.middle_name] - Отчество пациента (необязательное свойство).
 * @param {string} props.patient.user.sex - Пол пациента ('М' для мужчины, 'Ж' для женщины).
 * @param {string} [props.patient.user.image_standart] - URL-адрес изображения пациента (необязательное свойство).
 * @param {string} props.patient.user.date_birth - Дата рождения пациента в формате ISO (например, '1980-01-01').
 *
 * @returns {JSX.Element} Возвращает JSX-элемент для отображения карточки пациента.
 */
export const PatientCardProfile = ({ patient }) => {
    const { last_name, first_name, middle_name, sex, image_standart, date_birth } = patient.user;
    const fullName = `${last_name ? last_name : 'Пациент'} ${first_name ? first_name : ''} ${
        middle_name ? middle_name : ''
    }`;
    const patientGender = sex ? (sex === 'Ж' ? 'Женщина' : 'Мужчина') : 'Пол не указан';
    const avatar = image_standart ? image_standart : AvatarDefault;

    return (
        <PatientCardProfileStyled>
            <div className="avatar">
                <img src={avatar} alt="photo" />
            </div>
            <div className="name-and-age">
                <div className="name-and-age__name">{fullName}</div>
                <div className="name-and-age__age">
                    {patientGender}, {ageCalculator(date_birth)}
                </div>
            </div>
        </PatientCardProfileStyled>
    );
};
