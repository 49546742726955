import React from 'react';
import emptyStar from '../../../img/star-icon-empty.svg';
import fullStar from '../../../img/star-icon-full.svg';
import { FeedbackValueStl } from './feedbackValue.styled';
import { useController } from 'react-hook-form';

/**
 * Компонент для выбора оценки (рейтинга) в форме отзыва.
 * Отображает звезды, которые можно выбрать, чтобы установить рейтинг.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {string} props.name Название поля, используемое в `react-hook-form`.
 * @param {Object} props.control Контроллер из `react-hook-form`, используемый для управления состоянием поля.
 * @returns {JSX.Element} Компонент выбора рейтинга.
 */
export const FeedbackValue = ({ name, control }) => {
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: 0,
        rules: {
            validate: (value) => value > 0 || 'Пожалуйста, выберите оценку',
        },
    });

    return (
        <FeedbackValueStl>
            <h3>Оцените</h3>
            <div className="star_container">
                {[...Array(5)].map((_, index) => (
                    <button
                        onClick={() => onChange(index + 1)}
                        key={index}
                        style={
                            index < value
                                ? { backgroundImage: `url(${fullStar})` }
                                : { backgroundImage: `url(${emptyStar})` }
                        }
                        type="button"
                    />
                ))}
            </div>
        </FeedbackValueStl>
    );
};
