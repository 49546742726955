import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createPayment, getConsultation, createPaymentConfirmation } from './patientApi';

const initialState = {
    paymentConfirmationStatus: 'start',
    isPaid: null,
};

export const fetchCreatePayment = createAsyncThunk('patientPay/fetchCreatePayment', async (id) => {
    try {
        const response = await createPayment(id);
        window.open(response.data.link, '_self', 'noopener');
        return response.data;
    } catch (err) {
        // console.log(err);
    }
});

export const fetchPaymentConfirmation = createAsyncThunk('patientPay/fetchPaymentConfirmation', async (id) => {
    try {
        const response = await getConsultation(id);
        return response.data.data.consultation.is_payed;
    } catch (err) {
        // console.log(err);
    }
});

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentConfirmation.pending, (state) => {
                state.paymentConfirmationStatus = 'loading';
            })
            .addCase(fetchPaymentConfirmation.fulfilled, (state, action) => {
                state.paymentConfirmationStatus = 'idle';
                state.isPaid = action.payload;
            })
            .addCase(fetchPaymentConfirmation.rejected, (state) => {
                state.paymentConfirmationStatus = 'error';
            })
            .addDefaultCase(() => {});
    },
});

const { actions, reducer } = paymentsSlice;
export default reducer;
