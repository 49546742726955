import React, { useEffect } from 'react';
import { clearConsultation, getPatientConsultation } from '../../../api/PatientConsultationsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';
import { DoctorCardProfile } from '../../../../ourDoctors/doctorCard/doctorCardProfile/doctorCardProfile';
import { Feedback } from './feedback/Feedback';
import { PatientConsultationPageStyled } from './patientConsultationPage.styled';
import { ProblemForm } from '../../problemForm/problemForm';
import { Spinner } from '../../../../share/Spinner';
import { consultationStatus } from '../lkPatientConsultationHelpers';
import { DoctorRecommendations } from './DoctorRecommendations/doctorRecommendations';
import { ConsultationTimer } from '../../../../consultationTimer/ConsultationTimer';
import { useTimer } from '../../../../../hooks/useTimer';
import { Modal } from '../../../../share/Modal';
import { SuccessConsultation } from '../../../../ourDoctors/ourDoctorsModals/successConsultationModal/SuccessConsultation';
import { useState } from 'react';
import { setCloseModal } from '../../../../ourDoctors/api/doctorsSlise';
import { fetchPatientProfile } from '../../../api/PatientProfileSlice';

//  * Компонент для отображения страницы консультации пациента.
//  * @component
//  * @returns {JSX.Element} Полный интерфейс страницы консультации пациента.
//  */
export default function PatientConsultationPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const openModal = useSelector((state) => state.doctors.consultation.openModal);
    const [showModal, setShowModal] = useState(openModal);
    const { currentConsultation, isLoading } = useSelector((state) => state.patientConsultations);
    const { patientProfile } = useSelector((state) => state.patientProfile);
    const { email } = patientProfile;

    useEffect(() => {
        dispatch(getPatientConsultation(id))
            .unwrap()
            .catch((error) => console.error('Error fetching consultation:', error));

        return () => dispatch(clearConsultation());
    }, [dispatch, id]);

    useEffect(() => {
        if (!patientProfile) dispatch(fetchPatientProfile());
    }, []);

    const {
        status,
        consultation_time,
        doctor,
        recommendation,
        patient_notes,
        feedback,
        registration_log,
        is_payed,
        files,
        progress_files,
    } = currentConsultation;

    const { price, id: doctorId } = doctor || {};

    const isPatientCancelled = doctorId !== registration_log?.cancelled_by;

    const patientFiles = files?.filter((item) => item.owners_role === 'patient') || [];
    const doctorFiles = files?.filter((item) => item.owners_role === 'doctor') || [];

    const { minutes, isExpired } = useTimer({ date: consultation_time, trackSeconds: false });
    const disabledStart = (minutes >= 30 && isExpired) || (minutes >= 15 && !isExpired);
    const disabledComments =
        (status === 0 && minutes <= 30 && !isExpired && !patient_notes) || (isExpired && !patient_notes);

    const handleGoBack = async () => {
        navigate(-1);
    };

    useEffect(() => {
        const seenConsultation = { id, status };
        sessionStorage.setItem('seenConsultation', JSON.stringify(seenConsultation));
    }, [id, status]);

    const renderDoctorRecommendations = () => {
        if (status === 1 && recommendation) {
            return <DoctorRecommendations recommendation={recommendation} files={doctorFiles} />;
        }
        if (status === 1 && !recommendation) {
            return <p className="recommendationWarning">Врач выдаст заключение в течение трех часов.</p>;
        }
    };

    const closeModal = () => {
        dispatch(setCloseModal(false));
        setShowModal(() => false);
    };

    // isLoading влияет на загрузку файлов - проверить
    // if (isLoading || !consultation_time) return <Spinner />;
    if (!consultation_time) return <Spinner />;

    return (
        <>
            <PatientConsultationPageStyled>
                <div onClick={handleGoBack} className="go-back-btn">
                    Назад к списку
                </div>

                {!currentConsultation.id ? (
                    <Spinner />
                ) : (
                    <div className="consultation-data">
                        <div className="consultation-schedule">
                            {consultationStatus(status, is_payed, isPatientCancelled)}
                            <ConsultationTimeAndPrice
                                priceDisplay={true}
                                date={consultation_time}
                                price={price}
                                isCurrentConsultation={true}
                            />
                        </div>
                        {(status === 0 || status === 3) && !disabledStart && (
                            <ConsultationTimer date={consultation_time} />
                        )}
                        <div className="patient-data">{doctor && <DoctorCardProfile doctor={doctor} />}</div>

                        {!disabledComments && (
                            <ProblemForm
                                consultationId={id}
                                problem={patient_notes}
                                files={patientFiles}
                                progress_files={progress_files}
                                status={status}
                            />
                        )}
                        {renderDoctorRecommendations()}
                        <ConsultationButtons consultation={currentConsultation} />
                        {status === 1 && <Feedback consultationId={id} feedback={feedback} />}
                    </div>
                )}
            </PatientConsultationPageStyled>
            {showModal && (
                <Modal toggle={closeModal}>
                    <SuccessConsultation email={email} closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
}
