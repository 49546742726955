import React from 'react';

/**
 * Функция возвращает React-элемент с текстовым статусом консультации на основе её текущего состояния.
 *
 * @param {number} status - Статус консультации:
 *  - `0`: Ожидается оплата или онлайн-консультация.
 *  - `1`: Онлайн-консультация состоялась.
 *  - `2`: Консультация отменена.
 *  - `8`: Онлайн-консультация была прервана и не состоялась.
 *  - `9`: Онлайн-консультация не состоялась.
 * @param {boolean} is_payed - Оплачен ли текущий статус консультации.
 * @param {boolean} isPatientCancelled - Отменил ли консультацию пациент.
 *  Используется только при `status === 2`.
 * @returns {JSX.Element} React-элемент, представляющий статус консультации.
 */
export const consultationStatus = (status, is_payed, isPatientCancelled) => {
    if (status === 0 && !is_payed) {
        return <p className="statusConsultation red">Ожидается оплата консультации</p>;
    } else if (status === 0 && is_payed) {
        return <div className="statusConsultation">Онлайн-консультация</div>;
    } else if (status === 2) {
        return (
            // сейчас только пациент может отменить консультацию, проверка оставлена на будущее
            <p className="statusConsultation red">
                {`${isPatientCancelled ? 'Вы отменили консультацию' : 'Врач отменил консультацию'}`}
            </p>
        );
    } else if (status === 1) {
        return <div className="statusConsultation green">Онлайн-консультация состоялась</div>;
    } else if (status === 8) {
        return <div className="statusConsultation red">Консультация была прервана и не состоялась</div>;
    } else if (status === 9) {
        return <div className="statusConsultation red">Онлайн-консультация не состоялась</div>;
    }
};

/**
 * Функция возвращает набор кнопок для управления консультацией.
 *
 * @returns {JSX.Element} React-элемент, содержащий две кнопки:
 *  - Кнопка "Начать" с классом `startBtn`.
 *  - Кнопка "Отменить" с классом `closeBtn`.
 */
export const consultationNewBtn = () => {
    return (
        <>
            <button onClick={() => {}} className="startBtn">
                Начать
            </button>
            <button onClick={() => {}} className="closeBtn">
                Отменить
            </button>
        </>
    );
};
