import styled from 'styled-components';

export const NewFormSTL = styled.div`
    .axiosError {
        margin-bottom: 24px;
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .axiosErrorText {
        color: #ff3636;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        gap: 60px;
        @media (max-width: 600px) {
            gap: 40px;
        }
    }

    .title {
        font-family: Rubik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 25px;
    }

    .work__block {
        display: flex;
        gap: 20px;
    }

    .work__date {
        max-width: 156px;
        margin-bottom: 10px;
        &-checkbox {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 20px;
        }
    }

    .button {
        margin-top: 25px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        background-color: transparent;
        border: none;
        color: #19be6f;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;
        cursor: pointer;
        .span {
            font-size: 18px;
            color: #19be6f;
        }
        &:hover {
            opacity: 0.6;
        }
        &_border {
            border-bottom: 1px dashed #d2d2d2;
        }
        &_del {
            padding-bottom: 8px;
            margin: 25px 0px 40px;
            color: red;
        }
    }

    .dashed-border {
        border-bottom: 1px dashed #d2d2d2;
        margin-bottom: 40px;
    }

    .work__date-checkbox {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .price__comment {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .date__container {
        display: grid;
        column-gap: 20px;
        align-items: start;
        grid-template-columns: repeat(2, max-content);
        grid-template-areas:
            'title title'
            'start end';
        & input {
            max-width: 156px;
        }
        & label {
            max-width: 156px;
        }

        .date__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            grid-area: title;
        }
    }
    .btn__recive {
        margin-top: 60px;
        padding: 16px 32px;
        border-radius: 10px;
        border: 2px solid #19be6f;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        background-color: #19be6f;
        transition: all 0.3s linear;
        width: 100%;
        cursor: pointer;
        padding: 17px;
        &:hover {
            background-color: #169056;
            border: 2px solid #169056;
        }
        &:disabled {
            background-color: #8af0bf;
            border: 2px solid #8af0bf;
            color: #ffffff;
            text-align: center;
            cursor: default;
        }
        @media (max-width: 600px) {
            margin-top: 40px;
        }
    }

    .ql-container {
        max-width: 422px;
        height: 160px;
    }
    .ql-editor p {
        word-wrap: break-word;
        font-size: 16px;
    }

    .ql-editor::before {
        color: rgba(182, 183, 186, 1);
        font-size: 16px;
        line-height: 22px;
        font-style: normal;
        pointer-events: none;
    }
`;
