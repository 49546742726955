import styled from 'styled-components';

export const DaysStl = styled.div`
    display: grid;
    grid: repeat(6, 40px) / repeat(7, 40px);
    row-gap: 4px;
    align-items: center;
    justify-items: center;

    .day {
        height: 40px;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 100%;
        user-select: none;
        border: 1px solid transparent;
        transition: all 0.2s linear;
    }
    .calendar__days {
        transition: all 0.2s linear;
        /* &_no-receipts {
            &:hover {
                border: 2px solid #19be6f;
                border-radius: 100%;
            }
        } */
        &_roundLeft {
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
        }

        &_roundRight {
            border-top-right-radius: 100%;
            border-bottom-right-radius: 100%;
        }
        &_unused {
            color: #b6b7ba;
            cursor: pointer;
            &-noClick {
                cursor: default;
            }
        }
        &_weekend {
            color: #b6b7ba;
        }
        &_receipts {
            border-radius: 100%;
            background-color: #19be6f;
            color: #fff;
            &:hover {
                background-color: #169056;
            }
        }
        &_choosen {
            // border: 2px solid #6bebae;
            border: 1px solid #19be6f;
            border-radius: 100%;
        }
        &_choosen.calendar__days_rightPosition.calendar__days_no-receipts {
            border-right: none;
        }
        &_choosen.calendar__days_leftPosition.calendar__days_no-receipts {
            border-left: none;
        }

        &_rightPosition {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:hover {
                background-color: #169056;
                border: 2px solid #169056;
            }
        }
        &_choosen.calendar__days_rightPosition:hover {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &_leftPosition {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:hover {
                background-color: #169056;
                border: 2px solid #169056;
            }
        }
        &_choosen.calendar__days_leftPosition:hover {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &_choosen.calendar__days_receipts {
            border: 2px solid ${(props) => (props.pathname ? '#000000' : '#6bebae')};
            border-radius: 100%;
            // border-radius: ${(props) => props.pathname && '100%'};

            background-color: ${(props) => !props.pathname && '#6bebae'};
            :hover {
                border: 2px solid ${(props) => (props.pathname ? '#000000' : '#6bebae')};
                border-radius: 100%;
                // border-radius: ${(props) => props.pathname && '100%'};
            }
        }

        &_today {
            color: #6bebae;
        }
        &_today.calendar__days_choosen {
            color: ${(props) => !props.pathname && '#169056'};
        }

        &_record {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 4px;
                height: 4px;
                background-color: #fff;
                bottom: 4px;
                left: 50%;
                border-radius: 100%;
                transform: translateX(-50%);
            }
        }
    }

    &__day {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        transition: all 0.16s;
        color: #b6b7ba;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 50%;

        &:hover {
            border-color: #169056;
        }

        &_available {
            color: #070a13;
        }

        &_is-work-day {
            background-color: #19be6f;
            color: white;

            &:hover {
                background-color: #169056;
            }
        }

        &_past-work-day {
            color: #6bebae;
        }

        &_consultation {
            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: 4px;
                height: 4px;
                background-color: white;
                bottom: 4px;
                left: 50%;
                border-radius: 100%;
                transform: translateX(-50%);
            }
        }

        &_selected {
            border-color: #19be6f;
        }

        &_work-day-selected {
            border-color: black;

            &:hover {
                border-color: black;
            }
        }

        &_today {
            color: #32e48f;
        }

        &_pos_first {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }

        &_pos_middle {
            border-left: 0;
            border-right: 0;
            border-radius: 0;
        }

        &_pos_last {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
        }
    }
`;
