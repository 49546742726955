import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllDoctors, getDoctor, getReviews, getСonsultationID } from './api';
import { fetchMyConsultationFullDelete } from '../../lkPatient/api/PatientConsultationsSlice';
import { formatErrorMessage } from '../../../componets/share/helpers';
import { convertDatesToUserTimeZone } from '../../../utils/dateTransform';

/**
 * Начальное состояние для doctorsSlice, содержит данные о врачах, текущем враче, параметрах фильтрации, консультациях и ошибках.
 *
 * @typedef {Object} InitialState
 * @property {Array} doctors - Список врачей для страницы "Наши врачи".
 * @property {Object|null} doctor - Данные текущего врача для страницы "Профиль врача".
 * @property {string|null} next - URL для следующей страницы с врачами.
 * @property {number|null} count - Общее количество врачей.
 * @property {boolean} isLoading - Флаг загрузки данных.
 * @property {Object} consultation - Объект с информацией о текущей консультации.
 * @property {Object} messagesError - Объект для хранения сообщений об ошибках.
 */

const initialState = {
    doctors: [],
    doctor: null,
    next: null,
    count: null,
    isLoading: false,
    consultation: {
        id: null,
        doctor: null,
        // описание проблемы по умолчанию
        comment: '',
        time: null,
        openModal: false,
    },
    doctorReviews: {
        count: 0,
        reviews: [],
    },
    messagesError: {},
};

/**
 * Асинхронное действие для получения списка врачей с возможностью фильтрации и пагинации.
 *
 * @async
 * @function fetchGetDoctors
 * @param {Object} data - Данные для фильтрации и пагинации.
 * @param {Object} thunkAPI - Объект, предоставляемый createAsyncThunk.
 * @param {function} thunkAPI.getState - Функция для получения текущего состояния Redux.
 * @param {function} thunkAPI.rejectWithValue - Функция для возврата ошибок.
 * @returns {Promise<Object>} - Результат запроса с данными врачей.
 * @throws {Object} - Ошибка запроса.
 */
export const fetchGetDoctors = createAsyncThunk(
    'doctors/fetchGetDoctors',
    async (data, { getState, rejectWithValue }) => {
        try {
            const next = getState().doctors.next;
            const result = await getAllDoctors(data, next);
            return result.data;
        } catch (error) {
            console.error('Error in fetchGetDoctors', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

/**
 * Асинхронное действие для получения данных о конкретном враче по идентификатору.
 *
 * @async
 * @function fetchGetDoctor
 * @param {number|string} id - Уникальный идентификатор врача.
 * @param {Object} thunkAPI - Объект, предоставляемый createAsyncThunk.
 * @param {function} thunkAPI.rejectWithValue - Функция для возврата ошибок.
 * @returns {Promise<Object>} - Результат запроса с данными о враче.
 * @throws {Object} - Ошибка запроса.
 */

export const fetchGetDoctor = createAsyncThunk('doctors/fetchGetDoctor', async (id, { rejectWithValue }) => {
    try {
        const result = await getDoctor(id);
        return result.data;
    } catch (err) {
        console.error('Error in fetchGetDoctor', err);
        return rejectWithValue({ [err.name]: err.message });
    }
});
// Старое
// export const fetchReviews = createAsyncThunk('doctorInfo/fetchReviewsList', async ({ id, countReviews }) => {
//     const response = await getReviews(id, countReviews);
//     return response;
// });
export const fetchReviews = createAsyncThunk('doctorInfo/fetchReviewsList', async ({ id, offset }) => {
    const response = await getReviews({ id, offset });
    return { response, offset };
});

export const fetchRecordConsultations = createAsyncThunk(
    'RecordConsultations/fetchRecordConsultations',
    async (consultation, { rejectWithValue }) => {
        try {
            const response = await getСonsultationID(consultation);
            return response;
        } catch (error) {
            if (error.response.data) {
                return rejectWithValue(error.response.data.detail[0]);
            } else {
                return rejectWithValue('error');
            }
        }
    }
);

/**
 * Slice для управления состоянием, связанным с врачами, текущим врачом, консультациями и ошибками.
 *
 * @namespace doctorsSlice
 * @property {function} setConsultation - Устанавливает врача и время для текущей консультации.
 * @property {function} setTimeConsultation - Устанавливает время для текущей консультации.
 * @property {function} resetDoctors - Очищает список врачей и сбрасывает параметр next.
 * @property {function} resetDoctor - Сбрасывает данные о текущем враче.
 * @property {function} resetConsultation - Сбрасывает данные о текущей консультации.
 */

const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        setConsultation: (state, action) => {
            state.consultation.doctor = action.payload.doctor;
            state.consultation.time = action.payload.time;
            state.consultation.openModal = action.payload.openModal;
        },
        // закрытие модального окна на странице консультации при создании новой консультации
        setCloseModal: (state, action) => {
            state.consultation = {
                ...state.consultation,
                openModal: action.payload,
            };
        },
        setTimeConsultation: (state, action) => {
            state.consultation.time = action.payload.time;
            state.consultation.id = action.payload.id;
        },
        resetDoctors: (state) => {
            state.doctors = [];
            state.next = null;
        },
        resetDoctor: (state) => {
            state.doctor = null;
        },
        resetConsultation: (state) => {
            state.consultation = {
                id: null,
                doctor: null,
                comment: '',
                time: null,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetDoctors.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchGetDoctors.fulfilled, (state, action) => {
                // Если список врачей пуст (поиск на главной странице или раздел Наши врачи
                // без фильтров), то обновляем список врачей на основе результатов запроса.
                if (state.doctors.length === 0) {
                    state.doctors = action.payload.results.map((doctor) => {
                        return {
                            ...doctor,
                            free_reception_time: convertDatesToUserTimeZone(doctor.free_reception_time, [
                                'reception_time',
                            ]),
                            nearest_reception_time: convertDatesToUserTimeZone(doctor, ['nearest_reception_time'])
                                .nearest_reception_time,
                        };
                    });
                } else {
                    const newDoctors = action?.payload?.results.map((doctor) => {
                        return {
                            ...doctor,
                            free_reception_time: convertDatesToUserTimeZone(doctor.free_reception_time, [
                                'reception_time',
                            ]),
                            nearest_reception_time: convertDatesToUserTimeZone(doctor, ['nearest_reception_time'])
                                .nearest_reception_time,
                        };
                    });
                    state.doctors = [...state.doctors, ...newDoctors];
                }
                // Обновляем информацию о следующей странице и общем количестве врачей
                // после загрузки новых данных.
                state.next = action.payload.next;
                state.count = action.payload.count;
                state.isLoading = false;
            })
            .addCase(fetchGetDoctors.rejected, (state, action) => {
                state.isLoading = false;
                const newMessages = formatErrorMessage(action.payload);
                state.messagesError = {
                    ...state.messagesError,
                    ...newMessages,
                };
            })
            .addCase(fetchGetDoctor.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchGetDoctor.rejected, (state, action) => {
                state.isLoading = false;
                const newMessages = formatErrorMessage(action.payload);
                state.messagesError = {
                    ...state.messagesError,
                    ...newMessages,
                };
            })
            .addCase(fetchGetDoctor.fulfilled, (state, action) => {
                state.doctor = action.payload;
                const doctor = state.doctors.find((doc) => doc.id === action.payload.user.id);
                if (doctor) {
                    doctor.is_favorite = action.payload.user.is_favorite;
                }
            })
            .addCase(fetchRecordConsultations.fulfilled, (state, action) => {
                state.consultation.id = action.payload.consultation_id;
            })
            .addCase(fetchMyConsultationFullDelete.fulfilled, (state) => {
                state.consultation.id = null;
                state.consultation.doctor = null;
                state.consultation.time = null;
            })
            // получение отзывов и количество отзывов по каждому конкретному доктору
            .addCase(fetchReviews.fulfilled, (state, action) => {
                const { offset, response } = action.payload;
                // если изменился id,offset
                // обнуляется и массив создается заново,
                //  если нет, то в предыдущий массив добавляется новая партия отзывов
                if (offset === 0) {
                    state.doctorReviews.count = response.count;
                    state.doctorReviews.reviews = response.results;
                } else {
                    state.doctorReviews.count = response.count;
                    state.doctorReviews.reviews = [...state.doctorReviews.reviews, ...response.results];
                }
            });
    },
});

export const {
    setConsultation,
    setCloseModal,
    setTimeConsultation,
    resetDoctors,
    resetConsultation,
    resetDoctor,
    setNewFilters,
} = doctorsSlice.actions;

export default doctorsSlice.reducer;
