import styled from 'styled-components';

export const SuccessConsultationStl = styled.div`
    padding: 68px 120px 40px 120px;
    max-width: 510px;
    background: rgb(255, 255, 255);
    border-radius: 24px;

    @media (max-width: 320px) {
        width: 288px;
        padding: 63px 15px 40px 15px;
    }
    .success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__icon {
            height: 85px;
            @media (max-width: 320px) {
                height: 58px;
            }
        }
        &__title {
            margin-top: 24px;
            line-height: 28px;
            color: #212121;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            @media (max-width: 320px) {
                line-height: 24px;
                font-size: 18px;
                margin-top: 20px;
                margin-bottom: 0px;
            }
        }
        &__content {
            margin-top: 5px;
            line-height: 23.9px;
            text-align: center;
            > span {
                font-size: 18px;
                color: #666666;
                @media (max-width: 320px) {
                    font-size: 16px;
                }
            }
            @media (max-width: 320px) {
                margin-top: 3px;
            }
        }
        &__btn {
            margin-top: 24px;
            width: 43%;
            display: flex;

            @media (max-width: 320px) {
                margin-top: 25px;
                width: 39%;
            }
            &-repeat {
                @media (max-width: 320px) {
                    padding: 10px;
                    font-size: 14px;
                    text-align: centerж;
                }
            }
        }
    }
`;
