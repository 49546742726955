import React, { useEffect, useRef, useState } from 'react';
import { StyledSearchContainer, StyledSearchInput } from './searchInput.styled';

import SearchResult from './searchResult';
import { fetchMainSearch } from '../mainSearchSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { fetchGetDoctors, resetDoctors } from '../../ourDoctors/api/doctorsSlise';

/**
 * Компонент поисковой строки для поиска врача по имени или фамилии
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {boolean} props.isSearchActive - Определяет, активен ли режим поиска
 * @param {Function} [props.setSearchActive] - Функция для переключения состояния активности поиска (по умолчанию - пустая функция)
 * @returns {JSX.Element} Поле ввода для поиска врача
 */

const SearchInput = ({ isSearchActive, setSearchActive = () => {} }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const input = useRef(null);

    /** Состояние для отображения иконки поиска
     * @type {[boolean, Function]} */
    const [iconVisible, setIconVisible] = useState(true);

    /** Состояние для значения поиска
     * @type {[string, Function]} */
    const [searchValue, setSearchValue] = useState('');

    /**
     * Обработчик изменения значения поиска
     * @param {React.ChangeEvent<HTMLInputElement>} event - Событие изменения значения в поле ввода
     */
    const handleChange = (event) => {
        // Удаляем все символы, кроме букв русского, английского алфавита и пробела
        const newValue = event.target.value.replace(/[^А-Яа-яЁёa-zA-Z-\s]/g, '');
        setSearchValue(newValue);
        setDebouncedSearch(newValue);
    };
    
    /**
     * Обработчик события "blur" для очистки пробелов в начале и конце строки поиска
     */
    const handleBlur = () => {
        setSearchValue(searchValue.trim());
    };

    /**
     * Обработчик нажатия клавиши для перехода на страницу с результатами поиска
     * @param {React.KeyboardEvent<HTMLInputElement>} e - Событие нажатия клавиши
     */
    const goSearch = (e) => {
        if (e.key === 'Enter') {
            navigate('/searchDoctors');
            setSearchActive(!isSearchActive);
        } else return;
    };

    /**
     * Используется debounce для оптимизации поиска, чтобы избежать лишних перерисовок при каждом вводе текста.
     * Выполняется поиск врачей, когда значение `debouncedSearch` содержит не менее 2 символов.
     */
    const [debouncedSearch, setDebouncedSearch] = useState(searchValue);
    useEffect(() => {
        if (debouncedSearch.length < 2) return;

        // Устанавливается таймер для отложенного поискового запроса, чтобы избежать лишних перерисовок.
        const newTimer = setTimeout(() => {
            // Используется метод из слайса врачей, для корректной работы необходимо передать следующие параметры:
            const data = {
                search: debouncedSearch === '' || debouncedSearch === '  ' ? null : debouncedSearch,
                ordering: '',
                spec_ids: '',
            };
            dispatch(resetDoctors());
            dispatch(fetchGetDoctors(data));
        }, 500);

        // Очистка таймера при размонтировании компонента.
        return () => clearTimeout(newTimer);
    }, [debouncedSearch, dispatch]);

    /**
     * Обработчик для закрытия поисковой строки при клике за её пределами
     * @param {Event} e - Событие клика
     */
    const handleDropSearch = (e) => {
        if (e.target.name !== 'search_btn') {
            setSearchValue('');
            setIconVisible(true);
            setSearchActive(!isSearchActive);
        }
    }

    // Хук для закрытия поисковой строки при клике за её пределами
    useOutsideClick(input, handleDropSearch, isSearchActive);

    return (
        <StyledSearchContainer>
            <StyledSearchInput>
                <img className="input__icon" src="/img/header/search.svg" />

                <input
                    ref={input}
                    className="search__input"
                    type="text"
                    required
                    minLength="2"
                    maxLength="152"
                    title=""
                    placeholder="Поиск врача по Ф.И.О"
                    value={searchValue}
                    autoFocus
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyUp={(e) => goSearch(e)}
                />
            </StyledSearchInput>

            {searchValue !== '' && <SearchResult setSearchActive={setSearchActive} setSearchValue={setSearchValue} />}
        </StyledSearchContainer>
    );
}

export default SearchInput;
