import styled from 'styled-components';

export const StyledDoctorRecommendations = styled.div`
    .comments {
        &__title {
            color: #212121;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
            @media (max-width: 450px) {
                font-size: 18px;
                line-height: 23.4px;
            }
        }
        &__text {
            color: #666666;
            font-size: 18px;
            line-height: 150%;
            @media (max-width: 450px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__files {
            width: calc(100% - 10px);
            display: flex;
            gap: 20px;
            margin-top: ${(props) => (props.hasFiles ? '24px' : '0px')};
            @media (max-width: 500px) {
                flex-direction: column;
            }
        }
        &__file {
            display: flex;
            align-items: center;
            gap: 10px;
            position: relative;
            cursor: pointer;
            max-width: 31%;
            @media (max-width: 500px) {
                max-width: 100%;
            }
            > a {
                gap: 5px;
                color: #212121;
                font-size: 16px;
                text-decoration: none;
                display: flex;
                align-items: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .file_name {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            > span {
                color: red;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 520px) {
        .patient-comments {
            &__title {
                font-size: 18px;
            }
            &__text {
                font-size: 16px;
            }
        }
    }
`;
