import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledModalSuccessfulConsultation } from './modalSuccessfulConsultation.styled';
import logoModal from '../../../../img/icons/autorization/logo_popupErrorAutorization.svg';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';
import close_icon from '../../../../img/close-icon.svg';

export const ModalSuccessfulConsultation = ({ closePopupSuccessfulConsultation, timeConsultation, role }) => {
    const navigate = useNavigate();

    /** Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара */
    useLockBodyScroll();

    // const writeReview = () => {
    //     closePopupSuccessfulConsultation();
    //     navigate('/reviews');
    // };

    const recommendationsMessage =
        role === 'doctor'
            ? 'Вы должны отправить свои рекомендации пациенту в течение 3 часов после окончания консультации'
            : 'Врач отправит Вам свои рекомендации в течение 3 часов после окончания консультации';

    return (
        <StyledModalSuccessfulConsultation>
            <div className="modalSuccessful">
                <img className="close" src={close_icon} alt="close_icon" onClick={closePopupSuccessfulConsultation} />
                <img src={logoModal} className="modalSuccessful__logo" alt="логотип" />
                <p className="modalSuccessful__time">{`${Math.floor(timeConsultation / 60)} мин ${
                    timeConsultation % 60
                } сек`}</p>
                <h1 className="modalSuccessful__heading">Видео-консультация завершена</h1>
                {role === 'patient' && <p className="modalSuccessful__text">Мы будем благодарны за обратную связь!</p>}
                <p className="modalSuccessful__text">{recommendationsMessage}</p>

                {/* <div className="modalSuccessful__buttons">
                    <button className="modalSuccessful__close" onClick={closePopupSuccessfulConsultation}>
                        Закрыть
                    </button> */}
                {/* <button className="modalSuccessful__feedback" onClick={writeReview}>
                        Оставить отзыв
                    </button> */}
                {/* </div> */}
            </div>
        </StyledModalSuccessfulConsultation>
    );
};
