import styled from 'styled-components';

export const ErrorDeleteAccountModalStl = styled.div`
    max-width: 510px;
    background: #FFFFFF;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .error {
        text-align: center;
        width: 65%;
        padding: 40px 18px;
        @media (max-width: 480px) {
            width: 80%;
        }
        @media (max-width: 340px) {
            width: 100%;
        }

        &__icon {
            height: 112px;
            @media (max-width: 480px) {
                height: 80px;
            }
        }

        &__title {
            margin-top: 10px;
            color: #212121;
            font-weight: 500;
            font-size: 20px;
            @media (max-width: 480px) {
                font-size: 18px;
            }
        }
            
        &__content {
            margin-top: 10px;
            font-size: 18px;
            color: #666666;
            @media (max-width: 480px) {
                margin-top: 8px;
                font-size: 16px;
            }
        }
            
        &__btn-close {
            margin-top: 24px;
            padding: 14px 24px;
            @media (max-width: 480px) {
                font-size: 14px;
                max-width: 100px;
                padding: 8px 14px;
            }
        }
    }
`;
