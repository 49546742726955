import React, { memo, useEffect, useState } from 'react';
import { SimilarPostsStyled } from './similarPosts.styled';
import { FooterButtons } from '../../footerButtons/FooterButtons';
import { RowArticle } from '../../blogList/rowArticle/RowArticle';
import { Spinner } from '../../../share/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPostsByTheme } from '../../api/blogSlice';

/**
 * Компонент SimilarPosts
 * React-компонент, отображающий список похожих статей на основе текущей темы, с возможностью подгрузки дополнительных статей.
 * @component
 * @returns {JSX.Element} JSX-разметка компонента.
 *
 * Функциональность:
 * - Загружает и отображает похожие статьи, исключая текущую статью.
 * - Отображает спиннер во время загрузки данных.
 * - Включает кнопки для управления количеством отображаемых статей.
 */

const SimilarPosts = () => {
    const dispatch = useDispatch();
    const { themeId, postId } = useParams();
    const [isDefaultCountSimilarPosts, setIsDefaultCountSimilarPosts] = useState(true);
    const [isLoadingPosts, setIsLoadingPosts] = useState(false);
    const { posts, isLoading, articles } = useSelector((state) => state.blog);
    const renderSimilarPosts = posts[themeId]?.postsByTheme
        .filter((post) => post.id !== +postId)
        ?.map((post) => <RowArticle key={post.id} props={post} />);

    useEffect(() => {
        if (!posts[themeId] && articles.some((article) => article.id === +postId)) {
            dispatch(fetchPostsByTheme({ themeId: themeId, getMorePosts: false }));
        }
    }, [posts]);
    useEffect(() => {
        if (Array.isArray(renderSimilarPosts) && renderSimilarPosts.length === 0) {
            setIsDefaultCountSimilarPosts(false);
        }
    }, []);
    useEffect(() => {
        if (!isLoading) {
            setIsLoadingPosts(false);
        }
    }, [isLoading]);

    return (
        <SimilarPostsStyled>
            <div className="similarPosts">
                {isDefaultCountSimilarPosts ? renderSimilarPosts?.slice(0, 2) : renderSimilarPosts}
            </div>
            {isLoadingPosts && (
                <div className="centerSpinner">
                    <Spinner />
                </div>
            )}
            <FooterButtons
                isDefaultCountSimilarPosts={isDefaultCountSimilarPosts}
                setIsDefaultCountSimilarPosts={setIsDefaultCountSimilarPosts}
                setIsLoadingPosts={setIsLoadingPosts}
            />
        </SimilarPostsStyled>
    );
};
export default memo(SimilarPosts);
