import styled from 'styled-components';
import {Basic_dark} from '../../../vendor/styles/baseColors';

export const TitleH2Stl = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  color: ${Basic_dark};
  text-align: center;
`;

export const SubtitleH2Stl = styled(TitleH2Stl)`
  font-size: 42px;
  line-height: 130%;
`;

export const SubheaderCard = styled.h3`
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.fontSize || '28px'};
  line-height: ${(props) => props.lineHeight || '33px'};
  color: ${Basic_dark};
`;

export const DescrCard = styled.div`
  /* height: 70px; */
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: ${(props) => props.lineHeight || '150%'};
  color: ${(props) => props.color || Basic_dark};
  min-height: ${(props) => props.minHeight || null};
  display: ${(props) => props.flex || null};
  align-items: ${(props) => props.alignItems || null};
  min-width: ${(props) => props.width || null};
  
  ul {
    margin: 12px 0 8px 0;
    list-style: none;
      > li {
        display: inline-block;
        color: #666666;
        &:not(:last-child)::after {
          content: ", ";
          white-space: pre;
        }
        &:not(:first-child) {
          text-transform: lowercase;
        }
  }
`;

export const TitleH3Stl = styled(TitleH2Stl)`
  font-size: 24px;
  line-height: 130%;
`;

export const TextStl = styled.div`
  display: ${(props) => props.display};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: static;
  text-align: ${(props) => props.textAlign};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize || 'inherit'};
  line-height: ${(props) => props.lineHeight || 'inherit'};
  color: ${(props) => props.color || Basic_dark};
  margin: ${(props) => props.margin || 'auto'};
  padding: ${(props) => props.padding || 'auto'};
  background-color: ${(props) => props.bcolor};
  border-radius: ${(props) => props.bradius};
`;
