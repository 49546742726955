import React from 'react';
import { StyledConsultationTime } from './consultationTime.styled';
import { getDateForConsultation, getTimeFromDate } from '../helpers';
import calendarIcon from '../../../img/icons/doctorConsultation/calendar.svg';
import clockIcon from '../../../img/icons/doctorConsultation/clock.svg';
import hourglassIcon from '../../../img/icons/doctorConsultation/hourglass.svg';
import currencyRubleIcon from '../../../img/icons/doctorConsultation/currency-ruble.svg';

/**
 * Компонент для отображения времени консультации, цены и дополнительной информации.
 *
 * @component
 *
 * @param {Object} props - Свойства компонента.
 * @param {Date} props.date - Дата и время консультации.
 * @param {number} [props.price] - Цена консультации.
 * @param {boolean} [props.card] - Флаг для отображения карточки (передается в StyledConsultationTime).
 * @param {boolean} [props.priceDisplay] - Флаг для отображения цены.
 * @param {boolean} [props.isModal] - Флаг для определения, отображается ли компонент в модальном окне.
 *
 * @returns {React.Element} Возвращает JSX, отображающий дату, время, цену и другие элементы.
 *
 * @example
 * const date = new Date();
 * const price = 1000;
 * const card = true;
 * const priceDisplay = true;
 * const isModal = false;
 *
 * return (
 *   <ConsultationTimeAndPrice
 *     date={date}
 *     price={price}
 *     card={card}
 *     priceDisplay={priceDisplay}
 *     isModal={isModal}
 *   />
 * );
 */
export const ConsultationTimeAndPrice = ({ date, price, card, priceDisplay, isModal, isCurrentConsultation }) => {
    return (
        <StyledConsultationTime card={card} isModal={isModal} isCurrentConsultation={isCurrentConsultation}>
            <div className="dateAndTime">
                <div className="date">
                    <img src={calendarIcon} alt="calendar" />
                    <span className="dateValue">{getDateForConsultation(date)}</span>
                </div>
                <div className="time">
                    <img src={clockIcon} alt="clock" />
                    <span className="timeValue">{getTimeFromDate(date)}</span>
                </div>
                {!isModal && (
                    <div className="time">
                        <img src={hourglassIcon} alt="time" />
                        <span className="timeValue">30 мин</span>
                    </div>
                )}
                {priceDisplay && (
                    <>
                        {price && (
                            <div className="price">
                                <img src={currencyRubleIcon} alt="price" />
                                <span className="timeValue">{price}</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </StyledConsultationTime>
    );
};
