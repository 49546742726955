import styled from 'styled-components';

export const FeedbackFormStl = styled.div`
    .formHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .formHeader-title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: #212121;
    }

    .formHeader-symbols {
        display: grid;
        grid-template-columns: 3.2fr 0.75fr 1fr;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #666666;
        @media (max-width: 450px) {
            font-size: 14px;
            line-height: 18.2px;
        }
    }

    .rules {
        margin-top: 8px;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #666666;
    }

    .rules-link {
        color: #19be6f;
        text-decoration: none;
        cursor: pointer;
    }
    .rules-link:hover {
        transition: all 0.3s ease-in-out;
        opacity: 0.6;
    }

    .reviewBtn {
        margin-top: 24px;
        padding: 14px 32px;
        cursor: pointer;
        width: 240px;
        border: 2px solid #19be6f;
        border-radius: 10px;
        background: #ffffff;
        color: #19be6f;
        font-weight: 500;
        line-height: 20px;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;

        &:hover {
            border: 2px solid #169056;
            color: #169056;
        }

        &:disabled {
            border: 2px solid #8af0bf;
            color: #8af0bf;
            cursor: auto;
        }
    }

    @media (max-width: 1000px) {
        form {
            width: 530px;
        }
    }

    @media (max-width: 768px) {
        form {
            width: 100%;
        }

        .reviewArea {
            width: 100%;
            height: 176px;
            border: 1px solid #b6b7ba;
            font-size: 16px;
        }
    }

    @media (max-width: 550px) {
        form {
            width: 100%;
        }

        .formHeader-title {
            font-size: 16px;
        }

        .formHeader-symbols {
            font-size: 14px;
        }

        .reviewArea {
            width: 100%;
            border: 1px solid #b6b7ba;
            font-size: 16px;
        }

        .reviewBtn {
            width: 100%;
        }
    }
`;
