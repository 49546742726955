import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getConsultations,
    consultationUpdate,
    consultationDelete,
    сancelConsultation,
    getConsultation,
    postFeedbackСonsultation,
    getFeedback,
    consultationSendFile,
    consultationDeleteFile,
    changeStatusToStartedPatient,
    checkConsultationStatus,
} from './patientApi';
import { formatErrorMessage, formatNullData } from '../../../componets/share/helpers';
import { convertDatesToUserTimeZone } from '../../../utils/dateTransform';

/**
 * Асинхронный thunk для получения будущих консультаций.
 *
 * @async
 * @function fetchGetFutureConsultations
 * @param {Object} params - Параметры для thunk.
 * @param {number} params.limit - Максимальное количество консультаций на странице.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.getState - Функция для получения текущего состояния Redux.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Объект с данными о будущих консультациях:
 * - `consultations` (Array<Object>): Список будущих консультаций.
 * - `next` (string|null): Ссылка на следующую страницу (если есть).
 * @description
 * 1. Если в состоянии Redux есть ссылка на следующую страницу (`next`), используется она.
 * 2. Если ссылки нет, запрашиваются консультации:
 *    - Со статусом `0` (ожидаемые).
 *    - Отсортированные по полю `consultation_time` в порядке возрастания.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const fetchGetFutureConsultations = createAsyncThunk(
    'patientConsultations/fetchFutureConsultations',
    async ({ limit }, { getState, rejectWithValue }) => {
        try {
            const pageLink = getState().patientConsultations.patientConsultations.future.next;
            const response = pageLink
                ? await getConsultations({ limit, pageLink })
                : await getConsultations({ limit, status: [0, 3], consultation_time: 'consultation_time' });

            return {
                consultations: response.data.results,
                next: response.data.next,
            };
        } catch (error) {
            console.error('Ошибка в fetchGetFutureConsultations', error.response.status);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для получения прошедших консультаций.
 *
 * @async
 * @function fetchGetPastConsultations
 * @param {Object} params - Параметры для thunk.
 * @param {number} params.limit - Максимальное количество консультаций на странице.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.getState - Функция для получения текущего состояния Redux.
 * @returns {Promise<Object>} Объект с данными о прошедших консультациях:
 * - `consultations` (Array<Object>): Список прошедших консультаций.
 * - `next` (string|null): Ссылка на следующую страницу (если есть).
 *
 * @description
 * 1. Если в состоянии Redux есть ссылка на следующую страницу (`next`), используется она.
 * 2. Если ссылки нет, запрашиваются консультации:
 *    - Со статусами `[1, 2, 9]` (прошедшие).
 *    - Отсортированные по полю `consultation_time` в порядке убывания.
 *
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const fetchGetPastConsultations = createAsyncThunk(
    'patientConsultations/fetchPastConsultations',
    async ({ limit }, { getState, rejectWithValue }) => {
        try {
            const pageLink = getState().patientConsultations.patientConsultations.past.next;
            const response = pageLink
                ? await getConsultations({ limit, pageLink })
                : await getConsultations({ limit, status: [1, 2, 9], consultation_time: '-consultation_time' });

            return {
                consultations: response.data.results,
                next: response.data.next,
            };
        } catch (error) {
            console.error('Ошибка в fetchGetPastConsultations', error.response.status);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для отмены консультации пациента.
 *
 * @async
 * @function cancelPatientConsultation
 * @param {number|string} consultationId - Идентификатор консультации, которую необходимо отменить.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Ответ сервера после отмены консультации.
 * @description
 * Использует функцию `сancelConsultation` для выполнения запроса на отмену консультации.
 * Возвращает данные из ответа API: Статус меняется на '2'-'Отменена'.
 * Создается и возвращается объект консультации с новым атрибутом registration_log.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const cancelPatientConsultation = createAsyncThunk(
    'patientConsultations/cancelPatientConsultation',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await сancelConsultation(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для получения данных о конкретной консультации пациента.
 *
 * @async
 * @function getPatientConsultation
 * @param {number|string} consultationId - Идентификатор консультации.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Объект с данными о консультации.
 * @description
 * Использует функцию `getConsultation` для выполнения запроса к API.
 * Возвращает объект с данными о консультации, извлекая их из ответа API.
 * Объект консультации - см InitialState - CurrentConsultation.
 * * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const getPatientConsultation = createAsyncThunk(
    'patientConsultations/getPatientConsultation',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await getConsultation(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для обновления данных консультации.
 *
 * @async
 * @function fetchMyConsultationUpdate
 * @param {Object} data - Обновлённые данные консультации.
 * @param {string} data.id - Идентификатор консультации.
 * @param {string} data.patient_notes - Заметки пациента.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Ответ сервера после обновления.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const fetchMyConsultationUpdate = createAsyncThunk(
    'patientConsultations/fetchConsultationUpdate',
    async (data, { rejectWithValue }) => {
        try {
            const response = await consultationUpdate(data.id, {
                patient_notes: data.patient_notes,
            });
            return response;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для отправки файла консультации.
 *
 * @async
 * @function sendConsultationFile
 * @param {Object} params - Параметры для thunk.
 * @param {string} params.consultationId - Идентификатор консультации.
 * @param {File} params.file - Файл для отправки.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.dispatch - Функция для отправки экшенов Redux.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки через reject.
 * @returns {Promise<Object>} Ответ сервера после успешной отправки файла.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через rejectWithValue.
 */
export const sendConsultationFile = createAsyncThunk(
    'patientConsultations/sendConsultationFile',
    async ({ consultationId, file }, { dispatch, rejectWithValue }) => {
        try {
            const response = await consultationSendFile({
                consultationId,
                file,
                onProgress: (progress) => {
                    // Отправляем прогресс загрузки через экшен
                    dispatch(changeUploadFile(progress));
                },
            });
            return response;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для удаления файла консультации.
 *
 * @async
 * @function deleteConsultationFile
 * @param {string} id - Идентификатор файла.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Ответ сервера после удаления файла.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const deleteConsultationFile = createAsyncThunk(
    'patientConsultations/deleteConsultationFile',
    async (id, { rejectWithValue }) => {
        try {
            const response = await consultationDeleteFile(id);
            return response;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/** Асинхронный thunk для создания обратной связи по консультации.
 * @async @function createFeedback
 * @param {number|string} consultationId - Идентификатор консультации, для которой создается обратная связь.
 * @returns {Promise<Object>} Данные из ответа сервера после создания обратной связи.
 * data {
 * text: data.text,
 * raiting: data.raiting,
 * }
 * @description
 * Вызывает функцию `postFeedbackСonsultation` для отправки данных обратной связи по указанной консультации.
 * Возвращает результат обработки ответа API
 *{
  "id": 0,
  "doctor_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "patient_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "patient_avatar": "string",
  "patient_first_name": "string",
  "rating": "5",
  "created": "30.11.2024 19:50:19",
  "text": "string"
}
 */
export const createFeedback = createAsyncThunk(
    'patientConsultations/createFeedback',
    async ({ consultationId, data }, { rejectWithValue }) => {
        // console.log('data from fetch', data, consultationId);
        try {
            const response = await postFeedbackСonsultation({ consultationId, data });
            return response.data;
        } catch (error) {
            // console.log('name', error.name, error.message);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для получения данных обратной связи по идентификатору.
 * @async @function fetchFeedback
 * @param {number|string} feedbackId - Идентификатор обратной связи.
 * @returns {Promise<Object>} Данные обратной связи, полученные из ответа API.
 * @description
 * Вызывает функцию `getFeedback` для получения данных о конкретной обратной связи по её идентификатору.
 * Возвращает объект с данными обратной связи из ответа API:
 *  *{
  "id": 0,
  "doctor_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "patient_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "patient_avatar": "string",
  "patient_first_name": "string",
  "rating": "5",
  "created": "30.11.2024 19:50:19",
  "text": "string"
}
 */
export const fetchFeedback = createAsyncThunk(
    'patientConsultations/fetchFeedback',
    async (feedbackId, { rejectWithValue }) => {
        try {
            const response = await getFeedback(feedbackId);
            return response.data;
        } catch (error) {
            // console.log('name', error.name, error.message);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для перевода консультации в начавшиеся.
 * @async @function fetchChangeStatusToStartedPatient
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 *
 * @description
 * Использует функцию `changeStatusToStartedPatient` для выполнения запроса к API.
 * Статус консультации меняется на '3'-'Началась' .
 */
export const fetchChangeStatusToStartedPatient = createAsyncThunk(
    'patientConsultations/changeStatusToStartedPatient',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await changeStatusToStartedPatient(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для проверки статуса консультации.
 * @async @function fetchCheckConsultationStatusPatient
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 *
 * @description
 * Использует функцию `checkConsultationStatus` для выполнения запроса к API.
 */
export const fetchCheckConsultationStatusPatient = createAsyncThunk(
    'doctorConsultations/fetchCheckConsultationStatusPatient',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await checkConsultationStatus(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

// --------------------------------------------------------------------------

// const onSortConsultsByYear = ({ created_at }, idx, arr, year) => {
//     return new Date(+created_at * 1000).getFullYear() === year;
// };

//этот фетч не будет использоваться, но он вызывается в SaveTemplateModal.jsx и обрабатывается
//в экстра-редьюсере в слайсе врачей doctorsSlise
//пациенту в стейте он ничего не меняет
export const fetchMyConsultationFullDelete = createAsyncThunk(
    'consultations/fetchMyConsultationFullDelete',
    async (id) => {
        try {
            const response = await consultationDelete(id);
            // console.log(response.status, 'delStatus');
        } catch (err) {
            // console.log(err, 'errDelConsult');
        }
    }
);

// --------------------------------------------------------------------------

//статусы консультаций
// 0  Новая
// 1	Состоялась
// 2	Отменена
// 3	Началась
// 8	Прервана
// 9	Не проводилась

const initialState = {
    patientConsultations: {
        future: {
            // статус 0
            next: null,
            consultations: [],
        },
        past: {
            // статус 1, 2, 9
            next: null,
            consultations: [],
        },
    },
    currentConsultation: {
        id: null,
        status: '',
        doctor: {
            id: '', // id врача
            user: {
                id: '', // id пользователя
                last_name: '',
                first_name: '',
                middle_name: '',
                image_standart: '',
            },
            slug: '',
            specialization: [], // массив объектов специализаций врача в виде ": [{"id": 18,"name": "Психиатрия"}, {...}]
            price: '',
            scientific_degree: '',
            category: '',
            work: [], // массив объектов мест работы врача в виде ":
            //[{"id: 181, "organization": {"name": "Клиника урологии и нефрологии"}, "position": {	"name": "Отоларинголог"}, "work_from": "04.04.2010",	"work_to": null,	"until_now": true	}]
            rating_average: '',
            seniority: '',
        },
        patient: {
            user: {
                id: '', // id пользователя
                last_name: '',
                first_name: '',
                middle_name: '',
                image_standart: '',
                date_birth: '',
                sex: '',
            },
        },
        patient_notes: '',
        recommendation: {
            id: '', //id объекта рекоммендации
            text: '', //текст рекоммендации
            status: '',
        },
        created: '',
        consultation_time: '',
        registration_log: {
            cancelled_by: '', // id отменившего консультацию
        },
        is_payed: false,
        security_key: '',
        scheduled_duration: '',
        actual_duration: '',
        joining: {
            doctor_has_joined: false,
            patient_has_joined: false,
            join_log: '',
        },
        consultation_type: 1, // по умолчанию всегда 1
        files: [
            {
                id: '', //id объекта файла
                file: '', //ссылка на файл
                date: '', // дата загрузки файла
                owner: '', // id владельца (пользователя, загрузившего файл)
                owners_role: '', // роль владельца файлов "doctor" или "patient"
            },
        ],
        feedback: {
            id: '', // id объекта отзыва
            doctor_id: '', //id доктора
            patient_id: '', // id пациента
            patient_avatar: '', //ссылка на аватар пациента
            patient_first_name: '', // имя пациента
            rating: '', // оценка, поставленная пациентом
            created: '', // дата и время создания отзыва
            text: '',
        },
        progress_files: '',
    },
    keyConsultationPatient: null,
    messagesError: {},
    isLoading: false,
    isModalSuccessfulConsultation: false,
    timeConsultation: 0, // подсчёт времени состоявшейся консультации.
    wasInJitsi: false,
};

const patientConsultations = createSlice({
    name: 'patientConsultations',
    initialState,
    reducers: {
        // removeConsultation: (state, action) => {
        //    // избыточно, на удаление
        //     const { typeConsult, id } = action.payload;
        //     state.patientConsultations[typeConsult].consultations = state.patientConsultations[
        //         typeConsult
        //     ].consultations.filter((item) => item.id !== id);
        // },

        // избыточно, нигде не используется
        // defineIdActiveConsult: (state, action) => {
        //     state.idActiveConsult = action.payload;
        // },
        // избыточно, нигде не используется
        // setDoctor: (state, action) => {
        //     const doctor = action.payload;
        //     if (state.currentConsultation) {
        //         state.currentConsultation.doctor = doctor;
        //     } else {
        //         state.patientConsultations.future.consultations.forEach((item) => {
        //             if (item.doctor.id === doctor.id) {
        //                 item.doctor = doctor;
        //             }
        //         });
        //         state.patientConsultations.past.consultations.forEach((item) => {
        //             if (item.doctor.id === doctor.id) {
        //                 item.doctor = doctor;
        //             }
        //         });
        //     }
        // },
        // используется
        clearConsultation: (state) => {
            state.currentConsultation = initialState.currentConsultation;
        },
        // используется
        resetPatientConsultations: (state) => {
            state.patientConsultations = {
                future: {
                    next: null,
                    consultations: [],
                },
                past: {
                    next: null,
                    consultations: [],
                },
            };
        },
        // используется
        changeUploadFile: (state, action) => {
            state.currentConsultation.progress_files = action.payload; // Обновляем прогресс
        },
        // будет использоваться в видео-чате, перепроверить
        setIsModalSuccessfulConsultationPatient: (state, action) => {
            // state.timeConsultation = 0; зачем это?
            state.isModalSuccessfulConsultation = !state.isModalSuccessfulConsultation;
            state.timeConsultation = action.payload;
            // console.log('Длительность завершившейся консультации', JSON.stringify(state.timeConsultation));
            // console.log('Показывать окошко?', JSON.stringify(state.isModalSuccessfulConsultation));
        },
        //  используется в кнопках для начала видео-чата
        setKeyConsultationPatient: (state, action) => {
            state.keyConsultationPatient = action.payload;
        },
    },
    extraReducers: (builder) => {
        //--------------------------------------------------------
        // получение будущих консультаций, работает
        builder.addCase(fetchGetFutureConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetFutureConsultations.fulfilled, (state, action) => {
            const { consultations: newConsultations, next } = action.payload;

            // Массив обновляем, исключая дубли
            const updatedConsultations = [
                ...state.patientConsultations.future.consultations.filter(
                    (existing) => !newConsultations.some((newItem) => newItem.id === existing.id)
                ),
                ...newConsultations,
            ];

            // Фильтруем только по статусу 0
            const filteredConsultations = updatedConsultations.filter(
                (consultation) => [0, 3].includes(consultation.status) && consultation.doctor !== null
            );
            state.patientConsultations.future.consultations = convertDatesToUserTimeZone(filteredConsultations, [
                'created',
                'consultation_time',
                'files.date',
                'feedback.created',
            ]);
            state.patientConsultations.future.next = next;
            state.isLoading = false;
            // // console.log('future', JSON.stringify(state.patientConsultations.future.consultations));
        });
        builder.addCase(fetchGetFutureConsultations.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // получение прошлых консультаций, работает
        builder.addCase(fetchGetPastConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetPastConsultations.fulfilled, (state, action) => {
            const { consultations: newConsultations, next } = action.payload;

            // Обновляем или добавляем консультации, исключая дубли
            const updatedConsultations = [
                ...state.patientConsultations.past.consultations.filter(
                    (existing) => !newConsultations.some((newItem) => newItem.id === existing.id)
                ),
                ...newConsultations,
            ];

            // Фильтруем только прошедшие консультации (статусы 1, 2, 9)
            const filteredConsultations = updatedConsultations.filter(
                (consultation) => [1, 2, 9].includes(consultation.status) && consultation.doctor !== null
            );
            state.patientConsultations.past.consultations = convertDatesToUserTimeZone(filteredConsultations, [
                'created',
                'consultation_time',
                'files.date',
                'feedback.created',
            ]);
            state.patientConsultations.past.next = next;
            state.isLoading = false;

            // Логируем для проверки
            // // console.log('past:', state.patientConsultations.past.consultations);
            // // console.log('next link after update:', state.patientConsultations.past.next);
        });
        builder.addCase(fetchGetPastConsultations.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });

        //--------------------------------------------------------
        // отмена консультации - что длать с payload-ом?
        builder.addCase(cancelPatientConsultation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(cancelPatientConsultation.fulfilled, (action, state) => {
            state.isLoading = false;
        });
        builder.addCase(cancelPatientConsultation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });

        // ----------------------------------------------------------------------
        // получение консультации по id
        builder.addCase(getPatientConsultation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPatientConsultation.fulfilled, (state, action) => {
            state.currentConsultation = convertDatesToUserTimeZone(action.payload, [
                'created',
                'consultation_time',
                'files.date',
                'feedback.created',
            ]);
            state.isLoading = false;
            // // console.log('patient cons from state', JSON.stringify(state.currentConsultation));
        });
        builder.addCase(getPatientConsultation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });

        // ----------------------------------------------------------------------
        // Обновление данных консультации
        builder.addCase(fetchMyConsultationUpdate.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMyConsultationUpdate.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchMyConsultationUpdate.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });

        // ----------------------------------------------------------------------
        // отправка файла
        builder.addCase(sendConsultationFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(sendConsultationFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(sendConsultationFile.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        // ----------------------------------------------------------------------
        // удаление файла
        builder.addCase(deleteConsultationFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteConsultationFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteConsultationFile.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;

            // console.log('newMessages', JSON.stringify(state.messagesError));
        });
        // ----------------------------------------------------------------------
        // создание фидбэка
        builder.addCase(createFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createFeedback.fulfilled, (state, action) => {
            // state.currentConsultation.feedback = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createFeedback.rejected, () => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;

            // // console.log('newMessages', JSON.stringify(state.messagesError));
        });
        //--------------------------------------------------------
        // получение фидбэка
        builder.addCase(fetchFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFeedback.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(fetchFeedback.rejected, () => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;

            // // console.log('newMessages', JSON.stringify(state.messagesError));
        });
        //--------------------------------------------------------
        // перевод консультации в статус 3 (началась)
        builder.addCase(fetchChangeStatusToStartedPatient.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchChangeStatusToStartedPatient.fulfilled, (state, action) => {
            const newData = action.payload;
            state.currentConsultation = { ...state.currentConsultation, ...newData };
            // // console.log('status у пациента', JSON.stringify(state.currentConsultation.status));
            state.isLoading = false;
        });
        builder.addCase(fetchChangeStatusToStartedPatient.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = { ...state.messagesError, ...newMessages };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // Проверка статуса консультации
        builder.addCase(fetchCheckConsultationStatusPatient.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCheckConsultationStatusPatient.fulfilled, (state, action) => {
            const newData = action.payload;
            state.currentConsultation = { ...state.currentConsultation, ...newData };
            // // console.log('status у пациента', JSON.stringify(state.currentConsultation.status));
            state.isLoading = false;
        });
        builder.addCase(fetchCheckConsultationStatusPatient.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });

        //--------------------------------------------------------
        // старое
        // builder.addCase(fetchGetFutureConsultations.pending, (state) => {
        //     state.isLoading = true;
        // });
        // builder.addCase(fetchGetFutureConsultations.fulfilled, (state, action) => {
        //     const newConsultations = action.payload.data?.consultations?.filter(
        //         (consultation) =>
        //             !state.patientConsultations.future.consultations.some((item) => item.id === consultation.id)
        //     );
        //     if (state.patientConsultations.future.next) {
        //         state.patientConsultations.future.consultations.push(...newConsultations);
        //     } else {
        //         // action.payload.count === (state.patientConsultations.future.consultations.length)  ?
        //         //     state.patientConsultations.future.consultations = action.payload.data.consultations :
        //         // state.patientConsultations.future.consultations = newConsultations;
        //         // state.patientConsultations.future.consultations.push(...newConsultations);
        //     }
        //     state.patientConsultations.future.next = action.payload?.next;
        //     state.isLoading = false;
        // });
        // builder.addCase(fetchGetPastConsultations.pending, (state) => {
        //     state.isLoading = true;
        // });
        // builder.addCase(fetchGetPastConsultations.fulfilled, (state, action) => {
        //     const newConsultations = action.payload.data?.consultations?.filter(
        //         (consultation) =>
        //             !state.patientConsultations.past.consultations.some((item) => item.id === consultation.id)
        //     );
        //     state.patientConsultations.past.consultations.push(...newConsultations);
        //     state.patientConsultations.past.next = action.payload?.next;
        //     state.isLoading = false;
        // });
        // builder.addCase(cancelPatientConsultation.fulfilled, (state) => {
        // скорее всего это избыточно
        // получаем отменённую консультацию и её id
        // const cancelledConsultation = action.payload;
        // const consultationId = cancelledConsultation.id;

        // // обновляем текущую консультацию. Если пользователь работает с этой консультацией, её обновление должно мгновенно отобразиться
        // if (state.currentConsultation.id === consultationId) {
        //     state.currentConsultation = {
        //         ...state.currentConsultation,
        //         ...cancelledConsultation, // частичное обновление (сюда приходит новый статус и обновляются логи)
        //     };
        // }
        // // обновляем массивы, прошлый - будущий (проверить на избыточность)
        // // скорее всего уберём, тк будем притягивать обновлённые консультации с бэка,
        // // а не чистить стейт вот таким образом вручную
        // // в компонентах будем притягивать обновления через unwrap()
        // const futureIndex = state.patientConsultations.future.consultations.findIndex(
        //     (consultation) => consultation.id === consultationId
        // );
        // // Проверка в будущем массиве
        // if (futureIndex !== -1) {
        //     const [cancelledConsultation] = state.patientConsultations.future.consultations.splice(futureIndex, 1);
        //     if (cancelledConsultation.status !== 0) {
        //         state.patientConsultations.past.consultations.push({
        //             ...cancelledConsultation,
        //             ...action.payload,
        //         });
        //     } else {
        //         state.patientConsultations.future.consultations.push({
        //             ...cancelledConsultation,
        //             ...action.payload,
        //         });
        //     }
        // } else {
        //     // Если консультация не в будущем массиве, проверяем прошедший массив
        //     // Это тоже избыточно по-моему
        //     const pastIndex = state.patientConsultations.past.consultations.findIndex(
        //         (consultation) => consultation.id === consultationId
        //     );

        //     if (pastIndex !== -1) {
        //         state.patientConsultations.past.consultations[pastIndex] = {
        //             ...state.patientConsultations.past.consultations[pastIndex],
        //             ...action.payload,
        //         };
        //     }
        // }
        // state.isLoading = false;
        // });
        // ----------------------------------------------------------------------
        // старое
        // builder.addCase(getPatientConsultation.fulfilled, (state, action) => {
        //   state.currentConsultation = action.payload;
        //   state.isLoading = false;
        // // console.log('current consult from state', JSON.stringify(state.currentConsultation));
        // гипотеза: ищется индекс, мб были переходы между ними не выходя в общий список
        // const consultationUpdated = action.payload;
        // state.currentConsultation = consultationUpdated;
        // const pastConsultations = state.patientConsultations.past.consultations;
        // const futureConsultations = state.patientConsultations.future.consultations;
        // const indexPast = pastConsultations.findIndex((item) => item.id === consultationUpdated.id);
        // const indexFuture = futureConsultations.findIndex((item) => item.id === consultationUpdated.id);

        // if (indexPast !== -1) {
        //     pastConsultations.splice(indexPast, 1, consultationUpdated);
        // }
        // if (indexFuture !== -1) {
        //     futureConsultations.splice(indexFuture, 1, consultationUpdated);
        // }
        // });
    },
});

export const selectAllConsultations = (state) => state?.patientConsultations?.consultations?.data?.consultations;

// export const selectFutureConsultations = (state) => ({
//     consultationsList: state.patientConsultations.patientConsultations.future.consultations,
//     next: state.patientConsultations.patientConsultations.future.next,
//     isLoading: state.patientConsultations.isLoading,
// });

// export const selectPastConsultations = (state) => ({
//     consultationsList: state.patientConsultations.patientConsultations.past.consultations,
//     next: state.patientConsultations.patientConsultations.past.next,
//     isLoading: state.patientConsultations.isLoading,
// });

export default patientConsultations.reducer;

export const {
    changeUploadFile,
    clearConsultation,
    resetPatientConsultations,
    setIsModalSuccessfulConsultationPatient,
    setKeyConsultationPatient,
} = patientConsultations.actions;
