import React from 'react';
import { DoctorNavigationStl } from './doctorNavigation.styled';
import { useSelector } from 'react-redux';

export const DoctorNavigation = ({ pageShow, setPageShow }) => {
    const reviewsCount = useSelector((state) => state.doctors.doctorReviews?.count);

    const handleChangePage = (page) => {
        setPageShow(page);
    };

    return (
        <DoctorNavigationStl>
            <div onClick={(e) => handleChangePage('record')} className={pageShow !== 'record' ? 'link' : 'link chosen'}>
                Расписание
            </div>
            <div onClick={() => handleChangePage('about')} className={pageShow !== 'about' ? 'link' : 'link chosen'}>
                О враче
            </div>
            <div
                onClick={() => handleChangePage('reviews')}
                className={pageShow !== 'reviews' ? 'link' : 'link chosen'}
            >
                Отзывы
                {/* Отзывы ({reviewsCount
                    ? reviewsCount
                    : '0' */}
                {/* }) */}
            </div>
        </DoctorNavigationStl>
    );
};
