import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Компонент защищенного маршрута.
 * Если пользователь авторизован, отображает дочерние маршруты, иначе перенаправляет на страницу входа.
 *
 * @component
 * @returns {JSX.Element} JSX-элемент, отображающий защищенный маршрут.
 */
const ProtectedRoute = () => {
    const { isAuth } = useSelector((state) => state.authentification);

    return isAuth ? <Outlet /> : <Navigate to={'/login'} />;
};

export default ProtectedRoute;
