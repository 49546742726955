import { ErrorCalendarModalStl } from './errorCalenderModal.styled';
import { React } from 'react';
import Button from '../../../../share/Button';
// import errorIcon from '../../../../../img/icons/icon-error.svg';
import { useLockBodyScroll } from '../../../../../hooks/useLockBodyScroll';

/**
 * ErrorCalendarModal - Компонент, который отображает сообщение об ошибке при создании расписания.
 * Позволяет пользователю повторить попытку позже и закрыть модальное окно.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.closeModal - Функция для закрытия модального окна. Передает булево значение для указания состояния модала.
 *
 * @returns {JSX.Element} Возвращает JSX элемент, представляющий состояние ошибки.
 *
 * @example
 * return (
 *   <ErrorCalendarModal
 *     closeModal={someCloseModalFunction}
 *   />
 * );
 *
 * Компонент включает в себя следующие элементы:
 * - Иконка ошибки для визуального представления проблемы.
 * - Заголовок с текстом об ошибке.
 * - Текстовое описание с предложением проверить подключение к интернету.
 * - Кнопка закрытия модального окна.
 */

export const ErrorCalenderModal = ({ toggle, errorIcon }) => {
    useLockBodyScroll();
    return (
        <ErrorCalendarModalStl>
            <div className="error">
                <img src={errorIcon} alt="error" className="error__icon" />
                <h3 className="error__title">Не удалось сохранить расписание</h3>
                <div className="error__content">
                    <span>Проверьте подключение к интернету или повторите попытку позже</span>
                </div>
                <Button green XS className="error__btn-close" onClick={() => toggle(() => false)}>
                    Закрыть
                </Button>
            </div>
        </ErrorCalendarModalStl>
    );
};
