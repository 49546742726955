import styled from 'styled-components';
import { FieldWrapperStl } from '../fieldWrapperStl.styled';

export const SelectCityStl = styled.div`
        margin-bottom: 3px;
        .rs {
            &__control {
                border-radius: 10px;
                box-shadow: none;
                --arrow-color: #212121;
                &--is-focused {
                    --arrow-color: #19be6f;
                }
                &:hover {
                    --arrow-color: #19be6f;
                    border-color: #19be6f;
                }
            }
            &__value-container {
                padding: 11px 14px 12px ${(props) => (props.narrow ? 14 : 19)}px;
            }
            &__placeholder {
                text-align: left;
                margin-left: 0;
                color: #b6b7ba;
            }
            &__control--is-focused .rs__placeholder {
                color: #b6b7ba;
            }
            &__single-value {
                text-align: left;
                text-overflow: clip;
                margin-left: 0;
            }
            &__indicators {
                z-index:1;
                padding-right: ${(props) => (props.narrow ? 0 : 11)}px;
            }
            &__indicator-separator {
                display: none;

            }
            &__dropdown-indicator {
                padding: ${(props) => (props.narrow ? '8px 5px' : '8px')};
                path {
                    fill: var(--arrow-color);
                }
            }
            &__clear-indicator:hover {
                cursor: pointer;
                // оставлено на случай, есди нужно будет менят ьцвет крестика при ховере
                path {
                    // stroke: #19be6f;
                }
            &__input-container {
                margin: 0;
                padding: 0;
            }
            &__menu {
                border-radius: 8px;
                overflow: hidden;
                box-shadow: none;
                border: 1px solid #b6b7ba;
            }
            &__menu-list {
            background-color: red;
                padding: 0;
                max-height: ${(props) => (props.radioButton ? 'none' : '175px')};
                &::-webkit-scrollbar {
                    width: ${(props) => (props.narrow ? 36 : 30)}px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #19be6f;
                    border-width: 10px ${(props) => (props.narrow ? 16 : 13)}px;
                    border-radius: 30px;
                    border-color: white;
                    border-style: solid;
                    height: 40px;
                }
            }
            &__option {
                text-align: left;
                padding-top: ${(props) => (props.radioButton ? 9 : 12)}px;
                padding-right: ${(props) => (props.radioButton ? 65 : 20)}px;
                padding-bottom: 11px;
                padding-left: ${(props) => (props.narrow ? 14 : 19)}px;
                color: #282828;
                line-height: 130%;
                &:active {
                    background-color: black;
                }
                &:hover {
                    background-color: #black;
                }
                &--is-selected {
                    background-color: #f4f4f4;
                }
                &_radbtn {
                    display: flex;
                    align-items: center;
                    .radio {
                        &-dot {
                            fill: none;
                        }
                        &-outline {
                            stroke: #b6b7ba;
                        }
                    }
                    &.rs__option--is-selected .radio {
                        &-dot {
                            fill: #19be6f;
                        }
                        &-outline {
                            stroke: #19be6f;
                        }
                    }
                }
                &-btn {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    align-self: flex-start;
                }
            }
        }

        @media (max-width: 768px) {
            .rs__placeholder {
                font-size: 14px;
            }
        }

`;

export const SelectCommonFieldStl = styled(FieldWrapperStl)`
    .selected {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;
        gap: 8px;
        &__option {
            border-radius: 10px;
            background-color: #8af0bf;
            display: flex;
            flex-direction: row;
            &-label {
                padding: 7px 8px 6px 15px;
                user-select: none;
            }
            &-clear {
                display: flex;
                align-items: center;
                padding-right: 8px;
                path {
                    stroke: #19be6f;
                }
                &:hover path {
                    stroke: #169056;
                }
            }
        }
    }

    .rs__value-container {
        width: 100%;
        max-width: 370px;
    }
`;

export const SelectCityStlNew = styled.div``;
