import styled from 'styled-components';
import Form from '../../share/formFields/Form';

export const VacanciesFormStl = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;

    .vacancies__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 130%;
        text-align: center;
        margin-bottom: 36px;
    }

    button.btn {
        padding: 16px;
        border-radius: 8px;
        border: none;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        background-color: #19be6f;
        transition: background 0.5s ease-in-out;
        cursor: pointer;
        &:disabled {
            background: #8AF0BF;
            cursor: auto;
        }
    }

    @media (max-width: 680px) {
        input {
            font-size: 14px;
        }
    }
`;
