import React, { useEffect, useState } from 'react';
import { DoctorSettingsDiv } from './lkDoctorSettings.styled';
import { Modal } from '../../share/Modal';
import PopUpConfirm from '../../share/PopUpConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PopUpAlert from '../../share/PopUpAlert';
import { fetchDeleteUser } from '../../authentification/api/authentificationSlice';
import { fetchGetDoctorFuturePayedConsultations } from "../api/lkDoctorConsultationsSlice";
import { ErrorDeleteAccountModal } from "../lkDoctorModals/errorDeleteAccountModal/errorDeleteAccountModal";

/**
 * Компонент LkDoctorSettings
 * 
 * Компонент предназначен для управления настройками личного кабинета врача. 
 * Пользователь может удалить свой профиль через подтверждение и увидеть оповещение об успешном удалении 
 * или о невозможности удаления при наличии запланированных оплаченных консультаций.
 *
 * @component
 * @returns {JSX.Element} JSX-элемент с интерфейсом настроек профиля врача.
 */

const LkDoctorSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.authentification.user);
    const [popUpDeleteProfile, setPopUpDeleteProfile] = useState(false);
    const [popUpSuccessfulDeleteProfile, setPopUpSuccessfulDeleteProfile] = useState(false);
    const [popUpUnsuccessfulDeleteProfile, setPopUpUnsuccessfulDeleteProfile] = useState(false);
    const [hasPaidConsultation, setHasPaidConsultation] = useState(false);

    useEffect(() => {
        const checkPaidConsultations = async () => {
            const payedConsultations = await dispatch(fetchGetDoctorFuturePayedConsultations(user.id));
            if (payedConsultations.payload?.length > 0) {
                setHasPaidConsultation(true);
            }
        };
        checkPaidConsultations();
    }, [dispatch]);

    const deleteProfile = () => {
        setPopUpDeleteProfile(false);

        if (hasPaidConsultation) {
            setPopUpUnsuccessfulDeleteProfile(true);
            return;
        } else {
            setPopUpSuccessfulDeleteProfile(true);
        }
    };

    const successDeleteProfile = () => {
        navigate('/');
        setPopUpSuccessfulDeleteProfile(false);
        setPopUpUnsuccessfulDeleteProfile(false);
        dispatch(fetchDeleteUser(user.id));
    };

    return (
        <DoctorSettingsDiv>
            <p className="settings__item">
                Вы можете{' '}
                <label onClick={() => hasPaidConsultation ?
                    setPopUpUnsuccessfulDeleteProfile(true) : setPopUpDeleteProfile(true)} className="settings__item-label">
                    удалить
                </label>{' '}
                свой личный кабинет, если у вас нет запланированных оплаченных консультаций с&nbsp;пациентами.
            </p>

            {popUpDeleteProfile && (
                <Modal toggle={setPopUpDeleteProfile}>
                    <PopUpConfirm
                        onConfirm={deleteProfile}
                        onClose={() => setPopUpDeleteProfile(false)}
                        contentHeader={
                            <span style={{ fontWeight: '500' }}>
                                Вы уверены, что хотите удалить свой личный кабинет? <br />
                                <span style={{ color: '#666666', fontSize: '18px' }}>Все данные будут удалены</span>
                            </span>
                        }
                        isClarification={false}
                    />
                </Modal>
            )}

            {popUpSuccessfulDeleteProfile && (
                <Modal toggle={setPopUpDeleteProfile}>
                    <PopUpAlert
                        onConfirm={successDeleteProfile}
                        contentHeader={<span>Ваш аккаунт успешно удален</span>}
                        isClarification={true}
                    />
                </Modal>
            )}

            {popUpUnsuccessfulDeleteProfile && (
                <Modal toggle={setPopUpUnsuccessfulDeleteProfile}>
                    <ErrorDeleteAccountModal toggle={setPopUpUnsuccessfulDeleteProfile} />
                </Modal>
            )}
        </DoctorSettingsDiv>
    );
};

export default LkDoctorSettings;
