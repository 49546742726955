import { DataBlockStl } from '../viewDoctor.styled';
import React from 'react';

/**
 * Компонент для отображения информации о месте работы доктора.
 *
 * @component
 * @param {Array} props.work - Массив объектов, содержащих информацию о местах работы.
 * @param {string} props.work[].organization.name - Название организации.
 * @param {string} props.work[].position.name - Название должности.
 * @param {string} props.work[].work_from - Дата начала работы в формате "дд.мм.гггг".
 * @param {string} props.work[].work_to - Дата окончания работы в формате "дд.мм.гггг". Если работа продолжается, это значение может быть пустым.
 * @param {boolean} props.work[].until_now - Флаг, указывающий, продолжается ли работа на текущий момент.
 *
 * @returns {JSX.Element[]} Массив JSX-элементов, каждый из которых отображает информацию о месте работы.
 *
 * @example
 * // Пример использования в компоненте
 * <ViewWork work={workExperienceArray} />
 *
 * @description
 * Компонент отображает информацию о каждом месте работы, включая название должности,
 * название организации и период работы (с начала до окончания или до текущего момента).
 */

export const ViewWork = ({ work }) => {
    const workShow = work.map((w, index) => {
        const startDateModified = `${w.work_from.split('.')[1]}.${w.work_from.split('.')[2]}`;

        const endDateModified =
            w.work_to && w.work_to !== 'null'
                ? `${w.work_to.split('.')[1]}.${w.work_to.split('.')[2]} гг.`
                : 'по настоящее время';

        return (
            <DataBlockStl key={index}>
                <div className="info__wrapper">
                    <h4 className="info__header">{w.position?.name}</h4>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{w.organization?.name}</p>
                </div>
                <div className="info__wrapper">
                    <p className="info__data">{`${startDateModified} - ${endDateModified}`}</p>
                </div>
            </DataBlockStl>
        );
    });
    return workShow;
};
