import { Loader } from '../../../loader/loader';
import React from 'react';
import { ViewPatientStl } from './viewPatient.styled';
import { TitleWithPencil } from './titleWithPencil/TitleWithPencil';
import { useNavigate } from 'react-router-dom';
import { ViewData } from './patientData/ViewData';
import { SectionStl } from './viewPatient.styled';
import { useSelector } from 'react-redux';

/**
 * Компонент ViewPatient отображает личную информацию пациента и
 * позволяет пользователю перейти на страницу редактирования профиля.
 *
 * @component
 * @returns {JSX.Element} Отрендеренное представление профиля пациента.
 */

export const ViewPatient = () => {
    const isLoader = useSelector((state) => state.patientProfile.isLoader);
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/lk/patient-profile/profile/edit');
    };

    return (
        <>
            <ViewPatientStl>
                <SectionStl>
                    <TitleWithPencil handleRedirect={handleRedirect} title={'Личные данные'} />
                    <ViewData />
                    {isLoader && <Loader />}
                </SectionStl>
            </ViewPatientStl>
        </>
    );
};
