import { useController, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import React, { useEffect } from 'react';
import { DateFieldStl } from './inputDateStl.styled';

/**
 * Компонент InputDate для ввода даты с маской и валидацией.
 * Использует react-hook-form для управления формой и валидацией, и InputMask для маскирования ввода.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля ввода, используемое для управления формой.
 * @param {string} props.label - Текст метки для поля ввода.
 * @param {string} props.placeholder - Текст плейсхолдера для поля ввода.
 * @param {string} [props.defaultValue] - Значение по умолчанию для поля.
 * @param {string} props.mask - Маска для поля ввода, используемая InputMask.
 * @param {Object} [props.validation] - Дополнительные правила валидации для поля.
 * @param {boolean} [props.isRequired=false] - Флаг, указывающий, является ли поле обязательным.
 *
 * @returns {JSX.Element} Компонент поля ввода даты.
 */
const InputDate = ({ name, label, placeholder, defaultValue, mask, validation = {}, isRequired }) => {
    // outSideOnChange можем передать внешний коллбек, если будет нужно
    const { control, trigger, setValue } = useFormContext();

    // Устанавливаем значение по умолчанию при изменении
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, name, setValue]);

    const defaultValidation = {
        required: { value: false, message: 'Введите дату' },
        pattern: {
            value: /^(19|20)\d{2}$/,
            message: 'Некорректная дата',
        },
        validate: {
            // нельзя ввести будущую дату
            isFutureDate: (value) => {
                const currentDate = new Date();
                const enteredDate = new Date(value);
                if (enteredDate > currentDate) {
                    return 'Дата не может быть больше текущего времени';
                }
                return true;
            },
            // нельзя ввести слишком прошлую дату
            isPasteDate: (value) => {
                const enteredDate = new Date(value);
                const minDate = new Date(0);
                minDate.setFullYear(minDate.getFullYear() - 20); // 1950г
                if (enteredDate < minDate) {
                    return 'Дата не может быть ранее 1950 года';
                }
                return true;
            },
        },
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, defaultValue, rules: defaultValidation });
    const clearedView = '';

    const handleBlur = () => {
        trigger(field.name);
    };

    return (
        <DateFieldStl
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={() => {
                field.onChange(clearedView);
            }}
        >
            <InputMask
                {...field}
                inputMode="numeric"
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="text"
                mask={mask}
                maskChar=""
                value={field.value || ''}
                onBlur={handleBlur}
                onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger(field.name);
                }}
            />
        </DateFieldStl>
    );
};

export default InputDate;
