import styled from 'styled-components';

export const FiltersStl = styled.div`
    margin-bottom: 30px;
    form {
        display: flex;
        gap: 20px;
        > label {
            width: calc((100% - 40px) / 3);
        }

        .inputSearch {
            position: relative;
            > label input {
                border: none;
                background-color: #f4f4f4;
                padding-left: 50px;
                text-overflow: ellipsis;
                ::placeholder {
                    color: #b6b7ba;
                }
            }
            > label svg {
                top: 12.5px;
            }
            > svg {
                position: absolute;
                left: 15px;
                top: 45%;
                transform: translateY(-80%);
            }
            &:active {
                border: none;
            }
        }

        @media (max-width: 575px) {
            flex-direction: column;
            gap: 8px;
            > label {
                width: 100%;
            }
        }
    }
`;
