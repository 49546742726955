import React from 'react';
import { PoliticArticleStyled } from './PoliticArticle.styled';

/**
 * Компонент для отображения отдельной статьи соглашения.
 * Разбивает описание соглашения на параграфы и рендерит их.
 *
 * @param {Object} agreement Объект соглашения, содержащий заголовок и описание.
 * @param {string} agreement.title Заголовок соглашения.
 * @param {string} agreement.description Описание соглашения.
 *
 * @returns {JSX.Element} Рендерит заголовок и описание соглашения.
 */
export const PoliticArticle = ({ agreement }) => {
    const paragraphs = agreement.description ? agreement.description.split('\r\n') : [];

    return (
        <PoliticArticleStyled>
            <h3 className="agreementTitle">{agreement.title}</h3>
            <div className="agreementDescription">
                {paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </PoliticArticleStyled>
    );
};
