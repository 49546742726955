import { BASE_URL } from '../../consts';
import axios from 'axios';

export const API_URL = BASE_URL;

/**
 * Основной экземпляр axios для API-запросов с предустановленными заголовками и включенными `credentials`
 */
export const apiRequest = axios.create({
    withCredentials: true,
    headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
    },
    baseURL: API_URL,
});

/**
 * Интерсептор запроса для добавления токена из sessionStorage в заголовок Authorization при каждом запросе.
 * @param {Object} config - Конфигурация запроса
 * @returns {Object} - Обновленная конфигурация запроса с Authorization заголовком, если токен доступен.
 */
apiRequest.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

/**
 * Интерсептор ответа для обработки ошибок авторизации и обновления токенов.
 * @param {Object} config - Конфигурация ответа
 * @returns {Object|Promise} - Ответ или повторный запрос в случае обновления токенов
 * @throws {Object} Ошибка запроса, если не удалось обновить токены
 */
apiRequest.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        // console.log('Ошибка в интерсепторе:', error);

        // на случай если токен не валидный (пользователь не активный в админке) чистим сторедж
        if (error.response.status == 401 && error.response.data.code === 'user_inactive') {
            localStorage.clear();
            sessionStorage.clear();
        }

        // Обновление токенов при истечении access-токена запросом новой пары {refresh, acces} токенов и запись их в сторедж
        if ((error.response.status == 401 && localStorage.getItem('refresh')) || error.response.status == 500) {
            try {
                const res = await axios.post(`${API_URL}auth/jwt/refresh/`, {
                    refresh: localStorage.getItem('refresh'),
                });
                sessionStorage.setItem('token', res.data.access);
                localStorage.setItem('refresh', res.data.refresh);
                error.config.headers.Authorization = `Bearer ${res.data.access}`;

                // После успешного получения нового токена, делаем повторный запрос
                return apiRequest.request(error.config);

                // axios
                //     .post(`${API_URL}auth/jwt/refresh/`, {
                //         refresh: localStorage.getItem('refresh'),
                //     })
                //     .then((res) => {
                //         sessionStorage.setItem('token', res.data.access);
                //         localStorage.setItem('refresh', res.data.refresh);
                //         error.config.headers.Authorization = `Bearer ${res.data.access}`;
                //     })
                //     .catch((e) => {
                //         console.error(e);
                //         localStorage.clear();
                //         sessionStorage.clear();
                //     });

                // return apiRequest.request(error.config);
            } catch (e) {
                console.error(e);
                sessionStorage.removeItem('token', res.data.access);
                localStorage.removeItem('refresh', res.data.refresh);
            }
        }
        throw error;
    }
);

/**
 * Экземпляр axios для загрузки файлов с добавленным заголовком авторизации.
 */
export const fileLoader = axios.create({
    headers: {
        accept: 'application/json',
    },
    baseURL: API_URL,
});

/**
 * Интерсептор для добавления токена авторизации в запрос загрузки файла.
 * @param {Object} config - Конфигурация запроса
 * @returns {Object} - Обновленная конфигурация запроса с заголовком Authorization
 */
// fileLoader.interceptors.request.use((config) => {
//     config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
//     return config;
// });

fileLoader.interceptors.request.use((config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

/**
 * Загружает новый аватар пользователя на сервер.
 *
 * @async
 * @function loadMyAvatar
 * @param {File} file - Файл изображения, который нужно загрузить
 * @returns {Promise<Object>} Ответ от сервера с данными аватара
 * @throws {Object} Ошибка с информацией о запросе, если загрузка не удалась
 */
export const loadMyAvatar = async (file) => {
    const formData = new FormData();
    formData.append('image_standart', file);
    try {
        const response = await fileLoader.post('/avatars/me/', formData);
        return response.data;
    } catch (error) {
        console.error('Error in loadMyAvatar', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Удаляет текущий аватар пользователя с сервера.
 *
 * @async
 * @function deleteMyAvatar
 * @returns {Promise<Object>} Ответ от сервера, если удаление прошло успешно
 * @throws {Object} Ошибка с информацией о запросе, если удаление не удалось
 */
export const deleteMyAvatar = async () => {
    try {
        const response = await apiRequest.delete(`avatars/me/`);
        return response.data;
    } catch (error) {
        console.error('Error in deleteMyAvatar', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Получает данные о городе с API на основе строки поиска.
 *
 * @async
 * @function getCity
 * @param {string} searchString - Строка поиска, используемая для запроса названий городов через API.
 * @returns {Promise<Object|void>} Ответ API, содержащий данные о городе, или undefined в случае ошибки.
 *
 * @description
 * Отправляет GET-запрос на конечную точку API `cities/`, передавая `searchString` в качестве параметра запроса.
 * Если запрос не удается, ошибка перехватывается и выводится в консоль.
 *
 * @example
 * const cities = await getCity('Москва');
 * // console.log(cities);
 */
export const getCity = async (searchString) => {
    try {
        return await apiRequest.get(`cities/?name=${searchString}`);
    } catch (error) {
        console.error('Error in getCity', error.response);
        throw error.response ? error : { message: error.message };
    }
};
