import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppointmentStl } from './appointment.styled';
import { fetchLogout } from '../../../authentification/api/authentificationSlice';
import close_icon from '../../../../img/close-icon.svg';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';

/**
 * Компонент для отображения модального окна записи на приём.
 * Показывает информацию о необходимости входа в систему для записи, с возможностью
 * закрытия окна и выполнения входа / регистрации в зависимости от роли пользователя.
 *
 * @component
 * @example
 * return (
 *   <Appointment closeModal={setModalVisibility} />
 * );
 *
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.closeModal - Функция для закрытия модального окна.
 * Принимает параметр типа boolean для скрытия окна.
 */
export const Appointment = ({ closeModal }) => {
    useLockBodyScroll();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { whoAreYou } = useSelector((state) => state.authentification);

    const handleClick = () => {
        if (whoAreYou === 'doctor') {
            dispatch(fetchLogout()).then(() => navigate('/login'));
            return;
        }
        navigate('/login');
    };

    return (
        <AppointmentStl>
            <div className="appointment">
                <div className="appointment__close">
                    <img
                        className="appointment__close__icon"
                        src={close_icon}
                        alt="close_icon"
                        onClick={() => closeModal(false)}
                    />
                </div>
                <div className="appointment__icon">
                    <img src="/img/V_telemedicine_patient.svg" alt="header logo" />
                </div>
                <p className="appointment__text">Для записи необходимо войти в качестве пациента</p>
                <button className="appointment__button" onClick={handleClick}>
                    Войти / Зарегистрироваться
                </button>
            </div>
        </AppointmentStl>
    );
};
