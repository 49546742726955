import React from 'react';
import Container from '../../componets/share/Container';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import { useEffect } from 'react';
import { PoliticsStyled } from './Politics.styled';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgreements } from '../../componets/politics/api/politicSlice';
import { Spinner } from '../../componets/share/Spinner';
import { PoliticArticle } from '../../componets/politics/politicArticle/PoliticArticle';

/**
 * Компонент для отображения юридической информации (соглашений).
 * Загружает список соглашений с сервера и отображает их.
 * При этом отображается спиннер в случае загрузки.
 *
 * @returns {JSX.Element} Рендерит список соглашений или спиннер загрузки.
 */
const Politics = () => {
    const dispatch = useDispatch();
    const { agreements, isLoading } = useSelector((state) => state.politics);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchAgreements());
    }, []);

    return (
        <PoliticsStyled>
            <Container>
                <MainHeader title={'Юридическая информация'} />
                {isLoading && !agreements.length ? (
                    <div className="centerSpinner">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {agreements.map((agreement) => (
                            <PoliticArticle key={agreement.id} agreement={agreement}></PoliticArticle>
                        ))}
                    </>
                )}
            </Container>
        </PoliticsStyled>
    );
};

export default Politics;
