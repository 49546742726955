import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DivStl, LabelStl } from './radioGroupStl.styled';

/**
 * Компонент RadioGroup — группа радиокнопок с валидацией.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для формы.
 * @param {string} props.label - Лэйбл группы радиокнопок (текст, который отображается рядом с кнопками).
 * @param {Array<string>} props.options - Массив опций для отображения рядом с радиокнопками.
 * @param {boolean} [props.isRequired=false] - Флаг, указывающий, является ли поле обязательным для заполнения.
 * @param {string} [props.defaultChecked] - Значение, которое должно быть выбрано по умолчанию.
 *
 * @returns {JSX.Element} Возвращает компонент группы радиокнопок с валидацией.
 */

const RadioGroupDrCategories = ({ name, label, isRequired, options, defaultStateValue = '0' }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const { field } = useController({
        name,
        control,
        rules: { required: 'Это поле обязательно для заполнения' },
        defaultValue: Number(defaultStateValue),
    });

    useEffect(() => {
        field.onChange(Number(defaultStateValue));
    }, []);

    return (
        <DivStl>
            <span className="radio-group">
                {label}
                {isRequired && <span className="fieldWraper__red">*</span>}
            </span>
            {options.map((option, index) => {
                return (
                    <LabelStl key={index}>
                        <input
                            type="radio"
                            {...field}
                            value={index}
                            onChange={() => field.onChange(index)}
                            checked={field.value === index}
                        />
                        <div>
                            <svg className="svg" height="24px" width="24px">
                                <circle
                                    className="radio-outline"
                                    cx="12"
                                    cy="12"
                                    r="11"
                                    fill="white"
                                    stroke="#B6B7BA"
                                    strokeWidth="2"
                                />
                                <circle className="radio-dot" cx="12" cy="12" r="8" fill="none" />
                            </svg>
                        </div>
                        <span>{option}</span>
                    </LabelStl>
                );
            })}
            {errors[name] && <span className="error-message">{errors[name]?.message}</span>}
        </DivStl>
    );
};

export default RadioGroupDrCategories;
