import styled from 'styled-components';

export const SymbolCountTextAreaStl = styled.div`
    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #212121;

        @media (max-width: 450px) {
            font-size: 18px;
            line-height: 23.4px;
        }

        @media (max-width: 340px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .symbols {
        display: grid;
        grid-template-columns: 2.9fr auto 1fr;
        grid-template-areas: 'title count total';
        gap: 4px;

        &__title {
            grid-area: title;
        }

        &__count {
            grid-area: count;
            justify-self: end;
        }

        &__total {
            grid-area: total;
        }

        @media (max-width: 680px) {
            grid-template-columns: 0.75fr 1fr;
            grid-template-areas: 'count total';

            &__title {
                display: none;
            }
        }

        & > span {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 13px;
            color: #666666;

            @media (max-width: 680px) {
                font-size: 14px;
                line-height: 0px;
            }
        }
    }
`;
