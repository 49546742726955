import React, { useMemo } from 'react';
import { StyledLkDoctorNavigation } from './lkDoctorNavigation.styled';
import { MainMenu } from '../../share/mainMenu/mainMenu';
import { useLocation } from 'react-router-dom';
import {
    DateHint,
    DateHintAppointment,
    TimeHint,
    TimeHintAppointment,
} from '../lkDoctorCalendar/calendarComponents/CalendarHint';
import { useSelector } from 'react-redux';

/**
 * Компонент LkDoctorNavigation отвечает за отображение навигационного меню врача.
 * В зависимости от текущего пути (pathname), компонент также отображает подсказки по времени и дате в календаре.
 * @component
 * @returns {JSX.Element} Возвращает навигационное меню и, при необходимости, подсказки для календаря.
 */
const LkDoctorNavigation = () => {
    const { pathname } = useLocation();
    const lastName = useSelector((state) => state.doctorProfile.doctorData.user?.last_name);
    const isApproved = useSelector((state) => state.doctorProfile.doctorData.is_approved);

    /**
     * Массив naviData содержит данные для навигационного меню врача, включая название раздела, путь для перехода
     * и подменю (children). Каждая запись включает ключ, название, путь и флаг активности.
     */

    const naviData = useMemo(() => {
        return [
            {
                key: 1,
                title: 'Профиль',
                linkPathTo: '/lk/doctor-profile/profile',
                isActiveWithoutData: true,
            },
            {
                key: 2,
                title: 'Мои консультации',
                linkPathTo: '/lk/doctor-profile/consultations',
                isActiveWithoutData: !!isApproved,
                children: [
                    {
                        key: 2.1,
                        title: 'Запланированные',
                        linkPathTo: '/lk/doctor-profile/consultations/future',
                        isActiveWithoutData: !!isApproved,
                    },
                    {
                        key: 2.2,
                        title: 'Прошедшие',
                        linkPathTo: '/lk/doctor-profile/consultations/past',
                        isActiveWithoutData: !!isApproved,
                    },
                ],
            },
            {
                key: 4,
                title: 'Расписание',
                linkPathTo: '/lk/doctor-profile/doctor-calendar',
                isActiveWithoutData: !!isApproved,

                children: [
                    {
                        key: 4.1,
                        title: 'Настройка',
                        linkPathTo: '/lk/doctor-profile/doctor-calendar/settings',
                        isActiveWithoutData: !!isApproved,
                    },
                    {
                        key: 4.2,
                        title: 'Просмотр',
                        linkPathTo: '/lk/doctor-profile/doctor-calendar/viewer',
                        isActiveWithoutData: !!isApproved,
                    },
                ],
            },
            {
                key: 5,
                title: 'Настройки',
                linkPathTo: '/lk/doctor-profile/settings',
                isActiveWithoutData: true,
            },
        ];
    }, [lastName, isApproved]);

    return (
        <StyledLkDoctorNavigation>
            <MainMenu naviData={naviData} lastName={lastName} />
            {pathname === '/lk/doctor-profile/doctor-calendar/settings' && (
                <div className="date-time-hint">
                    <DateHint />
                    <DateHintAppointment />
                    <TimeHint />
                    <TimeHintAppointment />
                </div>
            )}
        </StyledLkDoctorNavigation>
    );
};

export default LkDoctorNavigation;
