import styled from 'styled-components';

export const UserNavigationStl = styled.div`
    display: flex;
    flex-direction: column;

    .exit {
        grid-column: 2 / 3;
        transition: background-color 0.5s linear;
        cursor: pointer;
        padding: 11px 0;
        &:hover {
            background-color: #f4f4f4;
        }
    }
    @media (max-width: 440px) {
        .exit {
            padding-bottom: 30px;
        }
    }
`;
