import styled from 'styled-components';

export const StyledConsultationTime = styled.div`
    grid-column: span 1;
    @media (max-width: 500px) {
        grid-area: footer;
    }
    .dateAndTime {
        display: flex;
        flex-wrap: wrap;
        justify-content: ${(props) => props.isModal && 'center'};
        width: ${(props) => props.isModal && '275px'};
        row-gap: 12px;
        column-gap: ${(props) => props.isCurrentConsultation ? '24px' : '16px'};
        & img {
            margin-right: 8px;
        }

        & .dateValue,
        .timeValue {
            font-weight: 400;
            font-size: ${(props) => (props.isModal ? '16px' : props.card ? '14px' : '18px')};
            color: ${(props) => (props.card === true ? '#666666' : '#212121')};
        }
    }

    .date,
    .time,
    .price {
        display: flex;
        align-items: center;
    }

    @media (max-width: 500px) {
        .dateAndTime {
            width: ${(props) => props.isModal && '220px'};
            gap: 13px;
            & img {
                width: 18px;
                height: 18px;
                margin-right: 5px;
            }
            & .dateValue,
            .timeValue {
                font-size: ${(props) => (props.isModal ? '16px' : props.card ? '14px' : '16px')};
            }
        }
    }
    @media (max-width: 370px) {
        .dateAndTime {
            width: auto;

            .dateValue,
            .timeValue {
                font-size: ${(props) => (props.isModal || props.card ? '12px' : '16px')};
            }
        }
    }
`;
