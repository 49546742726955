import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { clearAllMessages, fetchRegister } from '../../componets/authentification/api/authentificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticationStl } from './authentication.styled';
import Spacer from '../../componets/share/Spacer';
import Button from '../../componets/share/Button';
import Container from '../../componets/share/Container';
import InputEmail from '../../componets/share/formFields/InputEmail';
import InputPassword from '../../componets/share/formFields/InputPassword';
import InputUserName from '../../componets/share/formFields/InputUserName';
import AgreementField from '../../componets/share/formFields/AgreementField';
import { WhoAreYou } from '../../componets/authentification/whoAraYou/WhoAreYou';
import { Loader } from '../../componets/loader/loader';
import { useNavigate } from 'react-router-dom';
import { clearMessage } from '../../componets/authentification/api/authentificationSlice';

/**
 * Компонент формы регистрации, использующий react-hook-form для управления состоянием формы,
 * и Redux для обработки состояния аутентификации и ошибок.
 * @component
 * @returns {JSX.Element|null} Возвращает JSX элемент с формой регистрации или `null`, если пользователь уже авторизован.
 */
export const Authentication = () => {
    const methods = useForm({
        defaultValues: {
            who: '',
            email: '',
            password: '',
            confirmPassword: '',
            agreement: false,
        },
        mode: 'all',
    });

    const { isAuth, messagesError, isPreloader } = useSelector((state) => state.authentification);
    const { role } = useSelector((state) => state.authentification.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        formState: { isValid, isSubmitting },
        watch,
        setValue,
        register,
    } = methods;

    register('agreement', { required: true });

    useEffect(() => {
        if (messagesError) {
            setValue('agreement', false);
        }
    }, [messagesError, setValue]);

    useEffect(() => {
        dispatch(clearAllMessages());
    }, [dispatch]);

    /**
     * При условии, что пользователь авторизован, переход в личный кабинет в заисисмости от роли пользователя
     */
    useEffect(() => {
        if (isAuth && role === 'patient') navigate('/lk/patient-profile/profile/edit');
        if (isAuth && role === 'doctor') navigate('/lk/doctor-profile/profile/edit');
    }, [isAuth, role]);

    /**
     * Обрабатывает отправку данных формы. Вызывает action для регистрации пользователя с введёнными данными.
     * @param {Object} data - Данные формы, введённые пользователем.
     * @param {string} data.email - Email пользователя.
     * @param {string} data.password - Пароль пользователя.
     * @param {string} data.who - Роль пользователя.
     * @param {string} data.username - Логин пользователя.
     *
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {
        const { email, password, who, username } = data;
        await dispatch(fetchRegister({ username, email, password, role: who }));
    };

    const handleClearError = (errorKey) => {
        dispatch(clearMessage(errorKey));
    };

    if (isPreloader) {
        // console.log('preloader in auth component');
        return <Loader />;
    }

    /**
     * Отрисовывает форму регистрации с полями для ввода данных.
     * @returns {JSX.Element} Форма регистрации.
     */
    const renderPageAutorization = () => {
        return (
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <h2 className="auth__header">Регистрация</h2>
                    <Spacer marginBottom="28px" />
                    <WhoAreYou />
                    <Spacer marginBottom="28px" />
                    <InputUserName
                        name="username"
                        placeholder="example"
                        label="Логин"
                        isRequired={true}
                        messagesError={messagesError?.username}
                        onClearError={handleClearError}
                        errorKey="username"
                    />
                    <InputEmail
                        name="email"
                        label="Email"
                        placeholder={'example@gmail.com'}
                        control={methods.control}
                        isRequired={true}
                        messagesError={messagesError?.email}
                        onClearError={handleClearError}
                        errorKey="email"
                    />
                    <InputPassword
                        name="password"
                        confirmName="confirmPassword"
                        placeholder="Введите пароль"
                        label="Пароль"
                        isRequired={true}
                        messagesError={messagesError?.password}
                        errorKey="password"
                        onClearError={handleClearError}
                    />
                    <InputPassword
                        name="confirmPassword"
                        confirmName="password"
                        placeholder="Повторите пароль"
                        label="Подтвердите пароль"
                        isRequired={true}
                        messagesError={messagesError?.password}
                        errorKey="password"
                        onClearError={handleClearError}
                    />
                    <Spacer marginBottom="14px" />
                    <AgreementField name="agreement" required />
                    <Button green margin="0px" type="submit" disabled={!isValid || isSubmitting}>
                        Зарегистрироваться
                    </Button>
                </form>
            </FormProvider>
        );
    };

    if (isAuth) return null;

    return (
        <AuthenticationStl>
            <Container>
                <div className="auth">
                    <div className="auth__wrapper">{renderPageAutorization()}</div>
                </div>
            </Container>
        </AuthenticationStl>
    );
};
