import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyledDoctorsTop } from './doctorsTop.styled';
import Container from '../../share/Container';
import Card from './Card/Card';
import { Spinner } from '../../share/Spinner';
import { fetchGetDoctors } from '../../ourDoctors/api/doctorsSlise';

/**
 * @description Блок Компетентных врачей, каждого врача рендерит с помощью компонента `Card`
 * @returns {Element}
 * @constructor
 * @see Card
 */
export const DoctorsTop = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allDoctors = useSelector((state) => state.doctors.doctors);
    const isLoading = useSelector((state) => state.doctors.isLoading);
    const [filters, setFilters] = useState({ search: '', spec: '', sort: '' });
    const [randomizedDoctors, setRandomizedDoctors] = useState([]);
    const [visibleCardsCount, setVisibleCardsCount] = useState(4);

    useEffect(() => {
        const data = {
            search: filters.search,
            ordering: filters.sort,
            spec_ids: filters.spec,
        };
        dispatch(fetchGetDoctors(data));
    }, [filters, dispatch]);

    useEffect(() => {
        if (allDoctors?.length > 0) {
            const highRatedDoctors = allDoctors.filter(
                (doctor) => doctor.rating_average >= 4 && doctor.rating_average <= 5
            );
            const seniorDoctors = allDoctors.filter((doctor) => doctor.seniority >= 10);
            let doctorsToShow = highRatedDoctors.length > 0 ? highRatedDoctors : seniorDoctors;

            // Удаление дубликатов врачей по их ID и получение массива уникальных объектов врачей.
            const uniqueDoctors = Array.from(
                new Set(doctorsToShow.map((doctor) => doctor.id))
            ).map((id) => doctorsToShow.find((doctor) => doctor.id === id));

            const shuffledDoctors = uniqueDoctors.sort(() => Math.random() - 0.5);
            setRandomizedDoctors(shuffledDoctors);
        }
    }, [allDoctors]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setVisibleCardsCount(3);
            } else {
                setVisibleCardsCount(4);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function showDoctorsTop() {
        window.scrollTo(0, 0);
        navigate('/doctors');
    }
    return (
        <StyledDoctorsTop>
            {randomizedDoctors?.length > 0 && (
                <Container>
                    <h2 className="doctorsTop__heading">Наши врачи</h2>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <ul className="doctorsTop__specialization">
                            {randomizedDoctors?.length > 0
                                ? randomizedDoctors
                                      .slice(0, visibleCardsCount)
                                      .map((doctor) => (
                                          <Card
                                              key={doctor.id}
                                              name={doctor.user.first_name}
                                              middlename={doctor.user.middle_name}
                                              lastname={doctor.user.last_name}
                                              avatar={doctor?.user.image_standart}
                                              topDoctorsId={doctor.id}
                                              price={doctor.price}
                                              specialization={doctor.specialization}
                                              rating={doctor.rating_average}
                                              seniority={doctor.seniority}
                                              slug={doctor.slug}
                                              id={doctor.user.id}
                                          />
                                      ))
                                : null}
                        </ul>
                    )}
                    <button className="doctorsTop__button" onClick={showDoctorsTop}>
                        Смотреть больше врачей
                    </button>
                </Container>
            )}
        </StyledDoctorsTop>
    );
};
