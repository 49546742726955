import React from 'react';
import { SendCommentsModalStl } from './sendCommentsModalStl.styled';
import { ReactComponent as Cross } from '../../../../img/icons/cross-green.svg';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';

/**
 * Компонент `SendCommentsModal` отображает модальное окно для подтверждения отправки комментария и прикрепленных файлов.
 *
 * Компонент предупреждает пользователя о том, что после отправки внесение изменений будет невозможно.
 * Также использует хук `useLockBodyScroll` для блокировки прокрутки страницы при открытии модального окна.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.setSendCommentsConfirm - Функция для управления состоянием отображения модального окна.
 * Принимает булево значение: `false` закрывает модальное окно.
 * @param {Function} props.sendFiles - Функция-обработчик для отправки комментария и файлов.
 *
 * @returns {JSX.Element} Элемент JSX, представляющий модальное окно подтверждения отправки.
 */
export const SendCommentsModal = ({ setSendCommentsConfirm, sendFiles }) => {
    /** Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара */
    useLockBodyScroll();

    const closeSendCommentsModal = () => {
        setSendCommentsConfirm(false);
    };

    return (
        <SendCommentsModalStl>
            <div className="modalSend">
                <Cross className="modalSend__cross-icon" onClick={closeSendCommentsModal} />
                <p className="modalSend__heading">Вы уверены, что хотите отправить комментарий?</p>
                <p className="modalSend__text">
                    После отправки Вы не сможете внести изменения, включая прикрепленные файлы. Пожалуйста, убедитесь,
                    что Ваш комментарий и файлы верны перед тем, как подтвердить отправку
                </p>
                <button onClick={sendFiles} className="modalSend__close-btn">
                    Отправить
                </button>
            </div>
        </SendCommentsModalStl>
    );
};
