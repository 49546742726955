import styled from 'styled-components';

export const TextAreaStl = styled.div`
    .textarea {
        width: 100%;
        max-width: unset;
        height: 164px;
        overflow: hidden;
        margin: 20px 0 8px;
        padding: 20px 56px 20px 20px;
        resize: none;
        border: 1px solid #b6b7ba;
        border-radius: 10px;
        font-size: 18px;
        line-height: 24px;
        outline: none;

        &::placeholder {
            color: #b6b7ba;
        }

        &:focus {
            border: 1px solid #19be6f;
            box-shadow: none;
        }

        &:invalid {
            border: 1px solid #ff3636;
            box-shadow: none;
        }

        @media (max-width: 450px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
`;
