import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import Flex from './Flex';
import done_icon from '../../img/done.svg';
import { PopUpAlertSettingStyled } from './PopUpAlert.styled';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';

/**
 * @function PopUpAlert
 * Модальное окно для уведомления пользователя об успешной операции
 * Используется в компоненте LkPatientSettings.jsx для подтверждения успешного удаления профиля пациента
 */

const PopUpAlert = ({ onConfirm, contentHeader, isClarification = false, contentClarification = '' }) => {
    useLockBodyScroll();
    return (
        <PopUpAlertSettingStyled>
            <Flex>
                <img className="succsesLogo" src={done_icon} alt="done_icon" />
            </Flex>
            <Flex margin={isClarification ? '0 0 12px' : '0 0 24px'}>
                <h3 className="headerExiteProfile">{contentHeader}</h3>
            </Flex>
            {isClarification && <p className="textClarification">{contentClarification}</p>}
            <Flex className="buttonsExiteProfile">
                <Button
                    weight="500"
                    margin="0"
                    width="108px"
                    height="50px"
                    font_size="18px"
                    display="inline-flex"
                    align="center"
                    justify="center"
                    green
                    onClick={onConfirm}
                    hoverBorder
                    hoverBGColor
                >
                    Ок
                </Button>
            </Flex>
        </PopUpAlertSettingStyled>
    );
};

export default PopUpAlert;
