import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppointmentStl } from './appointment.styled';
import close_icon from '../../../../img/close-icon.svg';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';

/**
 * Компонент Appointment
 *
 * React-компонент, отображающий модальное окно с уведомлением о необходимости авторизации для выполнения определенного действия.
 *
 * @component
 * @param {Object} props - Свойства, переданные в компонент.
 * @param {Function} props.closeModal - Функция для закрытия модального окна. Принимает аргумент `false`.
 *
 * @returns {JSX.Element} JSX-разметка компонента.
 */

export const Appointment = ({ closeModal }) => {
    const navigate = useNavigate();
    /** Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара */
    useLockBodyScroll();

    function handleClick() {
        navigate('/login');
        window.scrollTo(0, 0);
    }

    return (
        <AppointmentStl>
            <div className="appointment">
                <div className="appointment__close">
                    <img
                        className="appointment__close__icon"
                        src={close_icon}
                        alt="close_icon"
                        onClick={() => closeModal(false)}
                    />
                </div>
                <div className="appointment__icon">
                    <img src="/img/V_telemedicine_patient.svg" alt="header logo" />
                </div>
                <p className="appointment__text">Для того чтобы оставить комментарий необходимо войти</p>
                <button className="appointment__button" onClick={handleClick}>
                    Войти / Зарегистрироваться
                </button>
            </div>
        </AppointmentStl>
    );
};
