import styled from 'styled-components';
export const AxiosErrorStl = styled.div`
    .axiosError {
        margin-top: 10px;
        // margin-bottom: 24px;
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .axiosErrorText {
        color: #ff3636;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
`;
