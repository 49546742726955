import styled, { css } from 'styled-components';

import React from 'react';

const ContainerStl = styled.div`
    max-width: ${(props) => props.maxWidth || '1070px'};
    width: ${(props) => props.width || '100%'};
    margin: ${(props) => props.margin || '0 auto'};
    padding: ${(props) => props.padding || '0 16px'};
    height: 100%;

    @media ${({ theme }) => theme.device.laptop} {
        padding: 0 20px;
    }

    @media ${({ theme }) => theme.device.tablet} {
        padding: 0 16px;
    }

    ${(props) =>
        props.flex &&
        css`
            display: flex;
            justify-content: ${(props) => props.justContent || 'space-between'};
            flex-wrap: ${(props) => props.flexWrap || null};
        `}
    ${(props) =>
        props.relative &&
        css`
            position: relative;
        `}
`;
/**
 * @param {Object} [props] Свойства компонента.
 * @param {string} [props.maxWidth] Максимальная ширина контейнера (max-width) - по умолчанию `1070px`.
 * @param {string} [props.width]  Ширина контейнера (width) - по умолчанию `100%`.
 * @param {string} [props.margin]  Внешние отступы (margin) - по умолчанию `0 auto`.
 * @param {string} [props.padding]  Внутрение отступы (margin) - по умолчанию `0 16px`.
 * @param {string} [props.justContent] Содержимое стиля `justify-content` (Выравнивание по оси X) - по умолчанию `space-between`.
 * @param {string} [props.flexWrap] Содержимое стиля `justify-content` (Выравнивание по оси X) - по умолчанию `space-between`.
 * @param {boolean} [props.flex]  `flex-wrap` управляет тем, как `flex-элементы`
 * (дочерние элементы контейнера с display: flex) размещаются внутри контейнера, когда им не хватает места - по умолчанию `null`.
 * @param {boolean} [props.relative]  Проп relative используется для добавления стиля position: relative к контейнеру.
 * Это позволяет изменять позиционирование элемента относительно его нормального (стандартного) положения в документе.
 * @returns {Element}
 * @description Div контейнер для элементов
 */
export const Container = (props) => {
    return <ContainerStl {...props} />;
};
export default Container;
