import { OnlineConsultationStl } from './onlineConsultation.styled';
import { React, useState } from 'react';
import { Button } from '../Button';
import { useDispatch } from 'react-redux';
import { getDateForConsultation, getTimeFromDate } from '../../../share/helpers';
import { fetchRecordConsultations, setConsultation } from '../../api/doctorsSlise';
import { useNavigate } from 'react-router-dom';
// убрано всвязи с оплатой без юкассы, вернуть при возвращении оплаты с юкассой
// import { fetchCreatePayment } from '../../../lkPatient/api/paymentsSlice';
import calendarIcon from '../../../../img/icons/doctorConsultation/calendar.svg';
import clockIcon from '../../../../img/icons/doctorConsultation/clock.svg';
import hourglassIcon from '../../../../img/icons/doctorConsultation/hourglass.svg';
import currencyRubleIcon from '../../../../img/icons/doctorConsultation/currency-ruble.svg';
import crossIcon from '../../../../img/icons/icon-cross-green.svg';
import { ErrorConsultation } from '../errorConsultationModal/ErrorConsultation';
import axios from 'axios';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';
import { formatUnixDateForServer } from '../../../../utils/dateTransform';
/**
 * Компонент онлайн-консультации.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.consultation - Объект, содержащий информацию о консультации.
 * @param {Object} props.doctor - Объект, содержащий информацию о враче.
 * @param {Function} props.closeModal - Функция для закрытия модального окна.
 *
 * @returns {JSX.Element} Размеченный компонент онлайн-консультации.
 *
 * @example
 * const consultation = {
 *     time: "2023-10-12T10:00:00Z",
 *     // другие свойства
 * };
 *
 * const doctor = {
 *     id: 1,
 *     specialization: [{ id: 1, name: "Терапевт" }],
 *     user: {
 *         last_name: "Иванов",
 *         first_name: "Иван",
 *         middle_name: "Иванович"
 *     },
 *     // другие свойства
 * };
 *
 * <OnlineConsultation
 *     consultation={consultation}
 *     doctor={doctor}
 *     closeModal={handleClose}
 * />
 */

export const OnlineConsultation = ({ consultation, doctor, closeModal }) => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(true);
    const [loading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { time } = consultation;
    useLockBodyScroll();

    const {
        id,
        free_reception_time: freeTime,
        price,
        nearest_reception_time: nearestTime,
        specialization,
        user,
    } = doctor;
    const { last_name, first_name, middle_name } = user;

    const specializations = doctor?.specialization?.length
        ? doctor.specialization
              .map((spec, index) => {
                  // Приводим имя специализации к нижнему регистру
                  const specializationName = spec.name.toLowerCase();
                  // Если это первый элемент, возвращаем его с большой буквы
                  return index === 0
                      ? specializationName.charAt(0).toUpperCase() + specializationName.slice(1)
                      : `, ${specializationName}`;
              })
              .join('') // Объединяем все элементы в одну строку
        : 'Доктор не указал специализацию';
    /**
     * handleConfirumClick - Обрабатывает клик по кнопке подтверждения записи на консультацию.
     * Асинхронная функция, которая выполняет операции по созданию записи на консультацию,
     * обновляет состояние загрузки и обрабатывает ошибки.
     *
     * @async
     * @function
     * @returns {Promise<void>} Возвращает промис, который разрешается после завершения операции.
     *
     * @throws {Error} Если возникает ошибка при создании записи на консультацию.
     *
     * Данная функция выполняет следующие ключевые действия:
     * - Устанавливает состояние загрузки в true.
     * - Формирует запрос для записи на консультацию, включая информацию о докторе и времени консультации.
     * - Закрывает модальное окно.
     * - Выполняет асинхронный запрос для создания записи на консультацию.
     * - При успешном исходе навигирует пользователя на страницу будущей консультации.
     * - Обновляет состояние и данные в Redux store.
     * - В случае возникновения ошибки устанавливает соответствующее состояние для отображения модального окна с ошибкой.
     */

    const handleConfirumClick = async () => {
        setIsLoading(true);

        const consultationRequest = {
            doctor: id,
            consultation_time: formatUnixDateForServer(consultation.time)
                ? formatUnixDateForServer(consultation.time)
                : formatUnixDateForServer(nearestTime),
        };

        try {
            closeModal(() => true);
            const data = await dispatch(fetchRecordConsultations(consultationRequest)).unwrap();
            navigate(`/lk/patient-profile/consultation/future-consultation/${data.id}`);
            dispatch(setConsultation({ doctor, time: consultationRequest.consultation_time, openModal: true }));
            setErrorMessage(null);
            setIsLoading(data);
        } catch (error) {
            setErrorMessage(' ');
            setShowModal(false);
            setIsLoading(false);
        }
    };

    return (
        <>
            {showModal && (
                <OnlineConsultationStl>
                    <div className="consultation">
                        <button className="consultation__cross" onClick={() => closeModal(false)}>
                            <img src={crossIcon} alt="close" className="cross" />
                        </button>

                        <img src="/img/V_telemedicine_patient.svg" alt="header logo" className="consultation__icon" />
                        <h3 className="consultation__title">Онлайн-консультация</h3>
                        <div className="consultation__content">
                            <div className="consultation__doctor">
                                <div className="consultation__doctor-name">
                                    {last_name} {first_name} {middle_name}
                                </div>
                                <ul className="consultation__doctor-skill">{specializations}</ul>
                            </div>
                            <div className="consultation__details">
                                <div className="details">
                                    <span className="details__cell">
                                        <img src={calendarIcon} alt="calendar" />
                                        <span>{getDateForConsultation(time)}</span>
                                    </span>
                                    <span className="details__cell">
                                        <img src={clockIcon} alt="clock" />
                                        <span>{getTimeFromDate(consultation.time)}</span>
                                    </span>
                                </div>
                                <div className="details">
                                    <span className="details__cell">
                                        <img src={hourglassIcon} alt="time" />
                                        <span>30 мин</span>
                                    </span>
                                    <span className="details__cell">
                                        <img src={currencyRubleIcon} alt="price" />
                                        <span>{price}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Button className="consultation__button" onClick={handleConfirumClick}>
                            Оплатить
                        </Button>
                    </div>
                </OnlineConsultationStl>
            )}
            {errorMessage && (
                <ErrorConsultation
                    handleConfirumClick={handleConfirumClick}
                    closeModal={closeModal}
                    loading={loading}
                    errorMessage={errorMessage}
                />
            )}
        </>
    );
};
