import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import InputText from '../../../../share/formFields/InputText';
import InputDate from '../../../../share/formFields/InputDate';
import InputFile from '../../../../share/formFields/InputFile';
import {
    validateInputOrganization,
    validateInputProgram,
    validateRequired,
} from '../../../../share/formFields/validateInput';
import { useDispatch } from 'react-redux';
import { fetchDeleteDrSkillsImprovement } from '../../../api/doctorUserProfileSlice';

/**
 * Компонент для управления информацией о повышении квалификации.
 *
 * Этот компонент позволяет добавлять, редактировать и удалять записи
 * о повышении квалификации, включая название организации, название программы,
 * год окончания и загрузку сертификата. Данные передаются родительскому
 * компоненту через колбек-функцию `onSkillsImprovementData`.
 *
 * ### Основные функции:
 * - **Добавление записи:** Кнопка "Добавить повышение квалификации" позволяет
 *   добавить новую запись о повышении квалификации.
 * - **Удаление записи:** Функция удаления вызывает удаление объекта
 *   на сервере (при наличии ID) и удаляет элемент из массива записей.
 * - **Редактирование записи:** Поля ввода позволяют редактировать данные
 *   в каждой записи. Данные обновляются автоматически при изменении.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.onSkillsImprovementData - Функция обратного вызова,
 * которая вызывается при изменении данных о повышении квалификации.
 *
 * @example
 * const handleSkillsImprovementData = (data) => {
 *   // console.log('Полученные данные о повышении квалификации:', data);
 * };
 *
 * <AdvancedTraining onSkillsImprovementData={handleSkillsImprovementData} />
 *
 * @returns {JSX.Element} Элемент, представляющий форму для повышения квалификации.
 */

const AdvancedTraining = ({ onSkillsImprovementData }) => {
    const dispatch = useDispatch();
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: 'data.skills_improvement',
        control,
    });

    /**
     * Используется watch для отслеживания изменений в полях
     */
    const skillsImprovementFields = useWatch({
        control,
        name: 'data.skills_improvement',
    });

    /**
     * Функция подготовки данных для отправки
     */
    const skillsImprovementData = (fields) => {
        return fields.map((field) => {
            const isFileUpdated = field.skills_improvement_image instanceof FileList;

            return {
                id: field.id || null,
                organization: { name: field.organization.name },
                program: { name: field.program.name },
                graduation_year: parseInt(field.graduation_year, 10),
                delete_skills_improvement_image: !isFileUpdated && !field.skills_improvement_image ? true : false,
                skills_improvement_image: isFileUpdated ? field.skills_improvement_image[0] : undefined,
            };
        });
    };

    // Функция поиска id удаляемого объекта, оставлена на случай, если id снова будет неверно возврящаться через react-hook-form
    // const skillsImprovementDataForDeletion = (fields) => {
    //     const fieldToDelete = fields.find((field) => field.toDeletion);
    //     return fieldToDelete ? fieldToDelete.id : null;
    // };

    /**
     * Вызов функции для передачи данных о повышении квалификации при изменении `skillsImprovementFields`.
     */
    useEffect(() => {
        const data = skillsImprovementData(skillsImprovementFields);
        onSkillsImprovementData(data);
    }, [skillsImprovementFields]);

    /**
     * Если массив fields пуст при первой отрисовке компонента,
     * в append добавляется пустой объект
     */
    useEffect(() => {
        if (fields.length === 0) {
            append({
                id: null,
                organization: { name: '' },
                program: { name: '' },
                graduation_year: '',
                delete_skills_improvement_image: false,
                skills_improvement_image: '',
            });
        }
    }, [fields, append]);

    /**
     * Функция для добавления нового блока повышения квалификации.
     * Добавляет новый объект с пустыми значениями полей.
     */
    const onAdd = () => {
        append({
            id: null,
            organization: { name: '' },
            program: { name: '' },
            graduation_year: '',
            delete_skills_improvement_image: false,
            skills_improvement_image: '',
        });
    };

    /**
     * Функция, которая удаляет блок повышения квалификации по указанному индексу.
     * Если у блока есть ID, инициирует удаление через API.
     */
    const handleDelete = (index) => {
        const skillToDelete = skillsImprovementFields[index]?.id;
        try {
            if (skillToDelete) {
                dispatch(fetchDeleteDrSkillsImprovement(skillToDelete));
            }
        } catch (err) {
            // console.log('Error deleting skill', err);
        }
        remove(index);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Повышение квалификации</h2>

            {fields.map((field, index) => (
                <div key={field.reactHookFormId || field.id}>
                    <InputText
                        name={`data.skills_improvement[${index}].organization.name`}
                        label="Организация"
                        placeholder="Название"
                        validation={validateInputOrganization(true)}
                        isRequired={true}
                    />
                    <InputText
                        name={`data.skills_improvement[${index}].program.name`}
                        label="Название программы"
                        placeholder="Название"
                        validation={validateInputProgram(true)}
                        isRequired={true}
                    />
                    <div className="work__date">
                        <InputDate
                            name={`data.skills_improvement[${index}].graduation_year`}
                            label="Год окончания"
                            placeholder="ГГГГ"
                            validation={validateRequired(true)}
                            isRequired={true}
                        />
                    </div>
                    <InputFile
                        name={`data.skills_improvement[${index}].skills_improvement_image`}
                        validation={validateRequired(false)}
                        isRequired={false}
                        fieldName="сертификат"
                    />

                    {fields.length > 1 && (
                        <button
                            className="button button_del button_border"
                            onClick={() => {
                                handleDelete(index);
                            }}
                        >
                            Удалить блок
                        </button>
                    )}
                </div>
            ))}
            <button type="button" className="button" onClick={onAdd}>
                <span className="span">+</span> Добавить повышение квалификации
            </button>
        </NewFormSTL>
    );
};
export default AdvancedTraining;
