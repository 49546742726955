import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { StyledLkDoctor } from './styled.lkDoctor';
import LkDoctorNavigation from '../lkDoctorNavigation/lkDoctorNavigation';
import { MainHeader } from '../../mainHeader/mainHeader';
import Container from '../../share/Container';
import { useMediaQuery } from 'react-responsive';

/**
 * LkDoctor компонент для отображения личного кабинета врача.
 *
 * Компонент отвечает за загрузку профиля врача при монтировании и использует адаптивный дизайн, чтобы адаптировать
 * интерфейс под устройства с разрешением экрана до 768px. Также он отслеживает текущее местоположение и
 * предоставляет возможность навигации с использованием React Router.
 *
 * @component
 * @returns {JSX.Element} Возвращает JSX для отображения ЛК врача.
 *
 * @example
 * // Пример использования компонента
 * <LkDoctor />
 *
 * @hook useEffect Загружает профиль врача при монтировании компонента.
 * @hook useMediaQuery Определяет, является ли устройство планшетом (с шириной экрана до 768px).
 * @hook useLocation Получает текущее местоположение пользователя (URL).
 * @hook useNavigate Предоставляет функцию для навигации.
 * @hook useDispatch Диспатчит экшен для получения профиля врача.
 */

const LkDoctor = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/lk/doctor-profile') {
            navigate('/lk/doctor-profile/profile', { replace: true });
        }
    }, [location.pathname, navigate]);

    return (
        <StyledLkDoctor>
            <Container>
                <MainHeader title={'Личный кабинет'} />
                <div className="content">
                    {!isMobile && <LkDoctorNavigation />}
                    <div className="rightBlock">
                        <Outlet />
                    </div>
                </div>
            </Container>
        </StyledLkDoctor>
    );
};

export default LkDoctor;
