import styled from 'styled-components';

export const StyledCancelConsultationModal = styled.div`
    .modalCancel {
        width: 508px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        justify-items: center;
        background-color: #fff;
        cursor: default;
        padding: 60px 68px;
        border-radius: 24px;
        position: relative;

        &__cross-icon {
            position: absolute;
            cursor: pointer;
            width: 20px;
            height: 20px;
            top: 30px;
            right: 30px;
        }
        &__heading {
            font-family: Rubik;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            text-align: center;
            color: #212121;
        }
        &__text {
            font-family: Rubik;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            text-align: center;
            color: #666666;
        }

        &__close-btn {
            margin-top: 16px;
            width: 100%;
            border: 2px solid #19be6f;
            padding: 14px 24px;
            border-radius: 8px;
            background: #19be6f;
            color: #fff;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
            &:hover {
                background-color: #169056;
                border: 2px solid #169056;
            }
        }
    }

    @media (max-width: 500px) {
        .modalCancel {
            width: 288px;
            padding: 52px 16px 40px;

            &__cross-icon {
                width: 13.8px;
                height: 13.8px;
                top: 22.5px;
                right: 22.5px;
            }
            &__heading {
                font-size: 18px;
            }
            &__text {
                font-size: 16px;
            }
        }
    }
`;
