import React from 'react';
import { ConsultationTimerStyled } from './consultationTimerStyled.stl';
import { useTimer } from '../../hooks/useTimer';

/**
 * Компонент отображает таймер до начала консультации или время с момента ее начала.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {string} props.date - Дата и время консультации в формате строки.
 * @returns {JSX.Element} JSX-элемент, отображающий таймер.
 */
export const ConsultationTimer = ({ date }) => {
    const { minutes, seconds, isExpired } = useTimer({ date });

    return (
        <ConsultationTimerStyled>
            {isExpired ? (
                <>
                    <div className="timer_comment">Консультация началась</div>
                    <div className="timer_red">
                        {minutes}:{seconds}
                    </div>
                </>
            ) : (
                <>
                    <div className="timer_comment">До начала консультации</div>
                    <div className="timer_green">
                        {minutes}:{seconds}
                    </div>
                </>
            )}
        </ConsultationTimerStyled>
    );
};
