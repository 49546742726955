import styled from 'styled-components';

export const DoctorReviewsStl = styled.div`
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 14px;
    .reviews__cards {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }
    .reviews__buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 29px;
        @media (max-width: 320px) {
            display: flex;

            justify-content: space-between;
        }
    }

    .noReviews {
        margin-left: 7px;
        font-size: 18px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
        @media (max-width: 350px) {
            font-size: 14px;
        }
    }
    .reviews__btn {
        color: #19be6f;
        display: flex;
        gap: 4px;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        background-color: #ffffff;
        border: none;
        transition: opacity 0.5s linear;
        cursor: pointer;
        padding: 12px;
        &:hover {
            opacity: 0.7;
        }
        @media (max-width: 350px) {
            font-size: 14px;
            padding: 0px;
        }
    }
`;
