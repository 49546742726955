import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';

/**
 * Компонент InputText для текстового ввода с управлением через react-hook-form.
 * Позволяет вводить текст с поддержкой валидации, автоформатирования и удаления лишних пробелов.
 *
 * @component
 *
 * @param {Object} props Свойства компонента.
 * @param {string} props.name - Название поля для привязки к форме и идентификатор инпута.
 * @param {string} [props.placeholder] - Текст-заполнитель для поля ввода.
 * @param {Object} [props.validation] - Правила валидации для поля, которые будут объединены с базовыми правилами.
 * @param {string} [props.label] - Текст метки, отображаемый рядом с полем ввода.
 * @param {Function} [props.onChange] - Внешняя функция, вызываемая при изменении значения поля.
 * @param {RegExp} [props.deleteSpaces] - Регулярное выражение для удаления определённых символов из значения поля.
 * @param {boolean} [props.isRequired=false] - Флаг, обозначающий обязательность поля для заполнения.
 *
 * @returns {JSX.Element} JSX разметка компонента для текстового поля с кастомными правилами ввода и валидации.
 *
 * @example
 * <InputText
 *   name="username"
 *   placeholder="Введите имя пользователя"
 *   validation={{ required: true, minLength: 3 }}
 *   label="Имя пользователя"
 *   onChange={(value) => // console.log(value)}
 *   deleteSpaces={/[\s]/g}
 *   isRequired={true}
 * />
 *
 * Внутренние методы:
 * - `handleBlur`: Обрабатывает событие потери фокуса, форматирует ввод пользователя:
 *   - Удаляет пробелы по краям, сокращает лишние пробелы внутри строки.
 *   - Убирает лишние дефисы и изменяет регистр первой буквы.
 * - `onClear`: Очищает значение поля и вызывает внешнюю функцию `outSideOnChange`.
 *
 * Стилизация:
 * - Обёрнут в `FieldWrapper`, который обрабатывает отображение метки, ошибки, иконки очистки.
 * - Кастомный класс `invalid` применяется при наличии ошибки валидации.
 */

const InputText = ({
    name,
    placeholder,
    validation = {},
    label,
    onChange: outSideOnChange = () => {},
    deleteSpaces = null,
    isRequired = false,
    isPrice = false,
}) => {
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        ...validation,
        validate: {
            ...validation.validate,
        },
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const handleBlur = () => {
        let inputValue = field.value;
        if (typeof inputValue === 'string') {
            // Удаление пробелов в начале и в конце строки
            inputValue = inputValue?.trim();
            // Сокращение всех последовательностей пробелов внутри строки до одного
            inputValue = inputValue?.replace(/ +/g, ' ');
            // Сокращение всех последовательностей дефисов внутри строки до одного
            inputValue = inputValue?.replace(/( -+)|(-+ )/g, '-').replace(/-+/g, '-');
            // Удаление дефисов по краям строки
            inputValue = inputValue?.replace(/^-+/, '').replace(/-+$/, '');
            // Изменение регистра первой буквы в строке
            inputValue = inputValue?.slice(0, 1).toUpperCase() + inputValue?.slice(1);

            if (deleteSpaces) {
                inputValue = inputValue?.replace(deleteSpaces, '');
            }

            if (isPrice) {
                inputValue = inputValue?.replace(/^0+/, '');
            }
        }
        trigger(field.name);
        field.onChange(inputValue);
    };
    const clearedView = '';

    return (
        <FieldWrapper
            error={error}
            label={label}
            isRequired={isRequired}
            clearCondition={!!field.value}
            onClear={() => {
                field.onChange(clearedView);
                outSideOnChange(clearedView);
            }}
        >
            <input
                {...field}
                onBlur={handleBlur}
                className={`${!error ? '' : 'invalid'}`}
                placeholder={placeholder}
                type="text"
                onChange={(e) => {
                    if (isPrice) {
                        let filteredValue = e.target.value.replace(/[^\d]/g, '');
                        if (filteredValue.length > 5) {
                            filteredValue = filteredValue.slice(0, 5);
                        }
                        field.onChange(filteredValue);
                    } else {
                        field.onChange(e.target.value);
                    }
                    trigger(field.name);
                    outSideOnChange(e.target.value);
                }}
            />
        </FieldWrapper>
    );
};

export default InputText;
