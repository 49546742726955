import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { clearAllMessages, fetchLogin } from '../../componets/authentification/api/authentificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoginStl } from './login.styled';
import Spacer from '../../componets/share/Spacer';
import Button from '../../componets/share/Button';
import Container from '../../componets/share/Container';
import InputEmail from '../../componets/share/formFields/InputEmail';
import InputPassword from '../../componets/share/formFields/InputPassword';
import { Loader } from '../../componets/loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearMessage } from '../../componets/authentification/api/authentificationSlice';

/**
 * Компонент формы входа, использующий react-hook-form для управления состоянием формы,
 * и Redux для обработки состояния аутентификации и ошибок.
 * @component
 * @returns {JSX.Element|null} Возвращает JSX элемент с формой входа или `null`, если пользователь уже авторизован.
 */
export const Login = () => {
    const methods = useForm({
        defaultValues: {
            email: '',
            loginPassword: '',
        },
        mode: 'onBlur',
    });

    const { isAuth, messagesError, isPreloader } = useSelector((state) => state.authentification);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const from = location.state?.from;

    const {
        formState: { isValid },
    } = methods;

    useEffect(() => {
        dispatch(clearAllMessages());
    }, [dispatch]);

    /**
     * При условии, что пользователь авторизован, переход на предыдущую страницу.
     * Если путь === /videochat, перенаправляем пользователя в его лк.
     * Запрещаем доктору попадать во вкладку "Наши врачи".
     */
    useEffect(() => {
        if (isAuth && whoAreYou) {
            if (whoAreYou === 'patient') {
                from?.startsWith('/videochat') ? navigate('/lk/patient-profile/profile/view') : navigate(-1);
            }
            if (whoAreYou === 'doctor') {
                from?.startsWith('/doctors') || from?.startsWith('/videochat')
                    ? navigate('/lk/doctor-profile/profile/view')
                    : navigate(-1);
            }
        }
    }, [isAuth, whoAreYou]);

    /**
     * Обрабатывает отправку данных формы. Вызывает action для входа пользователя с введёнными данными.
     * @param {Object} data - Данные формы, введённые пользователем.
     * @param {string} data.email - Email пользователя.
     * @param {string} data.password - Пароль пользователя.
     */
    const onSubmit = (data) => {
        const { email, loginPassword } = data;
        dispatch(fetchLogin({ email, password: loginPassword }));
    };

    /**
     * Переход на страницу регистрации.
     */
    const handleNavSignup = () => {
        navigate('/signup');
    };

    const handleClearError = (errorKey) => {
        dispatch(clearMessage(errorKey));
    };

    if (isAuth && !whoAreYou) return <Loader />;

    if (isPreloader) return <Loader />;

    /**
     * Отрисовывает форму входа с полями для ввода данных.
     * @returns {JSX.Element} Форма входа.
     */
    const renderPageLogin = () => {
        return (
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <h2 className="auth__header">Вход</h2>
                    <Spacer marginBottom="28px" />
                    <Spacer marginBottom="28px" />
                    <InputEmail
                        name="email"
                        label="Email"
                        placeholder={'example@gmail.com'}
                        control={methods.control}
                        isRequired={true}
                        messagesError={messagesError?.detail}
                        errorKey="detail"
                        onClearError={handleClearError}
                    />
                    <InputPassword
                        name="loginPassword"
                        placeholder="Введите пароль"
                        label="Пароль"
                        isRequired={true}
                        messagesError={messagesError?.detail}
                        errorKey="detail"
                        onClearError={handleClearError}
                    />
                    <Spacer marginBottom="14px" />
                    <Button green margin="0px" type="submit" disabled={!isValid}>
                        Войти
                    </Button>
                    <Spacer marginBottom="18px" />
                    <p className="auth__text">Ещё не зарегистрированы?</p>
                    <Spacer marginBottom="18px" />
                    <Button white margin="0px" type="submit" onClick={handleNavSignup}>
                        Зарегистрироваться
                    </Button>
                </form>
            </FormProvider>
        );
    };

    if (isAuth) return null;

    return (
        <LoginStl>
            <Container>
                <div className="auth">
                    <div className="auth__wrapper">{renderPageLogin()}</div>
                </div>
            </Container>
        </LoginStl>
    );
};
