import { apiRequest } from "../../share/api";

/**
 * Отправляет POST-запрос с контактными данными пользователя.
 * @async
 * @function sendContacts
 * @param {Object} contactsData - Данные контактной формы.
 * @param {string} contactsData.name - Имя пользователя.
 * @param {string} contactsData.topic - Тема обращения.
 * @param {string} contactsData.phoneNumber - Телефонный номер пользователя.
 * @param {string} contactsData.email - Email пользователя.
 * @param {string} contactsData.message - Сообщение пользователя.
 * @param {boolean} contactsData.agreement - Согласие на обработку персональных данных.
 * @returns {Promise<Object>} Ответ сервера с подтверждением отправки контактных данных. 
 * При успешном запросе возвращает объект:
 * {
 *   "name": "Александр",
 *   "topic": "Тех.Поддержка",
 *   "phone": "+79999991010",
 *   "email": "user@someexample.com",
 *   "message": "Текст сообщения"
 * }
 * @throws {Error} В случае ошибки выбрасывает объект ошибки с ответом сервера или текстом ошибки.
 */

export const sendContacts = async (contactsData) => {
    try {
        return await apiRequest.post(`contacts/contact_user/`, contactsData);
    } catch (error) {
        console.error(error.response);
        throw error.response ? error.response : new Error(error.message);
    }
};
