import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../share/Container';
import { StyledTopBanner } from './topBanner.styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useSelector } from 'react-redux';

/**
 * Компонент TopBanner, отображающий верхний баннер с изображениями, статистикой и кнопкой для выбора врача.
 *
 * @component
 * @returns {JSX.Element} Компонент верхнего баннера.
 *
 * @example
 * <TopBanner />
 *
 * @description
 * - Содержит слайдер с изображениями, текстовые блоки и статистику.
 * - Предназначен для главной страницы.
 *
 * @function showDoctorsTop - Перенаправляет пользователя на страницу с врачами.
 *
 * @constant {Object} statistics - Данные для отображения статистики.
 * @property {string} 'довольных клиентов' - Количество довольных клиентов.
 * @property {string} 'нанятых сотрудников' - Количество нанятых сотрудников.
 * @property {string} 'спасенных жизней' - Количество спасенных жизней.
 * @property {string} 'оказанной помощи' - Количество оказанной помощи.
 *
 * @constant {Object} sliderSettings - Настройки для слайдера.
 * @property {boolean} infinite - Бесконечный цикл слайдов.
 * @property {number} slidesToShow - Количество отображаемых слайдов.
 * @property {boolean} autoplay - Автоматическое воспроизведение слайдов.
 * @property {number} speed - Скорость смены слайдов.
 * @property {number} autoplaySpeed - Время между сменой слайдов.
 * @property {boolean} arrows - Отображение стрелок навигации.
 * @property {boolean} fade - Эффект плавного перехода между слайдами.
 */

const TopBanner = () => {
    const navigate = useNavigate();
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const sliderSettings = {
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        speed: 600,
        autoplaySpeed: 3000,
        arrows: false,
        fade: true,
    };

    function showDoctorsTop() {
        navigate('/doctors');
    }

    return (
        <StyledTopBanner whoAreYou={whoAreYou}>
            <Container>
                <div className="topBanner__inner">
                    <div className="topBanner__top">
                        <div className="topBanner__top-left">
                            <Slider {...sliderSettings}>
                                <img
                                    src="img/homePage/homePage-top-1.webp"
                                    alt="homePage-top-1"
                                    className="topBanner__top-img"
                                />
                                <img
                                    src="img/homePage/homePage-top-2.webp"
                                    alt="homePage-top-2"
                                    className="topBanner__top-img"
                                />
                                <img
                                    src="img/homePage/homePage-top-3.webp"
                                    alt="homePage-top-3"
                                    className="topBanner__top-img"
                                />
                            </Slider>
                        </div>
                        <div className="topBanner__top-right">
                            <h1 className="topBanner__top-title">
                                <span className="topBanner__top-title--wrapper">
                                    Заботимся
                                    <br /> о&nbsp;
                                </span>
                                <span className="topBanner__top-title--bold">вашем комфорте и</span>&nbsp;
                                <span className="topBanner__top-title--wrapper">здоровье</span>
                            </h1>
                            <p className="topBanner__top-text">
                                Онлайн консультации <br />с врачами&nbsp;
                                <span>24/7</span>
                            </p>
                            {whoAreYou !== 'doctor' && (
                                <button className="topBanner__top-btn" onClick={showDoctorsTop}>
                                    Выбрать врача
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </StyledTopBanner>
    );
};

export default TopBanner;
