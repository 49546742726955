import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getConsultations,
    getDoctorConsultation,
    сancelConsultation,
    getConsultation,
    changeStatusOfConsultationToPassedRequest,
    getFeedback,
    setCancelDoctorConsultation,
    setRecommendation,
    updateConsultationInfo,
    consultationSendFile,
    consultationDeleteFile,
    changeStatusToStartedDoctor,
    changeStatusToPassedDoctor,
    checkConsultationStatus,
} from './doctorApi';
import { formatErrorMessage, formatNullData } from '../../../componets/share/helpers';
import { convertDatesToUserTimeZone } from '../../../utils/dateTransform';

/**
 * Асинхронный thunk для получения будущих консультаций.
 *
 * @async
 * @function fetchGetDoctorFutureConsultations
 * @param {Object} params - Параметры для thunk.
 * @param {number} params.limit - Максимальное количество консультаций на странице.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.getState - Функция для получения текущего состояния Redux.
 * @returns {Promise<Object>} Объект с данными о будущих консультациях:
 * - `consultations` (Array<Object>): Список будущих консультаций.
 * - `next` (string|null): Ссылка на следующую страницу (если есть).
 *
 * @description
 * 1. Если в состоянии Redux есть ссылка на следующую страницу (`next`), используется она.
 * 2. Если ссылки нет, запрашиваются консультации:
 *    - Со статусом `0` (ожидаемые).
 *    - Отсортированные по полю `date` в порядке возрастания.
 *
 * @throws {Error} Пробрасывается из функции `getConsultations` при ошибке запроса.
 */
export const fetchGetDoctorFutureConsultations = createAsyncThunk(
    'doctorConsultations/fetchGetDoctorFutureConsultations',
    async ({ limit }, { getState, rejectWithValue }) => {
        try {
            const pageLink = getState().doctorConsultations.doctorConsultations.future.next;
            const response = pageLink
                ? await getConsultations({ limit, pageLink })
                : // : await getConsultations({ limit, status: 0, consultation_time: 'consultation_time' });
                await getConsultations({ limit, status: [0, 3], consultation_time: 'consultation_time' });

            return {
                consultations: response.data.results,
                next: response.data.next,
            };
        } catch (error) {
            console.error('Ошибка в fetchGetDoctorFutureConsultations', error.response.status);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для получения будущих оплаченных консультаций.
 *
 * @async
 * @function fetchGetDoctorFuturePayedConsultations
 * @param {Object} params - Параметры запроса.
 * @param {number} [params.limit=1] - Максимальное количество консультаций, которые нужно получить.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для обработки ошибок.
 * @returns {Promise<Array<Object>>} - Список будущих оплаченных консультаций.
 *
 * @description
 * 1. Запрашиваются только оплаченные (`is_payed: true`) консультации.
 * 2. Фильтруются по статусу:
 *    - `0` (ожидаемые).
 *    - `3` (начавшиеся).
 * 3. Запрос ограничивается `limit`, чтобы проверить хотя бы одну такую консультацию.
 *
 * @throws {Error} Пробрасывается из функции `getConsultations` при ошибке запроса.
 */

export const fetchGetDoctorFuturePayedConsultations = createAsyncThunk(
    'doctorConsultations/fetchGetDoctorFuturePayedConsultations',
    async ({ limit = 1 }, { rejectWithValue }) => {
        try {
            const response = await getConsultations({
                is_payed: true,
                limit,
                status: [0, 3],
                consultation_time: 'consultation_time'
            });

            return response.data.results;
        } catch (error) {
            console.error('Ошибка в fetchGetDoctorFuturePayedConsultations', error.response?.status);
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

/**
 * Асинхронный thunk для получения прошедших консультаций.
 *
 * @async
 * @function fetchGetDoctorPastConsultations
 * @param {Object} params - Параметры для thunk.
 * @param {number} params.limit - Максимальное количество консультаций на странице.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.getState - Функция для получения текущего состояния Redux.
 * @returns {Promise<Object>} Объект с данными о прошедших консультациях:
 * - `consultations` (Array<Object>): Список прошедших консультаций.
 * - `next` (string|null): Ссылка на следующую страницу (если есть).
 *
 * @description
 * 1. Если в состоянии Redux есть ссылка на следующую страницу (`next`), используется она.
 * 2. Если ссылки нет, запрашиваются консультации:
 *    - Со статусами `[1, 2, 9]` (прошедшие).
 *    - Отсортированные по полю `date` в порядке убывания.
 *
 * @throws {Error} Пробрасывается из функции `getConsultations` при ошибке запроса.
 */
export const fetchGetDoctorPastConsultations = createAsyncThunk(
    'doctorConsultations/fetchGetDoctorPastConsultations',
    async ({ limit }, { getState, rejectWithValue }) => {
        try {
            const pageLink = getState().doctorConsultations.doctorConsultations.past.next;
            const response = pageLink
                ? await getConsultations({ limit, pageLink })
                : await getConsultations({ limit, status: [1, 2, 9], consultation_time: '-consultation_time' });

            return {
                consultations: response.data.results,
                next: response.data.next,
            };
        } catch (error) {
            console.error('Ошибка в fetchGetPastConsultations', error.response.status);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для отмены консультации доктором.
 *
 * @async @function cancelDoctorConsultation
 * @param {number|string} consultationId - Идентификатор консультации, которую необходимо отменить.
 * @returns {Promise<Object>} Ответ сервера после отмены консультации.
 *
 * @description
 * Использует функцию `сancelConsultation` для выполнения запроса на отмену консультации.
 * Возвращает данные из ответа API: Статус меняется на '2'-'Отменена'.
 * Создается и возвращается объект консультации с новым атрибутом registration_log.
 */
export const cancelDoctorConsultation = createAsyncThunk(
    'doctorConsultations/cancelDoctorConsultation',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await сancelConsultation(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для получения данных о конкретной консультации пациента.
 * @async @function fetchDoctorConsultation
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Данные о консультации пациента - см InitialState - CurrentConsultation.
 *
 * @description
 * Использует функцию `getDoctorConsultation` для выполнения запроса к API.
 * Возвращает объект с данными о консультации, извлекая их из ответа API.
 * Объект консультации - см InitialState - CurrentConsultation.
 */
export const fetchDoctorConsultation = createAsyncThunk(
    'doctorConsultations/fetchDoctorConsultation',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await getConsultation(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для получения данных обратной связи по идентификатору.
 * @async @function fetchFeedback
 * @param {number|string} feedbackId - Идентификатор обратной связи.
 * @returns {Promise<Object>} Данные обратной связи, полученные из ответа API.
 * @description
 * Вызывает функцию `getFeedback` для получения данных о конкретной обратной связи по её идентификатору.
 * Возвращает объект с данными обратной связи из ответа API:
 *  *{
  "id": 0,
  "doctor_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "patient_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "patient_avatar": "string",
  "patient_first_name": "string",
  "rating": "-28.",
  "created": "2024-11-27T22:16:05.237Z",
  "text": "string"
}
 */

export const fetchFeedback = createAsyncThunk('doctorConsultations/fetchFeedback', async (feedbackId) => {
    try {
        const response = await getFeedback(feedbackId);
        return response.data;
    } catch (error) {
        if (error.response.status === 404) {
            return rejectWithValue({ [error.name]: error.message });
        } else {
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
});

/**
 * Асинхронный thunk для перевода консультации в начавшиеся.
 * @async @function fetchChangeStatusToStartedDoctor
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 *
 * @description
 * Использует функцию `changeStatusToStartedDoctor` для выполнения запроса к API.
 * Статус консультации меняется на '3'-'Началась' .
 */
export const fetchChangeStatusToStartedDoctor = createAsyncThunk(
    'doctorConsultations/fetchChangeStatusToStartedDoctor',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await changeStatusToStartedDoctor(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для перевода консультации в завершенные.
 * @async @function fetchChangeStatusToPassedDoctor
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 *
 * @description
 * Использует функцию `changeStatusOfConsultationToPassed` для выполнения запроса к API.
 * Статус консультации меняется на '1'-'Состоялась' .
 */
export const fetchChangeStatusToPassedDoctor = createAsyncThunk(
    'doctorConsultations/fetchChangeStatusToPassedDoctor',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await changeStatusToPassedDoctor(consultationId);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для проверки статуса консультации.
 * @async @function fetchCheckConsultationStatusDoctor
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 *
 * @description
 * Использует функцию `checkConsultationStatus` для выполнения запроса к API.
 */
export const fetchCheckConsultationStatusDoctor = createAsyncThunk(
    'doctorConsultations/fetchCheckConsultationStatusDoctor',
    async (consultationId, { rejectWithValue }) => {
        try {
            const response = await checkConsultationStatus(consultationId);
            // console.log('response.data', response.data);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронная функция для публикации рекомендации от доктора.
 * @async @function sendRecommendation
 * @param {Object} payload - Объект с параметрами запроса.
 * @param {string} payload.consultationId - Уникальный идентификатор консультации.
 * @param {Object} payload.data - Данные, необходимые для установки рекомендации.
 * @returns {Promise<Object>} Ответ сервера с рекомендацией.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 *  * {
 * 'id': 3,
 * 'text': 'Приложить поддорожник'
 * 'status': 'send'
 * }
 */

// проверить
export const sendRecommendation = createAsyncThunk(
    'doctorConsultations/sendRecommendation',
    async (data, { rejectWithValue }) => {
        try {
            const response = await setRecommendation(data);
            return response.data;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для отправки файла в рекомендации после консультации.
 *
 * @async
 * @function sendConsultationFile
 * @param {Object} params - Параметры для thunk.
 * @param {string} params.consultationId - Идентификатор консультации.
 * @param {File} params.file - Файл для отправки.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Ответ сервера после отправки файла.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
export const sendConsultationFile = createAsyncThunk(
    'doctorConsultations/sendConsultationFile',
    async ({ consultationId, file }, { dispatch, rejectWithValue }) => {
        try {
            const response = await consultationSendFile({
                consultationId,
                file,
                onProgress: (progress) => {
                    // Отправляем прогресс загрузки через экшен
                    dispatch(changeUploadFile(progress));
                },
            });
            return response;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронный thunk для удаления файла из рекомендаций после консультации.
 *
 * @async
 * @function deleteConsultationFile
 * @param {string} id - Идентификатор файла.
 * @param {Object} thunkAPI - Объект API, предоставляемый Redux Toolkit.
 * @param {Function} thunkAPI.rejectWithValue - Функция для возврата ошибки.
 * @returns {Promise<Object>} Ответ сервера после удаления файла.
 * @throws {Object} Если запрос завершился с ошибкой, возвращает объект ошибки через `rejectWithValue`.
 */
// проверить
export const deleteConsultationFile = createAsyncThunk(
    'doctorConsultations/deleteConsultationFile',
    async (id, { rejectWithValue }) => {
        try {
            const response = await consultationDeleteFile(id);
            return response;
        } catch (error) {
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);
// ----------------------------------------------------------------------------
//не будут использоваться, удалить
// export const cancelConsultation = createAsyncThunk('cancelConsultation/cancelDoctorConsultation', async (id) => {
//     // console.log('cancelConsultation', id);
//     const response = await setCancelDoctorConsultation(id);
//     return response.data;
// });

// export const updateDoctorConsultationInfo = createAsyncThunk(
//     'doctorConsultations/updateDoctorConsultationInfo',
//     async (payload) => {
//         const response = await updateConsultationInfo({ id: payload.id, str: payload.textRecommendation });
//         return response.data;
//     }
// );
// export const getDoctorConsultationInfo = createAsyncThunk(
//     'doctorConsultations/getDoctorConsultationInfo',
//     async (consultationId) => {
//         const response = await getDoctorConsultation(consultationId);
//         // // console.log(response);
//         return response.data.data.consultation;
//     }
// );

// export const fetchConsultation = createAsyncThunk('doctorConsultations/fetchConsultation', async (id) => {
//     const response = await getDoctorConsultation(id);
//     return response.data.data.consultation;
// });

// --------------------------------------------------------------------------

//статусы консультаций
// 0  Новая
// 1	Состоялась
// 2	Отменена
// 3	Началась
// 8	Прервана
// 9	Не проводилась

const initialState = {
    doctorConsultations: {
        future: {
            // статус 0
            next: null,
            consultations: [],
        },
        past: {
            // статус 1, 2, 9
            next: null,
            consultations: [],
        },
    },
    currentConsultation: {
        id: null,
        status: '',
        doctor: {
            id: '', // id врача
            user: {
                id: '', // id пользователя
                last_name: '',
                first_name: '',
                middle_name: '',
                image_standart: '',
            },
            slug: '',
            specialization: [], // массив объектов специализаций врача в виде ": [{"id": 18,"name": "Психиатрия"}, {...}]
            price: '',
            scientific_degree: '',
            category: '',
            work: [], // массив объектов мест работы врача в виде ":
            //[{"id: 181, "organization": {"name": "Клиника урологии и нефрологии"}, "position": {	"name": "Отоларинголог"}, "work_from": "04.04.2010",	"work_to": null,	"until_now": true	}]
            rating_average: '',
            seniority: '',
        },
        patient: {
            id: '', // id пациента
            user: {
                id: '', // id пользователя
                last_name: '',
                first_name: '',
                middle_name: '',
                image_standart: '',
                date_birth: '',
                sex: '',
            },
        },
        patient_notes: '',
        recommendation: {
            id: '', //id объекта рекоммендации
            text: '', //текст рекоммендации
            // status: '',
        },
        created: '',
        consultation_time: '',
        registration_log: {
            cancelled_by: '', // id user-a, отменившего консультацию
        },
        is_payed: false,
        security_key: '',
        scheduled_duration: '',
        actual_duration: '',
        joining: {
            doctor_has_joined: false,
            patient_has_joined: false,
            join_log: '',
        },
        consultation_type: 1, // по умолчанию всегда 1
        files: [
            {
                id: '', //id объекта файла
                file: '', //ссылка на файл
                date: '', // дата загрузки файла
                owner: '', // id владельца (пользователя, загрузившего файл)
                owners_role: '', // роль владельца файлов "doctor" или "patient"
            },
        ],
        feedback: {
            id: '', // id объекта отзыва
            doctor_id: '', //id доктора
            patient_id: '', // id пациента
            patient_avatar: '', //ссылка на аватар пациента
            patient_first_name: '', // имя пациента
            rating: '', // оценка, поставленная пациентом
            created: '', // дата и время создания отзыва
            text: '',
        },
        progress_files: '',
    },
    keyConsultationDoctor: null,
    messagesError: {},
    isLoading: false,
    isModalSuccessfulConsultation: false,
    timeConsultation: 0, // подсчёт времени состоявшейся консультации
    chatContents: '',
};

const doctorConsultations = createSlice({
    name: 'doctorConsultations',
    initialState,
    reducers: {
        clearConsultation: (state) => {
            state.currentConsultation = initialState.currentConsultation;
            // console.log('CURRENT CONSULTATION IS CLEARED IN STATE', JSON.stringify(state.currentConsultation));
        },
        resetDoctorConsultations: (state) => {
            state.doctorConsultations = {
                future: {
                    next: null,
                    consultations: [],
                },
                past: {
                    next: null,
                    consultations: [],
                },
            };
        },
        // используется
        changeUploadFile: (state, action) => {
            state.currentConsultation.progress_files = action.payload; // Обновляем прогресс
        },
        // будет использоваться в видео-чате,
        setIsModalSuccessfulConsultationDoctor: (state, action) => {
            // state.timeConsultation = 0;
            state.isModalSuccessfulConsultation = !state.isModalSuccessfulConsultation;
            state.timeConsultation = action.payload;
            // console.log('Длительность завершившейся консультации', JSON.stringify(state.timeConsultation));
        },
        //  используется в кнопках для начала видео-чата
        setKeyConsultationDoctor: (state, action) => {
            state.keyConsultationDoctor = action.payload;
            // // console.log('key', JSON.stringify(state.keyConsultationDoctor));
        },
        setChatContents: (state, action) => {
            state.chatContents = action.payload;
            // console.log('CHAT: ', JSON.stringify(state.chatContents));
        },
    },
    extraReducers: (builder) => {
        //--------------------------------------------------------
        // получение будущих консультаций
        builder.addCase(fetchGetDoctorFutureConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetDoctorFutureConsultations.fulfilled, (state, action) => {
            const { consultations: newConsultations, next } = action.payload;

            // Массив обновляем, исключая дубли
            const updatedConsultations = [
                ...state.doctorConsultations.future.consultations.filter(
                    (existing) => !newConsultations.some((newItem) => newItem.id === existing.id)
                ),
                ...newConsultations,
            ];

            // Фильтруем только по статусу 0 и 3
            const filteredConsultations = updatedConsultations.filter(
                (consultation) => [0, 3].includes(consultation.status) && consultation.patient !== null
            );
            state.doctorConsultations.future.consultations = convertDatesToUserTimeZone(filteredConsultations, [
                'created',
                'consultation_time',
                'files.date',
                'feedback.created',
            ]);

            state.doctorConsultations.future.next = next;
            state.isLoading = false;
            // // console.log('future', JSON.stringify(state.doctorConsultations.future.consultations));
            // // console.log('future', JSON.stringify(state.doctorConsultations.future.consultations.length));
        });
        builder.addCase(fetchGetDoctorFutureConsultations.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // получение будущих оплаченных консультаций
        builder.addCase(fetchGetDoctorFuturePayedConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetDoctorFuturePayedConsultations.fulfilled, (state, action) => {
            state.doctorConsultations.payed = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchGetDoctorFuturePayedConsultations.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // получение прошлых консультаций, работает
        builder.addCase(fetchGetDoctorPastConsultations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchGetDoctorPastConsultations.fulfilled, (state, action) => {
            const { consultations: newConsultations, next } = action.payload;

            // Обновляем или добавляем консультации, исключая дубли
            const updatedConsultations = [
                ...state.doctorConsultations.past.consultations.filter(
                    (existing) => !newConsultations.some((newItem) => newItem.id === existing.id)
                ),
                ...newConsultations,
            ];

            // Фильтруем только прошедшие консультации (статусы 1, 2, 9)
            const filteredConsultations = updatedConsultations.filter(
                (consultation) => [1, 2, 9].includes(consultation.status) && consultation.patient !== null
            );
            state.doctorConsultations.past.consultations = convertDatesToUserTimeZone(filteredConsultations, [
                'created',
                'consultation_time',
                'files.date',
                'feedback.created',
            ]);

            state.doctorConsultations.past.next = next;
            state.isLoading = false;

            // Логируем для проверки
            // // console.log('past consultations:', state.doctorConsultations.past.consultations);
            // // console.log('past consultations:', state.doctorConsultations.past.consultations.length);
        });
        builder.addCase(fetchGetDoctorPastConsultations.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // Отмена консультации
        builder.addCase(cancelDoctorConsultation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(cancelDoctorConsultation.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.currentConsultation = action.payload;
        });
        builder.addCase(cancelDoctorConsultation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // получение консультации по id
        builder.addCase(fetchDoctorConsultation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDoctorConsultation.fulfilled, (state, action) => {
            state.currentConsultation = convertDatesToUserTimeZone(action.payload, [
                'created',
                'consultation_time',
                'files.date',
                'feedback.created',
            ]);
            state.isLoading = false;
            // // console.log('currentConsultation from state: ', JSON.stringify(state.currentConsultation));
        });
        builder.addCase(fetchDoctorConsultation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        // ----------------------------------------------------------------------
        // отправка файла
        builder.addCase(sendConsultationFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(sendConsultationFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(sendConsultationFile.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        // ----------------------------------------------------------------------
        // удаление файла
        builder.addCase(deleteConsultationFile.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteConsultationFile.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteConsultationFile.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;

            // // console.log('newMessages', JSON.stringify(state.messagesError));
        });
        // ----------------------------------------------------------------------
        // проверить
        // получение фидбэка
        builder.addCase(fetchFeedback.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFeedback.fulfilled, (state, action) => {
            // state.currentConsultation.feedback = action.payload; перепроверить, нужно ли записывать в стейт
            state.isLoading = false;
        });
        builder.addCase(fetchFeedback.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // проверить
        // создание рекомендации - используется пейлоад или через получение консультации?
        builder.addCase(sendRecommendation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(sendRecommendation.fulfilled, (state, action) => {
            // state.currentConsultation.recommendation = action.payload; проверить - надо ли?
            state.isLoading = false;
        });
        builder.addCase(sendRecommendation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // перевод консультации в статус 3 (началась)
        builder.addCase(fetchChangeStatusToStartedDoctor.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchChangeStatusToStartedDoctor.fulfilled, (state, action) => {
            const newData = action.payload;
            state.currentConsultation = { ...state.currentConsultation, ...newData };
            // console.log('status у врача', JSON.stringify(state.currentConsultation.status));
            state.isLoading = false;
        });
        builder.addCase(fetchChangeStatusToStartedDoctor.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // Завершение консультации
        builder.addCase(fetchChangeStatusToPassedDoctor.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchChangeStatusToPassedDoctor.fulfilled, (state, action) => {
            const newData = action.payload;
            state.currentConsultation = { ...state.currentConsultation, ...newData };
            // // console.log('status у врача', JSON.stringify(state.currentConsultation.status));
            state.isLoading = false;
        });
        builder.addCase(fetchChangeStatusToPassedDoctor.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
        //--------------------------------------------------------
        // Проверка статуса консультации
        builder.addCase(fetchCheckConsultationStatusDoctor.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCheckConsultationStatusDoctor.fulfilled, (state, action) => {
            const newData = action.payload;
            state.currentConsultation = { ...state.currentConsultation, ...newData };
            // // console.log('status у врача', JSON.stringify(state.currentConsultation.status));
            state.isLoading = false;
        });
        builder.addCase(fetchCheckConsultationStatusDoctor.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoading = false;
        });
    },
});

export const selectAllConsultations = (state) => state?.doctorConsultations?.consultations?.data?.consultations;

// export const selectFutureConsultations = (state) => ({
//     consultationsList: state.doctorConsultations.doctorConsultations.future.consultations,
//     next: state.doctorConsultations.doctorConsultations.future.next,
//     isLoading: state.doctorConsultations.isLoading,
// });

// export const selectPastConsultations = (state) => ({
//     consultationsList: state.doctorConsultations.doctorConsultations.past.consultations,
//     next: state.doctorConsultations.doctorConsultations.past.next,
//     isLoading: state.doctorConsultations.isLoading,
// });

export const {
    clearConsultation,
    resetDoctorConsultations,
    changeUploadFile,
    setIsModalSuccessfulConsultationDoctor,
    setKeyConsultationDoctor,
} = doctorConsultations.actions;
export default doctorConsultations.reducer;
