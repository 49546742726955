import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    doctorMe,
    doctorDataViaID,
    doctorDataUpdate,
    doctorProffDataUpdate,
    postDrSkillsImprovement,
    patchDrSkillsImprovement,
    deleteDrSkillsImprovement,
    postDrEducation,
    patchDrEducation,
    deleteDrEducation,
} from './doctorApi';
import { deleteMyAvatar, getCity, loadMyAvatar } from '../../share/api';
import { fetchLogout } from '../../authentification/api/authentificationSlice';
import { formatErrorMessage, formatNullData } from '../../share/helpers';

/**
 * Асинхронное действие для получения данных текущего доктора.
 * @function fetchAllDoctorData
 * @async
 * @returns {Promise<Object>} Данные доктора.
 */
export const fetchAllDoctorData = createAsyncThunk(
    'doctorProfile/fetchAllDoctorData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await doctorMe();
            return response;
        } catch (error) {
            console.error('Ошибка в fetchAllDoctorData', error);
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

//этот метод нигде в коде пока не используется
/**
 * Асинхронное действие для получения данных доктора по ID
 * @function fetchDoctorDataViaID
 * @async
 * @returns {Promise<Object>} Данные доктора.
 */
export const fetchDoctorDataViaID = createAsyncThunk(
    'doctorProfile/fetchDoctorDataViaID',
    async (id, { rejectWithValue }) => {
        try {
            const response = await doctorDataViaID(id);
            return response;
        } catch (error) {
            console.error('Ошибка в fetchDoctorDataViaID', error);
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

/**
 * Асинхронное действие для редактирования личных данных доктора.
 * @function fetchDoctorDataUpdate
 * @async
 * @param {Object} data - Личные данные для обновления профиля доктора.
 * @returns {Promise<Object>} Обновлённые личные данные профиля доктора.
 */
export const fetchDoctorDataUpdate = createAsyncThunk(
    'doctorProfile/fetchDoctorDataUpdate',
    async (data, { rejectWithValue }) => {
        try {
            const response = await doctorDataUpdate(data);
            return response;
        } catch (error) {
            console.error('Ошибка в fetchDoctorDataUpdate', error.message);
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

/**
 * Асинхронное действие для редактирования проффесиональных данных доктора.
 * @function fetchDoctorProffDataUpdate
 * @async
 * @param {Object} data - Профессиональные данные для обновления профиля доктора.
 * @returns {Promise<Object>} Обновлённые данные профиля доктора.
 */
export const fetchDoctorProffDataUpdate = createAsyncThunk(
    'doctorProfile/fetchDoctorProffDataUpdate',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await doctorProffDataUpdate(id, data);
            return response;
        } catch (error) {
            console.error('Ошибка в fetchDoctorProffDataUpdate', error);
            return rejectWithValue(error.response ? error.response.data : { message: error.message });
        }
    }
);

/**
 * Асинхронное действие для выхода пользователя из системы.
 * @function logoutDoctor
 * @async
 * @returns {Promise<null>} Всегда возвращает null.
 */
export const logoutDoctor = createAsyncThunk('doctorProfile/logoutDoctor', async (_, { dispatch }) => {
    await dispatch(fetchLogout());
});

//---------------------------------------------------------------------------------------
/**
 * Асинхронное действие для загрузки аватара пользователя.
 * @function fetchLoadMyAvatar
 * @async
 * @param {File} file - Файл аватара.
 * @returns {Promise<Object|undefined>} Результат загрузки аватара.
 */
export const fetchLoadMyAvatar = createAsyncThunk(
    'doctorProfile/fetchLoadMyAvatar',
    async (file, { rejectWithValue }) => {
        try {
            return await loadMyAvatar(file);
        } catch (error) {
            console.error('error in fetchLoadMyAvatar', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

/**
 * Асинхронное действие для удаления аватара пользователя.
 * @function fetchDeleteMyAvatar
 * @async
 * @returns {Promise<Object|undefined>} Результат удаления аватара.
 */
export const fetchDeleteMyAvatar = createAsyncThunk(
    'doctorProfile/fetchDeleteMyAvatar',
    async (_, { rejectWithValue }) => {
        try {
            return await deleteMyAvatar();
        } catch (error) {
            console.error('error in fetchDeleteMyAvatar', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

//---------------------------------------------------------------------------------------
/**
 * Асинхронное действие для получения списка городов по строке поиска.
 * @function fetchCity
 * @async
 * @param {string} searchString - Строка поиска для фильтрации городов.
 * @returns {Promise<Array>} Список найденных городов.
 */
export const fetchCity = createAsyncThunk('doctorProfile/fetchCity', async (searchString, { rejectWithValue }) => {
    try {
        const response = await getCity(searchString);
        return response.data.results;
    } catch (err) {
        console.error('error in fetchCity', err);
        return rejectWithValue({ [err.name]: err.message });
    }
});

//---------------------------------------------------------------------------------------
/**
 * Асинхронное действие для создания и отправки данные о доп образовании врача с помощью запроса POST.
 * @function
 * @param {Object} data - Данные для отправки.
 * @param {Object} thunkAPI - API Redux thunk.
 * @returns {Promise<Object>} Ответ сервера или объект ошибки.
 */
export const fetchPostDrSkillsImprovement = createAsyncThunk(
    'doctorProfile/fetchPostDrSkillsImprovement',
    async (data, { rejectWithValue }) => {
        try {
            const response = await postDrSkillsImprovement(data);
            return response;
        } catch (error) {
            console.error('error in fetchPostDrSkillsImprovement', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

/**
 * Асинхронное действие для обновления данных о доп образовани врача с помощью запроса PATCH.
 * @function
 * @param {Object} data - Данные для обновления.
 * @param {Object} thunkAPI - API Redux thunk.
 * @returns {Promise<Object>} Ответ сервера или объект ошибки.
 */
export const fetchPatchDrSkillsImprovement = createAsyncThunk(
    'doctorProfile/fetchPatchDrSkillsImprovement',
    async (data, { rejectWithValue }) => {
        try {
            const response = await patchDrSkillsImprovement(data);
            return response;
        } catch (error) {
            console.error('error in fetchPatchDrSkillsImprovement', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

/**
 * Асинхронное действие для удаления данные о конкретном доп образовании врача с помощью запроса DELETE.
 * @function
 * @param {string} id - Идентификатор данных для удаления.
 * @param {Object} thunkAPI - API Redux thunk.
 * @returns {Promise<Object>} Ответ сервера или объект ошибки.
 */
export const fetchDeleteDrSkillsImprovement = createAsyncThunk(
    'doctorProfile/fetchDeleteDrSkillsImprovement',
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteDrSkillsImprovement(id);
            return response.data;
        } catch (error) {
            console.error('error in fetchDeleteDrSkillsImprovement', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

//---------------------------------------------------------------------------------------
/**
 * Асинхронное действие для отправки данные об образовании врача с помощью запроса POST.
 * @function
 * @param {Object} data - Данные для отправки.
 * @param {Object} thunkAPI - API Redux thunk.
 * @returns {Promise<Object>} Ответ сервера или объект ошибки.
 */
export const fetchPostDrEducation = createAsyncThunk(
    'doctorProfile/fetchPostDrEducation',
    async (data, { rejectWithValue }) => {
        try {
            const response = await postDrEducation(data);
            return response;
        } catch (error) {
            console.error('error in fetchPostDrEducation', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

/**
 * Асинхронное действие для обновления данных об образовании врача с помощью запроса PATCH.
 * @function
 * @param {Object} data - Данные для обновления.
 * @param {Object} thunkAPI - API Redux thunk.
 * @returns {Promise<Object>} Ответ сервера или объект ошибки.
 */
export const fetchPatchDrEducation = createAsyncThunk(
    'doctorProfile/fetchPatchDrEducation',
    async (data, { rejectWithValue }) => {
        try {
            const response = await patchDrEducation(data);
            return response;
        } catch (error) {
            console.error('error in fetchPatchDrEducation', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

// в текущем коде не исползуется, написано для следующего релиза
/**
 * Асинхронное действие для удаления данные об образовании врача с помощью запроса DELETE.
 * @function
 * @param {string} id - Идентификатор данных для удаления.
 * @param {Object} thunkAPI - API Redux thunk.
 * @returns {Promise<Object>} Ответ сервера или объект ошибки.
 */
export const fetchDeleteDrEducation = createAsyncThunk(
    'doctorProfile/fetchDeleteDrEducation',
    async (id, { rejectWithValue }) => {
        try {
            const response = await deleteDrEducation(id);
            return response.data;
        } catch (error) {
            console.error('error in fetchDeleteDrEducation', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);
//---------------------------------------------------------------------------------------

const initialEducation = [
    {
        id: 0,
        institution: {
            name: '',
        },
        specialty: {
            name: '',
        },
        graduation_year: 0,
        education_image: '',
    },
];

const initialSkills_improvement = [
    {
        id: 0,
        organization: {
            name: '',
        },
        program: {
            name: '',
        },
        graduation_year: 0,
        skills_improvement_image: '',
    },
];

/** Начальное состояние слайса */
const initialState = {
    doctorData: {
        id: '', // здесь указывается id доктора
        slug: '',
        user: {
            id: '', // здесь указывается id юзера
            username: '',
            email: '',
            phone: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            date_birth: '',
            sex: '',
            city: 0,
            image_standart: '',
        },
        specialization: [
            {
                id: 0,
                name: '',
            },
        ],
        diagnosis_and_treatment: '',
        price: 0,
        scientific_degree: '',
        category: '',
        education: initialEducation,
        skills_improvement: initialSkills_improvement,
        work: [
            {
                organization: {
                    name: '',
                },
                position: {
                    name: '',
                },
                work_from: '',
                work_to: '',
                until_now: false,
            },
        ],
        is_approved: false,
    },
    isLoader: false,
    isPreloaderCity: false,
    isAvatarLoader: false,
    messagesError: {},
    cityOptionsForFetching: [],
};

const doctorProfile = createSlice({
    name: 'doctorProfile',
    initialState,
    reducers: {
        /**
         * Сбрасывает список вариантов города для выборки в пустой массив.
         * @function resetCity
         * @param {Object} state - текущее состояние.
         */
        resetCity: (state) => {
            state.cityOptionsForFetching = [];
        },
        /**
         * Очищает сообщения об ошибках для указанного поля.
         * @function clearMessage
         * @param {string} field Имя поля, ошибки которого нужно очистить.
         */
        clearMessage(state, action) {
            const field = action.payload;
            if (state.messagesError[field]) {
                delete state.messagesError[field];
            }
        },
        clearDoctorState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        /** Обработка всех личных и профессиональных данных */
        builder.addCase(fetchAllDoctorData.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchAllDoctorData.fulfilled, (state, action) => {
            state.doctorData = formatNullData(action.payload);
            state.isLoader = false;
        });
        builder.addCase(fetchAllDoctorData.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка личных и профессиональных данных via ID */
        builder.addCase(fetchDoctorDataViaID.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchDoctorDataViaID.fulfilled, (state, action) => {
            state.doctorData = formatNullData(action.payload);
            state.isLoader = false;
        });
        builder.addCase(fetchDoctorDataViaID.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка личных данных врача */
        builder.addCase(fetchDoctorDataUpdate.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchDoctorDataUpdate.fulfilled, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchDoctorDataUpdate.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка профессиональных данных врача */
        builder.addCase(fetchDoctorProffDataUpdate.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchDoctorProffDataUpdate.fulfilled, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchDoctorProffDataUpdate.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка первичной загрузки данных образования врача */
        builder.addCase(fetchPostDrEducation.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPostDrEducation.fulfilled, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchPostDrEducation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка редактирования данных образования врача */
        builder.addCase(fetchPatchDrEducation.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPatchDrEducation.fulfilled, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchPatchDrEducation.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка удаления образования
         * Если в массиве образования только один элемент и его ID совпадает с переданным, удаление не происходит,
         * поскольку по ТЗ нельзя удалять единственную запись об образовании.
         */
        builder.addCase(fetchDeleteDrEducation.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchDeleteDrEducation.fulfilled, (state, action) => {
            state.isLoader = false;
            const idToRemove = action.meta.arg;
            if (state.doctorData.education.length === 1 && state.doctorData.education[0].id === idToRemove) {
                return;
            } else {
                state.doctorData.education = state.doctorData.education.filter((item) => item.id !== idToRemove);
            }
        });
        builder.addCase(fetchDeleteDrEducation.rejected, (state, action) => {
            state.isLoader = false;
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });

        /** Обработка первичной загрузки данных повышения квалификации врача */
        builder.addCase(fetchPostDrSkillsImprovement.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPostDrSkillsImprovement.fulfilled, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchPostDrSkillsImprovement.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        /** Обработка редактирования данных повышения квалификации врача */
        builder.addCase(fetchPatchDrSkillsImprovement.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPatchDrSkillsImprovement.fulfilled, (state) => {
            state.isLoader = false;
        });
        builder.addCase(fetchPatchDrSkillsImprovement.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };

            state.isLoader = false;
        });

        /** Обработка удаления повышения квалификации
         * Если в массиве дополнительного образования только один элемент и его ID совпадает с переданным, удаление не происходит,
         * поскольку по ТЗ нельзя удалять единственную запись о дополнительном образовании.
         */
        builder.addCase(fetchDeleteDrSkillsImprovement.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchDeleteDrSkillsImprovement.fulfilled, (state, action) => {
            state.isLoader = false;
            const idToRemove = action.meta.arg;
            if (
                state.doctorData.skills_improvement.length === 1 &&
                state.doctorData.skills_improvement[0].id === idToRemove
            ) {
                return;
            } else {
                state.doctorData.skills_improvement = state.doctorData.skills_improvement.filter(
                    (item) => item.id !== idToRemove
                );
            }
        });
        builder.addCase(fetchDeleteDrSkillsImprovement.rejected, (state, action) => {
            state.isLoader = false;
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });

        /** Обработка данных аватара */
        builder.addCase(fetchLoadMyAvatar.pending, (state) => {
            state.isAvatarLoader = true;
        });
        builder.addCase(fetchLoadMyAvatar.fulfilled, (state, action) => {
            state.doctorData.user.image_standart = action.payload.user.avatar;
            state.isAvatarLoader = false;
        });
        builder.addCase(fetchLoadMyAvatar.rejected, (state, action) => {
            state.isAvatarLoader = false;
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });
        builder.addCase(fetchDeleteMyAvatar.pending, (state) => {
            state.isAvatarLoader = true;
        });
        builder.addCase(fetchDeleteMyAvatar.fulfilled, (state) => {
            state.doctorData.user.image_standart = '';
            state.isAvatarLoader = false;
        });
        builder.addCase(fetchDeleteMyAvatar.rejected, (state, action) => {
            state.isAvatarLoader = false;
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });

        /** Обработка данных городов */
        builder.addCase(fetchCity.pending, (state) => {
            state.isPreloaderCity = true;
        });
        builder.addCase(fetchCity.fulfilled, (state, action) => {
            state.cityOptionsForFetching = action.payload;
            state.isPreloaderCity = false;
        });
        builder.addCase(fetchCity.rejected, (state) => {
            state.isPreloaderCity = false;
        });
    },
});

export const { clearMessage, resetCity, clearDoctorState } = doctorProfile.actions;
export const selectDoctorPersonalData = (state) => state.doctorProfile?.doctorData;
export default doctorProfile.reducer;
