import React, { useEffect, useState } from 'react';
import { fetchGetDoctor, fetchReviews, resetDoctor } from '../../componets/ourDoctors/api/doctorsSlise';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ArrowSvg } from '../../img/icons/newDoctorIcons/up-arrow.svg';
import { Container } from '../../componets/share/Container';
import { DoctorAbout } from '../../componets/doctorProfile/doctorAbout/DoctorAbout';
import { DoctorCardProfile } from '../../componets/ourDoctors/doctorCard/doctorCardProfile/doctorCardProfile';
import { DoctorNavigation } from '../../componets/doctorProfile/doctorNavigation/DoctorNavigation';
import { DoctorProfilePageStl } from './doctorProfile.styled';
import { DoctorReviews } from '../../componets/doctorProfile/doctorReviews/DoctorReviews';
import { DoctorSchedule } from '../../componets/ourDoctors/doctorCard/doctorSchedule/doctorSchedule';
import { Loader } from '../../componets/loader/loader';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import { fetchGetFutureConsultations } from '../../componets/lkPatient/api/PatientConsultationsSlice';

/**
 * Компонент DoctorProfilePage отображает страницу профиля врача.
 *
 * @component
 * @returns {JSX.Element} Страница профиля врача.
 *
 * @description
 * Компонент выполняет следующие задачи:
 * - Запрашивает данные врача с сервера с помощью идентификатора из URL.
 * - Обрабатывает состояния загрузки данных.
 * - Предоставляет функциональность навигации и переключения между разделами страницы (например, информация, отзывы и т. д.).
 * - Устанавливает количество отображаемых отзывов с возможностью расширения.
 *
 * @example
 * <DoctorProfilePage />
 */

export const DoctorProfilePage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const doctor = useSelector((state) => state.doctors.doctor);
    const { count, reviews } = useSelector((state) => state.doctors.doctorReviews);
    const [pageShow, setPageShow] = useState('record');
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const isAuth = useSelector((state) => state.authentification.isAuth);
    const [offset, setOffset] = useState(0);
    const { patientConsultations } = useSelector((state) => state.patientConsultations);
    const {
        future: { consultations },
    } = patientConsultations;

    useEffect(() => {
        if (isAuth) {
            dispatch(fetchGetFutureConsultations({ limit: 0 })).unwrap();
        }
    }, [isAuth]);

    useEffect(() => {
        dispatch(fetchGetDoctor(id));
        window.scrollTo(0, 0);
        return () => {
            dispatch(resetDoctor());
        };
    }, [dispatch, id]);

    useEffect(() => {
        setOffset(0);
    }, [id]);

    useEffect(() => {
        dispatch(fetchReviews({ id, offset }));
    }, [dispatch, id, offset]);

    if (!doctor)
        return (
            <Container>
                <DoctorProfilePageStl>
                    <div className="loader">
                        <Loader />
                    </div>
                </DoctorProfilePageStl>
            </Container>
        );

    return (
        <Container>
            <DoctorProfilePageStl>
                <span className="back" onClick={() => navigate(-1)}>
                    <ArrowSvg />
                    Назад к списку
                </span>
                <MainHeader title={'Профиль врача'} />
                <div className="doctor-info">
                    <DoctorCardProfile doctor={doctor} isVerified={doctor.is_approved} isProfile={true} />
                    <div className="right">
                        <DoctorNavigation pageShow={pageShow} setPageShow={setPageShow} />
                        {pageShow === 'record' && <DoctorSchedule consultations={consultations} doctor={doctor} />}
                        {pageShow === 'about' && <DoctorAbout doctor={doctor} />}
                        {pageShow === 'reviews' && (
                            <DoctorReviews
                                reviews={reviews}
                                reviewsCount={count}
                                showMoreReviews={() => setOffset((prev) => prev + 3)}
                            />
                        )}
                    </div>
                </div>
            </DoctorProfilePageStl>
        </Container>
    );
};
