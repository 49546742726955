import styled from 'styled-components';

export const LoadFileStl = styled.div`
    height: auto;

    input {
        display: none;
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: #19be6f;
        font-size: 16px;
        cursor: pointer;
        position: relative;
        top: -60px;
        margin: 0 15px -30px auto;
        img {
            width: 100%;
        }
    }

    .files {
        width: calc(100% - 10px);
        display: flex;
        gap: 20px;
        height: 22px;
        padding-top: 24px;
        padding-bottom: 24px;

        @media (max-width: 500px) {
            height: unset;
            flex-wrap: wrap;
            padding-bottom: unset;
        }

        .fileWrapper {
            height: 22px;

            .progress {
                margin-top: 8px;
                position: relative;
                overflow: hidden;
            }
            .progress progress {
                position: absolute;
                width: 0;
                height: 0;
                overflow: hidden;
                left: -777px;
            }
            .progress-bar {
                overflow: hidden;
                background: #f4f4f4;
                width: 0;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            .progress-bg {
                background: #19be6f;
                position: relative;
                height: 4px;
                border-radius: 5px;
                overflow: hidden;
            }
        }

        .file {
            display: flex;
            height: 22px;
            align-items: center;
            gap: 8px;
            position: relative;
            cursor: pointer;

            > a > svg {
                width: 18px;
                height: 18px;
            }

            > a {
                text-decoration: none;
                display: flex;
                gap: 6px;
                align-items: center;
            }

            .file_name {
                width: 100%;
                font-size: 16px;
                color: #212121;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .error {
        font-size: 12px;
        color: #ff3636;
        margin-top: 8px;
    }
`;
