import { fileLoader, apiRequest } from '../../share/api';

// готово
/**
 * Отправляет GET запрос для получения личной и профессиональной информации о докторе (доступно авторизованному доктору, т.н. информация о себе)
 * @async
 * @function doctorMe
 * @returns {Promise<Object>} Личная и профессиональная информация о текущем докторе или ошибка.
 * Возвращает данные по схеме:
{
  "id": "cc3937cc-ab3a-48f4-912e-8e41f4ab3c78", // Уникальный идентификатор ДОКТОРА!
  "slug": "khirurgiia-kardiologiia-umnyi-doktor",
  "user": {
    "id": "ef169ffe-afef-46cd-993a-463dcf12e245", // Уникальный идентификатор ПОЛЬЗОВАТЕЛЯ!
    "username": "klim@32",
    "email": "klim@kli.kli",
    "phone": "+79787444144",
    "first_name": "Умный",
    "middle_name": "Очень-Добрый-Приятный",
    "last_name": "Доктор",
    "date_birth": "20.02.1960",
    "sex": "М",
    "city": null,
    "image_standart": "https://api.test.telemed.doct24.com/backend_media/image_standart/ava-2_rl3gvr6.png"
  },
  "specialization": [
    {
      "id": 3,
      "name": "Хирургия"
    },
    {
      "id": 1,
      "name": "Кардиология"
    }
  ],
  "diagnosis_and_treatment": "Болезни сердца",
  "price": 1000,
  "scientific_degree": "2",
  "category": "3",
  "education": [
    {
      "id": 0,
      "institution": {
        "name": "Институт кардиологии"
      },
      "specialty": {
        "name": "Кардиология"
      },
      "graduation_year": 2010,
      "education_image": "https://api.test.telemed.doct24.com/backend_media/education_image/ava-2.png"
    }
  ],
  "skills_improvement": [
   {
      "id": 0,
      "organization": {
        "name": "Институт кардиологии"
      },
      "program": {
        "name": "Повышение квалификации по кардиохирургии"
      },
      "graduation_year": 2015,
      "skills_improvement_image": "https://api.test.telemed.doct24.com/backend_media/skills_improvement_image/%D0%BA%D1%80%D0%BE%D1%88.png"
    }
  ],
  "work": [
   {
      "organization": {
        "name": "Медицинский центр"
      },
      "position": {
        "name": "Кардиолог"
      },
      "work_from": "01.05.2010",
      "work_to": "",
      "until_now": true
    }
  ],
  "is_approved": false
}
 */
export const doctorMe = async () => {
    try {
        const response = await apiRequest.get('doctors/me/');
        return response.data;
    } catch (error) {
        console.error(error.response.data);
        throw error.response ? error.response : { message: error.message };
    }
};

//готово
/**
 * Отправляет GET запрос для получения личных и профессиональных данных о докторе по id доктора
 * @async
 * @function doctorDataViaID
 * @param {number} id - Уникальный идентификатор ДОКТОРА.
 * @returns {Promise<Object>} Объект с информацией о докторе, полученный от API.
 * @throws {Error} Выбрасывает ошибку, если запрос не удался или сервер вернул некорректный ответ.
 * Возвращает объект по аналогии с @function doctorMe
 */
export const doctorDataViaID = async (id) => {
    try {
        const response = await apiRequest.get(`/doctors/${id}`);
        return response.data;
    } catch (error) {
        console.error(error.response.data);
        throw error.response ? error.response : { message: error.message };
    }
};

// готово
/**
 * Редактирование личных данных доктора с использованием метода PATCH.
 * @async
 * @function doctorDataUpdate
 * @param {Object} data - Данные для обновления профиля доктора.
 * @param {string} data.id - Уникальный идентификатор ПОЛЬЗОВАТЕЛЯ!
 * @param {string} data.phone - Номер телефона доктора.
 * @param {string} data.first_name - Имя доктора.
 * @param {string} data.last_name - Фамилия доктора.
 * @param {string} [data.middle_name] - Отчество доктора, необязательный параметр
 * @param {string} data.date_birth - Дата рождения доктора в формате DD.MM.YYYY (prev YYYY-MM-DD).
 * @param {string} data.sex - Пол доктора, например, 'М' или 'Ж'.
 * @param {string} [data.city] - Город доктора, необязательный параметр
 * @returns {Promise<Object>} Обновлённые данные доктора.
 * @throws {Error} Если запрос не был успешным или произошла ошибка при выполнении.
 * Возвращает объект по образцу:
 * {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",// Уникальный идентификатор ПОЛЬЗОВАТЕЛЯ!
    "username": "string",
    "email": "user@exampleemail.com",
    "phone": "+79167775544",
    "first_name": "string",
    "middle_name": "string",
    "last_name": "string",
    "date_birth": "2024-11-10",
    "sex": "М_или_Ж",
    "city": "string"
  }
 */
export const doctorDataUpdate = async (data) => {
    try {
        const response = await apiRequest.patch('/users/partial_update/', {
            phone: data.phone,
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            date_birth: data.date_birth,
            sex: data.sex,
            city: data.city,
        });

        if (response.status !== 200) {
            const error = new Error('Ошибка при редактировании личных данных доктора');
            error.response = response;
            throw error;
        }
        return response.data;
    } catch (error) {
        console.error('Error from doctorDataUpdate in api', error.response);
        throw error.response ? error : { message: error.message };
    }
};

//готово
/**
 * Частичное редактирование личных и профессиональных данных доктора с помощью PATCH-запроса к API по id доктора.
 * @async
 * @function doctorProffDataUpdate
 * @param {string} id - Уникальный идентификатор доктора, чьи данные необходимо обновить.
 * @param {{ user: Object, data: Object }} params - Параметры для обновления.
 * @param {Object} params.data - Объект, содержащий профессиональные данные доктора.
 * @param {Array} params.data.work - Список объектов работы доктора.
 * @param {Array} params.data.specialization - Список объектов специализаций доктора.
 * @param {string} params.data.scientific_degree - Ученая степень доктора.
 * @param {string} params.data.category - Категория доктора.
 * @param {string} params.data.diagnosis_and_treatment - Диагноз и лечение, предоставляемое доктором.
 * @param {number} params.data.price - Цена консультации доктора.
 *
 * @throws {Error} Генерирует ошибку, если произошла ошибка при выполнении запроса к API или если ответ не имеет статус 200.
 *
 * @returns {Promise<Object>} Обновлённые данные доктора, полученные от API.
 * Возвращает данные по схеме:
 * {
  "id": "02b90f88-0622-490c-b520-e6a123775fc9", // Уникальный идентификатор ДОКТОРА.
  "slug": "pediatr-uho-fiodor",
  "user": {
    "id": "ef169ffe-afef-46cd-993a-463dcf12e245", // Уникальный идентификатор ПОЛЬЗОВАТЕЛЯ!
    "username": "klim@32",
    "email": "klim@kli.kli",
    "phone": "+79787444144",
    "first_name": "Умный",
    "middle_name": "Очень-Добрый-Приятный",
    "last_name": "Доктор",
    "date_birth": "20.02.1960",
    "sex": "М",
    "city": null,
    "image_standart": "https://api.test.telemed.doct24.com/backend_media/image_standart/ava-2_rl3gvr6.png"
  },
  "specialization": [
    {
      "id": 17,
      "name": "Отоларингология"
    },
    {
      "id": 5,
      "name": "Педиатрия"
    }
  ],
  "diagnosis_and_treatment": "Детские уши, горлы и носы",
  "price": 500,
  "scientific_degree": "1",
  "category": "2",
  "education": [
    {
      "id": 1,
      "institution": {
        "name": "Красноярский государственный медицинский университет имени профессора В. Ф. Войно-Ясенецкого"
      },
      "specialty": {
        "name": "Ортопедическая стоматология"
      },
      "graduation_year": 2003,
      "education_image": ""
    }
  ],
  "skills_improvement": [
    {
      "id": 1,
      "organization": {
        "name": "Национальный медицинский исследовательский центр кардиологии - Центр повышения квалификации"
      },
      "program": {
        "name": "Программа повышения квалификации по педиатрии"
      },
      "graduation_year": 2010,
      "skills_improvement_image": ""
    },
  "work": [
    {
      "organization": {
        "name": "Клиника восстановительной медицины"
      },
      "position": {
        "name": "Детский врач"
      },
      "work_from": "02.02.2010",
      "work_to": "",
      "until_now": true
    }
  ],
   "is_approved": false,
}
*/
export const doctorProffDataUpdate = async (id, data) => {
    try {
        const updatedData = {
            work: data.work,
            specialization: data.specialization,
            scientific_degree: data.scientific_degree,
            category: data.category,
            diagnosis_and_treatment: data.diagnosis_and_treatment,
            price: data.price,
        };

        const response = await apiRequest.patch(`/doctors/${id}/`, updatedData);

        if (response.status !== 200) {
            const error = new Error('Ошибка при редактировании данных доктора');
            error.response = response;
            throw error;
        }
        return response.data;
    } catch (error) {
        console.error('Ошибка из doctorProffDataUpdate', error.response);
        throw error.response ? error : { message: error.message };
    }
};

// готово
/**
 * Отправляет GET запрос для получения списка докторов из API и возвращает объект с данными.
 * @async
 * @function doctorsDataList
 * @returns {Promise<Object>} Информация о текущем пользователе или ошибка.
 * Возвращаемый объект имеет следующую структуру:
 * {
 *   count: number, // Общее количество докторов
 *   next: string | null, // URL для получения следующей страницы результатов
 *   previous: string | null, // URL для получения предыдущей страницы результатов
 *   results: Array<Object> // Массив объектов докторов
 * }
 * @throws {Error} Генерирует ошибку, если произошла ошибка при выполнении запроса к API.
 *
 * @returns {Promise<Object>} Врзвращает объект, содержащий список докторов и связанную информацию.
 * Возвращает данные по схеме:
 {
  "count": 17,
  "next": "https://api.test.telemed.doct24.com/api/doctors/?limit=14&offset=14",
  "previous": null,
  "results": [
    {
      "id": "02b90f88-0622-490c-b520-e6a123775fc9",
      "slug": "pediatr-uho-fiodor",
      "user": {
        "id": "4246f3cf-d8b4-45f6-8b3b-d922070b5581",
        "username": "vrachch",
        "first_name": "Фёдор",
        "middle_name": "Иванович",
        "last_name": "Ухо-Горло-Носов",
        "image_standart": "https://api.test.telemed.doct24.com/backend_media/image_standart/ava-3_dJUEOWG.png"
      },
      "specialization": [
        "Отоларингология",
        "Педиатрия"
      ],
      "diagnosis_and_treatment": "Детские уши, горлы и носы",
      "price": 500,
      "scientific_degree": "1",
      "category": "2",
      "education": [
        {
          "institution": {
            "name": "Санкт-Петербургский государственный медицинский университет имени академика И. П. Павлова"
          },
          "specialty": {
            "name": "Педиатрия"
          },
          "graduation_year": 2000,
          "education_image": "https://api.test.telemed.doct24.com/backend_media/education_image/ava-3.png"
        }
      ],
      "skills_improvement": [
        {
          "organization": {
            "name": "Академия постдипломного образования врачей имени И.М. Сеченова"
          },
          "program": {
            "name": "Программа повышения квалификации по педиатрии"
          },
          "graduation_year": 2022,
          "skills_improvement_image": "https://api.test.telemed.doct24.com/backend_media/skills_improvement_image/ava-2.png"
        }
      ],
      "work": [
        {
          "organization": {
            "name": "Клиника восстановительной медицины"
          },
          "position": {
            "name": "Детский врач"
          },
          "work_from": "02.02.2010",
          "work_to": "02.02.2020",
          "until_now": false
        }
      ],
      "is_approved": true,
      "rating_average": 10,
      "seniority": 10
    }, и тд
  ]
}
 */

export const doctorsDataList = async () => {
    try {
        const response = await apiRequest.get(`/doctors/`);
        return response.data;
    } catch (error) {
        console.error(error.response.data);
        throw error.response ? error.response : { message: error.message };
    }
};

//-----------------------------------------------------------------------
/**
 * Отправляет данные об образовании врача на сервер для создания новой записи.
 * @async
 * @function postDrEducation
 * @param {Object} data - Данные об образовании врача.
 * @param {Object} data.institution - Объект с информацией об учебном заведении.
 * @param {string} data.institution.name - Название учебного заведения.
 * @param {Object} data.specialty - Объект с информацией о специальности.
 * @param {string} data.specialty.name - Название специальности.
 * @param {number} data.graduation_year - Год окончания.
 * @param {File} [data.education_image] - Изображение сертификата или диплома (опционально).
 * @returns {Promise<Object>} Ответ от сервера с данными созданной записи.
 * @throws {Object} Ошибка с информацией о запросе, если загрузка не удалась.
 * @example
 * await postDrEducation({
 *   institution: { name: 'Медицинский университет' },
 *   specialty: { name: 'Терапия' },
 *   graduation_year: 2020,
 *   education_image: file // File объект
 * });
 */
export const postDrEducation = async (data) => {
    try {
        const formData = new FormData();

        formData.append('institution', JSON.stringify({ name: data.institution.name }));
        formData.append('specialty', JSON.stringify({ name: data.specialty.name }));
        formData.append('graduation_year', data.graduation_year);

        if (data.education_image) {
            formData.append('education_image', data.education_image);
        }

        const response = await fileLoader.post(`/doctor_education/`, formData);
        return response.data;
    } catch (error) {
        console.error('Error in postDrEducation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Обновляет данные об образовании врача на сервере.
 * @async
 * @function patchDrEducation
 * @param {Object} data - Данные для обновления.
 * @param {number} data.id - Идентификатор записи об образовании врача.
 * @param {Object} data.institution - Объект с информацией об учебном заведении.
 * @param {string} data.institution.name - Название учебного заведения.
 * @param {Object} data.specialty - Объект с информацией о специальности.
 * @param {string} data.specialty.name - Название специальности.
 * @param {number} data.graduation_year - Год окончания.
 * @param {boolean} data.delete_education_image - Указывает на необходимость удаления изображения сертификата на сервере.
 * @param {File|string} [data.education_image] - Новое изображение сертификата или пустая строка для удаления.
 * @returns {Promise<Object>} Ответ от сервера с обновленными данными.
 * @throws {Object} Ошибка с информацией о запросе, если обновление не удалось.
 * @example
 * await patchDrEducation({
 *   id: 1,
 *   institution: { name: 'Медицинский университет' },
 *   specialty: { name: 'Терапия' },
 *   graduation_year: 2020,
 *   delete_education_image: false,
 *   education_image: file // File объект
 * });
 */
export const patchDrEducation = async (data) => {
    try {
        const formData = new FormData();

        formData.append('institution', JSON.stringify({ name: data.institution.name }));
        formData.append('specialty', JSON.stringify({ name: data.specialty.name }));
        formData.append('graduation_year', data.graduation_year);
        formData.append('delete_education_image', data.delete_education_image);

        if (data.education_image) {
            formData.append('education_image', data.education_image);
        }

        const response = await fileLoader.patch(`/doctor_education/${data.id}/`, formData);
        return response.data;
    } catch (error) {
        console.error('Error in patchDrEducation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

// в текущем коде не исползуется, написано для следующего релиза
/**
 * Удаляет запись об образовании врача на сервере.
 * @async
 * @function deleteDrEducation
 * @param {number} id - Идентификатор записи об образовании.
 * @returns {Promise<Object>} Ответ от сервера, если удаление прошло успешно.
 * @throws {Object} Ошибка с информацией о запросе, если удаление не удалось.
 * @example
 * await deleteDrEducation(1);
 */
export const deleteDrEducation = async (id) => {
    try {
        const response = await apiRequest.delete(`/doctor_education/${id}/`);
        return response;
    } catch (error) {
        console.error('Error in deleteDrEducation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

//-----------------------------------------------------------------------
/**
 * Отправляет данные о повышении квалификации врача на сервер для создания новой записи.
 * @async
 * @function postDrSkillsImprovement
 * @param {Object} data - Данные о повышении квалификации.
 * @param {Object} data.organization - Объект с информацией об организации.
 * @param {string} data.organization.name - Название организации.
 * @param {Object} data.program - Объект с информацией о программе обучения.
 * @param {string} data.program.name - Название программы.
 * @param {number} data.graduation_year - Год окончания.
 * @param {File} [data.skills_improvement_image] - Изображение сертификата о повышении квалификации (опционально).
 * @returns {Promise<Object>} Ответ от сервера с данными созданной записи.
 * @throws {Object} Ошибка с информацией о запросе, если загрузка не удалась.
 * @example
 * await postDrSkillsImprovement({
 *   organization: { name: 'Медицинский центр' },
 *   program: { name: 'Повышение квалификации' },
 *   graduation_year: 2021,
 *   skills_improvement_image: file // File объект
 * });
 */
export const postDrSkillsImprovement = async (data) => {
    try {
        const formData = new FormData();

        formData.append('organization', JSON.stringify({ name: data.organization.name }));
        formData.append('program', JSON.stringify({ name: data.program.name }));

        formData.append('graduation_year', data.graduation_year);

        if (data.skills_improvement_image) {
            formData.append('skills_improvement_image', data.skills_improvement_image);
        }

        const response = await fileLoader.post(`/doctor_skills_improvement/`, formData);
        return response.data;
    } catch (error) {
        console.error('Error in postDrSkillsImprovement', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Обновляет данные о повышении квалификации врача на сервере.
 * @async
 * @function patchDrSkillsImprovement
 * @param {Object} data - Данные для обновления.
 * @param {number} data.id - Идентификатор записи о повышении квалификации.
 * @param {Object} data.organization - Объект с информацией об организации.
 * @param {string} data.organization.name - Название организации.
 * @param {Object} data.program - Объект с информацией о программе обучения.
 * @param {string} data.program.name - Название программы.
 * @param {number} data.graduation_year - Год окончания.
 * @param {boolean} data.delete_skills_improvement_image - Указывает на удаление изображения сертификата.
 * @param {File|string} [data.skills_improvement_image] - Новое изображение сертификата или пустая строка для удаления.
 * @returns {Promise<Object>} Ответ от сервера с обновленными данными.
 * @throws {Object} Ошибка с информацией о запросе, если обновление не удалось.
 * @example
 * await patchDrSkillsImprovement({
 *   id: 1,
 *   organization: { name: 'Медицинский центр' },
 *   program: { name: 'Повышение квалификации' },
 *   graduation_year: 2021,
 *   delete_skills_improvement_image: true,
 *   skills_improvement_image:  file // File объект
 * });
 */
export const patchDrSkillsImprovement = async (data) => {
    try {
        const formData = new FormData();

        formData.append('organization', JSON.stringify({ name: data.organization.name }));
        formData.append('program', JSON.stringify({ name: data.program.name }));
        formData.append('graduation_year', data.graduation_year);
        formData.append('delete_skills_improvement_image', data.delete_skills_improvement_image);

        if (data.skills_improvement_image) {
            formData.append('skills_improvement_image', data.skills_improvement_image);
        }

        const response = await fileLoader.patch(`/doctor_skills_improvement/${data.id}/`, formData);
        return response.data;
    } catch (error) {
        console.error('Error in patchDrSkillsImprovement', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Удаляет запись о повышении квалификации врача на сервере.
 * @async
 * @function deleteDrSkillsImprovement
 * @param {number} id - Идентификатор записи о повышении квалификации.
 * @returns {Promise<Object>} Ответ от сервера, если удаление прошло успешно.
 * @throws {Object} Ошибка с информацией о запросе, если удаление не удалось.
 * @example
 * await deleteDrSkillsImprovement(1);
 */
export const deleteDrSkillsImprovement = async (id) => {
    try {
        const response = await apiRequest.delete(`/doctor_skills_improvement/${id}/`);
        return response;
    } catch (error) {
        console.error('Error in deleteDrSkillsImprovement', error.response);
        throw error.response ? error : { message: error.message };
    }
};

// ---------------------------------------------------------------------
// КОНСУЛЬТАЦИИ - НОВЫЕ ЭНД-ПОИНТЫ & МЕТОДЫ

/**
 * Получает список консультаций с учетом статуса, даты, лимита и ссылки на страницу.
 *
 * @async
 * @function getConsultations
 * @param {Object} params - Объект параметров для запроса.
 * @param {boolean} [params.is_payed=null] - Фильтр по статусу оплаты консультации (true - оплаченные, false - неоплаченные).
 * @param {number} [params.limit=3] - Максимальное количество объектов на странице.
 *                                    (Примечание: для фильтрации в экстраредьюсере рекомендуется использовать лимит 20.)
 * @param {string} params.date - Поле сортировки по дате (например, `created_at` или `updated_at`).
 * @param {string|number|Array<string|number>|null} [params.status=null] - Статус(ы) для фильтрации. Может быть строкой, числом или массивом значений.
 * @param {string|null} [params.pageLink=null] - Ссылка на конкретную страницу для пагинации.
 *                                               Если указана, другие параметры игнорируются.
 * @returns {Promise<Object>} Объект с данными о консультациях, включая пагинацию:
 * - `count` (number): Общее количество найденных объектов.
 * - `next` (string|null): Ссылка на следующую страницу (если есть).
 * - `previous` (string|null): Ссылка на предыдущую страницу (если есть).
 * - `results` (Array<Object>): Список найденных объектов.
 * @throws {Error} Выбрасывается в случае ошибки запроса. Дополнительная информация содержится в сообщении ошибки.
 */
export const getConsultations = async ({ is_payed = null, limit = 3, consultation_time, status = null, pageLink = null }) => {
    try {
        if (pageLink) {
            return await apiRequest.get(pageLink);
        }

        /**  Формирование URL динамически, исключая статус, если он не задан */
        let url = `/consultations/?limit=${limit}&offset=0&ordering=${consultation_time}`;

        if (is_payed !== null) {
            url += `&is_payed=${is_payed}`;
        }

        if (status !== null && (typeof status === 'string' || typeof status === 'number')) {
            url += `&status=${status}`;
        }

        if (status !== null && Array.isArray(status)) {
            status.forEach((item) => {
                url += `&status=${item}`;
            });
        }

        const result = await apiRequest.get(url);

        return result;
    } catch (error) {
        console.error('Ошибка при загрузке списка консультаций', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Отправляет запрос на отмену консультации по её идентификатору.
 * @async @function сancelConsultation
 * @param {number|string} consultationId - Идентификатор консультации, которую необходимо отменить.
 * @returns {Promise<Object>} Ответ сервера после отмены консультации.
 * @throws {Error} Если произошла ошибка при выполнении запроса.
 *
 * @description
 * Выполняет PATCH-запрос к API на отмену указанной консультации.
 * Возвращает данные из ответа API: Статус меняется на '2'-'Отменена'.
 * Создается и возвращается объект консультации с новым атрибутом registration_log.
 * Вщзвращаемый объект консультации имеет структуру, аналогичную объекту в ответе на запрос get на api/consultations/ (метод consultations)
 * Если запрос завершится ошибкой, выводит сообщение об ошибке в консоль и выбрасывает исключение с описанием проблемы.
 */
export const сancelConsultation = async (consultationId) => {
    try {
        const result = await apiRequest.patch(`/consultations/${consultationId}/cancel/`);
        return result;
    } catch (error) {
        console.error('Ошибка при отмене консультации', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Получает данные о конкретной консультации по её идентификатору.
 * @async @function getConsultation
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект с данными консультации - см InitialState - lkDoctorConsultationsSlice
 * @throws {Error} Если произошла ошибка при выполнении запроса.
 *
 * @description
 * Отправляет GET-запрос на сервер для получения информации о консультации.
 * Если запрос завершается ошибкой, выводит сообщение в консоль и выбрасывает ошибку.
 *
 */
export const getConsultation = async (consultationId) => {
    try {
        const result = await apiRequest.get(`/consultations/${consultationId}/`);
        return result;
    } catch (error) {
        console.error('Ошибка при загрузке консультации', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для получения отзыва по идентификатору консультации.
 * @async @function
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные отзыва.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 */

export const getFeedback = async (feedbackId) => {
    try {
        const result = await apiRequest.get(`/doctors_feedbacks/${feedbackId}/`);
        return result;
    } catch (error) {
        console.error('Ошибка при загрузке отзыва', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для изменения статуса консультации на "началась" (со статуса 0 на статус 3).
 *@async @function changeStatusToStartedDoctor
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 * Статус консультации меняется на '3'-'Началась' при условии, что оба участника консультации присоединились
 * (то есть  "doctor_has_joined": true и "patient_has_joined": true)
 * Возвращаемый объект:
 * {
 * "id": 0,
 * "status": 0,
 * "joining": {
 *   "doctor_has_joined": true,
 *   "patient_has_joined": true
 *   }
 * }
 */
export const changeStatusToStartedDoctor = async (consultationId) => {
    try {
        const result = await apiRequest.patch(`/consultations/${consultationId}/started/`);
        // // console.log('НАЧАЛАСЬ У ВРАЧА!!!!!!!!!!!!!!!!!!!', result);
        return result;
    } catch (error) {
        console.error('Ошибка при переводе консультации в начавшиеся', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для изменения статуса консультации на "завершено" (со статуса 3 на статус 1).
 *@async @function changeStatusToPassedDoctor
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 * Статус консультации меняется на '1'-'Состоялась' .
 * Возвращаемый объект:
 * {
 *  "id": 0,
 *  "status": 0,
 *  "actual_duration": 0,
 *  "joining": {
 *    "doctor_has_joined": true,
 *    "patient_has_joined": true,
 *    "join_log": "string"
 *   }
 * }
 */
export const changeStatusToPassedDoctor = async (consultationId) => {
    try {
        const result = await apiRequest.patch(`/consultations/${consultationId}/passed/`);
        // // console.log('ЗАВЕРШИЛАСЬ У ВРАЧА!!!!!!!!!!!');
        return result;
    } catch (error) {
        console.error('Ошибка при переводе консультации в завершенные', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для проверки статуса консультации.
 *@async @function checkConsultationStatus
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 * Возвращаемый объект:
 * {
 * "id": 0,
 * "status": 0,
 * "actual_duration": 0
 * }
 */
export const checkConsultationStatus = async (consultationId) => {
    try {
        const result = await apiRequest.get(`/check_consultations_status/${consultationId}/`);
        // // console.log('ПРОВЕРКА СТАТУСА У ВРАЧА ');
        return result;
    } catch (error) {
        console.error('Ошибка при проверке статуса консультации', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для отправки врачом рекомендаций после консультации.
 *@async @function
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @param {string} date.text - Текст рекомендации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 * {
 * 'id': 3,
 * 'text': 'Приложить поддорожник'
 * 'status': 'send'
 * }
 */

export const setRecommendation = async ({ consultationId, text }) => {
    try {
        const response = await apiRequest.post(`consultations/${consultationId}/recommendation/`, { text });
        return response;
    } catch (error) {
        console.error('Ошибка в setRecommendation', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Отправка файла для рекомендации после консультации по её идентификатору.
 *
 * @async
 * @function consultationSendFile
 * @param {Object} params - Параметры для отправки файла.
 * @param {string} params.consultationId - Идентификатор консультации, к которой прикрепляется файл.
 * @param {File} params.file - Файл для загрузки.
 * @returns {Promise<Object>} Ответ от сервера с данными файла после загрузки.
 * @throws {Object} Если произошла ошибка при загрузке, выбрасывается ошибка с описанием.
 */
export const consultationSendFile = async ({ consultationId, file, onProgress }) => {
    try {
        if (!file) {
            throw new Error('No file provided for upload.');
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await fileLoader.post(`/consultations/${consultationId}/files/`, formData, {
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const totalLength = progressEvent.total;
                    const progress = Math.round((progressEvent.loaded * 100) / totalLength);
                    if (onProgress) {
                        onProgress(progress); // Передаём прогресс через callback
                    }
                }
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Удаление файла из рекомендаций после консультации по её идентификатору.
 *
 * @async
 * @function consultationDeleteFile
 * @param {string} fileId - Идентификатор файла, который нужно удалить.
 * @returns {Promise<Object>} Ответ от сервера об успешном удалении файла.
 * @throws {Object} Если произошла ошибка при удалении файла, выбрасывается ошибка с описанием.
 */
export const consultationDeleteFile = async (fileId) => {
    try {
        const response = await apiRequest.delete(`consultations/files/${fileId}/`);
        return response;
    } catch (error) {
        console.error('Failed to delete file in consultation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

// ------------------------------------------------------------
// старые методы
// отмена консультации
export const setCancelDoctorConsultation = async (id) => {
    return await apiRequest.patch(`/consultations/cancel/${id}`);
};
{
    /* Получение пациента по ID если была хотя бы одна консультация */
}
export const getPatient = async (id) => {
    const result = await apiRequest.get(`patients/${id}`);
    return result;
};
{
    /* Получение списка консультаций врача */
}
export const getDoctorConsultations = async ({ status = 0, date = 'date', next = null }) => {
    if (next) {
        return await apiRequest.get(next);
    }
    const result = await apiRequest.get(`/consultations/doctor/?status=${status}&ordering=${date}`);
    return result;
};
{
    /* Получение списка консультаций врача по дате*/
}
export const getDoctorConsultationsCurrentDay = async (date) => {
    const result = await apiRequest.get(`/consultations/doctor/?page_size=50&by_date=${date}`);
    return result;
};
/**
 * Получение всех консультаций врача по статусу.
 * 0 - новая,
 * 1 - состоялась,
 * 2 - отменена,
 * 9 - не проводилась.
 */
export const getDoctorConsultationsByStatus = async (...args) => {
    const result = await apiRequest.get(`/consultations/doctor/?status=${args.join(',')}`);
    return result;
};
/**
 * Получение всех консультаций врача.
 */
export const getDoctorAllConsultations = async () => {
    const result = await apiRequest.get(`/consultations/doctor/?page_size=50`);
    return result;
};

{
    /* Получение банковских реквизитов врача */
}
export const getDoctorBankDetails = async () => {
    const result = await apiRequest.get(`/doctors/bank_details/`);
    return result;
};
{
    /* Изменение банковских реквизитов врача */
}
export const setDoctorBankDetails = async (data) => {
    const result = await apiRequest.post(`/doctors/bank_details/`, data);
    return result;
};
// ------------------------------------------------------------

/**
 * Получает данные о всех временных слотах доктора.
 * @async @function getDoctorReceptions
 * @returns {Promise<Object>} Возвращает объект с временным слотом и информацией о консультации:
 * [
  {
    "reception_time": "2025-02-09T20:51:46.461Z",
    "consultation": {
      "consultation_id": 0,
      "patient_name": "string",
      "status": 0,
      "is_payed": true
    },
      {
    "reception_time": "2025-02-09T20:51:46.461Z",
    "consultation": null
  }
]
 * @throws {Error} Если произошла ошибка при выполнении запроса.
 *
 * @description
 * Отправляет GET-запрос на сервер.
 * Возвращает массив объектов, где каждый элемент - слот времени с информацией о консультации.
 * Если запрос завершается ошибкой, выводит сообщение в консоль и выбрасывает ошибку.
 *
 */

export const getDoctorReceptions = async () => {
    try {
        const result = await apiRequest.get(`/reception_time`);
        return result;
    } catch (error) {
        console.error('Failed to get reception times', error.result);
        throw error.result ? error : { message: error.message };
    }
};

/**
 * Создание расписания доктора.
 * @async @function createDoctorReception
 * @returns {Promise<Object>} Возвращает массив reception_time доктора
 *
 * @description
 * Отправляет POST-запрос на сервер.
 * Возвращает массив reception_time
 * Если запрос завершается ошибкой, выводит сообщение в консоль и выбрасывает ошибку.
 * "reception_time": "30.11.2024 23:30:00"
 */

export const createDoctorReception = async (reception_times) => {
    try {
        const result = await apiRequest.post(`/reception_time/doctor/generate`, reception_times);

        if (result.status !== 201) {
            throw new Error('Ошибка при создании расписания доктора');
        }
        return result.data;
    } catch (error) {
        console.error('Ошибка из createDoctorReception', error.response ? error.response.data : error.message);
        throw error.response ? error.response.data : { message: error.message };
    }
};

// ------------------------------------------------------------

/**
 * Добавление записи врача. - НЕ БУДЕТ РАБОТАТЬ _ ПЕРЕПИСАТЬ
 * @param {number} timestamp временная метка в секундах.
 */
export const setDoctorReception = async (timestamp) => {
    const result = await apiRequest.post(`reception_time/`, {
        time: timestamp,
    });
    return result;
};

export const updateDoctorReceptions = async (data) => {
    const result = await apiRequest.post(`reception_time/update/`, {
        begin_time: data.begin ?? 0,
        finish_time: data.finish ?? 0,
        reception_times: data.timeStamps,
    });
    return result;
};

/**
 * Удаление записи из расписания врача. - ПОДУМАТЬ КАК ДЕЛАТЬ УДАЛЕНИЕ, ВОЗМОЖНО БЕЗ ДЕЛЕТЕ
 * Удаление успешно, только если нет консультации.
 * @param {number} id Id записи.
 */
export const deleteDoctorReceptionById = async (id) => {
    await apiRequest.delete(`reception_time/${id}`);
};

{
    /* Получение списка консультаций пациента с врачем */
}
export const getConsultationsByID = async (id) => {
    const result = await apiRequest.get(`/consultations/doctor/patient_consultations/${id}`);
    return result;
};

{
    /* Получение списка платежей врача */
}

export const getPayments = async (data, next) => {
    if (next) {
        return await apiRequest.get(next);
    }
    if (data) {
        const { year, month } = data;
        if (month) {
            return await apiRequest.get(`/payments/doctor/?year=${year}&month=${month}`);
        }
        return await apiRequest.get(`/payments/doctor/?year=${year}`);
    }
    return await apiRequest.get(`/payments/doctor`);
};

{
    /* Получение консультации доктора по id*/
}
export const getDoctorConsultation = async (consultationId) => {
    return await apiRequest.get(`/consultations/doctor/${consultationId}`);
};

// Изменение рекомендации для консультации пациента(консультация состоялась)

export const updateConsultationInfo = async ({ id, str }) => {
    return await apiRequest.patch(`/consultations/doctor/${id}`, {
        status: 1,
        recommendation: str,
    });
};

/**
 * Получение пациентов доктора.
 */
export const getAllPatientsOfDoctor = async (search, next = '') => {
    if (next) {
        return await apiRequest.get(next);
    }
    return await apiRequest.get(`patients/doctor/list/?page_size=6&search=${search}`);
};
