import { useState, useEffect } from 'react';
import { calculateTimeDifference } from '../componets/share/helpers';

/**
 * Кастомный хук для отслеживания оставшегося или прошедшего времени.
 *
 * @param {Date | string} date - Целевая дата, до/после которой нужно считать время.
 * @param {number} intervalMs - Интервал обновления (в миллисекундах), по умолчанию 1 секунда.
 * @param {boolean} trackSeconds - Флаг для отслеживания секунд (по умолчанию true).
 * @returns {object} Объект с минутами, секундами, флагом isExpired: { minutes, seconds, isExpired }
 */
export const useTimer = ({ date, intervalMs = 1000, trackSeconds = true }) => {
    const [timeData, setTimeData] = useState(() => calculateTimeDifference(date, trackSeconds));

    useEffect(() => {
        const updateTime = () => {
            setTimeData(calculateTimeDifference(date, trackSeconds));
        };

        const intervalId = setInterval(updateTime, intervalMs);

        updateTime(); // Обновляем время сразу после монтирования.

        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании.
    }, [date, intervalMs, trackSeconds]);

    return timeData;
};
