import styled from 'styled-components';

export const InputFileWrapper = styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    .custom-file-button {
        display: inline-block;
        background-color: transparent;
        cursor: pointer;
        & .inputFileWrapper__heading {
            color: #19be6f;
            .span {
                font-size: 18px;
                color: #19be6f;
            }
        }
    }

    .custom-file-button span {
        pointer-events: none;
    }

    input[type='file'] {
        position: absolute;
        inset: 0;
        opacity: 0;
        width: 0;
        cursor: pointer;
    }
    .certificate-block {
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
            cursor: pointer;
        }
    }

    .certificate-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .error-message {
        font-size: 12px;
        color: #ff3636;
    }
    .inputFileWrapper__red {
        color: #ff3636;
    }
`;
