import React from 'react';
import { QuoteStyled } from './quoteStyled';
import { ReactComponent as QuotesIcon } from '../../../../img/newsImg/newsIcons/newsPhil-icon.svg';

/**
 * Компонент Quote
 *
 * React-компонент для отображения цитаты с текстом и автором, а также иконкой в виде кавычек.
 *
 * @component
 * @param {Object} props - Свойства, переданные в компонент.
 * @param {Object} props.quote - Объект с данными о цитате.
 * @param {string} [props.quote.text] - Текст цитаты.
 * @param {string} [props.quote.author] - Автор цитаты.
 *
 * @returns {JSX.Element} JSX-разметка компонента.
 */
export const Quote = ({ quote }) => {
    return (
        <QuoteStyled>
            <div className="quotes">
                <QuotesIcon />
            </div>
            <p className="text">{quote?.text}</p>
            <p className="author">{quote?.author}</p>
        </QuoteStyled>
    );
};
