import React from 'react';
import Button from '../../share/Button';
import { DescrCard } from '../../about/share/Titles';
import star from '../../../img/icons/star.png';
import emptyStar from '../../../img/icons/emptyStar.svg';
import { Basic_grey } from '../../../vendor/styles/baseColors';
import { useNavigate } from 'react-router-dom';
import Flex from '../../share/Flex';
import { CardDoctorText, CardImage, CardOptionsNote, CardWrapper, Rating, StarStl } from './styled.SearchDoctorCard';
import { useSelector } from 'react-redux';
import { getDoctorCategory } from '../../share/helpers';
import PhotoAlt from '../../../img/photo_alt.svg';

/**
 * Компонент карточки врача для страницы Результат поиска
 * При клике на карточку переходит на страницу профиля врача
 * При клике на запись к врачу переходит на страницу профиля врача
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {number} props.id - Уникальный идентификатор карточки врача
 * @param {Object} props.doctor - Объект с данными врача
 * @param {Object} props.doctor.user - Информация о пользователе (враче)
 * @param {string} props.doctor.user.first_name - Имя врача
 * @param {string} props.doctor.user.last_name - Фамилия врача
 * @param {string} props.doctor.user.middle_name - Отчество врача
 * @param {string} [props.doctor.user.image_standart] - URL аватара врача
 * @param {string} props.doctor.scientific_degree - Научная степень врача (1 - к.м.н., 2 - д.м.н., или пустая строка)
 * @param {string} props.doctor.category - Категория врача
 * @param {Object[]} props.doctor.specialization - Специализации врача
 * @param {string} props.doctor.specialization[].name - Название специализации
 * @param {number} [props.doctor.rating_average] - Средний рейтинг врача
 * @param {number} [props.doctor.price] - Цена услуги врача в рублях
 *
 * @returns {JSX.Element} JSX-разметка карточки врача
 */
const DoctorSearchCard = ({ id, doctor }) => {
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const navigate = useNavigate();
    const scientific_degree =
        doctor.scientific_degree === '1' ? 'к.м.н' : doctor.scientific_degree === '2' ? 'д.м.н' : '';
    const categoryAndScientificDegree = scientific_degree
        ? `${getDoctorCategory(doctor.category)}, ${scientific_degree}`
        : `${getDoctorCategory(doctor.category)}`;

    return (
        <>
            <CardWrapper onClick={() => navigate(`/doctors/${doctor.id}/${doctor.slug}`)}>
                <CardImage image={doctor.user.image_standart}>
                    <img src={doctor.user.image_standart ? doctor.user.image_standart : PhotoAlt} alt="doctor_avatar" />
                    {/* {whoAreYou === 'patient' &&
                        <>
                            {props.isFavorite?.id ? (
                                <Like
                                    imgSrc={cardHeartOutlineFilled}
                                    setFavorite={() => props.delFavorite(props.isFavorite.id)}
                                    altText="like"
                                />
                            ) : (
                                <Like imgSrc={cardHeartOutlineEmpty} setFavorite={() => props.setFavorite()} altText="like" />
                            )}
                        </>
                    } */}
                </CardImage>
                <div className="doctorsCard_top">
                    <CardDoctorText>
                        <div className="doctor_name">
                            {doctor.user.last_name} {doctor.user.first_name} {doctor.user.middle_name}
                        </div>
                        <Flex minWidth="fit-content" align="baseline" justContent="flex-end">
                            <StarStl src={doctor.rating_average ? star : emptyStar} alt="doctor rating star" />
                            {doctor.rating_average && (
                                <Rating aria-label="doctor rating">{doctor.rating_average}</Rating>
                            )}
                        </Flex>
                    </CardDoctorText>

                    <CardOptionsNote
                        padding="0"
                        justContent="space-between"
                        style={{ flexDirection: 'column' }}
                    >
                        <DescrCard lineHeight="130%" color={Basic_grey}>
                            <ul>{doctor.specialization.map((item, i, arr) => {
                                    return (
                                        <li key={i}>
                                            {item.name}
                                        </li>
                                    );
                                })}
                            </ul>
                            <p className="doctor_category">{categoryAndScientificDegree}</p>
                        </DescrCard>
                        {doctor.price ? (
                            <DescrCard flex="flex" alignItems="flex-end" width="fit-content">
                                <p className="price">{`${doctor.price} руб.`}</p>
                            </DescrCard>
                        ) : (
                            <p className="price">&nbsp;</p>
                        )}
                    </CardOptionsNote>

                    <CardOptionsNote padding="0" bottom="0px" style={{ marginTop: '10px' }}>
                        <div
                            onClick={() =>
                                navigate(`/doctors/${doctor.id}/${doctor.slug}`, { state: { id: doctor.id } })
                            }
                            style={{ width: '100%' }}
                        >
                            <Button margin="0" green>
                                Записаться
                            </Button>
                        </div>
                    </CardOptionsNote>
                </div>
            </CardWrapper>
        </>
    );
};

export default DoctorSearchCard;
