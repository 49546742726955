import { apiRequest } from '../../share/api';

/**
 * Отправляет GET запрос на получение списка тем новостей.
 * @async
 * @function getThemes
 */

export const getThemes = async () => {
    try {
        const response = await apiRequest.get('/news/themes/');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error.response ? error.response : { message: error.message };
    }
};

/**
 * Отправляет GET запрос на получение список постов
 * @async
 * @function getPosts
 * @param {data} data.themeId - возвращаются посты по конкретной теме.
 */

export const getPosts = async (data, next = '') => {
    try {
        if (!data.themeId) {
            const response = await apiRequest.get('/news/posts/');
            return response.data;
        }
        if (data.getMorePosts && next) {
            const response = await apiRequest.get(next);
            return response.data;
        }
        const response = await apiRequest.get(`/news/posts/?limit=3&theme=${data.themeId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch posts');
    }
};

/**
 * Отправляет GET запрос на получение данных статьи и комментарии к ней
 * @async
 * @function getArticlePage
 * @param {articleId} articleId - загружаются статья и комментарии к ней по articleId
 */

export const getArticlePage = async (articleId, next = '') => {
    try {
        if (next) {
            const comments = await apiRequest.get(next);
            const article = '';
            return { comments, article };
        }
        const comments = await apiRequest.get(`/news/post_chat/${articleId}/?limit=3`);
        const article = await apiRequest.get(`/news/posts/${articleId}/`);
        return { comments, article };
    } catch (error) {
        console.error(error);
        throw new Error('Failed to fetch article page');
    }
};

/**
 * Отправляет POST запрос на добавление комментария к посту
 * @async
 * @function postComments
 * @param {Object} data - Данные комментария, включая post_id и текст.
 * @returns {Promise<Object>} - Ответ с данными, если запрос успешен.
 * @throws {Error} - Если произошла ошибка при добавлении комментария.
 */

export const postComments = async (data) => {
    try {
        const response = await apiRequest.post(`/news/post_chat/${data.post_id}/`, { text: data.text });
        return response.data;
    } catch (error) {
        console.error('Error in postComments API call:', error.response || error.message);
        throw new Error(error.response?.data?.message || 'Ошибка при добавлении комментария');
    }
};

/**
 * Отправляет GET запрос на получение цитат
 * @async
 * @function getQuotes
 */

export const getQuotes = async () => {
    try {
        const response = await apiRequest.get('/system/text/quotes/');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error.response ? error.response : { message: error.message };
    }
};
