import { apiRequest } from '../../share/api';

/**
 * Получает список врачей с возможностью фильтрации и пагинации.
 *
 * @async
 * @function getAllDoctors
 * @param {Object} data - Данные для фильтрации и пагинации.
 * @param {number} [data.limit] - Максимальное количество результатов для возвращения.
 * @param {number} [data.offset] - Начальная позиция в списке результатов.
 * @param {string} [data.search] - Поисковый запрос для фильтрации врачей по имени.
 * @param {string} [data.ordering] - Поле для сортировки результатов (например, по рейтингу).
 * @param {string} [data.spec_ids] - Идентификатор специализации для фильтрации врачей.
 * @param {string} [next] - URL для получения следующей страницы результатов.
 * @returns {Promise<Object>} - Ответ API с данными о врачах.
 * @throws {Object} - Объект ошибки с сообщением и данными ответа, если запрос не выполнен.
 */

export const getAllDoctors = async (data, next = '') => {
    try {
        if (next) {
            return await apiRequest.get(next);
        }

        const { limit = 10, offset = 0, search, ordering, spec_ids } = data;
        return await apiRequest.get(
            `/doctors/is_approved/?limit=${limit}&offset=${offset}&search=${search}&ordering=${ordering}&spec_ids=${spec_ids}`
        );
    } catch (error) {
        console.error('Error in getAllDoctors:', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Получает данные о конкретном враче по идентификатору.
 *
 * @async
 * @function getDoctor
 * @param {number|string} id - Уникальный идентификатор врача.
 * @returns {Promise<Object>} - Ответ API с данными о враче.
 * @throws {Object} - Объект ошибки с сообщением и данными ответа, если запрос не выполнен.
 */

export const getDoctor = async (id) => {
    try {
        return await apiRequest.get(`doctors/${id}/`);
    } catch (error) {
        console.error('Error in getDoctor', error.response);
        throw error.response ? error : { message: error.message };
    }
};

// получение id новой консультации (создается на сервере)
export const getСonsultationID = async (consultation) => {
    const response = await apiRequest.post(`consultations/`, consultation);
    return response.data;
};

/**
 * Получает отзывы о враче по его идентификатору.
 *
 * @async
 * @function getReviews
 * @param {Object} params - Параметры запроса.
 * @param {number} params.id - Идентификатор врача.
 * @param {number} params.offset - Смещение для пагинации.
 * @returns {Promise<Object>} - Объект с данными отзывов.
 * @throws {Error} - Если запрос не удался.
 */

//  получение первых трех отзывов о докторе по id
export const getReviews = async ({ id, offset }) => {
    const response = await apiRequest.get(`doctors/${id}/feedbacks/?limit=3&offset=${offset}`);
    return response.data;
};

// отправка заявки на визит в клинику или вызов врача на дом
export const postVizit = async (data) => {
    const response = await apiRequest.post(`visit/`, data);
};
