import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ArrowIcon from '../../share/ArrowIcon';
import { StyledNavbar } from './navbar.styled';
import { useMediaQuery } from 'react-responsive';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useSelector } from 'react-redux';

/**
 * @function Navbar
 * компонент навигации на странице
 * В массиве linksArray есть проверка на user.role
 * При user.role === 'doctor', ссылка на страницу "Наши врачи" скрывается
 */

function Navbar() {
    const { pathname } = useLocation();
    const { user, whoAreYou } = useSelector((state) => state.authentification);
    const [activeUseful, setActiveUseful] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const menu = useRef(null);

    useEffect(() => {
        if (
            pathname.startsWith('/news') ||
            pathname === '/vacancies' ||
            pathname === '/reviews' ||
            pathname === '/politics'
        ) {
            setActiveUseful(true);
        } else {
            setActiveUseful(false);
        }
    }, [pathname]);

    const isTablet = useMediaQuery({ query: '(max-width: 700px)' });

    const linksArray = [
        { id: 1, link: '', text: <>О&nbsp;наc</>, hideLink: false },
        { id: 2, link: 'doctors', text: <>Наши&nbsp;врачи</>, hideLink: whoAreYou === 'doctor' },
        { id: 3, link: 'contacts', text: 'Контакты', hideLink: false },
    ];

    const menuArray = [
        { id: 1, link: 'news', text: 'Блог' },
        { id: 2, link: 'vacancies', text: 'Вакансии' },
        { id: 3, link: 'reviews', text: 'Отзывы' },
        { id: 4, link: 'politics', text: 'Юридическая информация' },
    ];

    useOutsideClick(menu, () => setOpenMenu(false), openMenu);

    function toggleMenu() {
        setOpenMenu(!openMenu);
    }

    function renderNavlinks(linksArray) {
        return linksArray
            .filter((link) => !link.hideLink)
            .map((link) => (
                <li key={link.id} className="navlink__item">
                    <NavLink to={link.link} className="navlink__link">
                        {link.text}
                    </NavLink>
                </li>
            ));
    }

    function renderMenu(menuArray) {
        return menuArray.map((link) => (
            <li key={link.id} className="menu__item">
                <NavLink to={`/${link.link}`} className="menu__link">
                    {link.text}
                </NavLink>
            </li>
        ));
    }

    return (
        <StyledNavbar>
            {renderNavlinks(linksArray)}
            <div className="last">
                {isTablet ? (
                    <li className="navlink__item last" onClick={toggleMenu} ref={menu}>
                        <span className={`navlink__link ${activeUseful ? 'active' : ''}`}>
                            Полезное {isTablet && <ArrowIcon isOpen={openMenu} />}
                        </span>
                        {openMenu && <menu className="navbar__menu">{renderMenu(menuArray)}</menu>}
                    </li>
                ) : (
                    <li className="navlink__item last">
                        <NavLink to="/useful" className={`navlink__link ${activeUseful ? 'active' : ''}`}>
                            Полезное
                        </NavLink>
                    </li>
                )}
            </div>
        </StyledNavbar>
    );
}

export default Navbar;
