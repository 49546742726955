import { useLocation, useNavigate } from 'react-router-dom';
import { CancelConsultationModal } from '../../lkPatientModalsNew/closeConsultationModal/closeConsultationModal';
import { ConsultationButtonsStl } from './consultationButtons.styled';
import { Modal } from '../../../share/Modal';
import React from 'react';
import { StartConsultationModal } from '../../lkPatientModalsNew/startConsultationModal/startConsultationModal';
import { dateComparator } from '../../../share/helpers';
import { setKeyConsultationPatient } from '../../api/PatientConsultationsSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTimer } from '../../../../hooks/useTimer';

/**
 * Компонент `ConsultationButtons` отвечает за отображение и управление кнопками
 * для работы с консультациями пациента. Кнопки зависят от статуса консультации
 * и предоставляют функционал начала, отмены консультации, записи к другому врачу и других действий.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.consultation - Объект консультации с данными о консультации, пациенте и враче.
 * @returns {JSX.Element} Компонент кнопок управления консультациями.
 */
export const ConsultationButtons = ({ consultation }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const regex = /\/\d+$/;
    const isSingleConsultation = regex.test(pathname);

    const [startConsultation, setStartConsultation] = useState(false);
    const [firstCancelConfirm, setFirstCancelConfirm] = useState(false);

    const {
        id: consultationID,
        status,
        doctor: {
            id: doctorId,
            user: { last_name, first_name, middle_name },
            slug,
            specialization,
        },
        patient: {
            user: { id: patientUserId },
        },
        consultation_time,
        is_payed,
        recommendation,
        registration_log,
        security_key,
    } = consultation;

    let fullName = `${last_name} ${first_name} ${middle_name}`;
    let buttons;

    const doctorSpecialization = specialization.map((spec) => spec.name).join(', ');

    // излишняя логика, оставим на будущее
    const isFutureConsultation = dateComparator(consultation_time);

    const patientCancelled = patientUserId === registration_log?.cancelled_by;

    const { minutes, isExpired } = useTimer({ date: consultation_time, intervalMs: 60000, trackSeconds: false });

    const disabledStart = !is_payed || (minutes >= 30 && isExpired) || (minutes >= 15 && !isExpired);
    // для тестирования видео-комнаты:
    // const disabledStart = (minutes >= 30 && isExpired) || (minutes >= 60 && !isExpired);

    /**
     * Обработчик отмены консультации.
     * Открывает модальное окно для подтверждения отмены.
     *
     * @param {React.MouseEvent} e - Событие клика мыши.
     */
    const cancelConsultation = (e) => {
        e.stopPropagation();
        setFirstCancelConfirm(true);
    };

    /**
     * Обработчик открытия модального окна для начала консультации.
     * Устанавливает ключ безопасности консультации в Redux store.
     *
     * @param {React.MouseEvent} e - Событие клика мыши.
     */
    const openStartModal = (e) => {
        e.stopPropagation();
        setStartConsultation(true);
        dispatch(setKeyConsultationPatient(security_key));
    };

    /**
     * Обработчик для выбора другого врача.
     * Перенаправляет пользователя на страницу со списком врачей.
     *
     * @param {React.MouseEvent} e - Событие клика мыши.
     */
    const chooseAnotherDoctor = (e) => {
        e.stopPropagation();
        navigate('/doctors');
    };

    /**
     * Обработчик записи к врачу повторно.
     * Перенаправляет на профиль текущего врача с передачей ID через state.
     *
     * @param {React.MouseEvent} e - Событие клика мыши.
     */
    // здесь нужно проверить  <DoctorProfilePage />, возможно добавить туда обработку:
    // const location = useLocation();  // Хук для получения состояния (state)
    // const doctorIdFromState = location.state?.id;
    // Используем id из state, если оно передано, иначе fallback на id из URL
    // const doctorId = doctorIdFromState || id;
    const registerAgain = (e) => {
        e.stopPropagation();
        navigate(`/doctors/${doctorId}/${slug}}`, { state: { id: doctorId } });
    };

    switch (status) {
        // новая
        case 0:
            buttons = (
                <>
                    <button onClick={openStartModal} className="startBtn" disabled={disabledStart}>
                        Начать консультацию
                    </button>
                    {/* {!disabledCancel && (     // на будущее, если понадобится ограничивать возможность удаления консультации */}
                    <button onClick={cancelConsultation} className="closeBtn">
                        Отменить консультацию
                    </button>
                    {/* )} */}
                </>
            );
            break;
        //состоялась
        case 1:
            buttons = (
                <>
                    <button onClick={registerAgain} className="startBtn">
                        Записаться повторно
                    </button>
                    {!regex.test(pathname) && recommendation && <button className="closeBtn">Заключение врача</button>}
                </>
            );
            break;
        //отменена
        case 2:
            buttons = (
                <>
                    {patientCancelled ? (
                        <>
                            <button onClick={registerAgain} className="startBtn">
                                Перенести
                            </button>
                        </>
                    ) : (
                        <button onClick={chooseAnotherDoctor} className="startBtn">
                            Выбрать другого врача
                        </button>
                    )}
                </>
            );
            break;
        //началась
        case 3:
            buttons = (
                <>
                    <button
                        onClick={openStartModal}
                        className={disabledStart ? 'disabledBtn' : 'startBtn'}
                        disabled={disabledStart}
                    >
                        Начать консультацию
                    </button>
                </>
            );
            break;
        //прервана
        case 8:
            buttons = (
                <>
                    <button
                        onClick={openStartModal}
                        className={disabledStart ? 'disabledBtn' : 'startBtn'}
                        disabled={disabledStart}
                    >
                        Начать консультацию
                    </button>
                </>
            );
            break;
        //не проводилась
        case 9:
            buttons = (
                <>
                    <button onClick={chooseAnotherDoctor} className="startBtn">
                        Выбрать другого врача
                    </button>
                </>
            );
            break;
        default:
            buttons = null;
    }

    return (
        <ConsultationButtonsStl
            pathname={'/lk/patient-profile/consultation/future-consultation' === pathname}
            isSingleConsultation={isSingleConsultation}
            isPastConsultations={'/lk/patient-profile/consultation/past-consultation' === pathname}
        >
            {buttons}
            {startConsultation && (
                <Modal toggle={setStartConsultation}>
                    <StartConsultationModal
                        consultation={consultationID}
                        isOpen={startConsultation}
                        setIsOpen={setStartConsultation}
                        doctor={fullName}
                        doctorSpecialization={doctorSpecialization}
                        consultationDate={consultation_time}
                    />
                </Modal>
            )}
            {firstCancelConfirm && (
                <Modal toggle={setFirstCancelConfirm}>
                    <CancelConsultationModal
                        consultation={consultation}
                        setFirstConfirm={setFirstCancelConfirm}
                        reload={true}
                    />
                </Modal>
            )}
        </ConsultationButtonsStl>
    );
};
