import { ReactComponent as Pensil } from '../../../../../img/icons/pencil.svg';
import React from 'react';
import { TitleWithPencilStl } from './titleWithPencil.styled';

/**
 * Компонент TitleWithPencil отображает заголовок с иконкой карандаша рядом,
 * при нажатии на которую происходт переключение режимов чтения и редактирования.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {string} props.title - Текст заголовка.
 * @param {function} props.handleRedirect - Функция, вызываемая при клике на иконку карандаша.
 * @param {string} [props.type='h2'] - Тип HTML-тега заголовка (например, 'h1', 'h2', 'h3').
 * @returns {JSX.Element} Отрендеренный заголовок с кликабельной иконкой карандаша.
 */

export const TitleWithPencil = ({ title, handleRedirect, type = 'h2' }) => {
    const TagName = type;
    const className = type === 'h2' ? 'title' : 'subtitle';
    return (
        <TitleWithPencilStl type={type}>
            <TagName className={className}>{title}</TagName>
            <Pensil onClick={() => handleRedirect()} />
        </TitleWithPencilStl>
    );
};
