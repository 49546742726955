import { useEffect, useState } from 'react';

export const useSchedule = (choosenDates, setSelectedTime, isInterval) => {
    const [times, setTimes] = useState([]);
    const [indexStart, setIndexStart] = useState(null);

    const timeArray = Array.from({ length: 48 }, (_, index) => {
        const hours = Math.floor(index / 2); // получаем количество часов
        const minutes = index % 2 === 0 ? '00' : '30'; // получаем минуты (00 или 30)
        const time = {
            id: null,
            consultation: null,
            timeStamp: null,
            timeString: `${hours.toString().padStart(2, '0')}:${minutes}`,
            workTime: false,
        };
        return time;
    });

    const handleSelect = (el, index) => {
        // инвертируем рабочее врямя для интервала
        const updateTimesForInterval = () => {
            const updatedTimes = times.map((time, i, arr) => {
                if (arr[i].workTime) return time; // Запрещаем отмену если workTime уже true (ТЕСТ!!)
                if (!el.consultation && ((indexStart <= i && index >= i) || (indexStart >= i && index <= i))) {
                    return { ...time, workTime: arr[indexStart].workTime };
                }
                return time;
            });
            setTimeout(() => {
                setIndexStart(null);
            }, 1); // сбрасываем стартовую точку интервала
            setTimes(updatedTimes);
        };

        // инвертируем рабочее врямя для 1 элемента
        // const updatedTimes = times.map((time) => {
        //     if (time.timeString === el.timeString && !el.consultation) {
        //         return { ...time, workTime: !time.workTime };
        //     }
        //     return time;
        // });

        const updatedTimes = times.map((time) => {
            if (time.timeString === el.timeString) {
                if (time.workTime) return time; // Запрещаем отмену если workTime уже true
                return { ...time, workTime: !time.workTime };
            }
            return time;
        });

        if (isInterval) {
            if (indexStart === null) {
                setTimes(updatedTimes);
                setIndexStart(index);
            } else {
                updateTimesForInterval();
            }
        } else {
            setTimes(updatedTimes);
        }
    };

    // преобразууем в массив временных меток
    function getTimeStamps(times, days) {
        let timeStamps = [];
        const today = new Date().getUTCDate();

        for (let i = 0; i < days.length; i++) {
            let receptions = days[i].receptions;

            if (days.length > 1 || (days.length === 1 && days[0].day !== today)) {
                for (let j = 0; j < times.length; j++) {
                    let time = times[j];

                    if (time.workTime) {
                        timeStamps.push(receptions[j].timeStamp);
                    }
                }
            } else if (days.length === 1 && days[0].day === today) {
                // обрезаем массив расписания до текущего времени
                let receptionsSliced = receptions.slice(-times.length);
                for (let j = 0; j < times.length; j++) {
                    let time = times[j];

                    if (time.workTime) {
                        timeStamps.push(receptionsSliced[j].timeStamp);
                    }
                }
            }
        }

        return timeStamps;
    }

    // useEffect(() => {
    //     setSelectedTime(getTimeStamps(times, choosenDates));
    // }, [times, choosenDates]);

    useEffect(() => {
        const newSelectedTime = getTimeStamps(times, choosenDates);
        setSelectedTime(newSelectedTime);
    }, [times, choosenDates]);
    // -----------------------------------------------

    // отображаем расписание одного дня или выбор для нескольких дней
    useEffect(() => {
        if (choosenDates.length === 1) {
            const times = choosenDates[0].receptions.filter((time) => time.timeStamp * 1000 > Date.now());
            setTimes(times);
        } else if (choosenDates.length > 1) {
            setTimes(timeArray);
        } else {
            setTimes([]);
        }
    }, [choosenDates]);

    return { handleSelect, times };
};
