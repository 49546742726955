import React, { useEffect } from 'react';
import { BlogListStyled } from './blogList.styled';
import { useDispatch, useSelector } from 'react-redux';
import { RowArticle } from './rowArticle/RowArticle';
import { Spinner } from '../../share/Spinner';
import { FooterButtons } from '../footerButtons/FooterButtons';
import { useParams } from 'react-router-dom';
import { fetchPostsByTheme, updatePosts } from '../api/blogSlice';
import { Quote } from './quote/Quote';

export const BlogList = () => {
    const dispatch = useDispatch();
    const { posts, isLoading, quotes } = useSelector((state) => state.blog);
    const { themeId } = useParams();

    useEffect(() => {
        if (themeId && !posts[themeId]) {
            dispatch(fetchPostsByTheme({ themeId, getMorePosts: false }));
        }
    }, [themeId, posts, dispatch]);
    
    useEffect(() => {
        if (themeId && posts[themeId]?.postsByTheme?.length && quotes?.length) {
            dispatch(updatePosts(themeId));
        }
    }, [themeId, quotes, posts, dispatch]);

    const renderPosts =
        posts[themeId]?.postsByTheme?.map((post) =>
            post?.quote ? (
                <div key={post.id}>
                    <RowArticle props={post} isFullTextContent={true} />
                    <Quote quote={post?.quote} />
                </div>
            ) : (
                <RowArticle key={post.id} props={post} isFullTextContent={true} />
            )
    );

    if (isLoading && !posts[themeId]) {
        return (
            <div className="centerSpinner">
                <Spinner />
            </div>
        );
    }

    return (
        <BlogListStyled>
        {renderPosts}
        {isLoading && (
            <div className="centerSpinner">
                <Spinner />
            </div>
        )}
        <FooterButtons />
    </BlogListStyled>
    );
};
