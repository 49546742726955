import React from 'react';
import { LabelStl } from './agreementFieldStl.styled';
import Checkbox from './Checkbox';
import { Link } from 'react-router-dom';
import { useController, useFormContext } from 'react-hook-form';

/**
 * AgreementField компонент предоставляет поле для согласия пользователя с условиями политики и пользовательского соглашения.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля для управления формой.
 * @param {Object} [props.validation={}] - Дополнительные правила валидации для поля. По умолчанию требуется заполнение.
 *
 * @returns {JSX.Element} - Рендерит компонент AgreementField.
 */
const AgreementField = ({ name, validation = {} }) => {
    const { control, trigger } = useFormContext();
    const defaultValidation = {
        required: true,
        ...validation,
    };

    const { field } = useController({
        name,
        control,
        rules: defaultValidation,
        defaultValue: false,
    });
    return (
        <LabelStl name={name}>
            <Checkbox field={field} />
            {name === "agreement"
                ? <p>
                    Регистрируясь, вы выражаете
                    <Link to={'/politics'} target="_blank">
                        {' '}
                        согласие на обработку персональных данных{' '}
                    </Link>
                    и принимаете условия
                    <Link to={'/politics'} target="_blank">
                        {' '}
                        пользовательского соглашения{' '}
                    </Link>
                </p>
                : <p>
                    Я принимаю условия
                    <Link to={'/politics'} target="_blank">
                        {' '}
                        пользовательского соглашения{' '}
                    </Link>
                    и даю
                    <Link to={'/politics'} target="_blank">
                        {' '}
                        согласие на обработку персональных данных{' '}
                    </Link>
                </p>
            }
        </LabelStl>
    );
};

export default AgreementField;
