import styled from 'styled-components';

export const BlogStyled = styled.div`
    display: flex;
    justify-content: space-between;

    .no-posts-text {
        font-weight: 500;
        font-size: 24px;
        line-height: 130%;
        color: #666666;
        text-align: center;
        flex-grow: 1;

        @media (max-width: 768px) {
            align-items: left;
            font-size: 20px;
        }

        @media (max-width: 361px) {
            font-size: 18px;
        }
    }

    .centerSpinner {
        margin-right: auto;
        margin-left: auto;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
