import { apiRequest } from '../../share/api';

/**
 * Отправляет POST запрос для регистрации нового пользователя.
 * @async
 * @function registerUser
 * @param {Object} userData Данные пользователя.
 * @param {string} userData.username Имя пользователя.
 * @param {string} userData.email Email пользователя.
 * @param {string} userData.role Роль пользователя.
 * @param {string} userData.password Пароль пользователя.
 * @returns {Promise<Object>} Ответ сервера с данными нового пользователя или ошибка. При успешной регистрации возвращает ответ в виде
 {
   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
   "username": "string",
   "email": "user@someexamle.com",
   "role": "patient"
 }
 */
export const registerUser = async (userData) => {
    try {
        return await apiRequest.post(`users/create/`, userData);
    } catch (error) {
        console.error(error.response);
        throw error.response ? error.response : new Error(error.message);
    }
};

/**
 * Отправляет POST запрос для логина пользователя.
 * @async
 * @function loginUser
 * @param {Object} credentials Объект с данными для входа.
 * @param {string} credentials.email Email пользователя.
 * @param {string} credentials.password Пароль пользователя.
 * @returns {Promise<Object>} Ответ сервера с токеном или ошибка. при успешной регистрации возвращает ответ (в т.ч. токен) в виде:
  curl -X 'POST' \
    'https://api.test.telemed.doct24.com/api/auth/jwt/create/' \
    -H 'accept: application/json' \
    -H 'Content-Type: application/json' \
    -H 'X-CSRFTOKEN: SWxR5jDdlGFtDpEcrOVnyrjR8c05mNcvjE1cfTbbaJNCHeBvColAiKjwxQwYPsx8' \
    -d '{
    "email": "string@string.com",
    "password": "stringpass"
  }'
  Токен перехватывается интерсептором и сохраняется в sessionStorage и localStorage
 */
export const loginUser = async ({ email, password }) => {
    try {
        return await apiRequest.post(`auth/jwt/create/`, {
            email: email,
            password: password,
        });
    } catch (error) {
        console.error(error.response);
        throw error.response ? error.response : new Error(error.message);
    }
};

/**
 * Отправляет GET запрос для получения информации о текущем пользователе (доступно всем, кто прошёл регистрацию)
 * @async
 * @function meUser
 * @returns {Promise<Object>} Информация о текущем пользователе или ошибка. Возвращает информацию по схеме: {
  "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "username": "string",
  "email": "user@someexample.com",
  "phone": "string",
  "first_name": "string",
  "middle_name": "string",
  "last_name": "string",
  "date_birth": "2024-08-19",
  "sex": "М",
  "role": "admin",
  "city": "string",
  "image_standart": "string"
}
 */
export const meUser = async () => {
    try {
        const response = await apiRequest.get('users/me/');
        return response.data;
    } catch (error) {
        // console.log(error);
        console.error(error.response.data);
        throw error.response ? error.response : { message: error.message };
    }
};

/**
 * Функция для редактирования данных пользователя с использованием метода PATCH.
 * @async
 * @function dataUpdate
 * @param {Object} data - Данные для обновления профиля пользователя.
 * @param {string} data.id - Уникальный идентификатор пользователя.
 * @param {string} data.username - Уникальный username пользователя.
 * @param {string} data.phone - Номер телефона пользователя.
 * @param {string} data.first_name - Имя пользователя.
 * @param {string} data.last_name - Фамилия пользователя.
 * @param {string} [data.middle_name] - Отчество пользователя.
 * @param {string} data.date_birth - Дата рождения пользователя в формате YYYY-MM-DD.
 * @param {string} data.sex - Пол пользователя, например, 'М' или 'Ж'.
 * @param {string} data.email - Электронная почта пользователя.
 * @param {string} [data.city] - Город пользователя.
 * @returns {Promise<Object>} Обновлённые данные пользователя.
 * @throws {Error} Если запрос не был успешным или произошла ошибка при выполнении.
 */
export const dataUpdate = async (data) => {
    try {
        const response = await apiRequest.patch('/users/partial_update/', {
            id: data.id,
            username: data.username,
            phone: data.phone,
            first_name: data.first_name,
            last_name: data.last_name,
            middle_name: data.middle_name,
            date_birth: data.date_birth,
            sex: data.sex,
            email: data.email,
            city: data.city,
        });

        if (response.status !== 200) {
            const error = new Error('Ошибка при редактировании данных пациента');
            error.response = response;
            throw error;
        }
        return response.data;
    } catch (error) {
        console.error(error.response);
        throw error.response ? error.response : { message: error.message };
    }
};

/**
 * Удаляет пользователя по его идентификатору (ID).
 * @async
 * @function deleteUser
 * @param {string|number} id - Идентификатор пользователя, которого нужно удалить.
 * @returns {Promise<Object>} Ответ от API. В случае ошибки возвращает объект ошибки с кодом или сообщением.
 * @throws {Error} Если запрос не удается выполнить, возвращает объект с кодом ошибки или сообщением.
 */
export const deleteUser = async (id) => {
    try {
        const response = await apiRequest.delete(`/users/delete/${id}/`);
        return response;
    } catch (error) {
        console.error(error.response.data.code);
        throw error.response ? error.response : { message: error.message };
    }
};

// ----------------------------------------------------------------------------------------
// старое, не будет использоваться
/**
 * Отправляет POST запрос для аутентификации по номеру телефона. на 02.09.24 не прописан путь `authentification/auth_phone_number/`
 * @param {string} phone Номер телефона пользователя.
 * @returns {Promise<Object>} Ответ сервера или ошибка.
 */
export const AuthMe = async (phone) => {
    try {
        return await apiRequest.post(`authentification/auth_phone_number/`, {
            phone_number: phone,
        });
    } catch (error) {
        console.error(error.response.data.code);
        return error.response ? error.response : { message: error.message };
    }
};

/**
 * Отправляет PATCH запрос для проверки кода подтверждения email. На 02.09.24 не прописан путь 'user/me/user/update_email/'
 * @param {Object} data Объект с данными для проверки.
 * @param {string} data.email Email пользователя.
 * @param {string} data.pin Код подтверждения, отправленный на email.
 * @returns {Promise<Object>} Ответ сервера или ошибка.
 */
export const checkEmailCode = async ({ email, pin }) => {
    try {
        return await apiRequest.patch(`user/me/user/update_email/`, {
            email: email,
            code_email: pin,
        });
    } catch (error) {
        console.error(error.response.data.code);
        return error.response ? error.response : { message: error.message };
    }
};

/**
 * Отправляет POST запрос для проверки email и получения кода подтверждения. На 02.09.24 не прописан путь `user/me/user/get_code_email/`
 * @param {string} email Email пользователя.
 * @returns {Promise<Object>} Ответ сервера или ошибка.
 */
export const checkEmail = async (email) => {
    try {
        return await apiRequest.post(`user/me/user/get_code_email/`, {
            email: email,
        });
    } catch (error) {
        console.error(error.response.data.code);
        return error.response ? error.response : { message: error.message };
    }
};
