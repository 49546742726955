/**
 * Функция identifyWhoAreYou, возвращающая путь к личному кабинету пользователя в зависимости от его роли.
 * @function
 * @param {string} role - Роль пользователя (пациент, доктор или неавторизованный пользователь).
 * @returns {string} Путь для перехода в личный кабинет в зависимости от роли пользователя. Если роль не определена, возвращается путь на страницу входа.
 *
 * @example
 * identifyWhoAreYou('patient'); // Возвращает '/lk/patient-profile'
 * identifyWhoAreYou('doctor');  // Возвращает '/lk/doctor-profile'
 * identifyWhoAreYou('guest');   // Возвращает '/login'
 */

export function identifyWhoAreYou(role) {
    switch (role) {
        case 'patient':
            return '/lk/patient-profile';
        case 'doctor':
            return '/lk/doctor-profile';
        default:
            return '/login';
    }
}
