import styled from 'styled-components';
export const FieldWrapperStl = styled.label`
    --primary-accent: #19be6f;
    --secondary-error: #ff3636;
    --secondary-grey: #b6b7ba;
    --secondary-accent-hover: #169056;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    // убираем стрелки для инпута number
    .input-price[type='number'] {
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
    }
    .input-price[type='number']::-webkit-outer-spin-button,
    .input-price[type='number']::-webkit-inner-spin-button {
        display: none;
    }

    .input-price {
        width: 100%;
    }

    > input,
    > textarea {
        max-width: 422px;
        width: 100%;
        appearance: none;
        border: 1px solid ${(props) => (props.hasError ? 'var(--secondary-error)' : 'var(--secondary-grey)')};

        border-radius: 8px;
        padding: 11.5px 40px 11.5px 16px;
        font-size: 16px;
        margin-bottom: 3px;
        text-overflow: ellipsis;
        &::placeholder {
            color: var(--secondary-grey);
        }
        &:hover {
            border-color: ${(props) =>
                props.disabled
                    ? 'var(--secondary-grey)'
                    : props.hasError
                    ? 'var(--secondary-error)'
                    : 'var(--primary-accent)'};
        }
        &:focus {
            outline: none;
            border-color: ${(props) => (props.hasError ? 'var(--secondary-error)' : 'var(--primary-accent)')};

            &::placeholder {
                color: transparent;
            }
        }
    }

    &:disabled {
        pointer-events: none;
    }

    @media (max-width: 768px) {
        > input,
        > textarea {
            &::placeholder {
                font-size: 14px;
            }
        }
    }
`;

export const LabelStl = styled.span`
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-bottom: 7px;
    line-height: 130%;
    font-size: 14px;
    .fieldWraper__red {
        color: #ff3636;
    }
    .editPensil {
        position: static;
        margin: 0 0 0 auto;
    }
`;

export const ClearStl = styled.svg`
    width: ${(props) => props.size || '15.5px'};
    height: ${(props) => props.size || '15.5px'};
    position: absolute;
    right: 18px;
    top: 39px;
    cursor: pointer;
`;

export const ErrorStl = styled.p`
    min-height: 14px;
    color: #ff3636;
    font-size: 12px;
    margin-bottom: 6px;
`;

export const EmailLKForm = styled.div`
    margin-bottom: 16px;

    .info__title {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 4px;
    }
    .info__data {
        font-size: 16px;
        line-height: 24px;
    }
`;
