import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formatErrorMessage, formatNullData } from '../../share/helpers';
import { getSpecializations } from './specializationsApi';


/**
 * @constant initialState
 * @type {Object}
 * @property {Array} specializations - Список специализаций в массиве, каждая специализация представляет собой объект: {id: number, name: string}.
 * specializations.id - Идентификатор специализации.
 * specializations.name - Название специализации.
 * @property {boolean} isLoading - Флаг загрузки данных.
 * @property {Object} messagesError - Сообщения об ошибках.
 */
const initialState = {
  specializations: [],
  isLoading: false,
  messagesError: {},
};

export const fetchSpecializations = createAsyncThunk(
    'specializations/fetchSpecializations',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await getSpecializations(data);
            return response.data.results;
        } catch (error) {
            console.error('error fetch specializations', error);
            return rejectWithValue(error);
        }
    }
);

const specializations = createSlice({
    name: 'specializations',
    initialState,
    reducers: {
        resetSpecializations: (state) => {
            state.specializations = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSpecializations.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchSpecializations.rejected, (state, action) => {
            state.isLoading = false;
            state.messagesError = formatErrorMessage(action.error);
        })
        builder.addCase(fetchSpecializations.fulfilled, (state, action) => {
            state.specializations = action.payload;
            state.isLoading = false;
        });
    },
});


export const { resetSpecializations } = specializations.actions;
export const selectAllSpecializations = (state) => state.specializations?.specializations;
export default specializations.reducer;