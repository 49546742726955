import React from 'react';
import { ScheduleViewerStl } from './scheduleViewer.styled';
import { useSelector } from 'react-redux';
import { getTimeFromDate, getTimeFromTimestampDate } from '../../../../share/helpers';

/**
 * Компонент для отображения записей или расписания для выбранной даты.
 * Отображает список консультаций или доступных временных слотов в зависимости от состояния.
 *
 * @param {Object} props
 * @param {Array} props.choosenDates - Массив выбранных дат для отображения.
 * @param {boolean} props.toShowWhole - Флаг, который определяет, показывать ли полное расписание или только записи.
 *
 * @returns {JSX.Element} Рендерит список консультаций или доступных слотов.
 */

export const ScheduleViewer = ({ choosenDates, toShowWhole }) => {
    const receptionsList = useSelector((state) => state.doctorCalendar.receptions);

    const renderConsultations = () => {
        if (choosenDates.length < 1) {
            return (
                <p className="schedule__placeholder">
                    Пожалуйста, выберите день, чтобы посмотреть {toShowWhole ? 'расписание' : 'записи'}
                </p>
            );
        }

        const workTimeReceptions = choosenDates[0]?.receptions?.filter((el) => el.workTime);

        // if (!workTimeReceptions || workTimeReceptions.length === 0) {
        //     return <p className="schedule__placeholder">Расписание не создано</p>;
        // }

        if (choosenDates[0]?.receptions?.every((el) => !el.workTime)) {
            return <p className="schedule__placeholder">Расписание не создано</p>;
        }
        const filteredConsultations = receptionsList?.filter((item) => {
            const consultationDate = new Date(item.reception_time * 1000).toDateString();

            const chosenDate = new Date(choosenDates[0]?.timeStamp * 1000).toDateString();

            return consultationDate === chosenDate;
        });
        const filteredItems = filteredConsultations?.filter((el) => el.consultation);

        if (toShowWhole) {
            return (
                <ul className="schedule__list">
                    {/* {choosenDates[0]?.receptions?.map((el) => { */}
                    {workTimeReceptions.map((el) => {
                        const { timeStamp, timeString, workTime, consultation } = el;

                        return (
                            <li key={timeStamp} className="schedule__item">
                                <p className={`${workTime ? 'work-time' : 'no-work'} ${consultation && 'black-color'}`}>
                                    {timeString}
                                </p>
                                {consultation ? (
                                    <>
                                        <p>{consultation.patient_name}</p>
                                        {consultation.is_payed ? (
                                            <p className="payedConsultation schedule__status">(оплачено)</p>
                                        ) : (
                                            <p className="notPayedConsultation schedule__status">(ожидает оплаты)</p>
                                        )}
                                    </>
                                ) : (
                                    <p className={workTime ? 'work-time' : 'no-work'}>
                                        {workTime ? 'Время для записи' : 'Не рабочее время'}
                                    </p>
                                )}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return filteredItems?.length > 0 ? (
                <ul className="schedule__list">
                    {filteredItems.map((el) => (
                        <li key={el.consultation.id} className="schedule__item">
                            <p>{getTimeFromTimestampDate(el.reception_time)}</p>
                            <p>{el.consultation.patient_name}</p>
                            {el.consultation.is_payed ? (
                                <p className="payedConsultation schedule__status">(оплачено)</p>
                            ) : (
                                <p className="notPayedConsultation schedule__status">(ожидает оплаты)</p>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="schedule__placeholder">На эту дату нет записей</p>
            );
        }
    };
    return <ScheduleViewerStl>{renderConsultations()}</ScheduleViewerStl>;
};
