import { DateTime } from 'luxon';

/**
 * Конвертирует даты в объекте/массиве в часовой пояс пользователя, с выбором формата выхода.
 * @param {Object|Array} data - Объект или массив объектов.
 * @param {Array<string>} fields - Массив путей к полям с датами (например, ["consultation_time", "feedback.created"]).
 * @param {string} serverTimeZone - Часовой пояс сервера (например, "Europe/Moscow").
 * @param {string} dateFormat - Формат даты на сервере (например, "dd.MM.yyyy HH:mm:ss").
 * @param {string} outputType - Тип выхода: "unix" для Unix timestamp или "formatted" для строки в формате даты.
 * @param {string} [outputFormat="dd.MM.yyyy HH:mm:ss"] - Формат даты для строкового выхода (игнорируется, если outputType="unix").
 * @returns {Object|Array} Новый объект/массив с преобразованными датами.
 */
export const convertDatesToUserTimeZone = (
    data,
    fields,
    serverTimeZone = 'Europe/Moscow',
    dateFormat = 'dd.MM.yyyy HH:mm:ss',
    outputType = 'formatted',
    outputFormat = 'dd.MM.yyyy HH:mm:ss'
) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Функция для обработки одного объекта
    function convertField(item, fieldPath) {
        const fieldParts = fieldPath.split('.');
        let current = item;

        // Двигаемся по пути к полю
        for (let i = 0; i < fieldParts.length - 1; i++) {
            if (!current[fieldParts[i]]) return; // Если часть пути не существует
            current = current[fieldParts[i]];
        }

        const lastPart = fieldParts[fieldParts.length - 1];
        if (current[lastPart]) {
            const serverDate = DateTime.fromFormat(current[lastPart], dateFormat, { zone: serverTimeZone });
            if (outputType === 'unix') {
                // Преобразуем в Unix timestamp
                current[lastPart] = serverDate.setZone(userTimeZone).toMillis();
            } else if (outputType === 'formatted') {
                // Преобразуем в строку в формате пользователя
                current[lastPart] = serverDate.setZone(userTimeZone).toFormat(outputFormat);
            }
        }
        return current;
    }

    // Обработка одного объекта
    function processItem(item) {
        // Если есть _converted, значит объект был обработан, возвращаем без изменений
        if (item._converted) return item;

        // Создаем глубокую копию объекта с флагом _converted для предотвращения повторной конвертации времени
        let newItem = JSON.parse(JSON.stringify(item));
        newItem._converted = true;

        fields.forEach((fieldPath) => convertField(newItem, fieldPath));

        return newItem;
    }

    // Обработка массива или одного объекта
    if (Array.isArray(data)) {
        return data.map(processItem);
    } else {
        return processItem(data);
    }
};

/**
 * Форматирует массив объектов с Unix timestamp в объект для отправки на сервер
 * с датами в формате сервера ("дд.мм.гггг чч:мм:сс" по Московскому времени).
 * @param {Array} data - Массив объектов с временем в формате Unix timestamp (временная зона пользователя).
 * @param {string} serverTimeZone - Часовой пояс сервера (например, "Europe/Moscow").
 * @param {string} outputFormat - Формат времени для сервера (например, "dd.MM.yyyy HH:mm:ss").
 * @returns {Object} Объект с массивом объектов, готовый для отправки на сервер.
 */
export const formatUnixDatesForServer = (
    data,
    serverTimeZone = 'Europe/Moscow',
    outputFormat = 'dd.MM.yyyy HH:mm:ss'
) => {
    const formattedData = data.map((item) => {
        const userDate = DateTime.fromMillis(item.reception_time); // Парсим Unix timestamp
        const serverDate = userDate.setZone(serverTimeZone); // Переводим во временную зону сервера
        return {
            reception_time: serverDate.toFormat(outputFormat), // Форматируем дату для сервера
        };
    });

    return {
        reception_times: formattedData, // Оборачиваем массив объектов в нужный формат
    };
};

/**
 * Форматирует дату в формате Unix для сервера.
 *
 * @param {string} data - Дата в формате 'dd.MM.yyyy HH:mm:ss', которую нужно преобразовать.
 * @param {string} [serverTimeZone='Europe/Moscow'] - Временная зона сервера, по умолчанию 'Europe/Moscow'.
 * @param {string} [outputFormat='dd.MM.yyyy HH:mm:ss'] - Формат, в который будет преобразована дата для сервера.
 * @returns {string} - Дата в формате, указанном в outputFormat, с учетом временной зоны сервера.
 */
export const formatUnixDateForServer = (
    data, // дата в формате 'dd.MM.yyyy HH:mm:ss'
    serverTimeZone = 'Europe/Moscow',
    outputFormat = 'dd.MM.yyyy HH:mm:ss'
) => {
    // Создаем объект DateTime из строки
    const dateTime = DateTime.fromFormat(data, 'dd.MM.yyyy HH:mm:ss');

    // Создаем объект DateTime из строки
    dateTime.toMillis();

    // Создаем объект DateTime из строки
    const userDate = DateTime.fromMillis(dateTime.toMillis());

    // Переводим во временную зону сервера
    const serverDate = userDate.setZone(serverTimeZone);

    // Форматируем дату для сервера
    return serverDate.toFormat(outputFormat);
};
