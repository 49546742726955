import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formatErrorMessage } from "../../share/helpers";
import { sendVacancies } from "./vacanciesApi";

/**
 * Асинхронное действие для отправки данных формы вакансий "Связаться со мной".
 * Отправляет данные пользователя на сервер и обрабатывает ответ.
 * @function fetchSendVacancies
 * @async
 * @param {Object} data Данные формы вакансий.
 * @param {string} data.name Ф.И.О отправителя.
 * @param {string} data.specialization Специализация отправителя.
 * @param {string} data.phone Номер телефона отправителя.
 * @param {string} data.email Email отправителя.
 * 
 * @returns {Promise<Object>} В случае успеха возвращает `response.data`, в случае ошибки — объект ошибки.
 */
export const fetchSendVacancies = createAsyncThunk(
    'vacancies/fetchSendVacancies',
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendVacancies(data);
            if (response.status === 201) {
                // console.log("Заявка обратной связи успешно отправлена.");
                return response.data;
            }
        } catch (error) {
            console.error('Error sending vacancies data', error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

/**
 * Изначальное состояние слайса формы вакансий.
 * @typedef {Object} InitialState
 * 
 * @property {Object} vacancies_data Данные формы вакансий.
 * @property {string} vacancies_data.name Ф.И.О пользователя.
 * @property {string} vacancies_data.specialization Специализация пользователя.
 * @property {string} vacancies_data.phone Номер телефона пользователя.
 * @property {string} vacancies_data.email Email пользователя.
 * @property {boolean} vacancies_data.agreement Флаг согласия с обработкой персональных данных.
 * @property {Object} messagesError Объект, содержащий ошибки валидации формы.
 */
const initialState = {
    vacancies_data: {
        name: '',
        specialization: '',
        phone: '',
        email: '',
        agreementVacancies: false,
    },
    messagesError: {},
};

/**
 * Слайс состояния для управления формой вакансий.
 * @constant
 * @type {Slice}
 */
const vacancies = createSlice({
    name: 'vacancies',
    initialState,
    reducers: {
        /**
         * Очищает сообщения об ошибках для указанного поля.
         * @function clearMessage
         * @param {string} field Имя поля, ошибки которого нужно очистить.
         */
        clearMessage(state, action) {
            const field = action.payload;
            if (state.messagesError[field]) {
                delete state.messagesError[field];
            }
        },
        /**
         * Сбрасывает объект `messagesError`, удаляя все сообщения об ошибках.
         * @function clearAllMessages
         * @param {Object} state Текущее состояние слайса.
         */
        clearAllMessages(state) {
            state.messagesError = {};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSendVacancies.pending, (state, action) => {
            state.isPreloader = true;
        });
        builder.addCase(fetchSendVacancies.fulfilled, (state, action) => {
            state.vacancies_data = action.payload;
            state.messagesError = {};
            state.isPreloader = false;
        });
        builder.addCase(fetchSendVacancies.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload.data);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isPreloader = false;
        });
    },
});

export const { clearMessage, clearAllMessages } = vacancies.actions;
export default vacancies.reducer;
