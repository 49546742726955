import React, { useEffect } from 'react';
import { ageCalculator } from '../../../../share/helpers';
import { DataBlockStl } from '../viewPatient.styled';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * Компонент ViewData отображает детальную информацию о пациенте,
 * включая имя, дату рождения, пол, номер телефона, email и город.
 * Автоматически перенаправляет на страницу редактирования профиля,
 * если поле `last_name` пустое или равно null.
 *
 * @component
 * @returns {JSX.Element|null} Блок с данными пациента или null, если данные пользователя не предоставлены.
 */

export const ViewData = () => {
    const navigate = useNavigate();
    const { patientProfile: user } = useSelector((state) => state.patientProfile);
    const { first_name, last_name, middle_name, date_birth, sex, phone, email, city } = user;

    const cityName = city && city.length > 0 ? city : '';

    useEffect(() => {
        if (last_name === '') {
            navigate('/lk/patient-profile/profile/edit');
        }
    }, [last_name]);

    if (!user) return null;

    return (
        <DataBlockStl>
            {(last_name || first_name || middle_name) && (
                <div className="info__wrapper">
                    <p className="info__data-bold">{`${last_name} ${first_name} ${middle_name ? middle_name : ''}`}</p>
                </div>
            )}
            {date_birth && (
                <div className="info__wrapper">
                    <p className="info__data">{ageCalculator(date_birth)}</p>
                </div>
            )}
            {sex && (
                <div className="info__wrapper">
                    <p className="info__data">{sex === 'М' ? 'Мужчина' : 'Женщина'}</p>
                </div>
            )}
            {email && (
                <div className="info__wrapper">
                    <p className="info__data">{email}</p>
                </div>
            )}
            {phone && (
                <div className="info__wrapper">
                    <p className="info__data">
                        {`+7 (${phone.slice(2, 5)}) ${phone.slice(5, 8)}-${phone.slice(8, 10)}-${phone.slice(10, 12)}`}
                    </p>
                </div>
            )}
            {cityName && (
                <div className="info__wrapper">
                    <p className="info__data">{cityName}</p>
                </div>
            )}
        </DataBlockStl>
    );
};
