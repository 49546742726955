import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

/**
 * Компонент `LkPatientConsultation`.
 *
 * Компонент управляет консультациями в личном кабинете пациента.
 * Выполняет перенаправление и загрузку данных при монтировании.
 *
 * @component
 * @returns {JSX.Element} Компонент с дочерними маршрутами.
 *
 * @example
 * <Route path="/lk/patient-profile/consultation" element={<LkPatientConsultation />}>
 *   <Route path="future-consultation" element={<FutureConsultations />} />
 *   <Route path="past-consultation" element={<PastConsultations />} />
 * </Route>
 */
export const LkPatientConsultation = () => {
    const { pathname } = useLocation();
    const redirect = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname === '/lk/patient-profile/consultation') {
            redirect('/lk/patient-profile/consultation/future-consultation', { replace: true });
        }
    }, [pathname]);

    return <Outlet />;
};
