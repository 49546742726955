/**
 * Загружает файл через указанный input.
 *
 * @function
 * @param {Object} inputRef Ссылка на элемент input для загрузки файлов.
 * @param {Object} options Настройки загрузки файла.
 * @param {boolean} [options.multiple=false] Разрешить загрузку нескольких файлов.
 * @param {Array<string>} [options.accept] Допустимые форматы файлов.
 * @param {Function} callback Функция, вызываемая после успешной загрузки файла.
 */

// export const uploadFile = (inputRef, options, callback) => {
//     inputRef.current.click();

//     if (options.multiple) inputRef.current.setAttribute('multiple', true);
//     if (options.accept && Array.isArray(options.accept)) {
//         inputRef.current.setAttribute('accept', options.accept.join(','));
//     }

//     const handleChange = (event) => {
//         if (!event.target.files.length) {
//             return;
//         }
//         const files = Array.from(event.target.files);
//         files.forEach((file) => {
//             if (!file.type.match('image')) {
//                 return;
//             }
//             const reader = new FileReader();
//             reader.onload = (ev) => {
//                 return ev.target.result;
//             };
//             reader.readAsDataURL(file);
//             callback(file);
//         });

//         // удаляем обработчик события после выполнения функции
//         inputRef.current.removeEventListener('change', handleChange);
//     };

//     inputRef.current.addEventListener('change', handleChange);
// };

/**
 * Загружает файл через указанный input.
 *
 * @function
 * @param {Object} inputRef Ссылка на элемент input для загрузки файлов.
 * @param {Object} options Настройки загрузки файла.
 * @param {boolean} [options.multiple=false] Разрешить загрузку нескольких файлов.
 * @param {Array<string>} [options.accept] Допустимые форматы файлов.
 * @param {Function} [options.validate] Функция валидации файла.
 * @param {Function} [options.onValidationError] Функция, вызываемая при ошибке валидации файла.
 * @param {Function} callback Функция, вызываемая после успешной загрузки файла.
 */
export const uploadConsultationFile = (inputRef, options, callback) => {
    inputRef.current.click();

    // Устанавливаем атрибуты для input
    if (options.multiple) inputRef.current.setAttribute('multiple', true);
    if (options.accept && Array.isArray(options.accept)) {
        inputRef.current.setAttribute('accept', options.accept.join(','));
    }

    const handleChange = (event) => {
        if (!event.target.files.length) return;

        const files = Array.from(event.target.files);

        files.forEach((file) => {
            const validation = options.validate ? options.validate(file) : { valid: true };
            if (!validation.valid) {
                // Если файл не проходит валидацию, вызываем колбэк ошибки
                if (options.onValidationError) options.onValidationError(validation.error);
                return;
            }
            callback(file);
        });

        // Удаляем обработчик после выполнения
        inputRef.current.removeEventListener('change', handleChange);
    };

    inputRef.current.addEventListener('change', handleChange);
};
