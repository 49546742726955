import React from 'react';
import { StyledMainHeader } from './mainHeader.styled';

/**
 * Компонент для отображения основного заголовка на странице.
 *
 * @param {Object} props Свойство для компонента.
 * @param {string} props.title Заголовок, который будет отображен.
 *
 * @returns {JSX.Element} Рендерит заголовок в стиле MainHeader.
 */
export const MainHeader = ({ title }) => {
    return (
        <StyledMainHeader>
            <h1>{title}</h1>
        </StyledMainHeader>
    );
};
