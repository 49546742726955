import React from 'react';
import { useDispatch } from 'react-redux';
import { useTimeReceptions } from './useTimeReceptions';
import { FreeTimeStl } from './freeTime.styled';
import { setTimeConsultation } from '../../../api/doctorsSlise';
import { useEffect } from 'react';

export const FreeTime = ({ selectedDay, freeTime, id }) => {
    const [receptions] = useTimeReceptions(freeTime, selectedDay);
    const dispatch = useDispatch();

    const handleClick = (timeStamp, e) => {
        e.stopPropagation();

        for (let i = 0; i < e.target.parentElement.children.length; i++) {
            e.target.parentElement.children[i].classList.remove('schedule__time_active');
        }
        e.target.classList.add('schedule__time_active');
        dispatch(setTimeConsultation({ time: timeStamp, id: id }));
    };

    return (
        <FreeTimeStl>
            <div className="schedule__body">
                {receptions
                    ? receptions.map((el) => {
                          return (
                              <div
                                  className="schedule__time"
                                  key={el.timeStamp}
                                  onClick={(e) => {
                                      handleClick(el.timeStamp, e);
                                  }}
                              >
                                  {el.timeString}
                              </div>
                          );
                      })
                    : 'Loading...'}
            </div>
        </FreeTimeStl>
    );
};
