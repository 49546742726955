import React from 'react';
import { useSelector } from 'react-redux';
import { TitleStl } from './doctorPrise.styled';
import { SectionStl } from '../viewDoctor.styled';

/**
 * Компонент для отображения стоимости консультации врача.
 *
 * @component
 * @returns {JSX.Element} Компонент, отображающий заголовок и стоимость консультации.
 *
 * @example
 * // Пример использования в компоненте
 * <DoctorPrice />
 *
 * @description
 * Компонент извлекает стоимость консультации из Redux хранилища и отображает её
 * в виде текста. Значение стоимости представлено в рублях.
 */

export const DoctorPrice = () => {
    const price = useSelector((state) => state.doctorProfile?.doctorData?.price);

    return (
        <SectionStl>
            <TitleStl>
                <h2 className="title">Стоимость консультации</h2>
            </TitleStl>
            <div className="info__wrapper">
                <p className="info__data">{price} руб.</p>
            </div>
        </SectionStl>
    );
};
