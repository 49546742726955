import { apiRequest, fileLoader } from '../../share/api';

// эта функция не будет использоваться. мы её заменяем на аналогичную для user-a
/**
 * Функция для редактирования данных пациента с использованием метода PATCH.
 * @async
 * @function patientDataUpdate
 * @param {Object} data - Данные для обновления профиля пациента.
 * @param {string} data.id - Уникальный идентификатор пациента.
 * @param {string} data.username - Уникальный username пациента.
 * @param {string} data.phone - Номер телефона пациента.
 * @param {string} data.first_name - Имя пациента.
 * @param {string} data.last_name - Фамилия пациента.
 * @param {string} [data.middle_name] - Отчество пациента.
 * @param {string} data.date_birth - Дата рождения пациента в формате YYYY-MM-DD.
 * @param {string} data.sex - Пол пациента, например, 'М' или 'Ж'.
 * @param {string} data.email - Электронная почта пациента.
 * @param {string} [data.city] - Город пациента.
 * @returns {Promise<Object>} Обновлённые данные пациента.
 * @throws {Error} Если запрос не был успешным или произошла ошибка при выполнении.
 */
export const patientDataUpdate = async (data) => {
    try {
        const response = await apiRequest.patch('/users/partial_update/', {
            id: data.id,
            username: data.username,
            phone: data.phone,
            first_name: data.first_name,
            last_name: data.last_name,
            middle_name: data.middle_name,
            date_birth: data.date_birth,
            sex: data.sex,
            email: data.email,
            city: data.city,
        });

        if (response.status !== 200) {
            const error = new Error('Ошибка при редактировании данных пациента');
            error.response = response;
            throw error;
        }
        return response.data;
    } catch (error) {
        console.error('Ошибка при редактировании данных пациента из patientDataUpdate', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Получает список консультаций с учетом статуса, даты, лимита и ссылки на страницу.
 *
 * @async
 * @function getConsultations
 * @param {Object} params - Объект параметров для запроса.
 * @param {number} [params.limit=3] - Максимальное количество объектов на странице.
 * @param {string} params.consultation_time - Поле сортировки по дате консультации.
 * @param {string|number|Array<string|number>|null} [params.status=null] - Статус(ы) для фильтрации. Может быть строкой, числом или массивом значений.
 * @param {string|null} [params.pageLink=null] - Ссылка на конкретную страницу для пагинации.
 *                                               Если указана, другие параметры игнорируются.
 * @returns {Promise<Object>} Объект с данными о консультациях, включая пагинацию:
 * - `count` (number): Общее количество найденных объектов.
 * - `next` (string|null): Ссылка на следующую страницу (если есть).
 * - `previous` (string|null): Ссылка на предыдущую страницу (если есть).
 * - `results` (Array<Object>): Список найденных объектов.
 * @throws {Error} Выбрасывается в случае ошибки запроса. Дополнительная информация содержится в сообщении ошибки.
 */
export const getConsultations = async ({ limit = 3, consultation_time, status = null, pageLink = null }) => {
    try {
        if (pageLink) {
            return await apiRequest.get(pageLink);
        }

        /**  Формирование URL динамически, исключая статус, если он не задан */
        let url = `/consultations/?limit=${limit}&offset=0&ordering=${consultation_time}`;

        if (status !== null && (typeof status === 'string' || typeof status === 'number')) {
            url += `&status=${status}`;
        }

        if (status !== null && Array.isArray(status)) {
            status.forEach((item) => {
                url += `&status=${item}`;
            });
        }

        const result = await apiRequest.get(url);
        return result;
    } catch (error) {
        console.error('Ошибка при загрузке списка консультаций', error);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Получает данные о конкретной консультации по её идентификатору.
 * @async @function getConsultation
 * @param {number|string} consultationId - Идентификатор консультации.
 * @returns {Promise<Object>} Объект с данными консультации - см InitialState - PatientConsultationsSlice
 * @throws {Error} Если произошла ошибка при выполнении запроса.
 *
 * @description
 * Отправляет GET-запрос на сервер для получения информации о консультации.
 * Если запрос завершается ошибкой, выводит сообщение в консоль и выбрасывает ошибку.
 *
 */
export const getConsultation = async (consultationId) => {
    try {
        const result = await apiRequest.get(`/consultations/${consultationId}/`);
        return result;
    } catch (error) {
        console.error('Ошибка при загрузке консультации', error.response || error.message);
        throw error.response ? error : new Error(`Не удалось получить консультацию ${consultationId}`);
    }
};

/**
 * Асинхронно обновляет информацию о консультации.
 *
 * @async
 * @param {number|string} id - Идентификатор консультации.
 * @param {Object} data - Объект с новыми данными для обновления.
 * @param {string} data.patient_notes - Новые заметки пациента.
 * @returns {Promise<Object>} Ответ от сервера с обновлённой консультацией.
 * @throws {Error} Выбрасывает ошибку при неудачном запросе на сервер.
 *
 * @example
 * const updatedConsultation = await consultationUpdate(1, { patient_notes: 'Обновленные заметки' });
 * // console.log(updatedConsultation);
 */
export const consultationUpdate = async (id, data) => {
    try {
        const response = await apiRequest.patch(`/consultations/${id}/`, {
            patient_notes: data.patient_notes,
        });

        return response;
    } catch (error) {
        console.error('Ошибка при обновлении консультации', error.response || error.message);
        throw error.response ? error : new Error('Не удалось обновить консультацию ');
    }
};

/**
 * Отправляет запрос на отмену консультации по её идентификатору.
 * @async @function сancelConsultation
 * @param {number|string} consultationId - Идентификатор консультации, которую необходимо отменить.
 * @returns {Promise<Object>} Ответ сервера после отмены консультации.
 * @throws {Error} Если произошла ошибка при выполнении запроса.
 *
 * @description
 * Выполняет POST-запрос к API на отмену указанной консультации.
 * Возвращает данные из ответа API: Статус меняется на '2'-'Отменена'.
 * Создается и возвращается объект консультации с новым атрибутом registration_log.
 * Вщзвращаемый объект консультации имеет структуру, аналогичную объекту в ответе на запрос get на api/consultations/ (метод consultations)
 * Если запрос завершится ошибкой, выводит сообщение об ошибке в консоль и выбрасывает исключение с описанием проблемы.
 */
export const сancelConsultation = async (consultationId) => {
    try {
        const result = await apiRequest.patch(`/consultations/${consultationId}/cancel/`);
        return result;
    } catch (error) {
        console.error('Ошибка при отмене консультации', error.response || error.message);
        throw error.response ? error : new Error(`Не удалось отменить консультацию ${consultationId}`);
    }
};

/**
 * Отправка файла для консультации по её идентификатору.
 *
 * @async
 * @function consultationSendFile
 * @param {Object} params - Параметры для отправки файла.
 * @param {string} params.consultationId - Идентификатор консультации, к которой прикрепляется файл.
 * @param {File} params.file - Файл для загрузки.
 * @param {Function} [params.onProgress] - Опциональная функция для отслеживания прогресса загрузки.
 * Эта функция будет вызвана с текущим прогрессом загрузки (в процентах) в качестве аргумента.
 * @returns {Promise<Object>} Ответ от сервера с данными файла после успешной загрузки.
 * @throws {Error} Если произошла ошибка при загрузке, выбрасывается объект ошибки с описанием.
 * @throws {Object} Если сервер вернул ошибку, выбрасывается объект ответа с подробностями ошибки.
 */
export const consultationSendFile = async ({ consultationId, file, onProgress }) => {
    try {
        if (!file) {
            throw new Error('No file provided for upload.');
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await fileLoader.post(`/consultations/${consultationId}/files/`, formData, {
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const totalLength = progressEvent.total;
                    const progress = Math.round((progressEvent.loaded * 100) / totalLength);
                    if (onProgress) {
                        onProgress(progress); // Передаём прогресс через callback
                    }
                }
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Удаление файла из консультации по её идентификатору.
 *
 * @async
 * @function consultationDeleteFile
 * @param {string} fileId - Идентификатор файла, который нужно удалить.
 * @returns {Promise<Object>} Ответ от сервера об успешном удалении файла.
 * @throws {Object} Если произошла ошибка при удалении файла, выбрасывается ошибка с описанием.
 */
export const consultationDeleteFile = async (fileId) => {
    try {
        const response = await apiRequest.delete(`/consultations/files/${fileId}/`);
        return response;
    } catch (error) {
        console.error('Failed to delete file in consultation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронно отправляет отзыв о консультации.
 *
 * @async
 * @param {Object} params - Параметры для отправки отзыва.
 * @param {number|string} params.consultationId - Идентификатор консультации.
 * @param {Object} params.data - Объект с текстом и оценкой отзыва.
 * @param {string} params.data.text - Текст отзыва.
 * @param {number} params.data.rating - Оценка отзыва (например, от 1 до 5).
 * @returns {Promise<Object>} Ответ от сервера с информацией об отправленном отзыве.
 * @throws {Error} Выбрасывает ошибку при неудачном запросе на сервер.
 *
 * @example
 * const feedback = await postFeedbackConsultation({ consultationId: 1, data: { text: 'Отлично!', rating: 5 } });
 * // console.log(feedback);
 */
export const postFeedbackСonsultation = async ({ consultationId, data }) => {
    try {
        // console.log('data for api', data);
        const response = await apiRequest.post(`/consultations/${consultationId}/feedback/`, {
            text: data.text,
            rating: data.rating,
        });
        return response;
    } catch (error) {
        console.error('Failed to create feedback to consultation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронно получает отзыв по его идентификатору.
 *
 * @async
 * @param {number|string} feedbackId - Идентификатор отзыва.
 * @returns {Promise<Object>} Ответ от сервера с данными отзыва.
 * @throws {Error} Выбрасывает ошибку при неудачном запросе на сервер.
 *
 * @example
 * const feedback = await getFeedback(1);
 * // console.log(feedback);
 */
export const getFeedback = async (feedbackId) => {
    try {
        const response = await apiRequest.get(`/doctors_feedbacks/${feedbackId}/`);
        return response;
    } catch (error) {
        console.error('Failed to get feedback to consultation', error.response);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для изменения статуса консультации на "началась" (со статуса 0 на статус 3).
 *@async @function changeStatusToStartedPatient
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 * Статус консультации меняется на '3'-'Началась' при условии, что оба участника консультации присоединились
 * (то есть  "doctor_has_joined": true и "patient_has_joined": true)
 * Возвращаемый объект:
 * {
 * "id": 0,
 * "status": 0,
 * "joining": {
 *   "doctor_has_joined": true,
 *   "patient_has_joined": true
 *   }
 * }
 */
export const changeStatusToStartedPatient = async (consultationId) => {
    try {
        const result = await apiRequest.patch(`/consultations/${consultationId}/started/`);
        // // console.log('НАЧАЛАСЬ У ПАЦИЕНТА!!!!!!!!!!!!!!!!', result);
        return result;
    } catch (error) {
        console.error('Ошибка при переводе консультации в начавшиеся', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

/**
 * Асинхронная функция для проверки статуса консультации.
 *@async @function checkConsultationStatus
 * @param {string} consultationId - Уникальный идентификатор консультации.
 * @returns {Promise<Object>} Объект, содержащий данные результата операции.
 * @throws {Error} Генерирует ошибку, если запрос не удался.
 * Возвращаемый объект:
 * {
 * "id": 0,
 * "status": 0,
 * "actual_duration": 0
 * }
 */
export const checkConsultationStatus = async (consultationId) => {
    try {
        const result = await apiRequest.get(`/check_consultations_status/${consultationId}/`);
        // // console.log('ПРОВЕРКА СТАТУСА У ПАЦИЕНТА ');
        // // console.log('result', result);
        return result;
    } catch (error) {
        console.error('Ошибка при проверке статуса консультации', error.response || error.message);
        throw error.response ? error : { message: error.message };
    }
};

// --------------------------------------------
// эта функция пойдёт под удаление
// используется в слайсе
export const consultationDelete = async (id) => {
    // console.log(id, 'deletingConsult');
    return await apiRequest.delete(`/consultations/${id}/`);
};

//------------------------------------------------------------------------------
// проверить, будем ли использовать
export const getFavoriteDoctors = async () => {
    //return await apiRequest.get('/patients/favorities/');
    return await apiRequest.get('/doctors/favorites/by_patient/?page_size=10');
};
// export const setFavoriteDoctors = async (id) => {
//    return await apiRequest.post('/patients/favorities/', { doctor_uid: id });
// 	//return await apiRequest.post('/doctors/favorites/by_patient/', { doctor_uid: id });
// };

export const setFavoriteDoctors = async (id) => {
    return await apiRequest.post('/doctors/favorites/by_patient/', { doctor_uid: id });
};

export const removeFavoriteDoctors = async (id) => {
    //return await apiRequest.delete(`/patients/favorities/${id}/`);
    return await apiRequest.delete(`/doctors/favorites/by_patient/${id}/`);
};

export const removeFavoriteDoctorsUid = async (uid) => {
    return await apiRequest.delete(`/doctors/favorites/by_doctor/${uid}`);
};

export const getAllDoctors = async (data) => {
    return await apiRequest.get(`/doctors/?search=${data.search}&page_size=${data.page_size}`);
};

// ------------------------------------------------------------------------------
// для удаления, это не используется
// на этой функции что-то завязано, перед удалением - проверить
export const createPayment = async (id) => {
    // console.log(id);
    return await apiRequest.post(`/payments/`, { consultation: id });
};
