import styled from 'styled-components';

export const ErrorConsultationStl = styled.div`
    padding: 41px 40px 40px 40px;
    max-width: 510px;
    background: rgb(255, 255, 255);
    border-radius: 24px;

    @media (max-width: 320px) {
        width: 288px;
        padding: 38px 15px 40px 15px;
    }
    .error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__icon {
            height: 112px;
            @media (max-width: 320px) {
                height: 80px;
            }
        }
        &__title {
            margin-top: 20px;
            color: #212121;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            @media (max-width: 320px) {
                line-height: 24px;
                font-size: 18px;
                margin-top: 14px;
                margin-bottom: 0px;
            }
        }
        &__content {
            margin-top: 10px;
            line-height: 24px;
            text-align: center;
            > span {
                font-size: 18px;
                color: #666666;
                @media (max-width: 320px) {
                    font-size: 16px;
                }
            }
            @media (max-width: 320px) {
                margin-top: 7px;
            }
        }
        &__btn {
            margin-top: 36px;
            width: 100%;
            display: flex;
            gap: 22px;
            @media (max-width: 320px) {
                margin-top: 25px;
                flex-direction: column;
                width: 80%;
                gap: 15px;
            }
            &-repeat {
                @media (max-width: 320px) {
                    padding: 10px;
                    font-size: 14px;

                    text-align: centerж;
                }
            }
            &-close {
                background: none;
                border: 2px solid #19be6f;
                color: #19be6f;
                @media (max-width: 320px) {
                    padding: 8px;
                    font-size: 14px;
                }
            }
        }
    }
`;
