import styled from 'styled-components';

export const FeedbackValueStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 35px;

    > h3 {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #212121;

        @media (max-width: 450px) {
            font-size: 18px;
            line-height: 23.4px;
        }

        @media (max-width: 340px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    > div > img {
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    > div > button {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: none;
        background: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .star_container {
        display: flex;
        flex-direction: row;
        column-gap: 15px;
    }

    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
        .star_container {
            column-gap: 5px;
        }
    }
    @media screen and (max-width: 420px) {
        padding-bottom: 24px;
        .star_container {
            column-gap: 15px;
        }
    }
`;
