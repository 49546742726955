import * as UTIF from 'utif';

/**
 * Обработка TIFF файлов.
 * Декодирует TIFF и возвращает DataURL формата PNG.
 *
 * @param {File} file TIFF файл.
 * @returns {Promise<string>} DataURL изображения.
 */
export const processTiffFile = async (file) => {
    try {
        const arrayBuffer = await file.arrayBuffer(); // Преобразуем файл в ArrayBuffer
        const ifds = UTIF.decode(arrayBuffer); // Декодируем TIFF
        UTIF.decodeImage(arrayBuffer, ifds[0]); // Декодируем первую картинку

        const firstImage = ifds[0];
        const rgba = UTIF.toRGBA8(firstImage); // Конвертируем в RGBA массив

        if (!rgba || rgba.length === 0) {
            throw new Error('Failed to decode TIFF to RGBA');
        }

        const { width, height } = firstImage;

        // Рисуем изображение на Canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        const imageData = ctx.createImageData(width, height);
        imageData.data.set(rgba);
        ctx.putImageData(imageData, 0, 0);

        return canvas.toDataURL('image/png');
    } catch (error) {
        console.error('Error processing TIFF file:', error);
        throw error;
    }
};
