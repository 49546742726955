import React from 'react';
import { StyledStartConsultationModal } from './startConsultationModal.styled';
import { Link } from 'react-router-dom';
import { ConsultationTimeAndPrice } from '../../../share/consultationTime/consultationTime';
import { ReactComponent as LogoModal } from '../closeConsultationModal/logo_modal.svg';
import { useTimer } from '../../../../hooks/useTimer';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';
import { ReactComponent as Cross } from '../../../../img/icons/cross-green.svg';
import { messageForNotExpired, messageForExpired } from '../../../share/helpers';

/**
 * Компонент модального окна для начала онлайн-консультации.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.consultation - Уникальный идентификатор консультации, используется для формирования ссылки на видеочат.
 * @param {Function} props.setIsOpen - Функция для управления состоянием модального окна (открытие/закрытие).
 * @param {string} props.patient - Имя и фамилия пациента, которые отображаются в модальном окне.
 * @param {Date} props.consultationDate - Время начала консультации.
 *
 * @returns {JSX.Element} Компонент модального окна.
 */
export const StartConsultationModal = ({ consultation, setIsOpen, patient, consultationDate }) => {
    /** Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара */
    useLockBodyScroll();

    /**
     * Хук для отслеживания оставшегося времени до указанной даты.
     */
    const { minutes, isExpired } = useTimer({ date: consultationDate, intervalMs: 60000, trackSeconds: false });

    const getTimeMessage = () => {
        if (minutes >= 0 && !isExpired) {
            return minutes > 60
                ? 'Подключение будет доступно за 5 минут до начала консультации'
                : messageForNotExpired(minutes);
        }
        return messageForExpired(minutes);
    };

    const renderActionButton = () => {
        if (minutes < 30 && isExpired) {
            return (
                <Link className="modalStart__delayBtn" to={`/videochat/${consultation}`}>
                    {/* <Link className="modalStart__delayBtn" to={`/`}> */}
                    Присоединиться
                </Link>
            );
        }
        // исправить затем на minutes <= 5 && !isExpired
        if (minutes <= 60 && !isExpired) {
            return (
                <Link className="modalStart__delayBtn" to={`/videochat/${consultation}`}>
                    {/* <Link className="modalStart__delayBtn" to={`/`}> */}
                    Начать
                </Link>
            );
        }
        return (
            <button onClick={closeModal} className="modalStart__delayBtn">
                Ок
            </button>
        );
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <StyledStartConsultationModal>
            <div className="modalStart">
                <Cross className="modalStart__cross-icon" onClick={closeModal} />
                <LogoModal />
                <div>
                    <h3 className="modalStart__heading">Онлайн-консультация</h3>
                    <p className="modalStart__namePatient">{patient}</p>
                    <ConsultationTimeAndPrice date={consultationDate} isModal />
                </div>
                <div className="modalStart__timeBeforeConsultation">{getTimeMessage()}</div>
                {renderActionButton()}
            </div>
        </StyledStartConsultationModal>
    );
};
