import React, { useEffect, useState } from 'react';
import { Parser } from 'html-to-react';
import { ArticleCommentsStyled } from './articleComments.styled';
import { dateFormatterFullMnth } from '../../../share/helpers';
import avatar from '../../../../img/user-avatar-template.png';
import { useDispatch } from 'react-redux';
import { fetchArticlePage } from '../../api/blogSlice';
import { Spinner } from '../../../share/Spinner';

/**
 * Компонент ArticleComments отвечает за отображение списка комментариев к статье,
 * с возможностью загрузки дополнительных комментариев, если они существуют.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.comments - Список комментариев.
 * @param {string} props.postId - ID статьи, к которой относятся комментарии.
 * @param {boolean} props.hasNextLink - Указывает, есть ли дополнительные комментарии для загрузки.
 * @param {boolean} props.isLoading - Указывает на процесс загрузки данных.
 * @returns {JSX.Element} Компонент для отображения комментариев.
 */

export const ArticleComments = ({ comments, postId, hasNextLink, isLoading }) => {
    const parser = new Parser();
    const dispatch = useDispatch();
    const [isLoadingComments, setIsLoadingComments] = useState(false);

    function showMoreComments() {
        setIsLoadingComments(true);
        dispatch(fetchArticlePage(postId));
    }

    useEffect(() => {
        if (!isLoading) {
            setIsLoadingComments(false);
        }
    }, [isLoading]);

    return (
        <ArticleCommentsStyled>
            {Boolean(!comments?.length) && (
                <p className="emptyComments">Комментарии не найдены. Будьте тем, кто прокомментирует первым!</p>
            )}
            <div className="comments">
                {comments?.map((comment) => (
                    <div className="comment" key={comment.id}>
                        <div className="dateOfCreation">
                            <p className="dateComment">{dateFormatterFullMnth(comment.created)}</p>
                        </div>
                        <div className="authorAvatar">
                            <img src={comment.author.avatar ? `${comment.author.avatar}` : `${avatar}`} alt="avatar" />
                        </div>
                        <div className="commentHeader">
                            <p className="authorName">
                                {comment?.author.first_name
                                    ? comment?.author.first_name.charAt(0).toUpperCase() +
                                      comment.author?.first_name.slice(1)
                                    : 'Пользователь'}
                            </p>
                        </div>
                        <div className="commentInfo">
                            <div className="textContent">{parser.parse(comment.text)}</div>
                        </div>
                    </div>
                ))}
            </div>
            {isLoadingComments && (
                <div className="centerSpinner">
                    <Spinner />
                </div>
            )}
            {Boolean(hasNextLink) && (
                <div className="articleFooterButton" onClick={showMoreComments}>
                    <p className="showCommentsButton">Показать больше</p>
                </div>
            )}
        </ArticleCommentsStyled>
    );
};
