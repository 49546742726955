import styled from 'styled-components';
import iconGoBack from '../../../../../img/icons/icon-arrow-back.svg';

export const PatientConsultationPageStyled = styled.div`
    .red {
        color: #ff3636;
    }

    .green {
        color: #19be6f;
    }

    .go-back-btn {
        color: #19be6f;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        margin-bottom: 50px;
        &:before {
            content: url(${iconGoBack});
            padding-right: 4px;
        }
        @media (max-width: 545px) {
            margin-bottom: 32px;
        }
    }
    .consultation-data {
        display: flex;
        flex-direction: column;
        gap: 36px;
        max-width: 690px;
        @media (max-width: 545px) {
            gap: 32px;
        }
    }

    .statusConsultation {
        transition: all 0.3s;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
    }

    .consultation-schedule {
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 545px) {
            gap: 16px;
        }
    }

    .patient-data {
        padding: 20px 52px;
        border: 2px solid #f4f4f4;
        border-radius: 10px;

        @media (max-width: 769px) {
            padding: 20px;
        }

        @media (max-width: 545px) {
            padding: 20px 16px;
        }
    }

    .recommendationWarning {
        font-size: 20px;
        line-height: 28px;

        @media (max-width: 450px) {
            font-size: 18px;
            line-height: 23.4px;
        }

        @media (max-width: 340px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
        .patient-data {
            gap: 20px;
        }
    }

    @media (max-width: 520px) {
        .consultationFormButtons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        .statusConsultation {
            font-size: 18px;
        }

        .schedule-data--box {
            span {
                font-size: 16px;
            }
            img {
                width: 15px;
                height: 15px;
            }
        }
    }
`;
