import styled from 'styled-components';

export const TitleWithPencilStl = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
        font-family: Rubik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 24px;

        @media (max-width: 1024px) {
            font-size: 22px;
        }
        @media (max-width: 767px) {
            font-size: 20px;
        }
    }
    .subtitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 16px;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }

    svg {
        cursor: pointer;
    }
`;
