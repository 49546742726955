import React from 'react';
import { ReactComponent as ModerationSvg } from '../../img/icons/icon_info.svg';
import { ModerationLabelStl } from './moderationLabelStl.styled';

/**
 * Компонент ModerationLabel отображает метку, если профиль находится на модерации.
 * Если профиль утвержден (параметр `isApproved` равен `true`), компонент не отображается.
 * В противном случае, компонент отображает текст "Профиль на модерации" и иконку с предупреждением.
 *
 * @component
 * @param {Object} props
 * @param {boolean} [props.isApproved=false] Флаг, показывающий, утвержден ли профиль.
 * Если `true`, компонент не будет отображаться.
 *
 * @returns {JSX.Element|null} Возвращает JSX с меткой модерации, если профиль не утвержден, или `null`, если профиль утвержден.
 *
 * @example
 * // Пример использования компонента
 * <ModerationLabel isApproved={false} />
 * // Профиль не утвержден, метка будет отображаться.
 */
export const ModerationLabel = ({ isApproved = false }) => {
    if (isApproved) {
        return null;
    } else {
        return (
            <ModerationLabelStl>
                <ModerationSvg />
                <span className="text">Профиль на модерации</span>
            </ModerationLabelStl>
        );
    }
};
