import React from 'react';
import Container from '../../componets/share/Container';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import { StyledUseful } from './Useful.styles';
import { Link } from 'react-router-dom';

/**
 * Компонент, отображающий блок с полезной информацией.
 * Содержит меню с несколькими ссылками, каждая из которых ведет на соответствующие страницы.
 *
 * @returns {JSX.Element} Рендерит меню с четырьмя ссылками и изображениями.
 */
const Useful = () => {
    const menuArray = [
        {
            id: 1,
            link: 'news',
            text: 'Блог',
            img: '/img/useful/useful_blog.png',
        },
        {
            id: 2,
            link: 'vacancies',
            text: 'Вакансии',
            img: '/img/useful/useful_vacancies.png',
        },
        {
            id: 3,
            link: 'reviews',
            text: 'Отзывы',
            img: '/img/useful/useful_reviews.png',
        },
        {
            id: 4,
            link: 'politics',
            text: 'Юридическая информация',
            img: '/img/useful/useful_legal.png',
        },
    ];

    return (
        <StyledUseful>
            <Container>
                <MainHeader title={'Полезное'} />
                <div className="useful">
                    {menuArray.map((item) => (
                        <div className="useful__item" key={item.id}>
                            <Link to={`/${item.link}`} className="useful__link">
                                <img src={item.img} alt={item.text} className="useful__image" />
                                <span className="useful__text">{item.text}</span>
                            </Link>
                        </div>
                    ))}
                </div>
            </Container>
        </StyledUseful>
    );
};
export default Useful;
