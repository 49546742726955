import React from 'react';
import { BlogMenuStyled } from './blogMenu.styled';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Компонент BlogMenu
 * 
 * React-компонент для отображения меню с темами блога. Каждая тема представлена в виде ссылки, которая позволяет 
 * перейти к списку статей по выбранной теме.
 * 
 * @component
 * 
 * @returns {JSX.Element} JSX-разметка компонента.
 * */

export const BlogMenu = () => {
    const navigate = useNavigate();
    const themes = useSelector((state) => state.blog.themes);
    const { themeId } = useParams();

    const renderLinks = themes.map((theme) => (
        <p
            key={theme.id}
            className={+themeId === theme.id ? 'themeLink activeTheme' : 'themeLink'}
            onClick={() => {
                navigate(`/news/${theme.id}`);
            }}
        >
            {theme.title}
        </p>
    ));

    return <BlogMenuStyled>{renderLinks}</BlogMenuStyled>;
};
