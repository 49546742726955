import styled from 'styled-components';

export const StyledDoctorSchedule = styled.div`
    width: 32%;

    .navMenu {
        display: flex;
        align-items: center;
        gap: 11px;
        & p {
            justify-self: flex-start;
            font-weight: 400;
            font-size: 16px;
            padding: 4px 9px;
            cursor: pointer;
            outline: none;
            border-radius: 24px;
            border: 2px solid transparent;
            transition: all 0.3s linear;
            text-align: center;
            :hover {
                border-color: #19be6f;
            }
            @media (max-width: 320px) {
                font-size: 14px;
                padding: 2px 8px;
            }
        }
        @media (max-width: 600px) {
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 14px;
            margin-bottom: 0px;
        }
        @media (max-width: 320px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 14px;
            margin-bottom: 0px;
        }
    }

    .navMenu__input {
        display: none;
    }

    .calendar-container {
        margin-top: 0px;
        margin-left: 5px;
        &__text {
            color: #666666;
            line-height: 23.5px;
            &_clinic {
                margin-top: 33px;
            }
        }
        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 130%;
            margin-top: 0px;
            margin-left: 0px;
        }
        @media (max-width: 320px) {
            margin-top: 12px;
            margin-left: 5px;
        }
    }

    .consultPrice {
        margin-top: 24px;
    }

    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;

        & p {
            font-weight: 400;
            font-size: 16px;
        }

        > p:last-child {
            font-size: 18px;
            padding-right: 17px;
            white-space: nowrap;
            @media (max-width: 768px) {
                padding-right: 21px;
            }
            @media (max-width: 320px) {
                padding-right: 3px;
            }
        }
        @media (max-width: 768px) {
            margin-top: 22px;
        }
    }

    .scheduleBtn {
        background: #19be6f;
        border-radius: 6px;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        padding: 11px 43px;
        border: 0;
        cursor: pointer;
        margin-top: 24px;
        &-disabled {
            display: inline-block;
            opacity: 0.5;
            cursor: default;
        }
    }

    .active {
        margin-bottom: 10px;
        color: #fff;
        background: #19be6f;
        border-radius: 24px;
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 27px;
        @media (max-width: 320px) {
            margin-top: 21px;
            margin-bottom: 17px;
        }
    }

    @media (max-width: 1024px) {
        width: 35%;
        .price {
            & p {
                font-size: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        width: 50%;
    }
    @media (max-width: 768px) {
        margin-top: 21px;
    }
    @media (max-width: 575px) {
        width: 100%;
        .scheduleBtn {
            width: 100%;
            margin-bottom: 4px;
        }
    }
`;
