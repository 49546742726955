import styled from 'styled-components';
import { FieldWrapper } from './FieldWrapper';

export const DateFieldStl = styled(FieldWrapper)`
    max-width: max-content;

    > input {
        padding: 10px 16px 10px 16px;
        width: 156px;
    }
`;
