import React from 'react';
import { CalendarBodyStl } from './calendarBody.styled';
import { WeekDays } from './weekDays/WeekDays';
import { Days } from './days/Days';

/**
 * Компонент тела календаря, который состоит из отображения дней недели и самих дней месяца.
 *
 * @param {Date} choosenDate - Текущая выбранная дата
 * @param {Function} setChoosenDate - Функция для обновления выбранной даты
 * @param {boolean} isDays - Флаг, указывающий, выбраны ли отдельные дни
 * @param {Function} setChoosenDates - Функция для обновления массива выбранных дат
 * @param {Array} choosenDates - Массив выбранных дат
 *
 * @returns {JSX.Element} Рендерит тело календаря с днями недели и днями месяца
 */

export const CalendarBody = ({
    choosenDate,
    setChoosenDate,
    isDays,
    setChoosenDates,
    choosenDates,
    savedAndChoosen,
}) => {
    return (
        <CalendarBodyStl>
            <WeekDays />
            <Days
                choosenDate={choosenDate}
                setChoosenDate={setChoosenDate}
                isDays={isDays}
                setChoosenDates={setChoosenDates}
                choosenDates={choosenDates}
                savedAndChoosen={savedAndChoosen}
            />
        </CalendarBodyStl>
    );
};
