import { StyledAvatar, StyledLink } from './avatar.styled';
import React from 'react';
// import { consultationDelete } from '../../lkPatient/api/patientApi';
import { identifyWhoAreYou } from '../helpers/identifyWhoAreYou';

/**
 * Компонент Avatar, отображающий аватар пользователя с возможностью перехода в личный кабинет.
 * @component
 * @param {string} role - Роль пользователя, определяющая путь для перехода по аватару (пациент, доктор или неавторизованный пользователь).
 * @param {string} avatar - URL аватара пользователя. Если аватар не указан, используется изображение по умолчанию.
 * @param {number} [size=24] - Размер аватара. По умолчанию используется значение 24px.
 *
 * @returns {JSX.Element} Возвращает компонент с изображением аватара и ссылкой на личный кабинет.
 *
 * @example
 * <Avatar role="patient" avatar="path/to/avatar.jpg" size={48} />
 *
 * @function identifyWhoAreYou - Функция, определяющая путь для перехода в зависимости от роли пользователя.
 */

function Avatar({ role, avatar, size = 24 }) {
    const path = identifyWhoAreYou(role);
    return (
        <StyledLink to={path}>
            <StyledAvatar src={avatar ? avatar : '/img/header/profile.svg'} alt="profile avatar" size={size} />
        </StyledLink>
    );
}

export default Avatar;
