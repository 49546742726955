import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { FeedbackFormStl } from './feedbackForm.styled';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientConsultation, createFeedback } from '../../../../../api/PatientConsultationsSlice';
import { FeedbackValue } from '../../../../../../share/feedbackValue/FeedbackValue';
import { useFormDirty } from '../../../../../../../routes/FormDirtyContext';
import { TextArea } from '../../../../../../share/formFields/TextArea';
import { SymbolCountTextArea } from '../../../../../../share/symbolCountTextArea/SymbolCountTextArea';

/**
 * Компонент формы для создания отзыва о консультации.
 * Поддерживает работу с состоянием формы, отправкой данных на сервер,
 * сохранением данных в `sessionStorage` и обработкой грязного состояния формы.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {number} props.consultationId ID консультации, для которой создается отзыв.
 * @returns {JSX.Element} Компонент формы отзыва.
 */
export const FeedbackForm = ({ consultationId }) => {
    const isLoading = useSelector((state) => state.patientConsultations.isLoading);
    const [isLoaderData, setIsLoaderData] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const dispatch = useDispatch();
    const { setIsFormDirty } = useFormDirty();

    const savedData = useMemo(() => {
        const data = sessionStorage.getItem('formData');
        return data ? JSON.parse(data) : null;
    }, []);

    const defaultValues = useMemo(() => savedData || { inputFeedback: { text: '', rating: 0 } }, [savedData]);

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const { isValid, isSubmitting, isDirty } = useFormState({ control: methods.control });

    useEffect(() => {
        const subscription = methods.watch((value) => {
            sessionStorage.setItem('formData', JSON.stringify(value));
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    /** Установка флаг грязного состояния при изменении формы */
    const handleFormChange = useCallback(() => {
        setIsFormDirty(true);
    }, []);

    /** Сброс грязного состояния формы при успешной отправке */
    const resetFormDirty = useCallback(() => {
        setIsFormDirty(false);
    }, []);

    /**  Обновлене состояния грязной формы, если флаг isDirty изменился*/
    useEffect(() => {
        setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    /**
     * Обработчик отправки формы отзыва.
     * Отправляет данные на сервер и сбрасывает состояние формы после успешной отправки.
     *
     * @async
     * @function
     * @param {Object} inputFeedback Объект данных отзыва.
     * @param {string} inputFeedback.text Текст отзыва.
     * @param {number} inputFeedback.rating Рейтинг отзыва.
     * @returns {Promise<void>} Промис, который завершается после завершения отправки данных.
     */
    const onSubmit = async ({ inputFeedback }) => {
        setIsLoaderData(true);

        const data = {
            text: inputFeedback.text,
            rating: inputFeedback.rating,
        };

        try {
            // console.log({ consultationId, data });
            await dispatch(createFeedback({ consultationId, data })).unwrap();
            resetFormDirty();
            sessionStorage.removeItem('formData');
            dispatch(getPatientConsultation(consultationId));
        } catch (error) {
            console.error('Ошибка при отправке отзыва пациента:', error);
        } finally {
            setIsLoaderData(false);
        }
    };

    return (
        <FeedbackFormStl>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} onChange={handleFormChange}>
                    <FeedbackValue name="inputFeedback.rating" control={methods.control} />
                    <SymbolCountTextArea
                        title="Отзыв"
                        placeholder="По желанию оставьте комментарий"
                        name="inputFeedback.text"
                    />
                    <p className="rules">
                        Перед отправкой отзыва, пожалуйста, ознакомьтесь с{' '}
                        <Link to="/regulations_reviews" className="rules-link">
                            {' '}
                            правилами публикации
                        </Link>
                        .
                    </p>
                    <button className="reviewBtn" type="submit" disabled={!isValid || isSubmitting || isLoaderData}>
                        Оставить отзыв
                    </button>
                </form>
            </FormProvider>
        </FeedbackFormStl>
    );
};
