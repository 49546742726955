import React, { useEffect, useState } from 'react';
import { StyledOurDoctorsPage } from './ourDoctorsPage.styled';
import Container from '../../componets/share/Container';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetDoctors, resetDoctors } from '../../componets/ourDoctors/api/doctorsSlise';
import { Filters } from '../../componets/ourDoctors/filters/Filters';
import { DoctorList } from '../../componets/ourDoctors/doctorList/DoctorList';
import { fetchGetFutureConsultations } from '../../componets/lkPatient/api/PatientConsultationsSlice';
import { useNavigate } from 'react-router-dom';

/**
 * Компонент OurDoctorsPage.
 * Отображает список врачей с фильтрацией и сортировкой.
 *
 * @component
 * @returns {JSX.Element} Компонент страницы с врачами.
 */
export const OurDoctorsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { patientConsultations } = useSelector((state) => state.patientConsultations);
    const whoAreYou = useSelector((state) => state.authentification.whoAreYou);
    const isAuth = useSelector((state) => state.authentification.isAuth);
    const {
        future: { consultations },
    } = patientConsultations;

    useEffect(() => {
        if (isAuth) {
            dispatch(fetchGetFutureConsultations({ limit: 0 })).unwrap();
        }
    }, [isAuth]);
    /**
     * Список всех врачей, полученный из Redux хранилища.
     * @type {Array<Object>}
     */
    const allDoctors = useSelector((state) => state.doctors.doctors);

    /**
     * Локальное состояние фильтров для поиска, специализации и сортировки.
     * @type {Object}
     * @property {string} search - Строка поиска для фильтрации врачей по имени.
     * @property {integer} spec - Идентификатор специальности для фильтрации врачей по специальности, пустое значение — пустая строка. Возможные значения: 1, 2, 3.
     * @property {string} sort - Поле для сортировки списка врачей: 'price', '-price', '-rating_average', '-seniority'.
     * @type {string|null} savedFilters - JSON-строка с сохраненными параметрами фильтрации или null,
     * если данных нет.
     * Используется для восстановления состояния фильтров при возврате на страницу без потери введенных значений.
     */
    const savedFilters = sessionStorage.getItem('filters');
    const [filters, setFilters] = useState(
        savedFilters ? JSON.parse(savedFilters) : { search: '', spec: '', sort: '' }
    );

    useEffect(() => {
        if (whoAreYou === 'doctor') {
            navigate('/');
        }
    }, [whoAreYou]);

    useEffect(() => {
        /**
         * Создает данные запроса для получения врачей с учетом фильтров.
         * После каждого обновления фильтров сбрасывает и загружает список врачей.
         */
        const data = {
            search: filters.search,
            ordering: filters.sort,
            spec_ids: filters.spec ? filters.spec : '',
        };

        const visitedDoctorPage = sessionStorage.getItem('visitedDoctorPage');
        const pagesLoaded = Number(sessionStorage.getItem('pagesLoaded')) || 1;

        if (visitedDoctorPage) {
            sessionStorage.removeItem('visitedDoctorPage');

            if (pagesLoaded > 1) {
                // Загружается столько страниц, сколько было загружено до перехода на врача.
                const fetchDoctors = async () => {
                    dispatch(resetDoctors());
                    for (let i = 1; i <= pagesLoaded; i++) {
                        await dispatch(fetchGetDoctors(data));
                    }
                };
                fetchDoctors();
            } else {
                dispatch(resetDoctors());
                dispatch(fetchGetDoctors(data));
            }
        } else {
            sessionStorage.removeItem('visitedDoctorPage');
            sessionStorage.removeItem('pagesLoaded');
            dispatch(resetDoctors());
            dispatch(fetchGetDoctors(data));
        }
    }, [filters, dispatch]);

    // Записывается обновленное значение фильтров в sessionStorage и очищаются координаты прокрутки.
    useEffect(() => {
        const prevFilters = JSON.parse(sessionStorage.getItem('filters'));
        if (JSON.stringify(prevFilters) !== JSON.stringify(filters)) {
            sessionStorage.setItem('filters', JSON.stringify(filters));
            sessionStorage.removeItem('scrollPosition');
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [filters]);

    useEffect(() => {
        // Очистка sessionStorage при уходе со страницы врачей.
        return () => {
            if (!location.pathname.includes('/doctors')) {
                sessionStorage.removeItem('filters');
                sessionStorage.removeItem('pagesLoaded');
                sessionStorage.removeItem('visitedDoctorPage');
                sessionStorage.removeItem('scrollPosition');
            }
        };
    }, [location.pathname]);

    return (
        <StyledOurDoctorsPage>
            <Container>
                <MainHeader title={'Наши врачи'} />
                <Filters filters={filters} setFilters={setFilters} />
                <DoctorList allDoctors={allDoctors} consultations={consultations} />
            </Container>
        </StyledOurDoctorsPage>
    );
};
