import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ModalSuccessfulConsultation } from '../lkDoctorModals/modalSuccessfulConsultation/ModalSuccessfulConsultation.jsx';
import { Modal } from '../../share/Modal.jsx';
import { setIsModalSuccessfulConsultationDoctor } from '../api/lkDoctorConsultationsSlice';
import { useSelector } from 'react-redux';

/**
 * Компонент `LkDoctorConsultations`.
 *
 * Компонент управляет консультациями в личном кабинете доктора.
 * Выполняет перенаправление и загрузку данных при монтировании.
 *
 * @component
 * @returns {JSX.Element} Компонент с дочерними маршрутами.
 */

export const LkDoctorConsultations = () => {
    const { pathname } = useLocation();
    const redirect = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (pathname === '/lk/doctor-profile/consultations') {
            redirect('/lk/doctor-profile/consultations/future', { replace: true });
        }
    }, [pathname]);

    return (
        <>
            <Outlet />
        </>
    );
};
