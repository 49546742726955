import React, { useEffect, useLayoutEffect } from 'react';
import {
    validateInputOrganization,
    validateInputPosition,
    validatePartialWorkDate,
} from '../../../share/formFields/validateInput';
import Checkbox from '../../../share/formFields/newElements/Checkbox';
import InputDate from '../../../share/formFields/InputDate';
import InputText from '../../../share/formFields/InputText';
import { useFormContext } from 'react-hook-form';

/**
 * Компонент `WorkList` представляет собой форму для ввода информации о работе.
 * Он используется для отображения и редактирования данных о рабочем опыте, включая организацию, должность,
 * даты начала и окончания работы, а также флаг "По настоящее время".
 *
 * @param {Object} props - Пропсы компонента.
 * @param {string} props.name - Имя поля в форме (используется для динамической работы с именами полей).
 *
 * @returns {JSX.Element} Компонент, отображающий форму с полями для ввода данных о работе.
 */
export function WorkList({ name }) {
    const { watch, setValue, setError, trigger } = useFormContext();
    const index = parseInt(name.match(/\[(\d+)\]/)[1], 10);
    const untilNow = watch(`${name}.until_now`);
    const watchedWork = watch('data.work');

    /**
     * Эффект для очистки или установки значения поля `work_to`, если флаг `until_now` изменился.
     * Если флаг `until_now` установлен, поле `work_to` очищается.
     * Если флаг снят и в текущем блоке нет значения `work_to`, оно устанавливается из состояния.
     */
    useEffect(() => {
        if (untilNow) {
            setValue(`${name}.work_to`, ''); // Очищаем поле "work_to"
        } else if (!watchedWork[index]?.work_to) {
            setValue(`${name}.work_to`, watchedWork[index]?.work_to || ''); // Устанавливаем значение из стейта
        }
    }, [untilNow, name, setValue, watchedWork, index]);

    // старый код, оставлен на всякий случай
    // const hideCheckbox = watchedWork.some((item, i, arr) => {
    //     return (!item.until_now && i != index) || arr.length === 1;
    // });

    /**
     * Проверка на скрытие чекбокса, если в другом блоке уже установлен флаг `until_now: true`.
     * Если флаг уже установлен в другом блоке, чекбокс в текущем блоке не будет отображаться.
     */
    const hideCheckbox = watchedWork.some((item, i) => {
        return item.until_now && i !== index;
    });

    /**
     * Функция для преобразования даты из формата `мм.гггг` в timestamp для сравнения дат.
     *
     * @param {string} str - Дата в формате `мм.гггг`.
     * @returns {number} Преобразованная дата в формате timestamp.
     */
    const createTime = (str) => new Date(str.slice(-4), +str.slice(0, 2) - 1).getTime();

    const validateCombinedEndDate = (value) => {
        /**
         * Валидатор для проверки даты окончания работы, который проверяет:
         * 1. Порядок дат (дата окончания должна быть позже даты начала).
         * 2. Корректность формата даты (мм.гггг).
         * 3. Не является ли дата будущей.
         * 4. Не является ли дата более ранней, чем 1950 год.
         *
         * @param {string} value - Дата окончания в формате `мм.гггг`.
         * @returns {string|boolean} Сообщение об ошибке или `true`, если дата валидна.
         */
        const work_from = watchedWork[index]?.work_from;
        if (work_from) {
            const work_from_date = createTime(work_from);
            const work_to_date = createTime(value);

            if (work_to_date <= work_from_date) {
                return 'Дата окончания должна быть позже даты начала';
            }
        }
        // Проверяем корректный формат
        if (!/^(0[1-9]|1[0-2])\.\d{4}$/.test(value)) {
            return 'Некорректный формат даты';
        }

        // Проверка на будущее
        const [month, year] = value.split('.');
        const enteredDate = new Date(year, month - 1, 1);
        const currentDate = new Date();
        if (enteredDate > currentDate) {
            return 'Дата не может быть в будущем';
        }

        // Проверяем минимальную дату
        const minDate = new Date(1950, 0); // Январь 1950 года
        if (enteredDate < minDate) {
            return 'Дата не может быть ранее 1950 года';
        }

        return true;
    };

    return (
        <>
            <InputText
                name={`data.work[${index}].organization.name`}
                label="Организация"
                placeholder="Название"
                validation={validateInputOrganization(true)}
                isRequired={true}
            />

            <InputText
                name={`data.work[${index}].position.name`}
                label="Должность"
                placeholder="Название"
                validation={validateInputPosition(true)}
                isRequired={true}
            />
            <div className="work__block">
                <div className="work__date">
                    <InputDate
                        name={`data.work[${index}].work_from`}
                        label="Начало"
                        placeholder="ММ.ГГГГ"
                        validation={validatePartialWorkDate(true)}
                        isRequired={true}
                        mask="99.9999"
                    />
                </div>
                {!untilNow && (
                    <div className="work__date">
                        <InputDate
                            name={`data.work[${index}].work_to`}
                            label="Конец"
                            placeholder="ММ.ГГГГ"
                            validation={{
                                required: { value: true, message: 'Поле обязательно для заполнения' },
                                pattern: {
                                    value: /^(0[1-9]|1[0-2])\.\d{4}$/,
                                    message: 'Некорректная дата. Ожидается формат mm.yyyy',
                                },
                                validate: validateCombinedEndDate,
                            }}
                            isRequired={true}
                            mask="99.9999"
                        />
                    </div>
                )}
            </div>
            {!hideCheckbox && (
                <div className="work__date-checkbox">
                    <Checkbox name={`data.work[${index}].until_now`} />
                    <span>По настоящее время</span>
                </div>
            )}
        </>
    );
}
