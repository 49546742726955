import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { StyledProblemForm } from './problemForm.styled';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { fetchMyConsultationUpdate, getPatientConsultation } from '../../api/PatientConsultationsSlice';
import { Spinner } from '../../../share/Spinner';
import { TextArea } from '../../../share/formFields/TextArea';
import { ConsultationFiles } from '../consultationList/patientConsultationPage/consultationFiles/ConsultationFiles';
import { useFormDirty } from '../../../../routes/FormDirtyContext';
import { ReactComponent as LowAttentionSvg } from '../../../../img/icons/icon_low_attention.svg';
import { SendCommentsModal } from '../../lkPatientModalsNew/sendCommentsModal/SendCommentsModal';
import { Modal } from '../../../share/Modal';
import { SymbolCountTextArea } from '../../../share/symbolCountTextArea/SymbolCountTextArea';

/**
 * Компонент `ProblemFormComponent` представляет форму для добавления комментариев пациента к консультации
 * и управления файлами, прикреплёнными к консультации.
 *
 * Компонент сохраняет введённые данные в `sessionStorage`, отслеживает состояние формы (валидность, отправка, "грязное" состояние)
 * и предоставляет пользователю возможность прикрепить файлы, а также отправить комментарий врачу.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.problem - Текущий комментарий к консультации (если он уже существует).
 * @param {string} props.consultationId - Уникальный идентификатор консультации.
 * @param {Array} props.files - Список файлов, прикреплённых к консультации.
 * @param {Array} props.progress_files - Прогресс загрузки текущего файла в процентах (0–100).
 * @param {string} props.status - Статус консультации.
 * @returns {JSX.Element} Элемент JSX, представляющий форму добавления комментария.
 */
const ProblemFormComponent = ({ problem, consultationId, files, progress_files, status }) => {
    const isLoading = useSelector((state) => state.patientConsultations.isLoading);
    const [isLoaderData, setIsLoaderData] = useState(false);
    const [sendCommentsConfirm, setSendCommentsConfirm] = useState(false);
    const dispatch = useDispatch();
    const { setIsFormDirty } = useFormDirty();
    const noPatientComments = status !== 0 && !problem && files.length === 0;

    /**
     * Извлечение сохраненных данных формы из `sessionStorage`.
     * Используется для восстановления введенных данных после обновления страницы.
     * @type {Object|null}
     */
    const savedData = useMemo(() => {
        const data = sessionStorage.getItem('formData');
        return data ? JSON.parse(data) : null;
    }, []);

    const defaultValues = useMemo(() => savedData || '', [savedData]);

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
    });

    const { isValid, isSubmitting, isDirty } = useFormState({ control: methods.control });

    /**
     * Сохранение данных формы в `sessionStorage` при любом изменении значений.
     */
    useEffect(() => {
        const subscription = methods.watch((value) => {
            sessionStorage.setItem('formData', JSON.stringify(value));
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    /** Установка флаг грязного состояния при изменении формы */
    const handleFormChange = useCallback(() => {
        setIsFormDirty(true);
    }, []);

    /** Сброс грязного состояния формы при успешной отправке */
    const resetFormDirty = useCallback(() => {
        setIsFormDirty(false);
    }, []);

    /**  Обновлене состояния грязной формы, если флаг isDirty изменился*/
    useEffect(() => {
        setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    /**
     * Обработчик отправки формы.
     * @param {Object} data - Данные формы, содержащие комментарий пользователя.
     * @param {string} data.inputProblem - Введенный пользователем комментарий.
     */
    const onSubmit = async ({ inputProblem }) => {
        setIsLoaderData(true);

        const data = {
            id: consultationId,
            patient_notes: inputProblem,
        };

        try {
            await dispatch(fetchMyConsultationUpdate(data)).unwrap();
            resetFormDirty();
            sessionStorage.removeItem('formData');
            dispatch(getPatientConsultation(consultationId));
        } catch (error) {
            console.error('Ошибка при обновлении жалоб пациента:', error);
        } finally {
            setIsLoaderData(false);
            setSendCommentsConfirm(false);
        }
    };

    const openSendCommentsModal = (e) => {
        e.stopPropagation();
        setSendCommentsConfirm(true);
    };

    return (
        <StyledProblemForm noPatientComments={noPatientComments}>
            <FormProvider {...methods}>
                <form className="problemForm" onChange={handleFormChange}>
                    {problem ? (
                        <>
                            <p className="problemForm__header">Комментарий для врача</p>
                            <p className="problemForm__text">{problem}</p>
                        </>
                    ) : status === 0 ? (
                        <SymbolCountTextArea
                            title="Комментарий для врача"
                            placeholder="По желанию оставьте комментарий"
                            name="inputProblem"
                        />
                    ) : null}
                    <ConsultationFiles
                        consultationId={consultationId}
                        files={files}
                        problem={problem}
                        status={status}
                        progress_files={progress_files}
                    />

                    {!problem && status === 0 && (
                        <div className="problemForm__submit">
                            <div className="attention">
                                <LowAttentionSvg className="lowAttention" />
                                <span className="submitComment">
                                    Комментарий и прикреплённые файлы нельзя будет изменить после отправки
                                </span>
                            </div>
                            <button
                                className="submitBtn"
                                type="button"
                                onClick={openSendCommentsModal}
                                disabled={!isValid || isSubmitting || isLoaderData}
                            >
                                {isLoading ? (
                                    <Spinner color={'2px solid green'} width={'20px'} height={'20px'} marginTop={'0'} />
                                ) : (
                                    'Отправить >'
                                )}
                            </button>
                        </div>
                    )}

                    {sendCommentsConfirm && (
                        <Modal toggle={setSendCommentsConfirm}>
                            <SendCommentsModal
                                setSendCommentsConfirm={setSendCommentsConfirm}
                                sendFiles={methods.handleSubmit(onSubmit)}
                            />
                        </Modal>
                    )}
                </form>
            </FormProvider>
        </StyledProblemForm>
    );
};

export const ProblemForm = React.memo(ProblemFormComponent);
