import { DateTime } from 'luxon';

// -------------------------------------------------------------------------
// преобразователи ученой степени и категории
/**
 * Возвращает ученую степень доктора по ее коду.
 * @param {string} degree - Код ученой степени. Возможные значения:
 *   - '1' — Кандидат медицинских наук.
 *   - '2' — Доктор медицинских наук.
 * @returns {string} Название ученой степени. Если код некорректен или отсутствует, возвращает "Ученая степень отсутствует".
 */
export const getScientificDegree = (degree) => {
    switch (degree) {
        case '1':
            return 'Кандидат медицинских наук';
        case '2':
            return 'Доктор медицинских наук';
        default:
            return 'Ученая степень отсутствует';
    }
};

/**
 * Возвращает категорию доктора по ее коду.
 * @param {string} category - Код категории. Возможные значения:
 *   - '1' — Врач первой категории.
 *   - '2' — Врач второй категории.
 *   - '3' — Врач высшей категории.
 * @returns {string} Название категории. Если код некорректен или отсутствует, возвращает "Врач не указал категорию".
 */
export const getDoctorCategory = (category) => {
    switch (category) {
        case '1':
            return 'Врач первой категории';
        case '2':
            return 'Врач второй категории';
        case '3':
            return 'Врач высшей категории';
        default:
            return 'Врач не указал категорию';
    }
};

// -------------------------------------------------------------------------
// конвертеры дат
/**
 * Парсит строку с датой и временем в объект Date.
 * @param {string} inputDateStr - Строка, представляющая дату и время, в формате "DD.MM.YYYY HH:mm:ss".
 * @returns {Date} Объект Date, представляющий указанную дату и время.
 */
export const parseDateFromString = (inputDateStr) => {
    const [datePart, timePart] = inputDateStr.split(' ');
    const [day, month, year] = datePart.split('.').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hours, minutes, seconds);
};

/**
 * Функция рассчитывает разницу во времени между текущим моментом и указанной датой.
 *
 * @function
 * @param {string} dateString - Дата в формате строки, например, ISO 8601.
 * @param {boolean} [trackSeconds=true] - Флаг, указывающий, нужно ли отслеживать секунды.
 * @returns {Object} Объект с расчетными значениями.
 * @returns {string} return.minutes - Количество минут (с ведущими нулями).
 * @returns {string|null} return.seconds - Количество секунд (с ведущими нулями) или null, если `trackSeconds` равен `false`.
 * @returns {boolean} return.isExpired - Флаг, указывающий, истекло ли время.
 */
export const calculateTimeDifference = (dateString, trackSeconds = true) => {
    if (!dateString) {
        return {
            minutes: '00',
            seconds: trackSeconds ? '00' : null,
            isExpired: false,
        };
    }
    const date = parseDateFromString(dateString);
    const targetDate = new Date(date).getTime();

    const now = new Date().getTime();

    const difference = now - targetDate; // Разница во времени.
    const isExpired = difference >= 0; // Проверка, истекло ли время.
    let absDifference = Math.abs(difference); // Абсолютная разница для расчета.

    if (isExpired) {
        absDifference += 1000; // Добавляем 1 секунду сразу после начала консультации
    }

    const minutes = Math.floor(absDifference / 1000 / 60);
    const seconds = trackSeconds ? Math.floor((absDifference / 1000) % 60) : null;

    return {
        minutes: trackSeconds ? String(minutes).padStart(2, '0') : String(minutes),
        seconds: trackSeconds ? String(seconds).padStart(2, '0') : null,
        isExpired,
    };
};

/**
 * Сравнивает текущую дату и время с указанной датой.
 * @param {string} time - Строка с датой и временем в формате "DD.MM.YYYY HH:mm:ss".
 * @returns {boolean|undefined} Возвращает true, если текущая дата и время меньше указанной даты,
 * false, если больше. Возвращает false, если аргумент не указан.
 */
export const dateComparator = (time) => {
    if (!time) return false;

    const now = new Date();
    const inputDate = parseDateFromString(time);

    return now < inputDate;
};

// проверить
// сравнивает даты и возвращает булево значение в зависимости от того - разница больше или меньше суток
// проверить для чего используется в компоненте StartConsultationModal
export const dateComparatorOneDay = (time) => {
    const now = new Date();
    const oneDay = 24 * 3600 * 1000;
    let res = time * 1000 - now.getTime() > oneDay;
    return res;
};

/**
 * Функция форматирует дату из строки формата "дд.мм.гггг чч:мм:сс" в вид "день месяц, день_недели".
 * @param {string} dateString - Входная строка даты в формате "дд.мм.гггг чч:мм:сс".
 * @returns {string} Отформатированная строка в формате "день месяц, день_недели" (например, "4 декабря, ср").
 * @example
 * getDateForConsultation("04.12.2024 14:00:00"); // "4 декабря, ср"
 * getDateForConsultation("25.01.2025 09:30:00"); // "25 января, сб"
 * getDateForConsultation(""); // "дата не выбрана"
 * getDateForConsultation(null); // "дата не выбрана"
 */
export function getDateForConsultation(dateString) {
    if (!dateString) return 'дата не выбрана';

    const date = parseDateFromString(dateString);

    const optionsForDate = { day: 'numeric', month: 'long' };
    const optionsForWeekDay = { weekday: 'short' };

    const formattedDate = date.toLocaleDateString('ru-RU', optionsForDate);
    const dayOfWeek = date.toLocaleDateString('ru-RU', optionsForWeekDay);

    return `${formattedDate}, ${dayOfWeek.replace('.', '').toLowerCase()}`;
}

/**
 * Функция извлекает время из строки формата "дд.мм.гггг чч:мм:сс" и возвращает его в формате "чч:мм".
 * @param {string} dateString - Входная строка даты и времени в формате "дд.мм.гггг чч:мм:сс".
 * @returns {string} Строка времени в формате "чч:мм".
 * @example
 * getTimeFromDate("04.12.2024 14:30:00"); // "14:30"
 * getTimeFromDate("25.01.2025 09:05:00"); // "09:05"
 * getTimeFromDate(""); // "время не выбрано"
 * getTimeFromDate(null); // "время не выбрано"
 */
export const getTimeFromDate = (dateString) => {
    if (!dateString) return 'время не выбрано';

    const date = parseDateFromString(dateString);
    const formattedTime = date.toLocaleTimeString().slice(0, -3);

    return formattedTime;
};

export const getTimeFromTimestampDate = (dateString) => {
    if (!dateString) return 'время не выбрано';

    const date = new Date(dateString * 1000);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
};

/**
 * Форматирует дату для отзывов из строкового представления даты.
 *
 * @param {string} dateString - Строка, представляющая дату.
 * @returns {string} - Форматированная дата в формате "день месяц", например, "15 декабря",
 * или сообщение "время не указано", если dateString не задан.
 */
export function getDateForReviews(dateString) {
    if (!dateString) return 'время не указано';

    const date = parseDateFromString(dateString);
    const options = { day: 'numeric', month: 'long' };
    const formattedDate = `${date.toLocaleDateString('ru-RU', options)}`;
    return formattedDate;
}

/**
 * Форматирует строку с датой для отображения в блоге.
 *
 * @param {string} dateString - Строка, представляющая дату и время, в формате "DD.MM.YYYY HH:mm:ss".
 * @returns {string} Отформатированная строка в формате "DD МММ YYYYг.", где МММ — краткое название месяца.
 * Если входная строка пуста или некорректна, возвращается пустая строка.
 */
const shortMonths = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

export function getDateForBlog(dateString) {
    if (!dateString) return '';

    const date = parseDateFromString(dateString);
    const formattedDate = `${date.getDate()} ${shortMonths[date.getMonth()]} ${date.getFullYear()}г.`;

    return formattedDate;
}

/**
 * Формирует сообщение для случаев, когда консультация ещё не началась.
 * @param {number} min - Количество минут до начала консультации.
 * @returns {string} Сообщение, информирующее, сколько минут осталось до начала консультации.
 */
export const messageForNotExpired = (min) => {
    const ending = min.toString().slice(-1);

    switch (true) {
        case min === 0:
            return 'Консультация началась';
        case ending === '1' && min !== '11':
            return `Осталась ${min} минута`;
        case ['2', '3', '4'].includes(ending) && ![12, 13, 14].includes(min % 100):
            return `Осталось ${min} минуты`;
        default:
            return `Осталось ${min} минут`;
    }
};

/**
 * Формирует сообщение для случаев, когда консультация уже началась.
 * @param {number} min - Количество минут, прошедших с начала консультации (отрицательное значение).
 * @returns {string} Сообщение, информирующее, сколько минут прошло с начала консультации.
 */
export const messageForExpired = (min) => {
    const ending = min.toString().slice(-1);

    switch (true) {
        case ending === '1' && min !== '11':
            return `Консультация началась ${Math.abs(min)} минуту назад`;
        case ['2', '3', '4'].includes(ending) && ![12, 13, 14].includes(min % 100):
            return `Консультация началась ${Math.abs(min)} минуты назад`;
        default:
            return `Консультация началась ${Math.abs(min)} минут назад`;
    }
};

/**
 * Форматирует строку даты в формате 'ДД.ММ.ГГГГ' в строку в формате 'ММ.ГГГГ'.
 *
 * Эта функция принимает строку, представляющую дату в формате 'ДД.ММ.ГГГГ', и возвращает строку в
 * формате 'ММ.ГГГГ', где 'ММ' — месяц, а 'ГГГГ' — год.
 *
 * Если входная дата не соответствует формату 'ДД.ММ.ГГГГ' или дата не передана, функция возвращает пустую строку.
 *
 * @param {string} date - Строка, представляющая дату в формате 'ДД.ММ.ГГГГ'.
 * @returns {string} Строка, представляющая дату в формате 'ММ.ГГГГ' или пустая строка, если дата некорректна.
 *
 * @example
 * // Пример использования
 * formatDateToMonthYear('12.11.2024');
 * // Возвращает '11.2024'
 */
export const formatDateToMonthYear = (date) => {
    if (!date || !/^\d{2}\.\d{2}\.\d{4}$/.test(date)) return '';

    const [day, month, year] = date.split('.');
    return `${month}.${year}`;
};

/**
 * Форматирует строку месяца и года в формате 'ММ.ГГГГ' в строку полной даты в формате '01.ММ.ГГГГ'.
 *
 * Эта функция принимает строку в формате 'ММ.ГГГГ' и добавляет к ней день '01', чтобы получить строку
 * в формате '01.ММ.ГГГГ', где '01' — стандартный день, а 'ММ.ГГГГ' — месяц и год.
 *
 * Если входная строка не соответствует формату 'ММ.ГГГГ' или строка не передана, функция возвращает пустую строку.
 *
 * @param {string} monthYear - Строка, представляющая месяц и год в формате 'ММ.ГГГГ'.
 * @returns {string} Строка в формате '01.ММ.ГГГГ' или пустая строка, если строка некорректна.
 *
 * @example
 * // Пример использования
 * formatToFullDate('11.2024');
 * // Возвращает '01.11.2024'
 */
export const formatToFullDate = (monthYear) => {
    if (!monthYear || !/^\d{2}\.\d{4}$/.test(monthYear)) return '';

    const [month, year] = monthYear.split('.');
    return `01.${month}.${year}`; // '01' - это стандартный день
};

/**
 * Форматирует даты работы врача для отображения на странице профиля.
 * @param {string} from - Дата начала работы в формате "дд.мм.гггг".
 * @param {string} [to] - Дата окончания работы в формате "дд.мм.гггг". Если не указана, считается, что врач продолжает работать.
 * @returns {string} Строка, представляющая форматированные даты работы врача.
 * @example
 * // Пример с датами
 * const workPeriod = getWorkDatesForDoctorProfilePage("01.01.2015", "31.12.2020");
 * // console.log(workPeriod); // "2015-2020 гг. "
 * @example
 * // Пример без даты окончания
 * const currentWorkPeriod = getWorkDatesForDoctorProfilePage("01.01.2021");
 * // console.log(currentWorkPeriod); // "2021г.-по наст.время "
 */
export const getWorkDatesForDoctorProfilePage = (from, to) => {
    const fromYear = from.slice(-4);
    const toYear = to ? to.slice(-4) : 'по наст.время';

    return `${fromYear}-${toYear} гг. `;
};

/**
 * Функция для вычисления возраста на основе заданной даты рождения.
 * Возвращает строку, содержащую возраст и правильное склонение слова "год/года/лет".
 *
 * @param {string} date - Дата рождения в строковом формате.
 *                        Может содержать любые разделители (например, "08-01-2025", "08.01.2025", "08/01/2025").
 *                        Ожидается, что дата начинается с дня, месяца и года: "DDMMYYYY".
 *
 * @returns {string} Возраст в виде строки с числом и правильным склонением слова, например: "30 лет", "1 год", "21 год".
 * - Если `date` не задан, возвращает строку `'Возраст не указан'`.
 *
 * @example
 * ageCalculator("08-01-1990"); // Возвращает "34 года" (если текущий год 2024).
 *
 * @example
 * ageCalculator("01.02.2000"); // Возвращает "24 года" (если текущий год 2024 и текущая дата до 1 февраля).
 */
export function ageCalculator(date) {
    if (!date) return 'возраст не указан';
    const now = new Date();
    const dateString = date.replace(/\D/g, '');
    const day = dateString.slice(0, 2);
    const month = dateString.slice(2, 4) - 1;
    const year = dateString.slice(4, 8);
    const birth = new Date(Date.UTC(year, month, day));
    let age = now.getFullYear() - birth.getFullYear();

    if (now.getMonth() < birth.getMonth() || (now.getMonth() === birth.getMonth() && now.getDate() < birth.getDate())) {
        age -= 1;
    }

    const titles = [' год', ' года', ' лет'];
    const cases = [2, 0, 1, 1, 1, 2];

    return age + titles[age % 100 > 4 && age % 100 < 20 ? 2 : cases[age % 10 < 5 ? age % 10 : 5]];
}

// -------------------------------------------------------------------------
// обработка входящих объектов
/**
 * Форматирует объект ошибок, выбирая первую ошибку для каждого ключа.
 * Если значение является массивом, выбирается первый элемент массива.
 * Если значение является строкой, оно добавляется в результат.
 * @param {Object} error - Объект ошибок, где ключи — это имена полей, а значения могут быть массивами строк или строками.
 * @returns {Object} Возвращает объект, где каждому ключу соответствует одна строка ошибки.
 *
 * @example
 * const error = {
 *   email: 'Этот пароль повторяет Ваш логин, выберите другой пароль',
 *   password: ['Этот пароль слишком распространён', 'Этот пароль повторяет Ваш логин, выберите другой пароль'],
 * };
 *
 * const formattedError = formatErrorMessage(error);
 * // Результат: { email: 'Этот пароль повторяет Ваш логин, выберите другой пароль', password: 'Этот пароль слишком распространён' }
 */
export const formatErrorMessage = (error) => {
    if (typeof error === 'object' && error !== null) {
        return Object.entries(error).reduce((acc, [key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                acc[key] = value[0];
            }
            if (typeof value === 'string' && value.length > 0) {
                acc[key] = value;
            }
            if (key === 'AxiosError' && value.length > 0) {
                acc[key] = 'Ошибка сети. Обратитесь в службу поддержки';
            }
            return acc;
        }, {});
    }
    return {};
};

/**
* Форматирует объект с данными, приходящими с сервера.
* Если значение является объектом и не являетяеся null, то перебираются поля этого объекта.
* Если значение является null, то оно заменяется пустой строкой ("").
* @param {Object} data - Объект с данными, где ключи — это имена полей, а значения могут быть null или строками.
* @returns {Object} Возвращает объект, где null заменено пустой строкой ("").

*/
export const formatNullData = (data) => {
    if (typeof data === 'object' && data !== null) {
        const mappedArr = Object.entries(data).map(([key, value]) => {
            if (value === null || value === undefined) {
                return [key, ''];
            }
            return [key, value];
        });
        return Object.fromEntries(mappedArr);
    }
    return {};
};

// -------------------------------------------------------------------------
/**
 * Проверка файла на допустимый формат и размер.
 *
 * @function
 * @param {File} file Объект файла для проверки.
 * @returns {Object} Результат проверки.
 * @returns {boolean} result.valid Указывает, прошел ли файл валидацию.
 * @returns {string} [result.error] Сообщение об ошибке, если файл не прошел проверку.
 */

// аргумент второй в фунции(arg) дает нам возможность указывать необходимые
//  форматы для загрузки файлов, н-р,['.jpg', '.jpeg', '.tiff', '.png', '.pdf', '.doc', '.docx', '.xls']
export const validateFile = (file, arg) => {
    const allowedFormats = arg ? arg : ['.jpg', '.jpeg', '.tiff', '.png', '.pdf', '.doc', '.docx', '.xls'];
    const maxFileSize = 2 * 1024 * 1024; // 2MB

    // Проверка формата файла
    const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
    if (!allowedFormats.includes(fileExtension)) {
        return { valid: false, error: `Недопустимый формат файла. Допустимые форматы: ${allowedFormats.join(', ')}` };
    }

    // Проверка размера файла
    if (file.size > maxFileSize) {
        return { valid: false, error: 'Размер файла не должен превышать 2 МБ' };
    }

    return { valid: true };
};

/**
 * Вычисляет разницу во времени между двумя датами в минутах.
 *
 * @param {Date} startTime - Начальное время (объект Date).
 * @param {Date} endTime - Конечное время (объект Date).
 * @returns {number} Количество полных минут между startTime и endTime.
 * Возвращает 0, если один из параметров отсутствует.
 *
 * @example
 * const start = new Date('2025-01-23T10:00:00');
 * const end = new Date('2025-01-23T10:30:00');
 * const elapsedMinutes = calculateElapsedTime(start, end);
 * // console.log(elapsedMinutes); // 30
 */
export const calculateElapsedTime = (startTime, endTime) => {
    if (startTime && endTime) {
        const elapsedMilliseconds = endTime - startTime;
        const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
        return elapsedSeconds;
    }
    return 0;
};

// -------------------------------------------------------------------------
// ПРОВЕРИТЬ!!!!!!!!!
// Конвертирует дату с Бэка в человекочитаемый код
export const dateFormatter = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    return `${dd}.${mm}.${yyyy}г.`;
};
export const dateFormatterForDoctorWork = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    return `${mm}.${yyyy}`;
};
export const dateFormatterForFormDoctor = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    return { dd, mm, yyyy };
};
export const dateFormatterShortened = (time) => {
    const date = new Date(time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let mm = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${dd}.${mm}`;
};

export const isHtmlString = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body.children.length > 0;
};

export const parseHTMLToText = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc;
};

// эти хэлперы не будут использоваться - к удалению
export const dateToUnix = (year = '0000', month = '01', day = '01') => {
    return Math.floor(new Date(`${year}.${month}.${day}`).getTime() / 1000);
};
export const dateToUnixMY = (date) => {
    const [month, year] = date.split('/');
    const dateUnix = Math.floor(new Date(year, month - 1, 1, 4, 0, 0, 0).getTime() / 1000);
    return dateUnix;
};

// проверить - будем ли использовать
export const dateFormatterYears = (time) => {
    const date = new Date(time * 1000);
    let yyyy = date.getFullYear();

    return `${yyyy}`;
};

/**
 * Массив с полными названиями месяцев на русском языке в родительном падеже.
 *
 * @constant {string[]} longMonths
 * @default
 */
const longMonths = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
];

/**
 * Форматирует дату в строку с полным названием месяца.
 *
 * Конвертирует дату из строки формата "ДД.ММ.ГГГГ ЧЧ:ММ:СС" в строку формата
 * "ДД <Полное название месяца> ГГГГ".
 *
 * @param {string} time - Время в формате "ДД.ММ.ГГГГ ЧЧ:ММ:СС".
 * @returns {string} Форматированная строка даты в виде "ДД <Полное название месяца> ГГГГ"
 *                  или пустая строка, если входные данные не заданы.
 *
 * @example
 * const result = dateFormatterFullMnth("03.11.2024 15:30:00");
 * // console.log(result); // "3 ноября 2024"
 */
// Конвертирует дату в формат день(цифрами):месяц(прописью):год(цифрами)
export const dateFormatterFullMnth = (time) => {
    if (!time) return '';
    const [datePart, timePart] = time.split(' ');
    const [day, month, year] = datePart.split('.').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    const formattedDate = `${day} ${longMonths[date.getMonth()]} ${date.getFullYear()}`;
    // Результат выполнения: 3 ноября 2024

    return formattedDate;
};

// Конвертирует дату в формат день(цифрами):месяц(прописью):год(цифрами) для PayCard в lkDoctorWallet/transactions
export const dateFormatterFullMnthForPayCard = (time) => {
    const date = new Date(time * 1000);
    const dd = date.getDate().toString().padStart(2, '0');
    const mm = date.getMonth();
    const yyyy = date.getFullYear();
    const monthNamesGenitive = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];
    const monthGenitive = monthNamesGenitive[mm];
    // Результат выполнения: 24 декабря 2022
    return `${dd} ${monthGenitive} ${yyyy}`;
};

// Получение текущего дня недели ("Понедельник")
export const getWeekDay = (time) => {
    const date = new Date(time * 1000).getDay();
    const days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
    const weekDay = days[date];
    return weekDay;
};

export const dateFormatterFullMnth3 = (time) => {
    const date = new Date(+time * 1000);
    let dd = date.getDate().toString().padStart(2, '0');
    let month = date.toLocaleString('ru', { month: 'long' });
    let dayWeek = getWeekDay(time);

    return `${dd} ${month}, ${dayWeek}`;
};

// исплоьзуется в любимых докторах - под удаление
// принимает стаж (seniority) в годах, возвращает стаж в годах с правильным окончанием (год/года/лет)
// getSeniorityWithEnd;
export function getSeniorityWithEnd(seniority) {
    const doctorSeniority = Math.floor(seniority);
    const titles = [' год', ' года', ' лет'];
    const cases = [2, 0, 1, 1, 1, 2];
    return (
        doctorSeniority +
        titles[
            doctorSeniority % 100 > 4 && doctorSeniority % 100 < 20
                ? 2
                : cases[doctorSeniority % 10 < 5 ? doctorSeniority % 10 : 5]
        ]
    );
}

// не понатно где используется - проверить
// из числа n делает массив от 1 до n
export const numberToArray = (number) => {
    return Array.from({ length: number }, (_, i) => i + 1);
};

// используется в кошельке - под удаление
// начальные значения для фильтра в кошельке доктора
export const initialFilterYear = 'год';
export const initialFilterMonth = 'месяц';

export const monthsOfYear = [
    { name: 'все', code: initialFilterMonth },
    { name: 'январь', code: '1' },
    { name: 'февраль', code: '2' },
    { name: 'март', code: '3' },
    { name: 'апрель', code: '4' },
    { name: 'май', code: '5' },
    { name: 'июнь', code: '6' },
    { name: 'июль', code: '7' },
    { name: 'август', code: '8' },
    { name: 'сентябрь', code: '9' },
    { name: 'октябрь', code: '10' },
    { name: 'ноябрь', code: '11' },
    { name: 'декабрь', code: '12' },
];
// export const years = ['2024', '2023', '2022', '2021', '2020', '2019'];
function calcYearsArr() {
    const arr = [];
    let year = new Date().getFullYear();
    while (year >= 2019) {
        arr.push(year);
        year--;
    }
    return arr;
}
export const years = calcYearsArr();

// функция проверяет что введены буквы на любом языке (имена, отчества)
export const isEnteredLetter = (str) => {
    return RegExp(/^[\p{L}]*$/, 'u').test(str);
};
export function formatPhoneNumber(phoneNumber) {
    const countryCode = phoneNumber.slice(0, 2);
    const firstPart = phoneNumber.slice(2, 5);
    const secondPart = phoneNumber.slice(5, 8);
    const thirdPart = phoneNumber.slice(8, 10);
    const fourthPart = phoneNumber.slice(10, 12);
    return `${countryCode} (${firstPart}) ${secondPart} ${thirdPart} ${fourthPart}`;
}

/**
 * Преобразует число в строку с пробелами между тысячами.
 * @param {number} num - Число для преобразования.
 * @returns {string} - Строка с пробелами между тысячами.
 */
export function formatNumber(num) {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
