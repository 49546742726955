import React, { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FieldWrapper } from './FieldWrapper';
import { TextAreaStl } from './textAreaStl.styled';

/**
 * Компонент текстового поля с обработкой ввода и валидацией.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {string} [props.placeholder=""] Текст-заполнитель для поля.
 * @param {string} props.name Название поля, используемое для `react-hook-form`.
 * @param {Function} [props.onCharCountChange] Функция, вызываемая при изменении количества символов в поле.
 * @returns {JSX.Element} Компонент текстового поля.
 */
export const TextArea = ({ placeholder = '', name, onCharCountChange, countSymbol }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [longTextError, setLongTextError] = useState(null);
    const refField = useRef(null);
    const maxLength = countSymbol ? countSymbol : 1000;
    const { control, trigger } = useFormContext();

    const defaultValidation = {
        maxLength: {
            value: maxLength,
            message: `Максимальная длина: ${maxLength} символов`,
        },
        minLength: {
            value: 2,
            message: 'Введите минимум 2 символа',
        },
        validate: (value) => value?.trim().length > 1 || '',
    };

    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const handleFocus = () => setIsFocused(true);

    const handleBlur = (e) => {
        if (!e.target.value) {
            setIsFocused(false);
            setLongTextError(null);
        }

        let inputValue = field.value || '';
        if (typeof inputValue === 'string') {
            inputValue = inputValue
                .trim()
                .replace(/ +/g, ' ')
                .replace(/-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '');
            inputValue = inputValue.slice(0, 1).toUpperCase() + inputValue.slice(1);
        }
        trigger(field.name);
        field.onChange(inputValue);
    };

    const handleChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxLength) {
            field.onChange(value);
            trigger(field.name);

            // Если передана функция для подсчёта символов, вызываем её
            if (onCharCountChange) {
                onCharCountChange(value.length);
            }

            setLongTextError(null);
        } else {
            setLongTextError({ message: `Максимальная длина: ${maxLength} символов` });
        }
    };

    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        const currentValue = field.value || '';

        if (currentValue.length + pastedText.length > maxLength) {
            e.preventDefault();
            setLongTextError({ message: `Вы пытаетесь вставить слишком длинный текст. Лимит: ${maxLength} символов.` });
        } else {
            setLongTextError(null);
        }
    };
    /**
     * Функция для установки высоты поля к исходной при нажатие на кнопку очистки поля
     */
    const resetHeight = () => {
        if (refField.current) {
            refField.current.style.height = '164px';
        }
    };
    const handleInput = (e) => {
        const textarea = e.target;
        textarea.style.height = '164px';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const clearedView = '';

    return (
        <FieldWrapper
            error={longTextError || error}
            isRequired={false}
            clearCondition={!!field.value}
            crossSize={'24px'}
            onClear={() => {
                field.onChange(clearedView);
                if (onCharCountChange) {
                    onCharCountChange(0);
                }
                setLongTextError(null);
                resetHeight();
            }}
        >
            <TextAreaStl>
                <textarea
                    {...field}
                    ref={refField}
                    value={field.value || ''}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="textarea"
                    placeholder={isFocused ? '' : placeholder}
                    minLength={2}
                    onChange={handleChange}
                    onInput={handleInput}
                    onPaste={handlePaste}
                />
            </TextAreaStl>
        </FieldWrapper>
    );
};
