import styled from 'styled-components';
import { ReactComponent as VerificationIconSvg } from '../../../img/icons/check-fill.svg';

export const VerificationIcon = styled(VerificationIconSvg)`
    position: absolute;
    right: 0px;
    top: 95px;
    z-index: 12;

    &:hover + div {
        display: block;
    }

    @media (max-width: 855px) {
        display: none;
    }
`;

export const Tooltip = styled.div`
    display: none;
    left: 151px;
    top: 65px;
    width: 273px;
    padding: 24px;
    position: absolute;
    color: white;
    border: 1px solid #19be6f;
    border-radius: 16px;
    z-index: 3;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    background-color: white;

    @media (max-width: 855px) {
        display: none;
    }
`;

export const VerificationIconTabMobile = styled(VerificationIconSvg)`
    display: none;

    @media (max-width: 855px) {
        z-index: 2;
        width: 24px;
        height: 24px;
        top: 66px;
        right: 0;
        cursor: pointer;
        display: block;
        position: absolute;
        @media (max-width: 575px) {
            top: 66px;
            left: 66px;
        }
        @media (max-width: 320px) {
            right: 0;
        }
    }
`;

export const TooltipTabMobile = styled.div`
    display: none;

    @media (max-width: 855px) {
        top: 24px;
        width: 273px;
        padding: 24px;
        position: absolute;
        color: white;
        border: 1px solid #19be6f;
        border-radius: 16px;
        z-index: 10;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #212121;
        background-color: white;
        display: ${({ isvisible }) => (isvisible ? 'block' : 'none')};
        left: 98px;
    }

    @media (max-width: 575px) {
        top: 100px;
        right: 0px;
        width: 216px;
        left: 36px;
        top: 98px;
        padding: 16px;
        font-size: 12px;
        line-height: 18px;
        display: ${({ isvisible }) => (isvisible ? 'block' : 'none')};
    }
`;
