import React, { useState, useRef, useEffect } from 'react';
import { VerificationIcon, Tooltip, VerificationIconTabMobile, TooltipTabMobile } from './doctorVerification.styled';

/**
 * DoctorVerification компонент для отображения подтверждения профиля врача.
 *
 * Компонент отображает иконку верификации и всплывающую подсказку при наведении, указывающую, что
 * профиль врача подтверждён и все документы об образовании проверены.
 *
 * Для экранов более 855px подсказка отображается при ховере на иконку.
 * Для экранов менее 855px подсказка отображается при клике на иконку.
 * Подсказка закрывается при клике вне компонента или на иконку.
 *
 * @component
 *
 * @returns {JSX.Element} Возвращает JSX с иконкой и всплывающей подсказкой.
 */
export const DoctorVerification = () => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [isTouchScreen, setIsTouchScreen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 855);

    /** Ссылка на контейнер компонента для проверки кликов вне его.*/
    const ref = useRef(null);

    /**
     * Проверка изменения и обновление состояния ширины экрана
     */
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 855);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /**
     * Проверка тачскрина.
     */
    useEffect(() => {
        setIsTouchScreen('ontouchstart' in window || navigator.maxTouchPoints > 0);
    }, [isTouchScreen, isTooltipVisible]);

    /**
     * Переключает видимость подсказки.
     * @param {MouseEvent} event - Событие клика, используется для предотвращения всплытия.
     */
    const toggleTooltip = (event) => {
        event.stopPropagation();
        setTooltipVisible((prev) => !prev);
    };

    /**
     * Закрывает подсказку, если клик был вне компонента.
     *
     * @param {MouseEvent} event - Событие клика.
     */
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setTooltipVisible(false);
        }
    };

    /**Эффект, который добавляет обработчик события клика при монтировании компонента.*/
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className="desktop">
                {/* Иконка для десктопа, подсказка показывается при ховере */}
                <VerificationIcon
                    component="button"
                    aria-label="Открыть подсказку подтверждения профиля врача"
                    onClick={isTouchScreen ? toggleTooltip : undefined}
                />
                {!isMobile && (
                    <Tooltip
                        style={{
                            display: isTouchScreen ? (isTooltipVisible ? 'block' : 'none') : undefined,
                        }}
                    >
                        Профиль врача подтверждён. Все документы об образовании проверены.
                    </Tooltip>
                )}
            </div>
            <div className="tab-mobile" ref={ref}>
                {/* Иконка для мобильных устройств, подсказка показывается при клике */}
                <VerificationIconTabMobile
                    component="button"
                    onClick={toggleTooltip}
                    isvisible={isTooltipVisible ? isTooltipVisible.toString() : undefined}
                    aria-label="Открыть подсказку подтверждения профиля врача"
                />
                <TooltipTabMobile isvisible={isTooltipVisible ? isTooltipVisible.toString() : undefined}>
                    Профиль врача подтверждён. Все документы об образовании проверены.
                </TooltipTabMobile>
            </div>
        </>
    );
};
