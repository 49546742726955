import React from 'react';
import { DoctorAboutStl } from './doctorAbout.styled';
import { getWorkDatesForDoctorProfilePage, isHtmlString, parseHTMLToText } from '../../share/helpers';

/**
 * Преобразует HTML-строку с описанием врача в массив React-элементов для вставки в JSX.
 *
 * @param {Object} doctor - Объект с данными врача.
 * @param {string} doctor.diagnosis_and_treatment - HTML-строка с описанием диагнозов и лечения.
 * @returns {React.ReactNode[]} Массив React-элементов, содержащий абзацы, списки или другие элементы.
 *
 * Описание:
 * - Если строка `diagnosis_and_treatment` не является HTML, функция возвращает её как текст, 
 *   обёрнутый в абзац (`<p>`).
 * - Если строка `diagnosis_and_treatment` является HTML, она парсится и каждый элемент 
 *   (`<p>`, `<ul>`, `<li>` и т.д.) преобразуется в соответствующий React-элемент.
 * - Списки (`<ul>` с `<li>`) преобразуются с сохранением структуры.
 * - Удаляются пустые элементы.
 *
 * Пример входных данных:
 * doctor.diagnosis_and_treatment = `
 *   <p>Лечу женщин</p>
 *   <ul>
 *     <li>Гормональные сбои</li>
 *     <li>Врожденные аномалии</li>
 *   </ul>
 * `;
 *
 * Пример результата:
 * [
 *   <p key="p-0" className="about__paragraph">Лечу женщин</p>,
 *   <ul key="ul-1" className="about__list">
 *     <li key="li-1-0" className="about__list-item">Гормональные сбои</li>,
 *     <li key="li-1-1" className="about__list-item">Врожденные аномалии</li>
 *   </ul>
 * ]
 */
const getDiagnosis_and_treatmentSection = (doctor) => {
    if (!isHtmlString(doctor?.diagnosis_and_treatment)) {
      return [<p key="default">{doctor?.diagnosis_and_treatment}</p>];
    }
  
    const doc = parseHTMLToText(doctor?.diagnosis_and_treatment);
    const elements = Array.from(doc.body.children);
    const result = elements.map((el, index) => {
      if (el.tagName === "P") {
        const text = el.textContent.trim();
        return text ? <p key={`p-${index}`} className="about__paragraph">{text}</p> : null;
      } else if (el.tagName === "UL") {
        const listItems = Array.from(el.querySelectorAll("li")).map((li, liIndex) => (
          <li key={`li-${index}-${liIndex}`} className="about__list-item">
            {li.textContent.trim()}
          </li>
        ));
        return (
          <ul key={`ul-${index}`} className="about__list">
            {listItems}
          </ul>
        );
      } else {
        const text = el.textContent.trim();
        return text ? <div key={`div-${index}`}>{text}</div> : null;
      }
    });
  
    return result.filter(Boolean);
  };

/**
 * Компонент для отображения информации о враче.
 *
 * @component
 * @param {Object} doctor - Объект, содержащий информацию о враче.
 * @param {string} doctor.diagnosis_and_treatment - Описание диагностики и лечения.
 * @param {Array<Object>} doctor.work - Список опытов работы врача.
 * @param {Object} doctor.work[].position - Информация о позиции врача.
 * @param {string} doctor.work[].position.name - Название позиции врача.
 * @param {Object} doctor.work[].organization - Организация, где работает врач.
 * @param {string} doctor.work[].organization.name - Название организации.
 * @param {string} doctor.work[].work_from - Дата начала работы в формате "дд.мм.гггг".
 * @param {string} doctor.work[].work_to - Дата окончания работы в формате "дд.мм.гггг".
 * @param {Array<Object>} doctor.skills_improvement - Список повышения квалификации врача.
 * @param {Object} doctor.skills_improvement[].organization - Организация, где проходило повышение квалификации.
 * @param {string} doctor.skills_improvement[].organization.name - Название организации.
 * @param {string} doctor.skills_improvement[].graduation_year - Год окончания повышения квалификации.
 * @param {Object} doctor.skills_improvement[].program - Программа повышения квалификации.
 * @param {string} doctor.skills_improvement[].program.name - Название программы.
 * @param {Array<Object>} doctor.education - Список образования врача.
 * @param {Object} doctor.education[].institution - Учебное заведение врача.
 * @param {string} doctor.education[].institution.name - Имя учебного заведения.
 * @param {Object} doctor.education[].specialty - Специализация врача в учебном заведении.
 * @param {string} doctor.education[].specialty.name - Имя специализации врача по образованию.
 * @param {string} doctor.education[].graduation_year - Год окончания образования.
 *
 * @returns {JSX.Element} Элемент, отображающий информацию о враче, включая его диагностику, опыт работы и повышение квалификации.
 *
 * @example
 * const doctorData = {
 *   diagnosis_and_treatment: "Заболевания сердца, диабет",
 *   work: [
 *     {
 *       position: { name: "Кардиолог" },
 *       organization: { name: "Городская больница" },
 *       work_from: "01.01.2015",
 *       work_to: "31.12.2020"
 *     }
 *   ],
 *   skills_improvement: [
 *     {
 *       graduation_year: "2022",
 *       organization: { name: "Медицинская академия" },
 *       program: { name: "Курсы повышения квалификации по кардиологии" }
 *     }
 *   ]
 * };
 *
 * <DoctorAbout doctor={doctorData} />
 */

export const DoctorAbout = ({ doctor }) => {
    const diagnosis_and_treatmentSection = getDiagnosis_and_treatmentSection(doctor);

    const workExperienceSection = doctor?.work?.map((work, index) => {
        const arrayLength = doctor.work.length;
        return (
            <li className="about__list-item" key={work.organization.name + work.work_from}>
                {getWorkDatesForDoctorProfilePage(work.work_from, work.work_to)}
                &mdash; {work.position.name}, {work.organization.name}
                {index + 1 === arrayLength ? '.' : ','}
            </li>
        );
    });

    const educationSection = doctor?.education.map((education) => {
        const year = `${education.graduation_year} г.`;
        return (
            <li key={education.id} className="about__list-item">
                {year} &mdash; {education.institution.name}. {education.specialty.name}.
            </li>
        );
    });

    const skillsImprovementSection = doctor?.skills_improvement?.map((skill) => {
        const year = `${skill.graduation_year} г.`;
        return (
            <li className="about__list-item" key={skill.organization.name + skill.graduation_year}>
                {year} &mdash; {skill.organization.name}. {skill.program.name}.
            </li>
        );
    });

    return (
        <DoctorAboutStl>
            <div className="about__wrapper">
                <div className="about__inner">
                    <h5 className="about__title about__title-desease">Диагностика и лечение следующих заболеваний:</h5>
                    {doctor?.diagnosis_and_treatment && doctor?.diagnosis_and_treatment !== null && diagnosis_and_treatmentSection}
                    {!doctor?.diagnosis_and_treatment && (
                            <h5 className="about__message">Врач не указал перечень заболеваний</h5>
                    )}
                </div>

                <div className="about__inner">
                    <h5 className="about__title">Опыт работы:</h5>
                    {doctor?.work?.length === 0 && (
                        <h5 className="about__message">Сведения об опыте работы отсутствуют</h5>
                    )}
                    {doctor?.work?.length !== 0 && <ul className="about__list">{workExperienceSection}</ul>}
                </div>
                <div className="about__inner">
                    <h5 className="about__title">Образование:</h5>
                    <ul className="about__list">
                        {doctor?.education.length !== 0 && educationSection}
                        {doctor?.education.length === 0 && (
                            <h5 className="about__message">Сведения об образовании отсутствуют</h5>
                        )}
                    </ul>
                </div>
                <div className="about__inner">
                    <h5 className="about__title">Повышение квалификации:</h5>
                    {doctor?.skills_improvement.length !== 0 && (
                        <ul className="about__list">{skillsImprovementSection}</ul>
                    )}
                    {doctor?.skills_improvement.length === 0 && (
                        <h5 className="about__message">Сведения о повышении квалификации отсутствуют</h5>
                    )}
                </div>
            </div>
        </DoctorAboutStl>
    );
};
