import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledCancelConsultationModal } from './closeConsultationModal.styled';
import {
    cancelPatientConsultation,
    getPatientConsultation,
    resetPatientConsultations,
    fetchGetPastConsultations,
    fetchGetFutureConsultations,
} from '../../api/PatientConsultationsSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as Cross } from '../../../../img/icons/cross-green.svg';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';

/**
 * Компонент CancelConsultationModal
 *
 * Отображает модальное окно для подтверждения отмены консультации.
 * При подтверждении отправляет запрос на отмену консультации и обновляет список консультаций.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.setFirstConfirm - Функция для управления состоянием модального окна.
 *     Вызывается с `false` для закрытия модального окна.
 * @param {Object} props.consultation - Данные о консультации, которую нужно отменить.
 * @param {string} props.consultation.id - Идентификатор консультации.
 * @param {boolean} [props.reload=false] - Флаг, указывающий, нужно ли перезагружать данные после отмены.
 *
 * @returns {JSX.Element} Модальное окно отмены консультации.
 *
 * @example
 * <CancelConsultationModal
 *   setFirstConfirm={setModalOpen}
 *   consultation={{ id: '12345' }}
 *   reload={true}
 * />
 */
export const CancelConsultationModal = ({ setFirstConfirm, consultation, reload = false }) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    /** Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара */
    useLockBodyScroll();

    const closeCancelConsultationModal = () => {
        setFirstConfirm(false);
    };

    const cancelConsultation = async () => {
        try {
            await dispatch(cancelPatientConsultation(consultation.id)).unwrap();
            await dispatch(getPatientConsultation(consultation.id)).unwrap();
            dispatch(resetPatientConsultations());
            if (pathname.includes('past')) {
                await dispatch(fetchGetPastConsultations({ limit: 3 })).unwrap();
            } else if (pathname.includes('future')) {
                await dispatch(fetchGetFutureConsultations({ limit: 3 })).unwrap();
            }
        } catch (err) {
            // console.log('Ошибка при отмене консультации', err);
        } finally {
            setFirstConfirm(false);
        }
    };

    return (
        <StyledCancelConsultationModal>
            <div className="modalCancel">
                <Cross className="modalCancel__cross-icon" onClick={closeCancelConsultationModal} />
                <p className="modalCancel__heading">Вы уверены, что хотите отменить консультацию?</p>
                <p className="modalCancel__text">
                    Нажмите «Отменить консультацию», чтобы подтвердить отмену.
                    <br />
                    Для сохранения консультации закройте окно, нажав на крестик или вне области окна
                </p>
                <button onClick={cancelConsultation} className="modalCancel__close-btn">
                    Отменить консультацию
                </button>
            </div>
        </StyledCancelConsultationModal>
    );
};
