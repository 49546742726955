import React from 'react';
import { useSelector } from 'react-redux';
import { ViewDoctorSpecialization } from './ViewDoctorSpecialization';
import { TitleStl } from '../title/titleStl.styled';
import { SectionStl } from '../viewDoctor.styled';

export const DoctorSpecialization = () => {
    const doctorSpecialization = useSelector((state) => state.doctorProfile?.doctorData?.specialization);
    const doctorScDegree = useSelector((state) => state.doctorProfile?.doctorData?.scientific_degree);
    const doctorCategory = useSelector((state) => state.doctorProfile?.doctorData?.category);

    return (
        <SectionStl>
            <TitleStl>
                <h2 className="title">Специализация</h2>
            </TitleStl>
            <ViewDoctorSpecialization
                specialization={doctorSpecialization}
                scientific_degree={doctorScDegree}
                category={doctorCategory}
            />
        </SectionStl>
    );
};
