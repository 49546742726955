import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emptyStar from '../../img/star-icon-empty.svg';
import fullStar from '../../img/star-icon-full.svg';

const RatingStarsStyled = styled.div`
    display: flex;
    align-items: center;
    img {
        width: 14px;
        height: 14px;
    }
`;

/**
 * Компонент `RatingStars` отображает звёздный рейтинг на основе переданного значения.
 * Компонент заполняет звёзды (полные или пустые) в зависимости от значения рейтинга.
 *
 * @param {Object} props - Свойства компонента.
 * @param {number} props.score - Рейтинг в числовом формате (от 0 до 5).
 * Значения интерпретируются следующим образом:
 * - 5: Полностью заполненные звёзды (5 из 5).
 * - 4: Четыре полные звезды и одна пустая.
 * - 3: Три полные звезды и две пустые.
 * - 2: Две полные звезды и три пустые.
 * - 1: Одна полная звезда и четыре пустые.
 * - 0: Все звёзды пустые.
 * @returns {JSX.Element} Элемент JSX с визуализацией рейтинга в виде звёзд.
 */
const RatingStars = ({ score }) => {
    const [rating, setrating] = useState(null);

    useEffect(() => {
        setrating(score);
    }, [score]);

    const ratingOut = (val) => {
        if (val >= 5) {
            return (
                <>
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                </>
            );
        } else if (val >= 4) {
            return (
                <>
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={emptyStar} alt="" />
                </>
            );
        } else if (val >= 3) {
            return (
                <>
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                </>
            );
        } else if (val >= 2) {
            return (
                <>
                    <img src={fullStar} alt="" />
                    <img src={fullStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                </>
            );
        } else if (val >= 1) {
            return (
                <>
                    <img src={fullStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                </>
            );
        } else if (val >= 0) {
            return (
                <>
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                    <img src={emptyStar} alt="" />
                </>
            );
        }
    };

    return <RatingStarsStyled>{ratingOut(rating)}</RatingStarsStyled>;
};

export default RatingStars;
