import React from 'react';
import { StyledSchedule } from './schedule.styled';
import { useState } from 'react';
import { useSchedule } from './useShedule';
import { ReactComponent as Arrow } from '../../../../../img/icons/newDoctorIcons/right-arrowsvg.svg';
import { useEffect } from 'react';
import { setClassNameForDay } from './setClassNameForDay';
import { useDispatch } from 'react-redux';
import { setTimeConsultation } from '../../../api/doctorsSlise';

export const Schedule = ({ setSelectedDay, selectedDay, freeTime }) => {
    const dispatch = useDispatch();
    const [weekSwitch, setWeekSwitch] = useState(true);
    const { currentDaysPeriod, currentNextWeek } = useSchedule();

    // useEffect(() => {
    //     setSelectedDay(currentDate);
    // }, [currentDate]);
    // // console.log(currentDate);

    const handleClick = (day) => (e) => {
        e.stopPropagation();
        setSelectedDay(day);
        dispatch(setTimeConsultation(''));
    };

    const currentPeriodMap = currentDaysPeriod.map((day) => {
        return (
            <div key={day.day} className={'tableColumn'} onClick={(e) => handleClick(day)(e)}>
                <p>{day.dayOfWeek}</p>
                <p className={setClassNameForDay(day, freeTime, selectedDay)}>{day.day}</p>
            </div>
        );
    });

    const nextPeriodMap = currentNextWeek.map((day) => {
        return (
            <div key={day.day} className={'tableColumn'} onClick={(e) => handleClick(day)(e)}>
                <p>{day.dayOfWeek}</p>
                <p className={setClassNameForDay(day, freeTime, selectedDay)}>{day.day}</p>
            </div>
        );
    });

    return (
        <StyledSchedule>
            <div className={'table'}>{weekSwitch ? currentPeriodMap : nextPeriodMap}</div>
            <span
                className="arrow"
                onClick={(e) => {
                    e.stopPropagation();
                    setWeekSwitch(!weekSwitch);
                }}
            >
                <Arrow className={!weekSwitch ? 'switch' : 'switchBack'} />
            </span>
        </StyledSchedule>
    );
};
