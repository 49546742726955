import React from 'react';
import { DataBlockStl } from '../viewDoctor.styled';

/**
 * Компонент `ViewEducation` отображает информацию об образовании доктора.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Array<Object>} props.education - Список объектов, содержащих данные об образовании.
 * @param {Object} [props.education[0]] - Первый элемент массива данных об образовании.
 * @param {Object} [props.education[0].institution] - Информация об учебном заведении.
 * @param {string} [props.education[0].institution.name] - Название учебного заведения.
 * @param {Object} [props.education[0].specialty] - Информация о специальности.
 * @param {string} [props.education[0].specialty.name] - Название специальности.
 * @param {string} [props.education[0].graduation_year] - Год окончания учебного заведения.
 * @param {string} [props.education[0].education_image] - URL изображения диплома (опционально).
 *
 * @returns {JSX.Element} Компонент, отображающий данные об образовании и дипломе.
 *
 * @example
 * const educationData = [
 *   {
 *     institution: { name: 'МГУ' },
 *     specialty: { name: 'Медицинская практика' },
 *     graduation_year: '2018',
 *     education_image: 'https://example.com/diplom.jpg',
 *   },
 * ];
 *
 * return <ViewEducation education={educationData} />;
 */

export const ViewEducation = ({ education }) => {
    const institutionName = education[0]?.institution?.name;
    const specializationName = education[0]?.specialty?.name;
    const graduationYear = education[0]?.graduation_year;
    const diplomURL = education[0]?.education_image;

    /**
     * Открывает изображение диплома в новой вкладке.
     *
     * @param {string} imageURL - URL изображения диплома.
     */
    const openImageInNewTab = (imageURL) => {
        window.open(imageURL, '_blank');
    };
    const diplomImage = diplomURL;

    return (
        <DataBlockStl>
            <div className="info__wrapper">
                <h4 className="info__header">{specializationName}</h4>
            </div>
            <div className="info__wrapper">
                <p className="info__data">{institutionName}</p>
            </div>
            <div className="info__wrapper">
                <p className="info__data">{graduationYear}</p>
            </div>
            {/* Отображение иконки сертификата с возможностью открыть изображение */}
            {diplomURL && (
                <div className="diplom-image-wrapper">
                    <img
                        src={diplomImage}
                        alt="Диплом"
                        onClick={() => openImageInNewTab(diplomImage)}
                        style={{ maxWidth: '200px', cursor: 'pointer' }}
                    />
                </div>
            )}
        </DataBlockStl>
    );
};
