import styled from 'styled-components';

export const StyledRecommendationForm = styled.div`
    .recommendationForm {
        display: flex;
        flex-direction: column;

        &__button {
            padding: 10px 20px;
            background-color: #19be6f;
            border: 2px solid #19be6f;
            border-radius: 6px;
            color: #ffffff;
            cursor: pointer;
            width: 200px;
            font-weight: 500;
            font-size: 16px;
            height: 40px;
            transition: all 0.3s ease-in;

            &:hover {
                background-color: #169056;
                border: 2px solid #169056;
            }

            &:disabled {
                cursor: default;
                background-color: #8af0bf;
                border: 2px solid #8af0bf;
            }
        }
        .attention {
            margin-top: 16px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            gap: 8px;

            .lowAttention {
                width: 24px;
                height: 24px;
            }

            .submitComment {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #666666;

                @media (max-width: 768px) {
                    font-size: 14px;
                }

                @media (max-width: 450px) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    . @media (max-width: 768px) {
        button {
            flex: 1;
            // margin-top: 14px;
        }
    }
`;
