import React from 'react';
import { CalendarStl } from './calendar.styled';
import { CalendarHeader } from './calendarHeader/CalendarHeader';
import { CalendarBody } from './calendarBody/CalendarBody';

/**
 * Компонент календаря, объединяющий заголовок и тело календаря.
 * Позволяет выбрать один или несколько дней для отображения.
 *
 * @param {Date} choosenDate - Текущая выбранная дата
 * @param {Function} setChoosenDate - Функция для обновления выбранной даты
 * @param {Function} setChoosenDates - Функция для обновления массива выбранных дат
 * @param {Array} choosenDates - Массив выбранных дат
 * @param {boolean|null} isDays - Флаг, указывающий, если выбраны дни
 *
 * @returns {JSX.Element} Рендерит календарь с заголовком и телом.
 */

export const Calendar = ({
    choosenDate,
    setChoosenDate,
    setChoosenDates,
    choosenDates,
    isDays = null,
    savedAndChoosen,
}) => {
    const clearChoosenDates = () => setChoosenDates([]);

    return (
        <CalendarStl>
            <CalendarHeader
                choosenDate={choosenDate}
                setChoosenDate={setChoosenDate}
                clearChoosenDates={clearChoosenDates}
            />
            <CalendarBody
                choosenDate={choosenDate}
                setChoosenDate={setChoosenDate}
                isDays={isDays}
                setChoosenDates={setChoosenDates}
                choosenDates={choosenDates}
                savedAndChoosen={savedAndChoosen}
            />
        </CalendarStl>
    );
};
