import React from 'react';
import Button from './Button';
import close_icon from '../../img/close-icon.svg';
import { PopUpConfirmSettingStyled } from './PopUpConfirm.styled';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';

/**
 * Компонент модального окна для подтверждения или отклонения действия.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {Function} props.onClose Функция, вызываемая при закрытии модального окна.
 * @param {Function} props.onConfirm Функция, вызываемая при подтверждении действия.
 * @param {string} props.contentHeader Текст заголовка модального окна.
 * @param {boolean} [props.isClarification=false] Флаг для отображения дополнительного текста пояснения.
 * @param {string} [props.contentClarification=''] Текст пояснения, отображаемый под заголовком.
 * @param {string} [props.buttonClose='Нет'] Текст кнопки закрытия.
 * @param {string} [props.buttonConfirm='Да'] Текст кнопки подтверждения.
 * @returns {JSX.Element} JSX-элемент модального окна.
 */

const PopUpConfirm = ({
    onClose,
    onConfirm,
    contentHeader,
    isClarification = false,
    contentClarification = '',
    buttonClose = 'Нет',
    buttonConfirm = 'Да',
}) => {
    /** Хук для блокировки прокрутки страницы и компенсации смещения из-за скрытия скроллбара */
    useLockBodyScroll();

    return (
        <PopUpConfirmSettingStyled>
            <img className="close" src={close_icon} alt="close_icon" onClick={onClose} />
            <div className="popupText">
                <h3 className="headerExiteProfile">{contentHeader}</h3>
                {isClarification && <p className="textClarification">{contentClarification}</p>}
            </div>
            <div className="popupBtns">
                <Button
                    green
                    hoverBorder
                    hoverBGColor
                    margin="0"
                    height="50px"
                    font_size="18px"
                    display="inline-flex"
                    align="center"
                    justify="center"
                    onClick={onClose}
                    className="popupBtn"
                >
                    {buttonClose}
                </Button>

                <Button
                    white
                    margin="0"
                    height="50px"
                    font_size="18px"
                    display="inline-flex"
                    align="center"
                    justify="center"
                    onClick={onConfirm}
                    className="popupBtn"
                >
                    {buttonConfirm}
                </Button>
            </div>
        </PopUpConfirmSettingStyled>
    );
};

export default PopUpConfirm;
