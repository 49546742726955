import styled from 'styled-components';

export const CalendarStl = styled.div`
    .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .title-hint {
        color: #666666;
        margin-bottom: 39px;
    }
    .calendar__select--date {
        // height: 382px;
        height: 376px;
        max-width: 333px;
        width: 333px;
        margin-top: 44px;
        margin-bottom: 10px;
        border: 1px solid rgb(182, 183, 186);
        border-radius: 10px;
        display: flex;
    }
    .calendar__select--date__placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 22px;
        overflow-y: hidden;
        text-align: center;
        font-size: 18px;
        line-height: 130%;
        color: #000;
        width: 100%;
    }
    .calendar {
        --gap: 109px;
        user-select: none;
        display: flex;
        justify-content: space-between;
        gap: var(--gap);
        &__wrapper {
            // width: calc((100% - var(--gap)) / 2);
            // max-width: 333px;
            width: 333px;
            display: flex;
            flex-direction: column;
            .hints {
                display: none;
            }

            .reset-btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 4px;
                            margin-top: 24px;
                margin-bottom: 30px;
            height: 48px;
                user-select: none;
                cursor: pointer;
                color: #b6b7ba;
                transition: color 0.2s linear;

                &_active {
                    color: #19be6f;
                }
            }
        }
                        .save-toast {
                user-select: none;
                background-color: #f4f4f4;
                border-radius: 4px;
                color: #212121;
                font-size: 14px;
                padding: 14px;
                gap: 4px;
                margin-top: 24px;
                margin-bottom: 30px;
                height: 48px;
                 display: flex;
    align-items: center;
            }
        }
        &__title {
            font-size: 14px;
        }

        &__hint {
            font-size: 12px;
            color: #666666;
        }

        &__button {
            display: block;
            color: #19be6f;
            cursor: pointer;
        }
    }

    .save-btn {
        // margin-top: 30px;
        width: 333px;
        height: 52px;
        // width: 60%;
        padding: 16px;
        background: #19be6f;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: white;
        cursor: pointer;
        outline: none;
        transition: opacity 0.3s linear;
        &:hover {
            opacity: 0.7;
        }
        &:disabled {
            opacity: 0.7;
            cursor: auto;
        }

    }

    @media (max-width: 1239px) {
        .calendar {
            --gap: 35px;
            &__wrapper {
                //max-width: 320px;
                width: 333px;
            }
        }
        .save-btn {
            width: 333px;
        }
    }

    // @media (max-width: 1023px) {
    //     .calendar {
    //         --gap: 35px;
    //         &__wrapper {
    //             // max-width: 320px;
    //             // width: calc((100% - var(--gap)) / 2);
    //             width:333px;
    //         }
    //     }
    //     .save-btn {
    //         //width: 60%;
    //         //max-width: 448px;
    //         width: 333px;
    //     }
    // }

    // @media (max-width: 870px) {
    // @media (max-width: 920px) {
    @media (max-width: 1023px) {
        .calendar__select--date {
            margin-bottom: 28.3px;
        }
        display: flex;
        flex-direction: column;
        .calendar {
            flex-direction: column;
            // align-items: center;
            align-items: flex-start;
            &__wrapper {
                // width: 100%;
                width:333px;
            }
        }
        .save-btn {
            // width: 53%;
            width:333px;
            // max-width: 241px;
            // align-self: center;
            align-self: flex-start;
        }
            
    }

    @media (max-width: 768px) {
        .calendar {
            // flex-direction: row;
            flex-direction: column;
            align-items: center;
            // align-items: flex-end;
            // justify-content: space-between;

            &__wrapper {
                .hints {
                    margin-top: 22px;
                    min-height: 40px;
                    display: flex;
                    gap: 16px;
                    justify-content: space-between;
                }
            }
        }
        .save-btn {
            align-self: center;
        }
                .title {
align-self: center;
width:320px;
    }
    .title-hint {
align-self: center;
width:320px;
    }
    }

    @media (max-width: 692px) {
        .calendar__select--date {
            margin-top: 106px;
        }
        .calendar {
            flex-direction: column;
            align-items: center;
            &__wrapper {
                width: 320px;
            }

            &__hint,
            &__button,
            &__title {
                padding-left: 12px;
                max-width: 320px;
            }

            &__wrapper {
                label,
                .hints,
                .reset-btn {
                    padding: 0 12px;
                }
            }
        }
        .save-btn {
            width: 320px;
            align-self: center;
        }
    }
`;
