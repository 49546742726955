import React from 'react';
import { FooterButtonsStyled } from './footerButtons.styled';
import { ReactComponent as ArrowUp } from '../../../img/icons/newDoctorIcons/up-arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostsByTheme } from '../api/blogSlice';
import { useParams } from 'react-router-dom';

/**
 * Компонент FooterButtons
 * 
 * React-компонент, отображающий кнопки "Показать больше" и "Наверх", используемые для управления списком статей 
 * и прокрутки страницы к началу.
 * 
 * @component
 * @param {Object} props - Свойства, переданные в компонент.
 * @param {boolean} props.isDefaultCountSimilarPosts - Флаг, определяющий, отображается ли базовое количество статей.
 * @param {Function} props.setIsDefaultCountSimilarPosts - Функция для обновления состояния, контролирующего отображение базового количества статей.
 * @param {Function} [props.setIsLoadingPosts] - Функция для управления состоянием загрузки статей.
 * 
 * @returns {JSX.Element} JSX-разметка компонента.
 * */

export const FooterButtons = ({ isDefaultCountSimilarPosts, setIsDefaultCountSimilarPosts, setIsLoadingPosts }) => {
    const dispatch = useDispatch();
    const { posts } = useSelector((state) => state.blog);
    const { themeId } = useParams();

    function setPageTopPosition() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function showMorePosts() {
        if (isDefaultCountSimilarPosts) {
            setIsDefaultCountSimilarPosts(false);
        } else {
            if (setIsLoadingPosts) {
                setIsLoadingPosts(true);
            }
            dispatch(fetchPostsByTheme({ themeId: themeId, getMorePosts: true }));
        }
    }

    return (
        <FooterButtonsStyled>
            {posts[themeId]?.next || isDefaultCountSimilarPosts ? (
                <p className="showMoreButton" onClick={showMorePosts}>
                    Показать больше
                </p>
            ) : (
                ''
            )}

            <div className="toTopPage" onClick={setPageTopPosition}>
                <p className="toTopPageButton">Наверх</p>
                <ArrowUp />
            </div>
        </FooterButtonsStyled>
    );
};
