import styled from 'styled-components';

export const StyledProblemForm = styled.div`
    display: ${(props) => (props.noPatientComments ? 'none' : 'block')};

    .problemForm {
        display: flex;
        flex-direction: column;

        &__header {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #212121;

            @media (max-width: 450px) {
                font-size: 18px;
                line-height: 23.4px;
            }

            @media (max-width: 340px) {
                font-size: 14px;
                line-height: 16px;
            }
        }

        &__text {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            color: #666666;
            margin-top: 16px;

            @media (max-width: 450px) {
                margin-top: 12px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__submit {
            padding-top: 16px;

            .attention {
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                gap: 8px;

                .lowAttention {
                    width: 24px;
                    height: 24px;
                }

                .submitComment {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #666666;

                    @media (max-width: 768px) {
                        font-size: 14px;
                    }

                    @media (max-width: 450px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }

            .submitBtn {
                text-align: left;
                margin: 0;
                padding: 0;
                border: none;
                background: #ffffff;
                color: #19be6f;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover {
                    color: #169056;
                }

                &:disabled {
                    cursor: default;
                    opacity: 1;
                    color: #b6b7ba;
                }

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
        }

        &__ button {
            min-width: 206px;
            max-height: 42px;
            padding: 10px 20px;
            background: #ffffff;
            border: 2px solid #19be6f;
            border-radius: 6px;
            color: #19be6f;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
`;
