import { ErrorStl, LabelStl } from '../fieldWrapperStl.styled';
import React, { memo, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import { useController, useFormContext } from 'react-hook-form';
import { ReactComponent as Cross } from '../../../../img/icons/cross-gray.svg';
import { SelectCityStl, SelectCommonFieldStl } from './selectCity.styled';

/**
 * Компонент `DropdownIndicator` отображает кастомный индикатор раскрытия выпадающего списка в компоненте `react-select`.
 * Используется для кастомизации стандартного индикатора (стрелки) выпадающего списка.
 *
 * @component
 * @param {Object} props - Свойства, передаваемые в компонент `DropdownIndicator`.
 * @returns {JSX.Element} Кастомный индикатор раскрытия выпадающего списка.
 *
 * @example
 * // Используется внутри компонента SelectCity для замены стандартной стрелки
 * <DropdownIndicator {...props} />
 */
const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span></span>
        </components.DropdownIndicator>
    );
};

/**
 * Компонент `ClearIndicator` отображает кастомный индикатор для очистки поля в компоненте `react-select`.
 * Заменяет стандартную кнопку очистки (крестик) на иконку с изображением крестика.
 *
 * @component
 * @param {Object} props - Свойства, передаваемые в компонент `ClearIndicator`.
 * @returns {JSX.Element} Кастомный индикатор для очистки поля.
 *
 * @example
 * // Используется внутри компонента SelectCity для замены стандартного крестика
 * <ClearIndicator {...props} />
 */
const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <Cross />
        </components.ClearIndicator>
    );
};

/**
 * Компонент `Clear` представляет собой кастомный компонент для очистки поля ввода.
 * Он использует иконку крестика (`Cross`) и управляется с помощью `useRef` и `useState`.
 * Также предоставляет метод `toShow`, который отображает или скрывает иконку в зависимости от введенного значения.
 *
 * @component
 * @param {Object} props - Свойства, передаваемые в компонент `Clear`.
 * @param {React.Ref} ref - Ссылка на компонент, предоставляющая метод `toShow` для управления видимостью.
 * @returns {JSX.Element} Кастомная иконка для очистки поля.
 *
 * @example
 * // Используется внутри компонента SelectCity для отображения и скрытия иконки очистки
 * <Clear ref={ref} />
 */
// const Clear = forwardRef(function Clear(props, ref) {
//     const show = useRef(false);
//     const [force, setForce] = useState(false);

//     useImperativeHandle(
//         ref,
//         () => {
//             return {
//                 toShow(inputValue) {
//                     const boolInput = Boolean(inputValue);
//                     if (boolInput !== show.current) {
//                         show.current = boolInput;
//                         setForce(() => boolInput);
//                     }
//                 },
//             };
//         },
//         []
//     );

//     return <Cross />;
// });

/**
 * Компонент SelectCity представляет собой кастомное поле для выбора города.
 * Использует библиотеку `react-select` с добавлением функционала очистки поля и кастомных индикаторов.
 * Компонент интегрирован с `react-hook-form` для управления состоянием формы и валидацией.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {string} props.name - Имя поля в форме, используется для связывания с `react-hook-form`.
 * @param {string} props.id - Идентификатор компонента для использования в DOM.
 * @param {Object} [props.validation={}] - Правила валидации поля. По умолчанию пустой объект.
 * @param {Array} props.options - Список доступных опций для выбора города. Каждая опция должна содержать `value` и `label`.
 * @param {string} [props.placeholder] - Текст подсказки, который будет отображаться в поле до выбора города.
 * @param {string} [props.label] - Метка, которая будет отображаться над полем.
 * @param {boolean} [props.radioButton=false] - Флаг, указывающий, следует ли показывать радио-кнопку.
 * @param {boolean} [props.narrow=false] - Флаг, указывающий, нужно ли сужать стиль поля.
 * @param {boolean} [props.isPreloaderCity=false] - Флаг, который показывает, нужно ли показывать индикатор загрузки.
 * @param {boolean} [props.isCity=false] - Флаг, указывающий, является ли поле выбора города активным.
 * @param {boolean} [props.isRequired=false] - Флаг, указывающий, обязательно ли поле для заполнения.
 * @param {function} [props.onChange] - Коллбэк-функция, которая будет вызвана при изменении значения поля.
 *
 * @returns {JSX.Element} Компонент выбора города, интегрированный с `react-hook-form`.
 *
 * @description
 * - Интеграция с `react-hook-form` позволяет использовать компоненты формы с валидацией и состоянием.
 * - Используется кастомная стрелка и крестик для очистки поля.
 * - При вводе текста в поле запускается поиск города, если введено более двух символов.
 * - При отсутствии подходящих вариантов отображается сообщение "Город не найден", либо "Введите город".
 *
 * @example
 * const cityOptions = [
 *   { value: 'Moscow', label: 'Москва' },
 *   { value: 'Saint Petersburg', label: 'Санкт-Петербург' },
 *   { value: 'Novosibirsk', label: 'Новосибирск' },
 * ];
 *
 * return (
 *   <SelectCity
 *     name="city"
 *     id="city-select"
 *     label="Выберите город"
 *     options={cityOptions}
 *     placeholder="Введите город"
 *     isRequired={true}
 *     onChange={(value) => // console.log(value)}
 *   />
 * );
 */

const SelectCity = ({
    name,
    id,
    validation = {},
    options,
    placeholder,
    label,
    radioButton,
    narrow,
    isPreloaderCity,
    isCity,
    isRequired,
    onChange = () => {},
}) => {
    const defaultValidation = {
        required: false,
    };
    const { control, trigger } = useFormContext();

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules: defaultValidation,
    });

    let components = {
        DropdownIndicator,
        ClearIndicator,
    };

    useEffect(() => {
        field.value && field.onChange(field.value);
    }, []);

    const handleBlur = () => {
        onChange(field.value);
        trigger(field.name);
    };
    useEffect(() => {
        onChange(field.value);
    }, [field.value]);

    return (
        <>
            <SelectCommonFieldStl>
                {label !== undefined && (
                    <LabelStl>
                        {label}
                        {isRequired && <span className="fieldWraper__red">*</span>}
                    </LabelStl>
                )}

                <SelectCityStl
                    value={options[0]}
                    id={id}
                    as={ReactSelect}
                    onChange={(value) => {
                        field.onChange(value);
                    }}
                    onInputChange={(value) => {
                        onChange(value);
                    }}
                    onBlur={handleBlur}
                    components={components}
                    options={options}
                    placeholder={placeholder}
                    isSearchable={true}
                    isClearable
                    noOptionsMessage={(value) => {
                        let message = '';
                        isPreloaderCity
                            ? (message = 'Поиск...')
                            : value.inputValue?.length < 2
                            ? (message = 'Введите город')
                            : (message = 'Город не найден');
                        return message;
                    }}
                    classNamePrefix="rs"
                    narrow={narrow}
                    radioButton={radioButton}
                    styles={{
                        option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isFocused ? '#F4F4F4' : 'B6B7BA',
                            color: 'black',
                        }),
                        control: (base, state) => ({
                            ...base,
                            borderColor: error ? '#ff3636' : state.isFocused ? '#19BE6F' : '#B6B7BA',
                        }),
                        // крестик сброса при выбранном значении
                        clearIndicator: (base, state) => ({
                            ...base,
                            padding: '5px',
                            display: 'flex',
                        }),
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            display: 'none', // прячем стрелку селекта
                        }),
                    }}
                />
                <ErrorStl>{error?.message}</ErrorStl>
            </SelectCommonFieldStl>
        </>
    );
};

export default memo(SelectCity);
