import React, { createContext, useContext, useState } from 'react';

export const FormDirtyContext = createContext();

/**
 * Хук для работы с контекстом "грязной" формы.
 * @returns {{ isFormDirty: boolean, setIsFormDirty: function }} Состояние и функция для его изменения.
 */
export const useFormDirty = () => useContext(FormDirtyContext);

/**
 * Провайдер контекста состояния "грязной" формы.
 * Предоставляет методы для установки и проверки состояния формы.
 * @component
 * @param {Object} props Свойства компонента.
 * @param {React.ReactNode} props.children Дочерние элементы.
 * @returns {JSX.Element} JSX-элемент провайдера контекста.
 */
export const FormDirtyProvider = ({ children }) => {
    const [isFormDirty, setIsFormDirty] = useState(false);

    return <FormDirtyContext.Provider value={{ isFormDirty, setIsFormDirty }}>{children}</FormDirtyContext.Provider>;
};
