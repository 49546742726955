import React, { Children, cloneElement, isValidElement } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

const descendants = (children, trigger, control, setValue) => {
    return Children.map(children, (child) => {
        if (!isValidElement(child)) {
            return child;
        }

        let newProps = {};

        if (child.props.children) {
            newProps.children = descendants(child.props.children, trigger, control, setValue);
        }

        if (child.props.name) {
            newProps = {
                ...{ key: child.props.name, trigger, control, setValue },
            };
        }

        return cloneElement(child, { ...newProps });
    });
};

const Form = ({ mode = 'onChange', children, onSubmit }) => {
    const { handleSubmit, trigger, control, setValue } = useForm({ mode });

    return (
        <FormProvider {...{ trigger, control, setValue }}>
            <form onSubmit={handleSubmit(onSubmit)}>{descendants(children, trigger, control, setValue)}</form>
        </FormProvider>
    );
};

export default Form;
