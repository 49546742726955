import React, { useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ReactComponent as Certificate } from '../../../img/certificate.svg';
import { ReactComponent as CertificateDelete } from '../../../img/certificate-delete.svg';
import { InputFileWrapper } from './inputFileStl.styled';

/**
 * Компонент InputFile для загрузки файлов с управлением через react-hook-form.
 * Позволяет отображать выбранный файл, удалять его и просматривать в новой вкладке.
 *
 * @component
 *
 * @param {Object} props Свойства компонента
 * @param {string} props.name - Название поля для привязки к форме и идентификатор инпута.
 * @param {Object} [props.validation] - Правила валидации для поля, которые будут объединены с базовыми правилами.
 * @param {boolean} [props.isRequired] - Флаг, обозначающий обязательность поля для заполнения.
 * @param {string} props.fieldName - Название, отображаемое для поля в пользовательском интерфейсе.
 *
 * @returns {JSX.Element} JSX разметка компонента для загрузки и управления файлами.
 *
 * @example
 * <InputFile
 *   name="certificate"
 *   validation={{ required: true, message: "Выберите файл" }}
 *   isRequired={true}
 *   fieldName="Сертификат"
 * />
 *
 * Внутренние методы:
 * - `showFile`: Открывает файл по URL в новой вкладке браузера.
 * - `getFileUrl`: Возвращает URL выбранного файла.
 * - `handleBlur`: Вызывает проверку валидации при потере фокуса.
 * - `handleFileDelete`: Удаляет выбранный файл и очищает input.
 *
 * Стилизация:
 * - Использует InputFileWrapper для стилизации контейнера.
 * - Кастомные классы для различных элементов, таких как .certificate-block и .error-message.
 */

const InputFile = ({ name, validation, isRequired, fieldName }) => {
    const { control, setError, clearErrors, trigger } = useFormContext();
    const inputRef = useRef(null);

    const defaultValidation = {
        required: { value: false, message: 'Поле обязательно для заполнения' },
        validate: {
            // размер файла меньше 2МБ
            fileSize: (value) => {
                if (value && value.length > 0) {
                    const file = value[0];

                    if (file.size > 2097152) {
                        return 'Размер файла должен быть не более 2 МБ';
                    }
                }
                return true;
            },
        },
        ...validation,
    };
    const {
        field,
        fieldState: { error },
    } = useController({ name, control, rules: defaultValidation });

    const showFile = (imageURL) => {
        window.open(imageURL, '_blank');
    };

    const getFileUrl = () => {
        let fileUrl = '';
        if (typeof field.value === 'string') {
            fileUrl = field.value;
        } else if (field.value instanceof FileList && field.value.length > 0) {
            fileUrl = URL.createObjectURL(field.value['0']);
            // console.log('fileUrl', fileUrl);
        }
        return fileUrl;
    };

    const handleBlur = () => {
        trigger(field.name);
    };

    const handleFileDelete = () => {
        field.onChange('');
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        trigger(field.name);
        clearErrors(name); // Очищаем ошибки при удалении файла
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > 2097152) {
                setError(name, {
                    message: 'Размер файла должен быть не более 2 МБ',
                });
                return;
            } else {
                clearErrors(name); // Очищаем ошибку, если файл валиден
                field.onChange(e.target.files);
            }
        } else {
            handleFileDelete(); // Очищаем поле, если файл удален
        }
    };

    return (
        <InputFileWrapper>
            {!field.value?.length > 0 ? (
                <>
                    <label htmlFor={name} className="custom-file-button">
                        <span className="inputFileWrapper__heading">
                            {' '}
                            <span className="span">+</span> Загрузить {fieldName}
                        </span>
                        {isRequired && <span className="inputFileWrapper__red">*</span>}
                        {error ? <p className="error-message">{error.message}</p> : null}
                    </label>
                    <input
                        {...field}
                        ref={inputRef}
                        className={`${!error ? '' : 'invalid'}`}
                        type="file"
                        id={name}
                        onBlur={handleBlur}
                        onChange={handleFileChange}
                    />
                </>
            ) : (
                <div className="certificate-block">
                    <div className="certificate-wrapper" onClick={() => showFile(getFileUrl())}>
                        <Certificate />
                        {/* <span>
                            {(typeof(field.value) === 'string') ?
                                decodeURIComponent(field.value.split('/').slice(-1)[0]) :
                                decodeURIComponent(field.value[0].name)}
                        </span> */}
                        <span>
                            {fieldName.slice(0, 1).toUpperCase()}
                            {fieldName.slice(1)}
                        </span>
                    </div>
                    <CertificateDelete onClick={() => field.onChange('')} />
                </div>
            )}
        </InputFileWrapper>
    );
};

export default InputFile;
