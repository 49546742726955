import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Container from '../../share/Container';
import { LeftColumn } from './lkPatient.styled';
import { LkPatientNavigation } from '../lkPatientNavigation/lkPatientNavigation';
import { MainHeader } from '../../mainHeader/mainHeader';
import { NavLink } from 'react-router-dom';
import { StyledLkPatient } from './lkPatient.styled';
import { useMediaQuery } from 'react-responsive';
import Button from '../../share/Button';

/**
 * LkPatient отображает страницу личного кабинета пациента,
 * включает навигацию и контент, который меняется в зависимости от режима (чтение/редактирование)
 *
 * @component
 * @returns {JSX.Element} Отрендеренная страница личного кабинета пациента.
 */

export const LkPatient = () => {
    const isTablet = useMediaQuery({ query: '(max-width: 700px)' });
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pathname === '/lk/patient-profile') {
            navigate('/lk/patient-profile/profile', { replace: true });
        }
    }, [pathname]);

    const handleDocNavigation = () => {
        navigate('/doctors');
    };

    return (
        <StyledLkPatient>
            <Container>
                <MainHeader title={'Личный кабинет'} />
                <div className="content">
                    {!isTablet && (
                        <LeftColumn>
                            <LkPatientNavigation />
                            {!pathname.startsWith('/lk/patient-profile/consultation') ? (
                                // <NavLink className="nav-link nav-button" to="/doctors">
                                //     Записаться к врачу
                                // </NavLink>
                                <Button
                                    green
                                    width="220px"
                                    height="52px"
                                    font_size="16px"
                                    padding="10px 20px 10px 20px"
                                    margin="32px 0"
                                    onClick={handleDocNavigation}
                                >
                                    Записаться к врачу
                                </Button>
                            ) : null}
                        </LeftColumn>
                    )}
                    <div className="rightBlock">
                        <Outlet />
                    </div>
                </div>
            </Container>
        </StyledLkPatient>
    );
};
