import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../img/Logo.svg';
import {
    fetchChangeStatusToStartedPatient,
    getPatientConsultation,
    setIsModalSuccessfulConsultationPatient,
    fetchCheckConsultationStatusPatient,
} from '../../componets/lkPatient/api/PatientConsultationsSlice';
import {
    fetchDoctorConsultation,
    fetchChangeStatusToStartedDoctor,
    fetchChangeStatusToPassedDoctor,
    setIsModalSuccessfulConsultationDoctor,
    fetchCheckConsultationStatusDoctor,
} from '../../componets/lkDoctor/api/lkDoctorConsultationsSlice';
import { Spinner } from '../../componets/share/Spinner';
import './videoChatJitsiPage.styled.js';
import { calculateElapsedTime } from '../../componets/share/helpers.js';
import { fetchUpcomingConsultation } from '../../componets/timerConsultation/api/timerSlice.js';

/**
 * Компонент VideoChatJitsi - видеочат на платформе Jitsi
 */
export default function VideoChatJitsi() {
    /** получение данных из стейта в компонент, установка данных */
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const DOMAIN = 'videocall.dev.doct24.com';
    const [isLoadingRoom, setIsLoadingRoom] = useState(false);
    const [currentConsiliumId, setCurrentConsiliumId] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [consultationStatus, setConsultationStatus] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(null);
    const [roomName, setRoomName] = useState(null);

    const { id: consultationId } = useParams();

    const patientConsultations = useSelector((state) => state.patientConsultations);
    const doctorConsultations = useSelector((state) => state.doctorConsultations);

    const keyPatient = patientConsultations.keyConsultationPatient;
    const keyDoctor = doctorConsultations.keyConsultationDoctor;

    const { role } = useSelector((state) => state.authentification.user);

    const isModalSuccessfulConsultationPatient = useSelector(
        (state) => state.patientConsultations.isModalSuccessfulConsultation
    );
    const isModalSuccessfulConsultationDoctor = useSelector(
        (state) => state.doctorConsultations.isModalSuccessfulConsultation
    );

    /**
     * Функция загрузки текущей консультации
     * @async
     */
    const fetchConsultation = async () => {
        try {
            setIsLoadingRoom(true);
            if (role === 'patient') {
                await dispatch(getPatientConsultation(consultationId)).unwrap();
            } else if (role === 'doctor') {
                await dispatch(fetchDoctorConsultation(consultationId)).unwrap();
            }
        } catch (error) {
            console.error('Ошибка при загрузке текущей консультации:', error);
        } finally {
            setIsLoadingRoom(false);
        }
    };

    /** Хук для загрузки текущей консультации в эффекте при изменении id консультации или роли участника видео-чата*/
    useEffect(() => {
        fetchConsultation();
    }, [consultationId, role]);

    /**Хук для установки информации в локальный стейт фио участника, id консультации, статуса консультации в зависимости от роли
     * после загрузки в стейт текущей консультации
     */
    useEffect(() => {
        if (role === 'patient' && patientConsultations.currentConsultation) {
            const user = patientConsultations.currentConsultation.patient?.user;
            if (!user) return;
            setFullName(`${user.last_name || ''} ${user.first_name || ''} ${user.middle_name || ''}`);
            setAvatar(user.image_standart || '');
            setCurrentConsiliumId(keyPatient);
            setConsultationStatus(patientConsultations.currentConsultation.status);
            setElapsedTime(patientConsultations.currentConsultation.actual_duration);
        } else if (role === 'doctor' && doctorConsultations.currentConsultation) {
            const user = doctorConsultations.currentConsultation.doctor?.user;
            if (!user) return;
            setFullName(`${user.last_name || ''} ${user.first_name || ''} ${user.middle_name || ''}`);
            setAvatar(user?.image_standart || '');
            setCurrentConsiliumId(keyDoctor);
            setConsultationStatus(doctorConsultations.currentConsultation.status);
            setElapsedTime(doctorConsultations.currentConsultation.actual_duration);
        }
    }, [
        patientConsultations.currentConsultation,
        doctorConsultations.currentConsultation,
        role,
        keyPatient,
        keyDoctor,
        consultationStatus,
    ]);

    //-------------------------------------------------------
    // управление консультацией
    useEffect(() => {
        if (!roomName && currentConsiliumId) {
            setRoomName(`Видео-консультация № ${currentConsiliumId}`);
        }
    }, [currentConsiliumId]);

    const apiRef = useRef(null);
    // const chatMessagesRef = useRef([]); // для получения и записи сообщений чата
    const changedToPassedRef = useRef(false);

    /**
     * Хэндлер начала консультации
     * @async
     */
    const handleStartConference = useCallback(async () => {
        if (!apiRef.current || consultationStatus === 3 || consultationStatus === 1) return;

        if (consultationStatus === 0) {
            try {
                if (role === 'doctor') {
                    await dispatch(fetchChangeStatusToStartedDoctor(consultationId)).unwrap();
                } else if (role === 'patient') {
                    await dispatch(fetchChangeStatusToStartedPatient(consultationId)).unwrap();
                }
            } catch (error) {
                console.error('Ошибка при переводе консультации в статус "начата":', error);
            }
        }
    }, [consultationId, dispatch, role, consultationStatus]);

    /**
     * Хэндлер обработки выхода участника из комнаты
     * @async
     * временно не используется
     */
    const handleParticipantJoined = useCallback(async () => {
        if (!apiRef.current) return;

        try {
            if (role === 'doctor') {
                // console.log('Вы присоединились');
                await dispatch(fetchCheckConsultationStatusDoctor(consultationId)).unwrap();
            } else if (role === 'patient') {
                // console.log('Вы присоединились');
                await dispatch(fetchCheckConsultationStatusPatient(consultationId)).unwrap();
            }
        } catch (error) {
            console.error('Ошибка при повторном подключении участника:', error);
        }
    }, [consultationId, dispatch, role, consultationStatus]);

    /** Хэндлер обработки выхода участника из комнаты
     * @async
     */
    const handleParticipantLeft = async () => {
        if (!apiRef.current) return;

        try {
            if (role === 'doctor') {
                // console.log('handleParticipantLeft у врача');

                let newData = await dispatch(fetchCheckConsultationStatusDoctor(consultationId)).unwrap();
                // console.log('new status (доктор):', newData.status);
                setConsultationStatus((prev) => newData.status);
            } else if (role === 'patient') {
                // console.log('handleParticipantLeft у пациента');

                setTimeout(async () => {
                    try {
                        const newData = await dispatch(fetchCheckConsultationStatusPatient(consultationId)).unwrap();
                        // console.log('new status (пациент):', newData.status);
                        setConsultationStatus((prev) => newData.status);

                        if (newData.status === 1) {
                            // console.log('Пациент выходит, тк консультация завершена. Статус: ', consultationStatus);
                            apiRef.current.dispose();
                            dispatch(setIsModalSuccessfulConsultationPatient(newData.actual_duration));
                        }
                    } catch (error) {
                        console.error('Ошибка при обновлении статуса для пациента:', error);
                    }
                }, 500);

                return;
            }
        } catch (error) {
            console.error('Ошибка при обработке случайного выхода участника:', error);
        }
    };

    /**
     * Хэндлер для полного завершения консультации
     * @async
     */
    const handleEndConsultation = useCallback(async () => {
        // console.log('changedToPassedRef.current', changedToPassedRef.current);
        if (changedToPassedRef.current) return;

        try {
            const response = await dispatch(fetchChangeStatusToPassedDoctor(consultationId)).unwrap();
            apiRef.current?.dispose();
            changedToPassedRef.current = true;
            dispatch(setIsModalSuccessfulConsultationDoctor(response.actual_duration));
        } catch (err) {
            console.error('что-то не так перед уничтожением конференции у врача', err);
        }
    }, [consultationId, dispatch, role, consultationStatus, elapsedTime]);

    /** Автоматический выход пациента, если консультация завершена
     * Пока что логика уничтожения комнаты для пациента используется в функции handleParticipantLeft
     * для ускорения принятия данных (прямо в компоненте, не через стейт)
     */
    // useEffect(() => {
    //     // console.log('useEffect при смене статуса сработал');
    //     console.log('consultationStatus: ', consultationStatus);

    //     if (role === 'patient' && consultationStatus === 1 && apiRef.current) {
    //         console.log('Пациент автоматически выходит, тк консультация завершена. Статус: ', consultationStatus);
    //         apiRef.current.dispose();
    //         dispatch(setIsModalSuccessfulConsultationPatient(elapsedTime));
    //     }
    // }, [consultationStatus, dispatch, role, elapsedTime]);

    useEffect(() => {
        if (isModalSuccessfulConsultationPatient) {
            navigate('/lk/patient-profile/consultation/past-consultation');
        } else if (isModalSuccessfulConsultationDoctor) {
            navigate('/lk/doctor-profile/consultations/past');
        }
    }, [isModalSuccessfulConsultationPatient, isModalSuccessfulConsultationDoctor, navigate]);

    // --------------------------------------------------------

    const jitsiConference = (
        <JitsiMeeting
            domain={DOMAIN}
            roomName={`Видео-консультация № ${currentConsiliumId}`}
            userInfo={{
                displayName: fullName || 'Участник встречи',
                avatarUrl: avatar || '',
            }}
            configOverwrite={{
                openBridgeChannel: true,
                userAvatarURL: avatar || '',
                startWithAudioMuted: true,
                disableModeratorIndicator: role === 'patient' ? false : true,
                startScreenSharing: true,
                enableEmailInStats: false,
                disablePolls: true,
                notifications: ['notify.chatMessages'],
                toolbarButtons:
                    role === 'patient'
                        ? ['microphone', 'desktop', 'camera', 'fullscreen', 'chat', 'tileview']
                        : ['hangup', 'microphone', 'desktop', 'camera', 'fullscreen', 'chat', 'tileview'],
                disableTileView: false,
                disableResponsiveTiles: true,
                disableSelfView: true,
                disable1On1Mode: null,
                filmstrip: {
                    disabled: true,
                },
            }}
            interfaceConfigOverwrite={{
                SHOW_JITSI_WATERMARK: false,
                HIDE_DEEP_LINKING_LOGO: true,
                SHOW_BRAND_WATERMARK: false,
                DEFAULT_LOGO_URL: logo,
                TILE_VIEW_MAX_COLUMNS: 4,
            }}
            onApiReady={(externalApi) => {
                apiRef.current = externalApi;
                // проверка доступного функционала объекта externalApi
                // const supportedEvents = externalApi.getSupportedEvents();
                // console.log('Supported Events:', supportedEvents);
                // console.log('Available methods:', Object.keys(externalApi));

                externalApi.executeCommand('avatarUrl', avatar);
                externalApi.executeCommand('displayName', fullName);

                // Событие при начале консультации
                externalApi.on('videoConferenceJoined', async () => {
                    console.log('videoConferenceJoined');
                    await handleStartConference();
                });

                // Событие при входе участника
                externalApi.on('participantJoined', async () => {
                    console.log('participantJoined');
                    // await handleParticipantJoined();
                });

                // externalApi.on('participantRoleChanged', (event) => {
                //     // console.log(`Роль пользователя ${event.id}:, event.role`);

                //     if (role === 'doctor' && event.role !== 'moderator') {
                //         // console.log('Доктор не модератор, пытаемся назначить...');
                //         externalApi.executeCommand('grantModerator', event.id);
                //     }
                // });

                // Событие при выходе участника
                externalApi.on('participantLeft', async () => {
                    console.log('participantLeft');
                    await handleParticipantLeft();
                });

                // событие при окончании конференции
                externalApi.on('videoConferenceLeft', async () => {
                    console.log(`videoConferenceLeft`);

                    if (role === 'doctor') {
                        await handleEndConsultation();
                    } else if (role === 'patient') {
                        await handleParticipantLeft();
                    }
                });
            }}
            getIFrameRef={(iframeRef) => {
                iframeRef.style.height = '600px';
            }}
        />
    );

    if (isLoadingRoom) return <Spinner />;

    if (!patientConsultations.currentConsultation && !doctorConsultations.currentConsultation) {
        return <div>Данные о консультации не найдены. Попробуйте обновить страницу.</div>;
    }

    return (
        <>
            <div>{currentConsiliumId ? jitsiConference : <Spinner />}</div>
        </>
    );
}
