import styled from 'styled-components';

export const ViewDoctorStl = styled.div`
    width: 100%;

    .viewBlock {
        display: flex;
        flex-direction: column;
        gap: 60px;
    }

    input {
        border-radius: 10px;
    }

    .btn {
        margin-top: 60px;
        text-align: center;
        display: flex;
        width: 100%;
        padding-right: 120px;
    }

    .btnLink {
        display: block;
        width: 100%;
        background-color: #19be6f;
        text-decoration: none;
        border-radius: 10px;
        padding: 17px 30px;
        font-weight: 400;
        font-size: 20px;
        color: #ffffff;
    }
`;

export const SectionStl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    .work__block {
        display: flex;
        gap: 20px;
    }
    .formDynamicInput {
        display: flex;
        flex-direction: column;
    }
    .work__title {
        font-size: 14px;
        margin-bottom: 8px;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .work__date {
        max-width: 156px;
        margin-bottom: 10px;
        &-checkbox {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 20px;
        }
    }
    .personalData {
        > div {
            margin-bottom: 60px;
        }
        + button {
            margin-top: 60px;
        }
    }
    .specialization {
        display: flex;
        flex-direction: column;
        gap: 60px;
        + button {
            margin-top: 16px;
        }
    }
    .block {
        margin-bottom: 30px;
    }
    .block::after {
        content: '';
        display: block;
        margin-top: 16px;
        background: linear-gradient(to right, #b6b7ba 0%, #b6b7ba 50%, #fff 50%);
        background-size: 17px 1px;
        color: #b6b7ba;
        width: 100%;
        height: 1px;
    }
    > .info__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 150%;
        color: #666;

        svg {
            cursor: pointer;
        }
        margin-bottom: -24px;
    }

    .btn__recive {
        padding: 10px 20px;
        border-radius: 10px;
        border: 2px solid #19be6f;
        font-weight: 500;
        font-size: 16px;
        line-height: 18.96px;
        color: #19be6f;
        background-color: #fff;
        transition: opacity 0.3s linear;
        width: 126px;
        height: 39px;
        cursor: pointer;
        :hover {
            opacity: 0.7;
        }
        &-disabled {
            text-align: center;
        }
        &:disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
    .btn__addBlock {
        align-items: flex-start;
        color: #19be6f;
        font-size: 16px;
        line-height: 24px;
        border: none;
        background: none;
        margin-bottom: 16px;
        cursor: pointer;
        text-align: left;
    }

    .block {
        position: relative;
        .delete {
            color: red;
            z-index: 100;
            cursor: pointer;
        }
    }
`;

export const DataBlockStl = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    form {
        width: 100%;
    }

    svg {
        cursor: pointer;
    }

    .info__wrapper {
        display: flex;
        flex-direction: column;
        &-training {
            width: 100%;
        }
    }
    .info__data-bold {
        font-size: 18px;
        font-weight: 500;
    }

    .certificate-wrapper {
        display: flex;
        gap: 5px;
        cursor: pointer;
    }

    .info__label {
        font-size: 14px;
        line-height: 150%;
        color: #666;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
    .info__header {
        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        color: #212121;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    .info__data {
        font-size: 16px;
        line-height: 150%;
        color: #212121;
        @media (max-width: 600px) {
            font-size: 14px;
        }
        &-training {
            display: flex;
            justify-content: space-between;
            width: 100%;
            > img {
                width: 50px;
                cursor: pointer;
            }
        }
        &_uppercase::first-letter {
            text-transform: uppercase;
        }
    }
    .btn__recive {
        margin-top: 60px;
        padding: 16px 32px;
        border-radius: 10px;
        border: 2px solid #19be6f;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        background-color: #19be6f;
        transition: opacity 0.3s linear;
        width: 100%;
        cursor: pointer;
        :hover {
            opacity: 0.7;
        }
        &-disabled {
            text-align: center;
        }
        &:disabled {
            opacity: 0.7;
        }
    }
`;
