import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getDoctorReceptions,
    createDoctorReception,
    // updateDoctorReceptions,
    // getDoctorConsultationsByStatus,
} from './doctorApi';
import { convertDatesToUserTimeZone } from '../../../utils/dateTransform';

/**
 * Асинхронное действие для получения списка временных слотов с консультациями.
 * @function fetchDoctorReceptions
 * @async
 * @returns {Promise<Array>} Список временных слотов и инфо о привязанной консультации.
 */

export const fetchDoctorReceptions = createAsyncThunk(
    'doctorReceptions/fetchDoctorReceptions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getDoctorReceptions();
            return response.data;
        } catch (error) {
            console.error('Ошибка в fetchDoctorReceptions:', error);
            return rejectWithValue(error.response?.data || { message: 'Ошибка загрузки расписания' });
        }
    }
);
/**
 * Асинхронное действие для создания новых временных слотов в расписание доктора.
 * @function fetchCreateDoctorReceptions
 * @async
 * @param {Object} receptions - список временных слотов для передачи на сервер
 * @returns {Promise<Array>} - список всех доступных временных слотов доктора.
 */

export const fetchCreateDoctorReception = createAsyncThunk(
    'doctorReceptions/fetchCreateDoctorReception',
    async (receptions, { rejectWithValue }) => {
        try {
            const response = await createDoctorReception(receptions);
            return response;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: 'Ошибка при создании расписания' });
        }
    }
);

// export const fetchGetConsultationsForCalendar = createAsyncThunk(
//     'doctorReceptions/fetchConsultationsForCalendar',
//     async () => {
//         try {
//             const response = await getDoctorConsultationsByStatus(0);
//             const consultations = response.data.data.consultations;
//             if (consultations.length) {
//                 return consultations;
//             }
//             return [];
//         } catch (error) {
//             console.error(error);
//         }
//     }
// );

// export const fetchUpdateDoctorReception = createAsyncThunk(
//     'doctorReceptions/fetchUpdateDoctorReception',
//     async (data) => {
//         try {
//             await updateDoctorReceptions(data);
//         } catch (error) {
//             console.error(error);
//         }
//     }
// );

const initialState = {
    receptions: null,
    error: null,
    isLoading: false,
};

const doctorCalendar = createSlice({
    name: 'doctorCalendar',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(fetchUpdateDoctorReception.pending, (state) => {
        //     state.isLoading = true;
        // });
        builder.addCase(fetchCreateDoctorReception.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCreateDoctorReception.rejected, (state, action) => {
            state.error = action.payload || 'Произошла ошибка';
        });

        builder
            .addCase(fetchDoctorReceptions.fulfilled, (state, action) => {
                if (!action.payload) {
                    state.error = 'Ошибка загрузки расписания';
                    state.isLoading = false;
                    return;
                }

                const convertedReceptions = convertDatesToUserTimeZone(
                    action.payload,
                    ['reception_time'],
                    'Europe/Moscow',
                    'dd.MM.yyyy HH:mm:ss',
                    'unix'
                );
                state.receptions = convertedReceptions.map((item) => ({
                    ...item,
                    reception_time: item.reception_time ? Math.floor(item.reception_time / 1000) : null,
                    workTime: true,
                }));
                state.isLoading = false;
            })
            // .addCase(fetchDoctorReceptions.rejected, (state, action) => {
            //     state.error = action.error.message;
            // })
            .addCase(fetchDoctorReceptions.rejected, (state, action) => {
                console.error('fetchDoctorReceptions rejected:', action.payload || action.error.message);
                state.error = action.payload?.message || 'Ошибка загрузки расписания';
                state.isLoading = false;
            });
        // builder
        //     .addCase(fetchGetConsultationsForCalendar.fulfilled, (state, action) => {
        //         state.consultations = action.payload;
        //     })
        //     .addCase(fetchGetConsultationsForCalendar.rejected, (state, action) => {
        //         state.error = action.error.message;
        //     });
    },
});

export const { setLoading } = doctorCalendar.actions;
export default doctorCalendar.reducer;
