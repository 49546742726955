import styled from 'styled-components';

export const PopUpErrorStl = styled.div`
    padding: 40px;
    max-width: 510px;
    background: #FFFFFF;
    border-radius: 24px;
    
    .error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &__icon {
            height: 112px;
            @media (max-width: 480px) {
                height: 80px;
            }
        }

        &__title {
            margin-top: 10px;
            color: #212121;
            font-weight: 500;
            font-size: 20px;
            @media (max-width: 480px) {
                font-size: 18px;
            }
        }

        &__content {
            margin-top: 12px;
            > span {
                font-size: 18px;
                color: #666666;
                @media (max-width: 480px) {
                    font-size: 16px;
                }
            }
            @media (max-width: 480px) {
                margin-top: 8px;
            }
        }

        &__btn {
            margin-top: 36px;
            width: 100%;
            display: flex;
            gap: 24px;

            button {
                margin: 0;
                width: 100%;
                height: auto;
                border: 2px solid #19be6f;
            }

            &-repeat {
                background: #19be6f;
                color: #FFFFFF;
            }

            &-close {
                background: none;
                color: #19be6f;
            }

            @media (max-width: 480px) {
                margin-top: 24px;
                flex-direction: column;
                gap: 16px;
                &-repeat, &-close {
                    font-size: 14px;
                    padding: 10px 16px;
                }
            }
        }
    }
`;
