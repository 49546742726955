import styled from 'styled-components';
import iconGoBack from '../../../../../img/icons/icon-arrow-back.svg';

export const DoctorConsultationPageStyled = styled.div`
    .go-back-btn {
        color: #19be6f;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
        margin-bottom: 52px;

        &:before {
            content: url(${iconGoBack});
            padding-right: 4px;
        }

        @media (max-width: 455px) {
            margin-bottom: 32px;
        }
    }

    .consultation-data {
        display: flex;
        flex-direction: column;
        gap: 36px;
        max-width: 690px;

        @media (max-width: 455px) {
            gap: 32px;
        }
    }

    .statusConsultation {
        transition: all 0.3s;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;

        @media (max-width: 455px) {
            font-size: 18px;
        }
    }

    .red {
        color: #ff3636;
    }

    .green {
        color: #19be6f;
    }

    .schedule {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (max-width: 455px) {
            gap: 16px;
        }
    }

    .patient-comments {
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__title {
            color: #212121;
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;

            @media (max-width: 450px) {
                font-size: 18px;
                line-height: 23.4px;
            }

            @media (max-width: 340px) {
                font-size: 14px;
                line-height: 16px;
            }
        }
        &__text {
            color: #666666;
            font-size: 18px;
            line-height: 150%;
            font-weight: 400;

            @media (max-width: 450px) {
                font-size: 14px;
                line-height: 20px;
            }
            @media (max-width: 340px) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        @media (max-width: 768px) {
            gap: 12px;
        }
    }
`;
