import React, { useEffect, useState } from 'react';
import { StyledVideoChatPage } from './videoChatPage.styled';
import { MainHeader } from '../../componets/mainHeader/mainHeader';
// import { VideoChatWindow } from '../../componets/videoChatWindow/videoChatWindow/videoChatWindow';
import Container from '../../componets/share/Container';
// import PageNavigation from '../../componets/share/PageNavigation';
import VideoChatJitsi from '../../componets/videoChatJitsiPage/VideoChatJitsiPage';

/**
 * Компонент страницы видео-чата.
 * Отображает заголовок, сообщение о загрузке и компонент видеочата.
 *
 * @component
 * @returns {JSX.Element} Разметка страницы видео-чата
 */
export const VideoChatPage = () => {
    const [showMessage, setShowMessage] = useState(true);
    const title = 'Видео чат';

    useEffect(() => {
        const timerID = setTimeout(() => {
            setShowMessage(false);
        }, 5000);

        return () => clearTimeout(timerID);
    }, []);

    return (
        <StyledVideoChatPage>
            <Container margin="0 auto" maxWidth="1040px">
                <MainHeader title={title} />
            </Container>
            <div className="videoChatWindow">
                {/* <VideoChatWindow /> */}
                {showMessage ? (
                    <div className="videoChatWindow__loadingMessage">
                        Подключение к видео-комнате может занять некоторое время, подождите, пожалуйста
                    </div>
                ) : (
                    <VideoChatJitsi />
                )}
            </div>
        </StyledVideoChatPage>
    );
};
