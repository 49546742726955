import styled from 'styled-components';

export const PoliticArticleStyled = styled.div`

    .agreementTitle {
        margin-top: 35px;
        margin-bottom: 35px;
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 31.2px;

        @media (max-width: 768px) {
            font-size: 22px;
            line-height: 28.6px;
        }
        @media (max-width: 500px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    .agreementDescription {
        font-style: normal;
        color: var(--primary-text, #212121);
        font-family: Rubik;
        font-weight: 400;
    }
`;