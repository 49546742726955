import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/**
 * Компонент Diagnostic представляет собой редактор текста для заполнения раздела
 * "Диагностика и лечение заболеваний". Использует ReactQuill для создания
 * текстового редактора с поддержкой форматирования, используется только параграф и списки.
 *
 * @component
 * @param {string} initialDiagnosis - начальное значение для поля диагностики,
 * если оно передано из родительского компонента.
 *
 * @returns {JSX.Element} - Возвращает JSX с текстовым редактором.

 * @example
 * <Diagnostic initialDiagnosis="<p>Начальное значение</p>" />
 */

const Diagnostic = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const modules = {
        toolbar: [[{ list: 'bullet' }]],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = ['list'];

    return (
        <NewFormSTL>
            <h2 className="title">Диагностика и лечение заболеваний</h2>
            <Controller
                name="data.diagnosis_and_treatment"
                control={control}
                rules={{
                    // validate: {
                    //     minLength: (value) =>
                    //         value.replace(/<[^>]*>/g, '').trim().length >= 2 || 'Минимальная длина: 2 символа',
                    //     maxLength: (value) =>
                    //         value.replace(/<[^>]*>/g, '').trim().length <= 2000 || 'Максимальная длина: 2000 символов',
                    // },
                    validate: {
                        minLength: (value) => {
                            const text = value?.replace(/<[^>]*>/g, '').trim() || '';
                            return text.length === 0 || text.length >= 2 || 'Минимальная длина: 2 символа';
                        },
                        maxLength: (value) => {
                            const text = value?.replace(/<[^>]*>/g, '').trim() || '';
                            return text.length <= 2000 || 'Максимальная длина: 2000 символов';
                        },
                    },
                }}
                render={({ field: { onChange, value } }) => (
                    <>
                        <ReactQuill
                            value={value || ''}
                            onChange={(content) => onChange(content)}
                            modules={modules}
                            formats={formats}
                            style={{ height: '200px' }}
                            placeholder="Опишите заболевания с которыми Вы работали"
                        />
                        {errors.data?.diagnosis_and_treatment && (
                            <p style={{ color: 'red', marginTop: '8px', fontSize: '12px', position: 'absolute' }}>
                                {errors.data.diagnosis_and_treatment.message}
                            </p>
                        )}
                    </>
                )}
            />
        </NewFormSTL>
    );
};

export default Diagnostic;
