import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AgreementField from '../share/formFields/AgreementField';
import { ContactFormStl } from './contactForm.styled';
import InputEmail from '../share/formFields/InputEmail';
import InputTel from '../share/formFields/InputTel';
import InputNameText from '../share/formFields/InputNameText';
import Select from '../share/formFields/newElements/Select';
import { topics } from './topics.js';
import { Modal } from '../share/Modal.jsx';
import PopUpAlert from '../share/PopUpAlert.jsx';
import { PopUpError } from "../share/PopUpError.jsx";
import { SymbolCountTextArea } from "../share/symbolCountTextArea/SymbolCountTextArea.jsx";
import { clearAllMessages, clearMessage, fetchSendContacts } from "./api/contactsSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../share/Spinner.jsx";

/**
 * Форма обратной связи, позволяющая пользователю оставить контактные данные и задать тему обращения.
 * Использует `react-hook-form` для управления состоянием формы и валидацией.
 *
 * @component
 * @returns {JSX.Element} Компонент формы обратной связи.
 */

export default function ContactForm() {
    const dispatch = useDispatch();
    const [popUpSuccess, setPopUpSuccess] = useState(false);
    const [popUpUnsuccess, setPopUpUnsuccess] = useState(false);
    const { messagesError, isPreloader } = useSelector((state) => state.contacts);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            topic: '',
            phone: '',
            email: '',
            message: '',
            agreementContact: false,
        },
        mode: 'all',
    });

    const {
        formState: { isValid, isSubmitting },
        watch,
        setValue,
        register,
    } = methods;

    register('agreementContact', { required: true });

    /**
     * Сбрасывает состояние agreementContact при возникновении ошибки отправки контактных данных, 
     * оставляя остальные поля формы заполненными, чтобы пользователь мог повторить попытку отправки.
     */
    useEffect(() => {
        if (messagesError) {
            setValue('agreementContact', false);
        }
    }, [messagesError, setValue]);

    useEffect(() => {
        dispatch(clearAllMessages());
    }, [dispatch]);

    /**
     * Обрабатывает отправку данных формы и вызывает action для передачи контактных данных.
     * @param {Object} data - Данные, введённые пользователем в форму.
     * @param {string} data.name - Имя пользователя.
     * @param {string} data.topic - Тема обращения.
     * @param {string} data.phone - Номер телефона пользователя.
     * @param {string} data.email - Email пользователя.
     * @param {string} data.message - Сообщение пользователя.
     *
     * @returns {Promise<void>}
     */
    const onSubmit = async (data) => {
        const { name, topic, phone, email, message } = data;
        try {
            await dispatch(fetchSendContacts({
                name,
                topic: topic.value,
                phone: phone.replace(/[\s()-]/g, ''),
                email,
                message
            })).unwrap();

            setPopUpSuccess(true);
            methods.reset();
        } catch (error) {
            setPopUpUnsuccess(true);
            methods.setValue('agreementContact', false, { shouldValidate: true });
        }
    };

    const handleClearError = (errorKey) => {
        dispatch(clearMessage(errorKey));
    };

    if (isPreloader) {
        return <div className="centerSpinner">
            <Spinner />
        </div>
    }

    return (
        <ContactFormStl>
            <p className="subtitle">Оставьте ваши контактные данные и мы постараемся вам помочь</p>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <fieldset>
                        <InputNameText
                            name="name"
                            placeholder="Александр"
                            label="Имя"
                            validation={{
                                required: {
                                    value: true,
                                    message: 'Поле обязательно для заполнения'
                                },
                                maxLength: {
                                    value: 152,
                                    message: 'Максимальная длина: 152 символа',
                                },
                                minLength: {
                                    value: 2,
                                    message: 'Минимальная длина: 2 символа',
                                },
                                pattern: {
                                    value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,152}$/,
                                    message: 'Поле может содержать символы алфавита, пробел, дефис',
                                },
                            }}
                            isFullName={true}
                            isRequired={true}
                        />
                        <Select
                            name="topic"
                            options={topics}
                            placeholder="Выберите тему обращения"
                            validation={{
                                validate: {
                                    isValidValue: (value) => value || 'Поле обязательно для заполнения',
                                },
                            }}
                            label="Тема обращения"
                            isRequired={true}
                        />
                        <InputTel
                            validation={{ pattern: false }}
                            name="phone"
                            label="Телефон"
                            placeholder="+7 (999) 999-99-99"
                            isRequired={true}
                            messagesError={messagesError?.phone}
                            onClearError={handleClearError}
                            errorKey="phone"
                        />
                        <InputEmail
                            name="email"
                            label="Email"
                            placeholder={'example@gmail.com'}
                            control={methods.control}
                            isRequired={true}
                            messagesError={messagesError?.email}
                            onClearError={handleClearError}
                            errorKey="email"
                        />
                        <SymbolCountTextArea
                            placeholder="Введите текст обращения"
                            name="message"
                        />
                    </fieldset>
                    <AgreementField name="agreementContact" required />
                    <button className="btn" type="submit" disabled={!isValid || isSubmitting}>
                        Отправить
                    </button>

                    {popUpSuccess && (
                        <Modal toggle={setPopUpSuccess}>
                            <PopUpAlert
                                onConfirm={() => setPopUpSuccess(false)}
                                contentHeader={<span>Ваше сообщение отправлено</span>}
                                isClarification={false}
                            />
                        </Modal>
                    )}

                    {popUpUnsuccess && (
                        <Modal toggle={setPopUpUnsuccess}>
                            <PopUpError
                                // Функция для повторной отправки данных формы при возникновении ошибки
                                handleConfirumClick={() => {
                                    setPopUpUnsuccess(false);
                                    const formData = methods.getValues();
                                    methods.setValue('agreementContact', true, { shouldValidate: true });
                                    onSubmit(formData);
                                }}
                                closeModal={setPopUpUnsuccess}
                            />
                        </Modal>
                    )}
                </form>
            </FormProvider>
        </ContactFormStl>
    );
}
