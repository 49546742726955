import React, { useEffect } from 'react';
import { DoctorCard } from '../doctorCard/doctorCard';
import { Loader } from '../../loader/loader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchGetDoctors } from '../api/doctorsSlise';
import { ReactComponent as ArrowUp } from '../../../img/icons/newDoctorIcons/up-arrow.svg';

/**
 * Компонент DoctorList отображает список врачей и управляет состоянием загрузки и пагинацией.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.allDoctors - Массив объектов врачей для отображения.
 * @returns {JSX.Element} Список врачей с функциональностью "Показать больше" и "Наверх".
 *
 * @description
 * Компонент выполняет следующие задачи:
 * - Отображает список врачей с использованием компонента DoctorCard.
 * - Показывает сообщение "ничего не найдено", если список пуст и данные не загружаются.
 * - Показывает индикатор загрузки, пока данные находятся в процессе получения.
 * - Реализует функциональность для загрузки дополнительного списка врачей и прокрутки страницы вверх.
 */
export const DoctorList = ({ allDoctors, consultations }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.doctors.isLoading);
    const count = useSelector((state) => state.doctors.count);

    // Восстанавливается прокрутка до только что просмотренного врача.
    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                const scrollPosition = Number(sessionStorage.getItem("scrollPosition")) || 0;
                window.scrollTo({ top: scrollPosition, behavior: "smooth" });
            }, 100);
        }
    }, [isLoading]);

    return (
        <>
            {allDoctors.length > 0 && (
                <ul className="doctors__list">
                    {allDoctors.map((doctor) => {
                        return <DoctorCard consultations={consultations} key={doctor.id} doctor={doctor} />;
                    })}
                </ul>
            )}
            {allDoctors.length === 0 && !isLoading && (
                <p className="not_found">По вашему запросу ничего не&nbsp;найдено</p>
            )}

            {isLoading && (
                <div className="loader">
                    <Loader />
                </div>
            )}
            <div className="btns">
                {count > allDoctors.length && (
                    <span
                        onClick={() => {
                            dispatch(fetchGetDoctors());
                            const currentPages = Number(sessionStorage.getItem("pagesLoaded")) || 1;
                            sessionStorage.setItem("pagesLoaded", currentPages + 1);
                            sessionStorage.setItem("scrollPosition", window.scrollY);
                        }}
                    >
                        Показать больше
                    </span>
                )}
                {count > 1 && (
                    <span
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            sessionStorage.removeItem("scrollPosition");
                        }}
                    >
                        Наверх
                        <ArrowUp />
                    </span>
                )}
            </div>
        </>
    );
};
