import React from 'react';
import { NewFormSTL } from './newFormSTL.styled';
import InputText from '../../../../share/formFields/InputText';
import { validatePrice } from '../../../../share/formFields/validateInput';

/**
 * Компонент для добавления и редактирования стоимости консультации.
 *
 * @component
 * @returns {JSX.Element} JSX элемент компонента Price
 */

const Price = () => {
    return (
        <NewFormSTL>
            <div className="price__wrapper">
                <h2 className="title">Стоимость консультаций</h2>
                <InputText
                    validation={validatePrice(true)}
                    name="data.price"
                    label="Стоимость консультации, руб."
                    placeholder="1000"
                    isRequired={true}
                    isPrice={true}
                />
                <p className="price__comment">Максимальное время консультации 30 минут</p>
            </div>
        </NewFormSTL>
    );
};

export default Price;
