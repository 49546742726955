import { LkPatientProfileStl } from './lkPatientProfile.styled';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchDeleteMyAvatar, fetchLoadMyAvatar, fetchPatientProfile } from '../api/PatientProfileSlice';
import { Loader } from '../../loader/loader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Avatar } from '../../avatar/avatar';

/**
 * Логика Avatar вынесена в отдельный компонент для переиспользования в ЛК пациента и доктора
 * @see Avatar
 */

/**
 * Компонент для отображения профиля пациента в личном кабинете.
 * Позволяет загружать и удалять аватар пользователя, а также отображает данные профиля.
 * @component
 * @returns {JSX.Element} Разметка компонента профиля пациента с возможностью загрузки и удаления аватара.
 */
export const LkPatientProfile = () => {
    const dispatch = useDispatch();
    const path = useLocation();
    const redirect = useNavigate();

    /**
     * Селектор для получения данных профиля пациента из Redux.
     * @type {Object} patientProfile
     */
    const { patientProfile, isLoader } = useSelector((state) => state.patientProfile);

    useEffect(() => {
        if (!patientProfile) dispatch(fetchPatientProfile());
    }, []);

    useEffect(() => {
        if (path.pathname === '/lk/patient-profile/profile') {
            redirect('/lk/patient-profile/profile/view', { replace: true });
        }
    }, [path, patientProfile]);

    // Отображение загрузчика, если данные профиля еще не получены или email отсутствует, отображаем Loader.
    if (isLoader || !patientProfile || !patientProfile.email) {
        return <Loader />;
    }

    return (
        <LkPatientProfileStl>
            <Avatar
                userProfile={patientProfile}
                fetchDeleteMyAvatar={fetchDeleteMyAvatar}
                fetchLoadMyAvatar={fetchLoadMyAvatar}
            />
            <div className="data">
                <Outlet />
            </div>
        </LkPatientProfileStl>
    );
};
