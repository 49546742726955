import { apiRequest } from '../../share/api';

export const getSpecializations = async (data) => {
    const { limit, offset, searchString } = data;
    try {
        return await apiRequest.get(`specialization/?name=${searchString}&limit=${limit}&offset=${offset}`);
    } catch (error) {
        console.error('Error in getSpecializations', error.response);
        throw error.response ? error : { message: error.message };
    }
};