import styled from 'styled-components';
import closeIcon from '../../../../../img/icons/icon-close.svg';

export const StyledDoctorConsultationCard = styled.div`
    max-width: 600px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    font-weight: 400;
    line-height: 150%;
    transition: all ease 0.3s;
    display: grid;
    gap: 8px 40px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'photoBlock status'
        'photoBlock info'
        'photoBlock details';

    .photoBlock {
        grid-area: photoBlock;
        width: 108px;
        height: 108px;
        border-radius: 50%;
        position: relative;
        z-index: 10;

        & img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .status {
        grid-area: status;
    }

    .info {
        grid-area: info;
    }

    .details {
        grid-area: details;
    }

    .patientName {
        font-size: 18px;
        color: #212121;
        word-break: break-word;

        @media (max-width: 500px) {
            font-size: 16px;
            line-height: 19px;
            align-self: center;
        }
    }

    .statusConsultation {
        font-size: 14px;
        color: #666666;

        @media (max-width: 320px) {
            font-size: 12px;
        }
    }
    .dateAndTime {
        padding-bottom: 20px;
    }

    .red {
        color: #ff3636;
    }
    .green {
        color: #19be6f;
    }

    .closeBtnX {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 21px;
        right: 21px;
        cursor: pointer;
        background-image: url(${closeIcon});
        background-size: contain;
        background-repeat: no-repeat;
        color: #b6b7ba;
    }
    &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
    }

    @media (max-width: 810px) {
        padding: 20px;
        column-gap: 16px;
        .photoBlock {
            width: 64px;
            height: 64px;
        }
    }

    @media (max-width: 500px) {
        padding: 16px;
        grid-template-areas:
            'status status'
            'photoBlock info'
            'details details';
    }
`;
