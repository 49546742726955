import styled from 'styled-components';

export const DoctorDeseasesStyled = styled.div`
    h1,
    h2,
    h3 {
        word-break: break-word;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
        @media (max-width: 770px) {
            font-size: 20px;
            line-height: 20.8px;
        }
        @media (max-width: 400px) {
            font-size: 16px;
            line-height: 18.2px;
        }
    }
    strong {
        font-weight: 500;
    }
    ul,
    p {
        word-break: break-word;
        font-size: 16px;
        line-height: 24px;
        @media (max-width: 770px) {
            font-size: 16px;
            line-height: 20.8px;
        }
        @media (max-width: 400px) {
            font-size: 14px;
            line-height: 18.2px;
        }
        li {
            word-break: break-word;
            margin-bottom: 4px;
        }
    }
    ul {
        margin-left: 20px;
    }
    .ql-editor {
        h1,
        h2,
        h3 {
            word-break: break-word;
            font-size: 20px;
            line-height: 24px;
            @media (max-width: 770px) {
                font-size: 20px;
                line-height: 20.8px;
            }
            @media (max-width: 400px) {
                font-size: 16px;
                line-height: 18.2px;
            }
        }
        strong {
            font-weight: 500;
        }
        ul,
        p {
            padding-left: 0;
            font-size: 20px;
            word-break: break-word;
            line-height: 24px;
            @media (max-width: 770px) {
                font-size: 16px;
                line-height: 20.8px;
            }
            @media (max-width: 400px) {
                font-size: 14px;
                line-height: 18.2px;
            }
        }
    }
    .error {
        transform: translateY(50px);
        color: red;
        font-size: 14px;
        padding-left: 0;
    }
    .ql-toolbar {
        border-radius: 10px 10px 0 0;
    }
    .ql-container {
        border-radius: 0 0 10px 10px;
    }
`;
