import styled from 'styled-components';

export const OnlineConsultationStl = styled.div`
    padding: 52px 40px 40px 40px;
    max-width: 414px;
    background: rgb(255, 255, 255);
    border-radius: 24px;
    position: relative;
    @media (max-width: 320px) {
        width: 288px;
        padding: 52px 15px 40px 15px;
    }
    .consultation {
        text-align: center;
        &__cross {
            border: none;
            position: absolute;
            background: none;
            top: 26px;
            right: 26px;
            cursor: pointer;
        }

        &__icon {
            height: 110px;
            margin: 0 auto;
            margin-bottom: 22px;
            @media (max-width: 320px) {
                width: 72px;
                height: 76px;
            }
        }
        &__title {
            color: #666666;
            font-weight: 400;
            font-size: 16px;
            margin-top: 15px;
            margin-bottom: 10px;
            @media (max-width: 320px) {
                font-size: 14px;
                margin-top: 0px;
                margin-bottom: 8px;
            }
        }
        &__doctor {
            margin-bottom: 23px;
            @media (max-width: 320px) {
                margin-bottom: 23px;
            }
            &-name {
                font-size: 18px;
                margin-bottom: 14px;
                @media (max-width: 320px) {
                    font-size: 16px;
                    margin-bottom: 11px;
                }
            }
            &-skill {
                display: flex;
                flex-direction: column;
                gap: 4px;
                list-style: none;
                color: #666;
                @media (max-width: 320px) {
                    font-size: 16px;
                }
            }
        }
        &__details {
            .details {
                img {
                    padding: 0 8px 0 0;
                    height: 24px;
                }
                &__cell {
                    display: inline-flex;
                    align-items: center;

                    &:first-of-type {
                        padding: 0 18px 0px 0;
                    }

                    span {
                        color: #666;
                        @media (max-width: 320px) {
                            font-size: 14px;
                        }
                    }
                }
                &:first-of-type {
                    margin-bottom: 8px;
                    @media (max-width: 320px) {
                        margin-bottom: 12px;
                    }
                }
            }
        }
        &__button {
            height: 48px;
            width: 334px;
            margin-top: 33px;
            border-radius: 8px;
            @media (max-width: 320px) {
                font-size: 14px;
                width: 256px;
                margin-top: 20px;
                width: 100%;
            }
        }
    }
    .error {
        width: 700px;
    }
`;
