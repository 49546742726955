// валидация длины от 2 до num знаков
export const validateInputLength = (num) => ({
    maxLength: {
        value: num,
        message: `Максимальная длина: ${num} символов`,
    },
    minLength: {
        value: 2,
        message: 'Минимальная длина: 2 символа',
    },
    pattern: {
        value: /./,
        message: '',
    },
});

// текст в блоке формы с данными пациента
export const validateInputTextName = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,50}$/,
            message: 'Поле может содержать символы алфавита, пробел, дефис',
        },
    };
};

export const validateInputOrganization = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 150,
            message: 'Максимальная длина: 150 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z0-9()№""\s\-]{2,150}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис, цифры, символы ()№""',
        },
    };
};

export const validateWorkDate = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^(0[1-9]|1[0-2])\/\d{4}$/,
            message: 'Некорректная дата',
        },
        validate: {
            isFutureDate: (value) => {
                const workDate = new Date(value.slice(-4), +value.slice(0, 2) - 1);
                const currentDate = new Date();
                const enteredDate = new Date(workDate);

                if (enteredDate > currentDate) {
                    return 'Некорректная дата';
                }

                return true;
            },
            isPasteDate: (value) => {
                const workDate = new Date(value.slice(-4));
                // const minDate = new Date();
                // minDate.setFullYear(minDate.getFullYear() - 60); // 60 лет назад

                const minDate = new Date(0);
                minDate.setFullYear(minDate.getFullYear() - 20); // 1950 г

                if (workDate < minDate) {
                    // return 'Дата не может быть более чем 60 лет назад';
                    return 'Дата не может быть ранее 1950 года';
                }

                return true;
            },
        },
    };
};

export const validatePartialWorkDate = (isRequired) => {
    return {
        required: { value: isRequired, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^(0[1-9]|1[0-2])\.\d{4}$/,
            message: 'Ожидается формат mm.yyyy',
        },
        validate: {
            isFutureDate: (value) => {
                // Проверка на корректный формат
                if (!/^(0[1-9]|1[0-2])\.\d{4}$/.test(value)) return 'Некорректный формат даты';

                const [month, year] = value.split('.');
                const enteredDate = new Date(year, month - 1, 1); // Устанавливаем 1-е число месяца
                const currentDate = new Date();

                // Приводим текущую дату к началу текущего месяца, без учета дня и времени
                const normalizedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

                // Сравниваем только месяц и год, чтобы проверка работала корректно
                if (enteredDate.getTime() > normalizedCurrentDate.getTime()) {
                    return 'Дата не может быть в будущем';
                }

                return true;
            },
            isPasteDate: (value) => {
                // Проверка на корректный формат
                if (!/^(0[1-9]|1[0-2])\.\d{4}$/.test(value)) return 'Некорректный формат даты';

                const [month, year] = value.split('.');
                const enteredDate = new Date(year, month - 1); // Месяцы начинаются с 0

                // Устанавливаем минимальную дату (например, 1950 год)
                const minDate = new Date(1950, 0); // Январь 1950 года

                if (enteredDate < minDate) {
                    return 'Дата не может быть ранее 1950 года';
                }

                return true;
            },
        },
    };
};

/**
 * Возвращает объект с правилами валидации для поля ввода даты рождения.
 *
 * @param {boolean} isRequired - Определяет, является ли поле обязательным.
 * @returns {Object} Объект с правилами валидации, включая обязательность поля, шаблон для даты, и различные пользовательские проверки.
 * @returns {Object} return.required - Правило обязательности поля.
 * @returns {boolean} return.required.value - Значение, указывающее, обязательно ли поле.
 * @returns {string} return.required.message - Сообщение об ошибке, если поле обязательно.
 * @returns {Object} return.pattern - Правило шаблона для проверки формата даты (ДД.ММ.ГГГГ).
 * @returns {RegExp} return.pattern.value - Регулярное выражение для проверки корректности даты.
 * @returns {string} return.pattern.message - Сообщение об ошибке, если дата имеет некорректный формат.
 * @returns {Object} return.validate - Объект с пользовательскими функциями валидации.
 * @returns {Function} return.validate.isDayOfMonth - Проверяет корректность дня месяца в зависимости от месяца и года.
 * @param {string} return.validate.isDayOfMonth.value - Введенная дата в формате ДД.ММ.ГГГГ.
 * @returns {string|boolean} return.validate.isDayOfMonth - Возвращает true, если дата корректная, или сообщение об ошибке.
 * @returns {Function} return.validate.isFutureDate - Проверяет, не является ли введенная дата будущей.
 * @param {string} return.validate.isFutureDate.value - Введенная дата в формате ДД.ММ.ГГГГ.
 * @returns {string|boolean} return.validate.isFutureDate - Возвращает true, если дата корректная, или сообщение об ошибке.
 * @returns {Function} return.validate.isPasteDate - Проверяет, не является ли введенная дата слишком далекой в прошлом (до 1930 года).
 * @param {string} return.validate.isPasteDate.value - Введенная дата в формате ДД.ММ.ГГГГ.
 * @returns {string|boolean} return.validate.isPasteDate - Возвращает true, если дата корректная, или сообщение об ошибке.
 * @returns {Function} return.validate.is18Old - Проверяет, достиг ли пользователь возраста 18 лет.
 * @param {string} return.validate.is18Old.value - Введенная дата в формате ДД.ММ.ГГГГ.
 * @returns {string|boolean} return.validate.is18Old - Возвращает true, если пользователю исполнилось 18 лет, или сообщение об ошибке.
 */

export const validateBirthdayInput = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
            message: 'Некорректная дата',
        },
        validate: {
            isDayOfMonth: (value) => {
                const [day, month, year] = value.split('/').map(Number);
                // Проверка високосного года
                const isLeapYear = (year) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
                // Проверка количества дней в месяце
                const daysInMonth = new Map([
                    [2, isLeapYear(year) ? 29 : 28],
                    [4, 30],
                    [6, 30],
                    [9, 30],
                    [11, 30],
                ]);

                const maxDays = daysInMonth.get(month) || 31;

                if (day > maxDays) return 'Некорректная дата';

                return true;
            },
            // нельзя ввести будущую дату/переопределим для др доктора
            isFutureDate: (value) => {
                const birthdayDate = new Date(Date.UTC(value.slice(6), value.slice(3, 5) - 1, value.slice(0, 2)));
                const currentDate = new Date();
                const enteredDate = new Date(birthdayDate);

                if (enteredDate > currentDate) {
                    return 'Некорректная дата';
                }

                return true;
            },
            // нельзя ввести слишком прошлую дату
            isPasteDate: (value) => {
                // оставлено если решат делать валидацию от возраста, а не от даты
                // const birthdayDate = new Date(Date.UTC(value.slice(6), value.slice(3, 5) - 1, value.slice(0, 2)));
                // const currentDate = new Date();
                // if (birthdayDate < currentDate.setFullYear(currentDate.getFullYear() - 100)) {
                //     return 'Дата не может быть более чем 100 лет назад';
                // }
                const birthdayDate = new Date(Date.UTC(value.slice(6))).getFullYear();
                if (birthdayDate < 1930) {
                    return 'Некорректная дата';
                }

                return true;
            },
            // если нет 18
            is18Old: (value) => {
                const birthdayDate = new Date(Date.UTC(value.slice(6), value.slice(3, 5) - 1, value.slice(0, 2)));
                const currentDate = new Date();
                if (birthdayDate > currentDate.setFullYear(currentDate.getFullYear() - 18)) {
                    return 'Нельзя зарегистрироваться на сайте, если Вам меньше 18 лет';
                }

                return true;
            },
        },
    };
};

export const validateRequired = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
    };
};

// валидация поля стоимости консультации
export const validatePrice = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения. Максимальная длина: 5 символов' },
        min: {
            value: 1,
            message: 'Минимальная цифра ввода 1',
        },
        // maxLength: {
        //     value: 5,
        //     message: 'Максимальная длина: 5 символов',
        // },
        minLength: {
            value: 1,
            message: 'Минимальная длина: 1 символ',
        },
        pattern: {
            value: /^[0-9]{1,5}$/,
            message: 'Значение может содержать только цифры',
        },
    };
};

// рост и вес в блоке формы с параметрами пациента
export const validAteInputParametrs = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        pattern: {
            value: /^[0-9]{1,3}$/,
            message: ' не число ',
        },
        validate: {},
    };
};

export const validateInputEmail = {
    required: 'Поле обязательно для заполнения',
    validate: {
        symbolInclude: (value) =>
            !/[^\w\-\@\.]/gi.test(value) ||
            'E-mail может содержать латинские буквы, цифры, дефис, нижнее подчеркивание',
        format: (value) => /\S+\@\S+\.\S{2,}/.test(value) || 'Допустимый формат почты: test@test.ru',
    },
    maxLength: {
        value: 70,
        message: 'В поле должно быть максимум 70 символов',
    },
};

// doctor LK
export const validateInputEducation = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 150,
            message: 'Максимальная длина: 150 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z0-9()№""\s\-]{2,150}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис, цифры, символы ( ) № " "',
        },
    };
};

export const validateInputSpecialization = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,50}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис',
        },
    };
};

export const validateInputProgram = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 150,
            message: 'Максимальная длина: 150 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z\s\-]{2,150}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис',
        },
    };
};

export const validateInputPosition = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 50,
            message: 'Максимальная длина: 50 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
        pattern: {
            value: /^[А-Яа-яЁёa-zA-Z0-9()№""\s\-]{2,50}$/,
            message: 'Значение может содержать символы алфавита, пробел, дефис, цифры, символы ()№""',
        },
    };
};

export const validateDiagnostic = (isRequred) => {
    return {
        required: { value: isRequred, message: 'Поле обязательно для заполнения' },
        maxLength: {
            value: 2000,
            message: 'Максимальная длина: 2000 символов',
        },
        minLength: {
            value: 2,
            message: 'Минимальная длина: 2 символа',
        },
    };
};
