import styled from 'styled-components';

export const PopUpAlertSettingStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 40px;
    width: 510px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    z-index: 100;
    .close {
        display: inline-flex;
        cursor: pointer;
    }
    .headerExiteProfile {
        margin: 0 auto;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: #06a7b3;
        width: 80%;
        span {
            font-weight: 500;
        }
    }
    .textClarification {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        color: #a2a3a8;
    }

    @media (max-width: 600px) {
        width: 280px;
        padding: 17px;
        .headerExiteProfile {
            font-size: 18px;
        }
        .buttonsExiteProfile {
            flex-direction: column;
            gap: 8px;
        }
    }
`;
