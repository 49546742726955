import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { patientDataUpdate } from '../api/patientApi';
import { deleteMyAvatar, getCity, loadMyAvatar } from '../../share/api';
import { fetchLogout } from '../../authentification/api/authentificationSlice';
import { meUser } from '../../authentification/api/authentificationApi';
import { formatErrorMessage, formatNullData } from '../../../componets/share/helpers';

/**
 * Асинхронное действие для получения профиля пациента.
 * @function fetchPatientProfile
 * @async
 * @returns {Promise<Object>} Данные профиля пациента.
 */
export const fetchPatientProfile = createAsyncThunk('userPatient/patientProfile', async (_, { dispatch }) => {
    const response = await meUser();
    return response;
});

/**
 * Асинхронное действие для создания и редактирования профиля пациента.
 * @function fetchPatientDataUpdate
 * @async
 * @param {Object} data - Данные для обновления профиля пациента.
 * @returns {Promise<Object>} Обновлённые данные профиля пациента.
 */
export const fetchPatientDataUpdate = createAsyncThunk(
    'userPatient/patientCreate',
    async (data, { rejectWithValue }) => {
        try {
            const response = await patientDataUpdate(data);
            return response;
        } catch (error) {
            console.error('Ошибка в fetchPatientDataUpdate', error.response.status);
            if (error.response.status === 404) {
                return rejectWithValue({ [error.name]: error.message });
            } else {
                return rejectWithValue(error.response ? error.response.data : { message: error.message });
            }
        }
    }
);

/**
 * Асинхронное действие для выхода пользователя из системы.
 * @function logoutPatient
 * @async
 * @returns {Promise<null>} Всегда возвращает null.
 */
export const logoutPatient = createAsyncThunk('userPatient/logout', async (_, { dispatch }) => {
    await dispatch(fetchLogout());
});

/**
 * Асинхронное действие для загрузки аватара пользователя.
 * @function fetchLoadMyAvatar
 * @async
 * @param {File} file - Файл аватара.
 * @returns {Promise<Object|undefined>} Результат загрузки аватара.
 */
export const fetchLoadMyAvatar = createAsyncThunk('userPatient/loadMyAvatar', async (file, { rejectWithValue }) => {
    try {
        return await loadMyAvatar(file);
    } catch (error) {
        console.error('error fetch loadMyAvatar', error);
        return rejectWithValue({ [error.name]: error.message });
    }
});

/**
 * Асинхронное действие для удаления аватара пользователя.
 * @function fetchDeleteMyAvatar
 * @async
 * @returns {Promise<Object|undefined>} Результат удаления аватара.
 */
export const fetchDeleteMyAvatar = createAsyncThunk(
    'userPatient/fetchDeleteMyAvatar',
    async (_, { rejectWithValue }) => {
        try {
            return await deleteMyAvatar();
        } catch (error) {
            console.error('error fetch deleteMyAvatar', error);
            return rejectWithValue({ [error.name]: error.message });
        }
    }
);

/**
 * Асинхронное действие для получения списка городов по строке поиска.
 * @function fetchCity
 * @async
 * @param {string} searchString - Строка поиска для фильтрации городов.
 * @returns {Promise<Array>} Список найденных городов.
 */
export const fetchCity = createAsyncThunk('userPatient/fetchCity', async (searchString, { rejectWithValue }) => {
    try {
        const response = await getCity(searchString);
        return response.data.results;
    } catch (err) {
        console.error('error fetch city', err);
        return rejectWithValue({ [err.name]: err.message });
    }
});

//--------------------------------------------------------------
/**
 * Начальное состояние слайса patientProfile.
 * @constant
 * @type {Object}
 * @property {Object} patientProfile - Профиль пациента.
 * @property {string} patientProfile.id - Идентификатор пациента.
 * @property {string} patientProfile.username - Уникальное имя пользователя пациента.
 * @property {string} patientProfile.role Роль пользователя: пациент.
 * @property {string} patientProfile.first_name - Имя пациента.
 * @property {string} patientProfile.last_name - Фамилия пациента.
 * @property {string} patientProfile.middle_name - Отчество пациента.
 * @property {string} patientProfile.date_birth - Дата рождения пациента.
 * @property {string} patientProfile.sex - Пол пациента.
 * @property {string} patientProfile.email - Электронная почта пациента.
 * @property {string} patientProfile.phone Телефон пользователя.
 * @property {string} patientProfile.city - название города пациента.
 * @property {File|string} patientProfile.image_standart - Файл аватара пациента.
 * @property {boolean} isLoader - Флаг загрузки данных профиля пациента.
 * @property {boolean} isPreloaderCity - Флаг загрузки данных городов.
 * @property {Array<Object>} cityOptionsForFetching - Список городов для выбора в SelectCity.
 * @property {boolean} isAvatarLoader - Флаг загрузки аватара.
 * @property {Object} messagesError Сообщения об ошибках (например, при аутентификации или регистрации).
 */
const initialState = {
    patientProfile: {
        id: '',
        username: '',
        role: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        date_birth: '',
        sex: '',
        email: '',
        phone: '',
        city: '',
        image_standart: '',
    },
    isLoader: false,
    isPreloaderCity: false,
    isAvatarLoader: false,
    messagesError: {},
    cityOptionsForFetching: [],
};

/**
 * Слайс состояния для управления профилем пациента.
 * @constant
 * @type {Slice}
 */
const patientProfile = createSlice({
    name: 'patientProfile',
    initialState,
    reducers: {
        /**
         * Сбрасывает список городов для получения и выбора в начальное состояние.
         * @function resetCity
         * @param {Object} state - Текущее состояние.
         */
        resetCity: (state) => {
            state.cityOptionsForFetching = [];
        },
        /**
         * Очищает сообщения об ошибках для указанного поля.
         * @function clearMessage
         * @param {string} field Имя поля, ошибки которого нужно очистить.
         */
        clearMessage(state, action) {
            const field = action.payload;
            if (state.messagesError[field]) {
                delete state.messagesError[field];
            }
        },
        clearPatientState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPatientProfile.pending, (state) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPatientProfile.fulfilled, (state, action) => {
            state.patientProfile = formatNullData(action.payload);
            state.isLoader = false;
        });
        builder.addCase(fetchPatientProfile.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });

        builder.addCase(fetchPatientDataUpdate.pending, (state, action) => {
            state.isLoader = true;
        });
        builder.addCase(fetchPatientDataUpdate.fulfilled, (state, action) => {
            state.patientProfile = {
                ...state.patientProfile,
                ...action.payload,
            };
            state.isLoader = false;
        });
        builder.addCase(fetchPatientDataUpdate.rejected, (state, action) => {
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
            state.isLoader = false;
        });
        builder.addCase(fetchLoadMyAvatar.pending, (state) => {
            state.isAvatarLoader = true;
        });
        builder.addCase(fetchLoadMyAvatar.fulfilled, (state, action) => {
            state.patientProfile.image_standart = action.payload.user.avatar;
            state.isAvatarLoader = false;
        });
        builder.addCase(fetchLoadMyAvatar.rejected, (state, action) => {
            state.isAvatarLoader = false;
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });
        builder.addCase(fetchDeleteMyAvatar.fulfilled, (state) => {
            state.patientProfile.image_standart = '';
            state.isAvatarLoader = false;
        });
        builder.addCase(fetchDeleteMyAvatar.rejected, (state, action) => {
            state.isAvatarLoader = false;
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });
        builder.addCase(fetchDeleteMyAvatar.pending, (state) => {
            state.isAvatarLoader = true;
        });
        builder.addCase(fetchCity.pending, (state) => {
            state.isPreloaderCity = true;
        });
        builder.addCase(fetchCity.fulfilled, (state, action) => {
            state.cityOptionsForFetching = action.payload;
            state.isPreloaderCity = false;
        });
        builder.addCase(fetchCity.rejected, (state, action) => {
            state.isPreloaderCity = false;
            const newMessages = formatErrorMessage(action.payload);
            state.messagesError = {
                ...state.messagesError,
                ...newMessages,
            };
        });
    },
});

export const { clearMessage, resetCity, clearPatientState } = patientProfile.actions;
export default patientProfile.reducer;
