import React from 'react';
import { useSelector } from 'react-redux';
import { TitleStl } from '../title/titleStl.styled';
import { SectionStl } from '../viewDoctor.styled';
import { ViewAdvancedTraining } from './ViewAdvancedTraining';

/**
 * Компонент для отображения информации о повышении квалификации доктора.
 *
 * @component
 * @returns {JSX.Element} Компонент секции с информацией о повышении квалификации.
 *
 * @example
 * // Пример использования в компоненте
 * <DoctorAdvancedTraining />
 *
 * @description
 * Компонент отображает заголовок "Повышение квалификации" и вызывает компонент `ViewAdvancedTraining`,
 * передавая ему данные о повышении квалификации, полученные из состояния Redux.
 */
export const DoctorAdvancedTraining = () => {
    const advanced_training = useSelector((state) => state.doctorProfile?.doctorData?.skills_improvement);

    return (
        <SectionStl>
            <TitleStl>
                <h2 className="title">Повышение квалификации</h2>
            </TitleStl>
            <ViewAdvancedTraining advanced_training={advanced_training} />
        </SectionStl>
    );
};
