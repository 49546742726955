import styled from 'styled-components';

export const MainMenuStyled = styled.nav`
    display: flex;
    flex-direction: column;
    .nav {
        &-link {
            text-decoration: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            text-align: left;
            letter-spacing: 0.01em;
            width: 100%;
            color: #212121;
            transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out;
            white-space: nowrap;
            padding: 10px 15px;
            &:hover {
                color: #19be6f;
                opacity: 0.7;
            }

            &__sub-link {
                display: flex;
                flex-direction: column;
                text-decoration: none;
                color: #212121;
                & .nav-link {
                    border: none;
                    padding: 5px 30px;
                    font-size: 14px;
                }
            }
            &--disabled {
                opacity: 0.7;
                cursor: default;
                &:hover {
                    color: #212121;
                }
            }
        }
        &-button {
            display: block;
            margin: 16px 0 40px;
            padding: 17px;
            border: 2px solid #19be6f;
            border-radius: 10px;
            width: 100%;
            text-align: center;
            color: #ffffff;
            background-color: #19be6f;
            cursor: pointer;
            transition: opacity 0.3s linear;
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .active {
        color: #19be6f;
        font-weight: 500;
        border-left: 3px solid #19be6f;
        @media (max-width: 700px) {
            padding: 11px 0;
            border: none;
        }
    }

    @media (max-width: 700px) {
        .nav {
            &-link {
                &:last-child {
                    padding-bottom: 11.5px;
                }
                padding: 11.5px 0;
                font-size: 16px;
                @media (hover: hover) {
                    &:hover {
                        color: #212121;
                        opacity: 1;
                        background-color: #f4f4f4;
                    }
                }
                @media (hover: none) {
                    &:active {
                        color: #212121;
                        opacity: 1;
                        background-color: #f4f4f4;
                    }
                }
                &--static {
                    align-items: center;
                    display: flex;
                    gap: 20px;
                }

                &__sub-link {
                    display: flex;
                    flex-direction: column;

                    & .nav-link {
                        padding: 0;
                        padding: 11.5px 16px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
`;
