import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { NewFormSTL } from './newFormSTL.styled';
import InputText from '../../../../share/formFields/InputText';
import InputDate from '../../../../share/formFields/InputDate';
import InputFile from '../../../../share/formFields/InputFile';
import {
    validateInputEducation,
    validateInputSpecialization,
    validateRequired,
} from '../../../../share/formFields/validateInput';
import { useDispatch } from 'react-redux';
import { fetchDeleteDrEducation } from '../../../api/doctorUserProfileSlice';

/**
 * Компонент `HigherEducationForm` для отображения и управления блоками высшего образования.
 * Позволяет добавлять, редактировать и удалять записи об образовании,
 * включая учебное заведение, специальность, год окончания и диплом.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.onHigherEducationData - Функция обратного вызова для отправки подготовленных данных об образовании.
 * @returns {JSX.Element} JSX-элемент компонента `HigherEducationForm`.
 *
 * @example
 * <HigherEducationForm onHigherEducationData={handleEducationData} />
 *
 * @description
 * Внутри компонента используется `react-hook-form` для управления состоянием полей формы.
 * Компонент поддерживает:
 * - Отслеживание изменений в полях образования.
 * - Обработку файлов дипломов.
 * - Возможность добавления и удаления записей.
 *
 * Основные функции:
 * - `higherEducationData`: Формирует данные о блоках образования для отправки на сервер, включая обработку изображения диплома.
 * - `onAdd`: Добавляет новый блок образования с пустыми значениями.
 * - `handleDelete`: Удаляет блок образования по индексу, при наличии ID вызывает экшен `fetchDeleteDrEducation` для удаления из API.
 */
const HigherEducationForm = ({ onHigherEducationData }) => {
    const dispatch = useDispatch();
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'data.education',
    });

    /**
     * Отслеживает изменения в полях данных об образовании
     * для дальнейшей передачи актуальных значений с помощью watch
     */
    const higherEducationFields = useWatch({
        control,
        name: 'data.education',
    });

    /**
     * Функция подготовки данных для отправки
     */
    const higherEducationData = (fields) => {
        return fields.map((field) => {
            const isFileUpdated = field.education_image instanceof FileList;

            return {
                id: field.id || null,
                institution: { name: field.institution.name },
                specialty: { name: field.specialty.name },
                graduation_year: parseInt(field.graduation_year, 10),
                delete_education_image: !isFileUpdated && !field.education_image ? true : false,
                education_image: isFileUpdated ? field.education_image[0] : undefined,
            };
        });
    };

    /**
     * Вызов функции для передачи данных о высшем образовании при изменении `higherEducationFields`.
     */
    useEffect(() => {
        const data = higherEducationData(higherEducationFields);
        onHigherEducationData(data);
    }, [higherEducationFields]);

    /**
     * Если массив fields пуст при первой отрисовке компонента,
     * в append добавляется пустой объект
     */
    useEffect(() => {
        if (fields.length === 0) {
            append({
                id: null,
                institution: { name: '' },
                specialty: { name: '' },
                graduation_year: '',
                delete_education_image: false,
                education_image: '',
            });
        }
    }, [fields, append]);

    /**
     * Не используется в текущем коде, но может понадобится
     * Функция для добавления нового блока образования.
     * Добавляет новый объект с пустыми значениями полей.
     */
    const onAdd = () => {
        append({
            id: null,
            institution: { name: '' },
            specialty: { name: '' },
            graduation_year: '',
            delete_education_image: false,
            education_image: '',
        });
    };

    /**
     * Не используется в текущем коде, но может понадобится
     * Функция, которая удаляет блок образования по указанному индексу.
     * Если у блока есть ID, инициирует удаление через API.
     */
    const handleDelete = (index) => {
        const eduToDelete = higherEducationFields[index]?.id;
        try {
            if (eduToDelete) {
                dispatch(fetchDeleteDrEducation(eduToDelete));
            }
        } catch (err) {
            console.error('Error deleting education', err);
        }
        remove(index);
    };

    return (
        <NewFormSTL>
            <h2 className="title">Образование</h2>

            {fields.map((field, index) => (
                <div key={field.reactHookFormId || field.id}>
                    <InputText
                        name={`data.education[${index}].institution.name`}
                        label="Учебное заведение"
                        placeholder="Название"
                        validation={validateInputEducation(true)}
                        isRequired={true}
                    />
                    <InputText
                        name={`data.education[${index}].specialty.name`}
                        label="Специальность"
                        placeholder="Название"
                        validation={validateInputSpecialization(true)}
                        isRequired={true}
                    />
                    <div className="work__date">
                        <InputDate
                            name={`data.education[${index}].graduation_year`}
                            label="Год окончания"
                            placeholder="ГГГГ"
                            validation={validateRequired(true)}
                            isRequired={true}
                        />
                    </div>
                    <InputFile
                        name={`data.education[${index}].education_image`}
                        validation={validateRequired(false)}
                        isRequired={false}
                        fieldName="диплом"
                    />

                    {fields.length > 1 && (
                        <button
                            type="button"
                            onClick={() => {
                                handleDelete(index);
                            }}
                        >
                            Удалить образование
                        </button>
                    )}
                </div>
            ))}

            {/* Кнопка для добавления нового элемента, не используется в текущем коде, но может понадобится */}
            {/* <button
                type="button"
                onClick={() => {
                  onAdd();
                  }
                }
            >
                Добавить образование
            </button> */}
        </NewFormSTL>
    );
};

export default HigherEducationForm;
