import styled from 'styled-components';

export const StyledDoctorCardProfile = styled.div`
    grid-template:${(props) =>
        props.isProfile
            ? '"aside main" minmax(98px, max-content) "aside right" auto/ 1fr 3fr'
            : props.isOurDoctors
                ? '"aside main" minmax(77px, max-content) "aside right" auto/ 1fr 3fr'
                : '"aside main" minmax(69px, max-content) "aside right" auto / 1fr 3fr'};
    display: grid;
    gap: ${(props) => (props.isProfile ? '20px' : props.isOurDoctors ? '5px' : ' 18px')};
    @media (max-width: 855px) {
        gap: ${(props) => (props.isProfile ? '21px' : props.isOurDoctors ? '6px' : ' 1px')};
        grid-template: ${(props) =>
        props.isOurDoctors
            ? '"aside main" minmax(102px, max-content) "footer footer" auto/ 1fr 3fr'
            : props.isProfile
                ? '"aside main" max-content "footer footer" auto/ 1fr 3fr'
                : '"aside main" max-content "aside right" auto / 1fr 3fr'};
    }
    @media (max-width: 420px) {
        gap: 6px;
        padding: ${(props) => (props.isOurDoctors ? '4px' : '0')};
        grid-template:${(props) =>
        props.isOurDoctors || props.isProfile
            ? '"aside main" minmax(93px, max-content) "footer footer" auto/ 1fr 3fr'
            : '"aside main" minmax(81px, max-content) "footer footer" auto / 1fr 3fr'};
    }
    .doctorPhoto {
        align-self: start;
        position: relative;
        grid-area: aside;

    }

    .doctorMainPhoto {
        width: 143px;
        height: 143px;
        border-radius: 84px;
        object-fit: cover;
        @media (max-width: 855px) {
            width:${(props) => (props.isProfile ? '90px' : '100px')};
            height: ${(props) => (props.isProfile ? '90px' : '100px')};
            border-radius: 84px;
            object-fit: cover;
        }
        @media (max-width: 420px) {
            width: ${(props) => (props.isOurDoctors || props.isProfile ? '90px' : ' 64px')};
            height: ${(props) => (props.isOurDoctors || props.isProfile ? '90px' : ' 64px')};
            border-radius: 84px;
            object-fit: cover;
        }
    }
    .doctorName {
        font-weight: 500;
        grid-area: main;
        margin-left: ${(props) => (props.isProfile ? '15px' : props.isOurDoctors ? '52px' : ' 34px')};
        margin-top: ${(props) => (props.isProfile ? '3px' : '0')};
        > p {
            word-break: normal;
            font-weight: 500;
            font-size:20px;
            line-height:26px;
            display: ${(props) => (props.isOurDoctors ? 'block' : ' inline')};
            @media (max-width: 855px) {
                line-height:23.4px;
                font-size:18px;
            }
            @media (max-width: 420px) {
                line-height:19.2px;
                font-weight: 500;
                font-size:16px;
            }
        }
        @media (max-width: 855px) {
            margin-left: ${(props) => (props.isProfile ? '3px' : ' 9px')};
            margin-top: ${(props) => (props.isProfile ? '0px' : '0')};

        }
        @media (max-width: 420px) {
            grid-area: main;
            margin-left: ${(props) => (props.isProfile ? '10px' : ' 9px')};
            margin-top: ${(props) => (props.isProfile ? '1px' : '0')};
        }
    }
    .about {
        grid-area: right;
        margin-left: ${(props) => (props.isProfile ? '11px' : props.isOurDoctors ? '44px' : ' 27px')};
        margin-top: ${(props) => (props.isProfile ? '12px' : props.isOurDoctors ? '28px' : ' 0')};
        display: flex;
        flex-direction: column;
        gap: 10px;
        @media (max-width: 855px) {
            grid-area: ${(props) => (props.isOurDoctors || props.isProfile ? 'footer' : ' right')};
            margin-left: ${(props) => (props.isProfile ? '0px' : props.isOurDoctors ? '0px' : ' 6px')};
            margin-top: ${(props) => (props.isProfile ? '0px' : props.isOurDoctors ? '6px' : '18px')};
        }
        @media (max-width: 420px) {
            grid-area: footer;
            margin-left: 0px;
            gap: ${(props) => (props.isProfile ? '7px' : props.isOurDoctors ? '8px' : ' 10px')};
            margin-top: ${(props) => (props.isProfile ? '10px' : props.isOurDoctors ? '7px' : ' 13px')};
        }
    }

    .like-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 100%;
        padding: 10px;
        background-color: #fff;
        cursor: pointer;
    }

    .like {
        width: 19px;
        height: 18px;
    }

    .name {
        font-weight: 500;
        line-height:19.2px
        font-size: 20px;
        :first-child {
            margin-bottom: 4px;
        }
        @media (max-width: 855px) {
            font-weight: 500;
            font-size: 18px;
        }
        @media (max-width: 420px) {
            font-size: 16px;
        }
    }

    .specialization {
        max-width: 90%;
        list-style: none;
        margin-top: ${(props) => (props.isProfile || props.isOurDoctors ? '7px' : ' 5px')};
        > li {
            display: inline-block;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            &:not(:last-child)::after {
                content: ", ";
                white-space: pre;
            }
            &:not(:first-child) {
                text-transform: lowercase;
            }
            @media (max-width: 420px) {
                font-size: 14px;
            }
        }
        @media (max-width: 855px) {
            margin-top: ${(props) => (props.isProfile ? '6px' : props.isOurDoctors ? '5px' : ' 9px')};
        }
        @media (max-width: 420px) {
            margin-top: ${(props) => (props.isProfile ? '4px' : props.isOurDoctors ? '5px' : ' 9px')};
        }
    }

    .mainFlex {
        display: flex;
        align-items: center;
        > svg {
            justify-self: flex-start;
            min-width: 10%;
            @media (max-width: 855px) {
                min-width: ${(props) => (props.isOurDoctors || props.isProfile ? '6%' : ' 10%')};
            }
            @media (max-width: 420px) {
                min-width: ${(props) => (props.isOurDoctors ? '10%' : '5%')};
            }
        }
    }

    .infoText {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #666666;
        max-width: 80%;
        margin-left: 1px;
        @media (max-width: 855px) {
            margin-left: ${(props) => (props.isProfile ? '10px' : props.isOurDoctors ? '9px' : '1px')};
        }
        @media (max-width: 420px) {
            font-size: 14px;
            margin-left: ${(props) => (props.isOurDoctors ? '4px' : '6px')};
        }
    }
    .infoText::first-letter {
        text-transform: uppercase;
    }

    .seniority .infoText {
        margin-right: ${(props) => (props.isOurDoctors ? '0' : '3px')};
        @media (max-width: 855px) {
            margin-right: ${(props) => (props.isProfile ? '10px' : props.isOurDoctors ? '12px' : '3px')};
        }
        @media (max-width: 420px) {
            margin-right: ${(props) => (props.isProfile ? '8px' : props.isOurDoctors ? '4px' : '12px')};
        }
    }

    .imgWrap {
        width: 6%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .star {
        margin-left: ${(props) => (props.isOurDoctors ? '0' : '1px')};
        @media (max-width: 855px) {
            margin-left: 5px;
        }
    }

    .mrImg {
        margin-right: ${(props) => (props.isOurDoctors ? '0' : '1px')};
    }
    .clock > svg {
        @media (max-width: 420px) {
            width: ${(props) => (props.isProfile ? '25px' : props.isOurDoctors ? '19px' : '17px')};
            height: ${(props) => (props.isProfile ? '18px' : props.isOurDoctors ? '19px' : '14px')};
        }
    }
    .starIcon > svg {
        @media (max-width: 420px) {
            width: ${(props) => (props.isOurDoctors || props.isProfile ? '19px' : '18px')};
            height: ${(props) => (props.isOurDoctors || props.isProfile ? '19px' : '12px')};
        }
    }
    .categoryIcon > svg {
        @media (max-width: 855px) {
            width: ${(props) => (props.isOurDoctors ? '19px' : '19px')};
        }
        @media (max-width: 420px) {
            width: ${(props) => (props.isProfile ? '25px' : props.isOurDoctors ? '24px' : '17px')};
            height: ${(props) => (props.isProfile ? '24px' : props.isOurDoctors ? '23px' : '18px')};
        }
    }
    .educationIcon > svg {
        @media (max-width: 420px) {
            width: ${(props) => (props.isProfile ? '24px' : props.isOurDoctors ? '19px' : '16px')};
            height: ${(props) => (props.isProfile ? '19px' : props.isOurDoctors ? '16px' : '12px')};
        }
    }
    .geo > svg {
        @media (max-width: 420px) {
            width: ${(props) => (props.isOurDoctors || props.isProfile ? '24px' : '17px')};
            height: ${(props) => (props.isOurDoctors || props.isProfile ? '21px' : '16px')};
        }
    }
`;
