import { VisitNoticeStl } from './visitNotice.styled';
import { React } from 'react';
import { Modal } from '../../../share/Modal';
import { Appointment } from '../appointment/Appointment';
import { useSelector } from 'react-redux';
import { OnlineConsultation } from '../onlineConsultation/OnlineConsultation';
import { EmptyProfileConsultation } from '../emptyProfileConcultationModal/EmptyProfileConsultation';
import { ErrorConsultation } from '../errorConsultationModal/ErrorConsultation';

export const VisitNoticeModal = ({
    handleCancelClick,
    typeOfNotice,
    isAuth,
    closeModal,
    doctor,
    patientProfile,
    existsConsultation,
}) => {
    const consultation = useSelector((state) => state.doctors.consultation);

    if (!isAuth) {
        return (
            <Modal toggle={closeModal}>
                <Appointment closeModal={closeModal} />
            </Modal>
        );
    }
    if (!patientProfile || patientProfile.first_name === '') {
        // при незаполненном профиле пациента , всплывает модальное окно,в котором описывается необходимость заполнения обязательных данных пациента
        return (
            <Modal toggle={closeModal}>
                <EmptyProfileConsultation closeModal={closeModal} />
            </Modal>
        );
    } else if (typeOfNotice === 'online' && existsConsultation === true) {
        return (
            <Modal toggle={closeModal}>
                <ErrorConsultation closeModal={closeModal} errorMessage={'У вас уже есть консультация у этого врача'} />
            </Modal>
        );
    } else if (typeOfNotice === 'online') {
        return (
            <Modal toggle={closeModal}>
                <OnlineConsultation consultation={consultation} doctor={doctor} closeModal={closeModal} />
            </Modal>
        );
    } else if (typeOfNotice === 'clinic' || typeOfNotice === 'home') {
        const getNoticeText = () => {
            if (typeOfNotice === 'clinic') {
                return (
                    <p className="application__text">
                        Оператор свяжется с вами в ближайшее время для уточнения времени посещения клиники
                    </p>
                );
            } else if (typeOfNotice === 'home') {
                return (
                    <p className="application__text">
                        Оператор свяжется с вами в ближайшее время для уточнения деталей вызова врача на дом
                    </p>
                );
            }
        };
        return (
            <Modal toggle={closeModal}>
                <VisitNoticeStl>
                    <div className="application">
                        <div className="application__icon">
                            <img src="/img/V_telemedicine_patient.svg" alt="header logo" />
                        </div>
                        <h2 className="application__title">Заявка отправлена</h2>
                        {getNoticeText()}
                        <button onClick={handleCancelClick} className="application__button">
                            Отменить
                        </button>
                    </div>
                </VisitNoticeStl>
            </Modal>
        );
    }
};
