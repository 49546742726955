import React, { useEffect, useState } from 'react';
import { ConsultationListStl } from './consultationList.styled';
import { Outlet, useLocation } from 'react-router-dom';
import { ConsultationList } from './ConsultationList';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSuccessfulConsultation } from '../../lkDoctorModals/modalSuccessfulConsultation/ModalSuccessfulConsultation.jsx';
import { Modal } from '../../../share/Modal.jsx';
import { setIsModalSuccessfulConsultationDoctor } from '../../api/lkDoctorConsultationsSlice';
import { AxiosError } from 'axios';

/**
 * Компонент `DoctorConsultationList`.
 *
 * Отвечает за отображение списка консультаций (прошедших или будущих),
 * а также управление модальными окнами и пагинацией.
 *
 * @component
 * @returns {JSX.Element} Элемент списка консультаций или дочерний маршрут.
 */

export const DoctorConsultationList = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const regex = /\/\d+$/; // проверка на наличие цифр (id консультации)
    const { doctorConsultations, isModalSuccessfulConsultation, timeConsultation, messagesError } = useSelector(
        (state) => state.doctorConsultations
    );

    const axiosError = messagesError.AxiosError;

    const [consultationsList, setConsultationsList] = useState({});
    const role = 'doctor';

    useEffect(() => {
        setConsultationsList((prev) =>
            pathname.includes('future') ? doctorConsultations.future : doctorConsultations.past
        );
    }, [pathname, doctorConsultations.future.consultations, doctorConsultations.past.consultations]);

    const closePopupSuccessfulConsultation = () => {
        dispatch(setIsModalSuccessfulConsultationDoctor());
    };

    return (
        <ConsultationListStl>
            {axiosError && <AxiosError error={axiosError} />}
            {regex.test(pathname) ? <Outlet /> : <ConsultationList list={consultationsList} pathname={pathname} />}
            {isModalSuccessfulConsultation && (
                <Modal toggle={closePopupSuccessfulConsultation}>
                    <ModalSuccessfulConsultation
                        closePopupSuccessfulConsultation={closePopupSuccessfulConsultation}
                        timeConsultation={timeConsultation}
                        role={role}
                    />
                </Modal>
            )}
        </ConsultationListStl>
    );
};
