import React from 'react';
import { NewFormSTL } from './newFormSTL.styled';
import { useSelector } from 'react-redux';
import RadioGroupDrCategories from '../../../../share/formFields/RadioButtonDrCategories';
import SelectSpecialization from '../../../../allSpecializations/specializations';
import { useFormContext } from 'react-hook-form';

/**
 * Компонент `Specialization` отображает форму редактирования специализации, категории и научной степени врача.
 *
 * @component
 * @returns {JSX.Element} JSX-элемент, представляющий форму для редактирования специализации врача.
 *
 * @example
 * // Использование компонента в профиле врача
 * <Specialization />
 */

const Specialization = () => {
    const { watch } = useFormContext();
    const category = watch('data.category');
    const scientific_degree = watch('data.scientific_degree');

    return (
        <NewFormSTL>
            <h2 className="title">Специализация</h2>
            <SelectSpecialization
                label="Специализация"
                isRequired={true}
                name="data.specialization"
                isMulti={true}
                placeholder="Введите специализацию"
                onChange={() => {}}
            />
            <RadioGroupDrCategories
                defaultStateValue={category}
                name="data.category"
                label="Категория"
                options={['Категория отсутствует', 'Первая категория', 'Вторая категория', 'Высшая категория']}
            />
            <RadioGroupDrCategories
                defaultStateValue={scientific_degree}
                name="data.scientific_degree"
                label="Научная степень"
                options={['Степень отсутствует', 'Кандидат медицинских наук', 'Доктор медицинских наук']}
            />
        </NewFormSTL>
    );
};

export default Specialization;
