import styled from 'styled-components';

export const ReviewCardStl = styled.div`
    .docReview__title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
        color: #000000;

        @media (max-width: 450px) {
            font-size: 18px;
            margin-bottom: 23.4px;
        }

        @media (max-width: 340px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .docReview__card {
        display: flex;
        gap: 19px;
        margin-bottom: 15px;

        &_header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;

            > div {
                display: flex;
                gap: 16px;
            }
        }

        &_patient-name {
            font-size: 18px;
            color: #000000;
            @media (max-width: 550px) {
                font-size: 16px;
            }
        }

        &_date {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #666666;
        }
        &_body {
            width: 100%;
        }
        &_description {
            overflow-wrap: anywhere;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: justify;

            @media (max-width: 550px) {
                font-size: 14px;
            }
        }
    }
`;
