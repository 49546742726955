import React from 'react';
import { DaysStl } from './days.styled';
import { useCalendar } from '../../../helpers/useCalendar';
import { setClassName } from '../../../helpers/setClassName';
import { useLocation } from 'react-router-dom';
import { chooseDays } from '../../../helpers/helpers';

/**
 * Компонент для отображения дней месяца в календаре.
 * Отображает как дни текущего месяца, так и дни соседних месяцев.
 * Позволяет выбрать день или несколько дней в зависимости от состояния.
 *
 * @param {Date} choosenDate - Текущая выбранная дата
 * @param {Function} setChoosenDate - Функция для обновления выбранной даты
 * @param {boolean} isDays - Флаг, указывающий, выбраны ли отдельные дни
 * @param {Function} setChoosenDates - Функция для обновления массива выбранных дат
 * @param {Array} choosenDates - Массив выбранных дат
 *
 * @returns {JSX.Element} Рендерит дни месяца с возможностью выбора
 */

export const Days = ({ choosenDate, setChoosenDate, isDays, setChoosenDates, choosenDates, savedAndChoosen }) => {
    const { pathname } = useLocation();
    const {
        previousMonthDays,
        nextMonthDays,
        beforeSettingsDays,
        settingsDays,
        afterSettingsDays,
        currentMonthDaysWithSettings,
    } = useCalendar(choosenDate);

    const pathnameBoolean = pathname === '/lk/doctor-profile/doctor-calendar/viewer';
    const handleSttingsDayClick = (day) => {
        if (pathname === '/lk/doctor-profile/doctor-calendar/settings') {
            if (isDays) {
                day.choosen = !day.choosen;
                setChoosenDates(settingsDays.filter((day) => day.choosen));
            } else {
                setChoosenDates(chooseDays(settingsDays, day));
            }
        } else {
            if (choosenDates.length && choosenDates[0]?.month !== day.month) {
                setChoosenDates([]);
            }
            setChoosenDates(chooseDays(currentMonthDaysWithSettings, day));
        }
    };

    const handleClick = (day) => {
        setChoosenDates([]);
        setChoosenDate(new Date(day.year, day.month, day.day, 0, 0, 0));
    };

    return (
        <DaysStl pathname={pathnameBoolean}>
            {previousMonthDays.map((day) => (
                <div
                    key={day.day}
                    onClick={() => {
                        handleClick(day);
                    }}
                    className="calendar__days_unused"
                >
                    {day.day}
                </div>
            ))}
            {pathnameBoolean ? (
                <>
                    {currentMonthDaysWithSettings.map((day, index, arr) => {
                        const classes = setClassName(day, index, arr, choosenDates, savedAndChoosen);
                        return (
                            <div
                                key={day.day}
                                onClick={() => {
                                    handleSttingsDayClick(day);
                                }}
                                className={`${classes} calendar__days_unused`}
                            >
                                {day.day}
                            </div>
                        );
                    })}
                </>
            ) : (
                <>
                    {beforeSettingsDays.map((day) => (
                        <div key={day.day} className="calendar__days_unused calendar__days_unused-noClick">
                            {day.day}
                        </div>
                    ))}
                    {settingsDays.map((day, index, arr) => {
                        const classes = setClassName(day, index, arr, choosenDates, savedAndChoosen);
                        return (
                            <div
                                key={day.day}
                                onClick={() => {
                                    handleSttingsDayClick(day);
                                }}
                                className={classes}
                            >
                                {day.day}
                            </div>
                        );
                    })}
                    {afterSettingsDays.map((day) => (
                        <div key={day.day} className="calendar__days_unused calendar__days_unused-noClick">
                            {day.day}
                        </div>
                    ))}
                </>
            )}
            {nextMonthDays.map((day) => (
                <div
                    key={day.day}
                    onClick={() => {
                        handleClick(day);
                    }}
                    className="calendar__days_unused"
                >
                    {day.day}
                </div>
            ))}
        </DaysStl>
    );
};
