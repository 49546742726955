import React from 'react';
import { DoctorReviewsStl } from './doctorReviews.styled';
import { ReviewCard } from './reviewCard/ReviewCard';
import { ScrollToTopArrowUp } from '../../share/ScrollToTopArrowUp';

/**
 * Компонент для отображения отзывов о докторе.
 *
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.reviews - Массив отзывов о докторе.
 * @param {number} props.reviewsCount - Общее количество отзывов о докторе.
 * @param {Function} props.showMoreReviews - Функция для отображения дополнительных отзывов.
 * @returns {JSX.Element} Возвращает элементы JSX, представляющие список отзывов и кнопки для отображения дополнительных отзывов.
 *
 * @example
 * <DoctorReviews
 *   reviews={[{ id: 1, text: 'Отличный врач!' }]}
 *   reviewsCount={5}
 *   showMoreReviews={() => // console.log('Показать больше отзывов')}
 * />
 */

export const DoctorReviews = ({ reviews, reviewsCount, showMoreReviews }) => {
    return (
        <DoctorReviewsStl>
            {reviews?.length === 0 ? (
                <p className="noReviews">Об этом докторе ещё нет отзывов</p>
            ) : (
                <>
                    <div className="reviews__cards">
                        {reviews?.map((review) => (
                            <ReviewCard feedback={review} key={review.id} />
                        ))}
                    </div>
                    <div className="reviews__buttons-container">
                        {reviews?.length < reviewsCount ? (
                            <button className="reviews__btn" onClick={showMoreReviews}>
                                Показать больше
                            </button>
                        ) : (
                            <div />
                        )}
                        <ScrollToTopArrowUp isReviews={true} />
                    </div>
                </>
            )}
        </DoctorReviewsStl>
    );
};
