import React from 'react';
import { ScheduleSettingsStl } from './scheduleSettings.styled';
import { useSchedule } from '../../calendarComponents/helpers/useSchedule';
import { setClassNameTime } from '../../calendarComponents/helpers/setClassName';

/**
 * Компонент для отображения и выбора времени для записи на выбранные даты.
 * Отображает список доступных временных интервалов и позволяет выбрать время для записи.
 *
 * @component
 *
 * @param {Array} choosenDates Массив выбранных дат.
 * @param {Function} setSelectedTime Функция для установки выбранного времени.
 * @param {Boolean} isInterval Флаг, определяющий, выбран ли интервал времени или отдельные временные интервалы.
 *
 * @returns {React.Element} Компонент для настройки времени на выбранные дни.
 */

export const ScheduleSettings = ({ choosenDates, setSelectedTime, isInterval }) => {
    const { handleSelect, times } = useSchedule(choosenDates, setSelectedTime, isInterval);

    const timesShow = times.map((el, index) => {
        const { timeString } = el;
        const classes = setClassNameTime(el);
        return (
            <div key={timeString} className={classes} onClick={() => handleSelect(el, index)}>
                {timeString}
            </div>
        );
    });

    return <ScheduleSettingsStl>{timesShow}</ScheduleSettingsStl>;
};
