import { EmptyProfileConsultationStl } from './emptyProfileConsultation.styled';
import { React } from 'react';
import { Button } from '../Button';
import crossIcon from '../../../../img/icons/icon-cross-green.svg';
import { useNavigate } from 'react-router-dom';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';

/**
 * EmptyProfileConsultation - Компонент, который отображает сообщение, когда пользователю необходимо
 * заполнить обязательные поля в личном кабинете перед записью на консультацию.
 *
 * @component
 * @returns {JSX.Element} Отображает сообщение с просьбой обновить свой профиль.
 *
 * @example
 * return (
 *   <EmptyProfileConsultation
 *     closeModal={someCloseModalFunction}
 *   />
 * );
 *
 * @prop {Function} closeModal - Функция для закрытия модального окна. Передает булево значение для указания состояния модала.
 *
 * Компонент включает в себя следующие элементы:
 * - Кнопка закрытия с иконкой для закрытия модального окна.
 * - Информационная иконка, визуально представляющая пустое состояние.
 * - Текст с инструкцией, побуждающий пользователя заполнить свои личные данные.
 * - Кнопка, которая переводит к профилю пользователя в личном кабинете.
 */

export const EmptyProfileConsultation = ({ closeModal }) => {
    const navigate = useNavigate();
    useLockBodyScroll();

    return (
        <EmptyProfileConsultationStl>
            <div className="empty">
                <button className="empty__cross" onClick={() => closeModal(false)}>
                    <img src={crossIcon} alt="close" className="cross" />
                </button>
                <img src="/img/V_telemedicine_patient.svg" alt="header logo" className="empty__icon" />
                <div className="empty__content">
                    <span>Для записи необходимо заполнить все обязательные поля в личном кабинете</span>
                </div>
                <div className="empty__btn">
                    <Button className="empty__btn-transition" onClick={() => navigate('/lk/patient-profile/profile')}>
                        Перейти в личный кабинет
                    </Button>
                </div>
            </div>
        </EmptyProfileConsultationStl>
    );
};
