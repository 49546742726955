import React from 'react';
import { InputStl } from './checkboxStl.styled';

/**
 * Компонент `Checkbox` представляет собой кастомный чекбокс, который интегрируется с библиотекой управления формами.
 *
 * @param {Object} props - Пропсы компонента.
 * @param {Object} props.field - Поле, предоставленное контроллером формы (например, `react-hook-form`).
 * @param {boolean} props.field.value - Текущее значение чекбокса (отмечен или нет).
 * @param {Function} props.field.onChange - Функция, вызываемая при изменении состояния чекбокса.
 * @param {Function} props.field.onBlur - Функция, вызываемая при потере фокуса чекбоксом.
 * @param {string} [props.label] - Текстовая метка, отображаемая рядом с чекбоксом.
 *
 * @returns {JSX.Element} Элемент JSX, представляющий чекбокс с меткой.
 */
const Checkbox = ({ field, label }) => {
    return (
        <label>
            <InputStl
                type="checkbox"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                onBlur={field.onBlur}
            />
            {label}
        </label>
    );
};

export default Checkbox;