import React from 'react';
import { TitleWithPencil } from '../../lkPatientProfile/viewPatient/titleWithPencil/TitleWithPencil';
import { useNavigate } from 'react-router-dom';
import EditData from '../../lkPatientProfile/viewPatient/patientData/EditData';
import { SectionStl } from '../../lkPatientProfile/viewPatient/viewPatient.styled';
import { ViewPatientStl } from '../../lkPatientProfile/viewPatient/viewPatient.styled';

/**
 * Компонент EditPatient позволяет пациенту редактировать свои личные данные.
 * @component
 * @returns {JSX.Element} Отрендеренная страница редактирования профиля пациента с формой для изменения личных данных.
 */

const EditPatient = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/lk/patient-profile/profile/view');
    };

    return (
        <ViewPatientStl>
            <SectionStl>
                <TitleWithPencil handleRedirect={handleRedirect} title={'Личные данные'} />
                <EditData />
            </SectionStl>
        </ViewPatientStl>
    );
};

export default EditPatient;
