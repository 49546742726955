import styled from 'styled-components';

export const StyledConsultationButtons = styled.div`
    font-size: ${(props) => (props.isSingleConsultation ? '16px' : '14px')};
    line-height: 20px;

    button {
        width: ${(props) => (props.isSingleConsultation ? '244px' : '200px')};
        height: ${(props) => (props.isSingleConsultation ? '48px' : '36px')};
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        @media (max-width: 500px) {
            width: 100%;
            height: ${(props) => (props.isSingleConsultation ? '40px' : '36px')};
        }
    }

    .startBtn {
        border: 2px solid #19be6f;
        border-radius: 10px;
        background: #19be6f;
        color: #ffffff;
        font-weight: 500;
        background: #19be6f;
        border-radius: 6px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: #169056;
            border: 2px solid #169056;
        }
    }

    .disabledBtn {
        border: 2px solid #8af0bf;
        border-radius: 10px;
        color: #ffffff;
        font-weight: 500;
        border-radius: 6px;
        background-color: #8af0bf;
        cursor: default;
    }

    .recommendText {
        color: #19be6f;
        font-size: 16px;
    }
    .closeBtn {
        color: #19be6f;
        background: #ffffff;
        font-weight: 500;
        border: 2px solid #19be6f;
        border-radius: 6px;
    }

    @media (max-width: 500px) {
        grid-column: span 2 !important;
    }
`;
