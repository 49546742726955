import React from 'react';
import { ReactComponent as Arrow } from '../../../../../../../img/icons/doctorProfilIcons/arrow.svg';

export const StepButton = ({ direction, className, targetDate, setTargetDate, clearChoosenDates }) => {
    // const handleClick = () =>
    //     setTargetDate(new Date(Date.UTC(targetDate.getUTCFullYear(), targetDate.getUTCMonth() + direction)));

    const handleClick = () => {
        const newDate = new Date(targetDate);

        const targetYear = newDate.getFullYear();
        const targetMonth = newDate.getMonth();
        const updTargetMonth = newDate.getMonth() + direction;
        const daysInUpdTargetMonth = new Date(targetYear, updTargetMonth + 1, 0).getDate();
        const currentDay = newDate.getDate();

        newDate.setDate(1);
        newDate.setMonth(updTargetMonth);

        clearChoosenDates();
        setTargetDate(newDate);
    };

    return (
        <button onClick={handleClick} type="button" className={className}>
            <Arrow />
        </button>
    );
};
