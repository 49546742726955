import styled from 'styled-components';

export const StyledTopBanner = styled.section`
    background-color: #f7f7f7;
    padding-top: 52px;
    padding-bottom: 180px;

    @media (max-width: 768px) {
       padding-top: 60px;
       padding-bottom: 120px;
    }

    @media (max-width: 500px) {
       padding-top: 32px;
       padding-bottom: 80px;
    }
    .topBanner__inner {
        .topBanner__top {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
            }
            .topBanner__top-left {
                border-radius: 16px;
                overflow: hidden;
                flex: 1;

                .topBanner__top-img {
                    width: 100%;
                    max-height: 444px;
                    object-fit: cover;
                    height: 100%;
                    border-radius: 16px
                }

                div {
                    height: 100%;
                }
            }
            .topBanner__top-right {
                flex: 1;
                border-radius: 16px;
                background-color: #fff;
                padding: 40px 20px;
                padding: ${({ whoAreYou }) => (whoAreYou !== 'doctor' ? '40px 20px' : '100px 20px 0')};
                display: flex;
                flex-direction: column;

                @media (max-width: 768px) {
                    padding: ${({ whoAreYou }) => (whoAreYou !== 'doctor' ? '20px 10px' : '54px 10px 0')};
                }

                @media (max-width: 500px) {
                    padding: ${({ whoAreYou }) => (whoAreYou !== 'doctor' ? '16px 12px' : '54px 10px 0')};
                }

                .topBanner__top-title {
                    font-size: 44px;
                    line-height: 46px;
                    margin-top: 0;
                    margin-bottom: 36px;
                    &--wrapper {
                        color: #19be6f;
                        font-weight: 500;
                    }
                    &--bold {
                        color: #212121;
                        font-weight: 500;
                    }

                    @media (max-width: 1000px) {
                        font-size: 32px;
                        line-height: 36px;
                        margin-bottom: 16px;
                    }

                    @media (max-width: 500px) {
                        font-size: 24px;
                        line-height: 28px;
                        margin-bottom: 12px;
                    }
                }
                .topBanner__top-text {
                    margin-bottom: 72px;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 300;
                    span {
                        color: #19be6f;
                        font-weight: 300;s
                    }

                    @media (max-width: 768px) {
                        margin-bottom: 35px;
                    }

                    @media (max-width: 500px) {
                        margin-bottom: 27px;
                    }
                }
                .topBanner__top-btn {
                    font-size: 20px;
                    line-height: 30px;
                    border: none;
                    border-radius: 10px;
                    padding: 13px 37px;
                    color: #fff;
                    background-color: #19be6f;
                    cursor: pointer;
                    width: fit-content;

                    @media (max-width: 500px) {
                        width: 100%;
                    }
                }
            }
        }
    }
`;
