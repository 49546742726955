import { MainMenu } from '../../share/mainMenu/mainMenu';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StyledLkPatientNavigation } from './lkPatientNavigation.styled';

/**
 * Компонент LkPatientNavigation отображает навигационное меню для личного кабинета пациента.
 * Использует компонент MainMenu для рендеринга пунктов меню на основе данных naviData.
 * @returns {JSX.Element} Возвращает навигационное меню
 */

export const LkPatientNavigation = () => {
    const lastName = useSelector((state) => state.patientProfile.patientProfile?.last_name);

    const naviData = useMemo(() => {
        return [
            {
                key: 1,
                title: 'Профиль',
                linkPathTo: '/lk/patient-profile/profile',
                isActiveWithoutData: true,
            },
            {
                key: 2,
                title: 'Мои консультации',
                linkPathTo: '/lk/patient-profile/consultation',
                isActiveWithoutData: !!lastName,
                children: [
                    {
                        key: 2.1,
                        title: 'Запланированные',
                        linkPathTo: '/lk/patient-profile/consultation/future-consultation',
                        isActiveWithoutData: !!lastName,
                    },
                    {
                        key: 2.2,
                        title: 'Прошедшие',
                        linkPathTo: '/lk/patient-profile/consultation/past-consultation',
                        isActiveWithoutData: !!lastName,
                    },
                ],
            },
            {
                key: 3,
                title: 'Настройки',
                linkPathTo: '/lk/patient-profile/settings',
                isActiveWithoutData: true,
            },
        ];
    }, [lastName]);

    /**
     * Массив naviData содержит данные для навигационного меню  пациента.
     * Содержит название раздела, путь для перехода и подменю (children).
     * Каждая запись включает ключ, название, путь и флаг активности.
     */

    return (
        <StyledLkPatientNavigation>
            <MainMenu naviData={naviData} lastName={lastName} />
        </StyledLkPatientNavigation>
    );
};
