import React, { forwardRef } from 'react';
import { ConsultationButtons } from '../../consultationButtons/consultationButtons';
import { ConsultationTimeAndPrice } from '../../../../share/consultationTime/consultationTime';
import { StyledPatientConsultationCard } from './patientConsultationCard.styled';
import { consultationStatus } from '../lkPatientConsultationHelpers';
import { useNavigate } from 'react-router-dom';
import AvatarDefault from '../../../../../img/avatar_default.png';

/**
 * Компонент для отображения краткой карточки консультации пациента.
 * Выполняет следующие действия:
 * - Отображает краткую информацию о консультации, включая:
 *   - Имя и фото врача (или изображение по умолчанию, если фото отсутствует).
 *   - Специализации врача.
 *   - Дату, время и цену консультации.
 *   - Статус консультации и комментарии пациента.
 * - Позволяет пользователю кликнуть на карточку, чтобы перейти на страницу с подробной информацией о консультации.
 *
 * @component
 * @param {Object} props Свойства компонента.
 * @param {Object} props.consultation Данные о консультации пациента.
 * @param {number} props.consultation.status Статус консультации (0 - запланировано, 1 - завершено).
 * @param {string} props.consultation.consultation_time Дата и время консультации.
 * @param {Object} props.consultation.doctor Данные о враче.
 * @param {number} props.consultation.doctor.user.id Идентификатор врача.
 * @param {string} props.consultation.doctor.user.last_name Фамилия врача.
 * @param {string} props.consultation.doctor.user.first_name Имя врача.
 * @param {string} [props.consultation.doctor.user.middle_name] Отчество врача.
 * @param {string} [props.consultation.doctor.image_standart] URL изображения врача.
 * @param {number} props.consultation.doctor.price Цена консультации.
 * @param {Array} [props.consultation.doctor.specialization] Специализации врача.
 * @param {number} props.consultation.id Идентификатор консультации.
 * @param {Object} [props.consultation.registration_log] Лог регистрации консультации.
 * @param {Object} [props.consultation.registration_log.cancelled_by] Информация об отмене консультации.
 * @param {number} [props.consultation.registration_log.cancelled_by.uid] ID пользователя, отменившего консультацию.
 * @param {string} [props.consultation.patient_notes] Заметки пациента.
 * @param {boolean} props.consultation.is_payed Флаг оплаты консультации.
 * @param {React.Ref} ref Ссылка на элемент карточки для использования в родительских компонентах.
 * @returns {JSX.Element} Карточка консультации пациента.
 */
export const PatientConsultationCard = forwardRef(({ consultation }, ref) => {
    const navigate = useNavigate();

    const {
        status,
        consultation_time,
        doctor: {
            user: { id: doctorId, last_name, first_name, middle_name, image_standart },
            price,
            specialization,
        },
        id: consultationID,
        registration_log,
        patient_notes,
        is_payed,
    } = consultation;

    let fullName = `${last_name} ${first_name} ${middle_name ? middle_name : ''}`;

    // сейчас только пациент может отменить консультацию, проверка оставлена на будущее
    // проверить!!!!! что у доктора нужно - id user-а или доктора
    const isPatientCancelled = doctorId !== registration_log?.cancelled_by?.uid;

    const showMoreConsultation = () => {
        navigate(`${consultationID}`);
    };

    const spec = specialization?.map((specialization) => {
        return <li key={specialization.id}>{specialization.name}</li>;
    });

    /**
     * URL-адрес изображения врача. Использует изображение по умолчанию, если изображение врача отсутствует.
     * @type {string}
     */
    const avatar = image_standart ? image_standart : AvatarDefault;

    return (

        <StyledPatientConsultationCard onClick={showMoreConsultation} ref={ref}>
            <div className="photoBlock">
                <img src={avatar} alt="photo" />
            </div>
            <div className="status"> {consultationStatus(status, is_payed, isPatientCancelled)}</div>
            <div className="info">
                <div className="doctorName">{fullName}</div>
                <ul className="specialization">{spec}</ul>
            </div>
            <div className="details">
                <ConsultationTimeAndPrice priceDisplay={true} card={true} date={consultation_time} price={price} />
                {status === 0 && (
                    <p className={`comment ${(patient_notes === '' || !patient_notes) && 'noComment'}`}>
                        {patient_notes === '' || !patient_notes ? 'Комментарии не оставлены' : patient_notes}
                    </p>
                )}
                <ConsultationButtons consultation={consultation} />
            </div>
        </StyledPatientConsultationCard>

    );
});

// Добавляем displayName для улучшения читаемости предупреждений и дебаггинга
PatientConsultationCard.displayName = 'PatientConsultationCard';
