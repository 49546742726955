import styled from 'styled-components';

export const PopUpConfirmSettingStyled = styled.section`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 60px 68px;
    width: 508px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    gap: 24px;
    align-items: center;
    justify-items: center;
    cursor: default;

    .close {
        position: absolute;
        cursor: pointer;
        width: 20px;
        height: 20px;
        top: 30px;
        right: 30px;
    }

    .popupText {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .headerExiteProfile {
        font-size: 24px;
        line-height: 134%;
        text-align: center;
        color: #212121;
        width: 100%;
        font-weight: 500;
    }

    .textClarification {
        font-size: 18px;
        line-height: 134%;
        text-align: center;
        color: #666666;
        font-weight: 400;
    }

    .popupBtns {
        display: flex;
        gap: 24px;
        width: 100%;
        button {
            font-weight: 500;
        }
    }

    .popupBtn {
        width: 174px;
    }

    @media (max-width: 500px) {
        width: 288px;
        padding: 52px 16px 40px;

        .close {
            width: 13.8px;
            height: 13.8px;
            top: 22.5px;
            right: 22.5px;
        }
        .headerExiteProfile {
            font-size: 18px;
        }
        .textClarification {
            font-size: 16px;
        }
        .popupBtns {
            flex-direction: column;
            gap: 16px;
            width: 100%;
        }

        .popupBtn {
            width: 100%;
        }
    }
`;
