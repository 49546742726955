import React, { useState } from 'react';
import { Modal } from '../../share/Modal';
import { PatientSettingsDiv } from './lkPatientSettings.styled';
import PopUpConfirm from '../../share/PopUpConfirm';
import PopUpAlert from '../../share/PopUpAlert';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchDeleteUser } from '../../authentification/api/authentificationSlice';

/**
 * @function LkPatientSettings
 * Данная функция предназначена для удаления профиля Пациента в Настройках личного кабинета
 */

const LkPatientSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.authentification.user);
    const [popUpDeleteProfile, setPopUpDeleteProfile] = useState(false);
    const [popUpSuccessfulDeleteProfile, setPopUpSuccessfulDeleteProfile] = useState(false);

    const deleteProfile = () => {
        setPopUpDeleteProfile(false);
        setPopUpSuccessfulDeleteProfile(true);
    };

    const successDeleteProfile = () => {
        navigate('/');
        setPopUpSuccessfulDeleteProfile(false);
        dispatch(fetchDeleteUser(user.id));
    };

    return (
        <PatientSettingsDiv>
            <p className="settings__item">
                Вы можете{' '}
                <label onClick={() => setPopUpDeleteProfile(true)} className="settings__item-label">
                    удалить
                </label>{' '}
                свой личный кабинет.
            </p>

            {popUpDeleteProfile && (
                <Modal toggle={setPopUpDeleteProfile}>
                    <PopUpConfirm
                        onConfirm={deleteProfile}
                        onClose={() => setPopUpDeleteProfile(false)}
                        contentHeader={
                            <span style={{ fontWeight: '500' }}>
                                Вы уверены, что хотите удалить свой личный кабинет? <br />
                                <span style={{ color: '#666666', fontSize: '18px' }}>Все данные будут удалены</span>
                            </span>
                        }
                        isClarification={false}
                    />
                </Modal>
            )}

            {popUpSuccessfulDeleteProfile && (
                <Modal toggle={setPopUpDeleteProfile}>
                    <PopUpAlert
                        onConfirm={successDeleteProfile}
                        contentHeader={<span style={{ fontWeight: '500' }}>Ваш аккаунт успешно удален</span>}
                        isClarification={true}
                    />
                </Modal>
            )}
        </PatientSettingsDiv>
    );
};

export default LkPatientSettings;
