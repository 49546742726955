import React from 'react';
import { fetchCity, resetCity } from '../../api/PatientProfileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { validateBirthdayInput, validateInputTextName } from '../../../share/formFields/validateInput';
import InputDate from '../../../share/formFields/InputDate';
import InputTel from '../../../share/formFields/InputTel';
import InputNameText from '../../../share/formFields/InputNameText';
import RadioGroup from '../../../share/formFields/RadioGroup';
import { EmailLKForm } from '../../../share/formFields/fieldWrapperStl.styled';
import SelectCity from '../../../share/formFields/selectCity/SelectCity';
import { clearMessage } from '../../../lkPatient/api/PatientProfileSlice';
import { useFormContext } from 'react-hook-form';

/**
 * Компонент EditDataList предоставляет форму для редактирования данных пользователя.

 * В форме используются различные кастомные поля ввода, такие как `InputText`, `InputDate`, `RadioGroup` и `InputTel`.
 * Валидация применяется к каждому полю с помощью функций валидации.
 * Выбор города применяется к полю `SelectCity` и получает список городов из API исходя из ввода символов.
 * Перед отправкой запроса на сервер о поиске города проверяется валидность поля (более 2х символов).
 *
 * @component
 * @param {Object} props - Пропсы компонента.
 * @param {Object} props.user - Объект пользователя, содержащий текущие данные, которые будут отображены и доступны для редактирования.
 * @param {string} props.user.first_name - Имя пользователя.
 * @param {string} props.user.last_name - Фамилия пользователя.
 * @param {string} [props.user.middle_name] - Отчество пользователя (опционально).
 * @param {string} props.user.date_birth - Дата рождения пользователя в формате 'ДД.ММ.ГГГГ'.
 * @param {string} props.user.sex - Пол пользователя ('М' - мужчина, 'Ж' - женщина).
 * @param {string} props.user.phone - Номер телефона пользователя.
 * @param {string} props.user.email - Электронная почта пользователя.
 * @param {number} [props.user.city] - Город пользователя (опционально).
 * @returns {JSX.Element} Форма для редактирования данных пользователя.
 *
 * @description
 * - Переменная `cityOptions` используется для хранения доступных городов, получаемых через запрос API.
 * - Поле города (`SelectCity`) автоматически подставляет выбранный город или использует значение по умолчанию.
 */

export const EditDataList = () => {
    const dispatch = useDispatch();
    const { control, watch } = useFormContext();
    const { cityOptionsForFetching, isPreloaderCity, messagesError } = useSelector((state) => state.patientProfile);

    const handleOnChange = (searchString) =>
        searchString?.length >= 2 ? dispatch(fetchCity(searchString)) : dispatch(resetCity());

    const handleClearError = (errorKey) => {
        dispatch(clearMessage(errorKey));
    };

    const cityValue = watch('user.city');

    return (
        <>
            <InputNameText
                name="user.last_name"
                label="Фамилия"
                placeholder="Иванов"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputNameText
                name="user.first_name"
                label="Имя"
                placeholder="Иван"
                validation={validateInputTextName(true)}
                isRequired={true}
                isFullName={true}
            />
            <InputNameText
                name="user.middle_name"
                label="Отчество"
                placeholder="Иванович"
                validation={validateInputTextName(false)}
                isFullName={true}
            />
            <div className="work__date">
                <InputDate
                    name="user.date_birth"
                    label="Дата рождения"
                    placeholder="ДД.ММ.ГГГГ"
                    mask="99.99.9999"
                    validation={validateBirthdayInput(true)}
                    isRequired={true}
                />
            </div>
            <RadioGroup name="user.sex" label="Пол" options={['Мужской', 'Женский']} isRequired={true} />
            <EmailLKForm>
                <p className="info__title">Email</p>
                <p className="info__data">{control._formValues.user?.email}</p>
            </EmailLKForm>
            <InputTel
                validation={{ pattern: false }}
                name="user.phone"
                label="Номер телефона"
                placeholder="+7 (999) 999-99-99"
                isRequired={true}
                messagesError={messagesError?.phone}
                onClearError={handleClearError}
                errorKey="phone"
            />
            <SelectCity
                name="user.city"
                label="Город"
                isCity={!!cityValue}
                placeholder={cityValue || 'Москва'}
                onChange={handleOnChange}
                options={cityOptionsForFetching.map((item) => ({ value: item.name, label: item.name, id: item.id }))}
                isPreloaderCity={isPreloaderCity}
                validation={{}}
                isRequired={false}
            />
        </>
    );
};
